export const templateGetDataCoordinator = {
  id: '_id',
  availability: 'availability',
  name: 'name',
  email: 'email',
  userName: 'user_name',
  phone: 'phone',
  role: 'role.name',
  company: 'company',
  createdAt: 'created_at',
  logoUrl: 'avatar'
};

export const templateSendDataCoordinator = {
  file: 'logo.file',
  user_name: 'userName',
  name: 'completeName',
  email: 'email',
  phone: 'contactPhone',
  role: 'role'
};

export const templateUpdateDataCoordinator = {
  file: 'logo.file',
  user_name: 'userName',
  name: 'completeName',
  email: 'email',
  phone: 'contactPhone'
};

export const templateGetHeaderCoordinator = {
  headerResponse: {
    code: 'headerResponse.code',
    message: 'headerResponse.message'
  }
};
