import { allNumbersInsideToString, getFormData } from 'utils/helpers';
import { responseMapper } from 'utils/responseMapper';
import { instance } from '../instance';
import {
  templateGetOrganizations,
  templateAddOrganization,
  templateUpdateOrganization,
  counterTemplateAddOrganization,
  counterTemplateUpdateOrganization,
  templateGetOrganizationById
} from '../mappers';

export const getOrganizations = async () => {
  const response = await instance.get('/clients');

  const mappedResponse = responseMapper({
    template: templateGetOrganizations,
    data: response.data
  });

  const {
    headerResponse: { code },
    payload: { items }
  } = mappedResponse;

  if (code !== 200) throw code;

  return items;
};

export const getOrganizationById = async (id) => {
  const response = await instance.get(`/clients/${id}`);

  const mappedResponse = responseMapper({
    template: templateGetOrganizationById,
    data: response.data
  });

  const {
    headerResponse: { code },
    payload
  } = mappedResponse;

  if (code !== 200) throw code;

  return payload;
};

export const addOrganization = async (payload) => {
  const mappedPayload = responseMapper({
    template: counterTemplateAddOrganization,
    data: allNumbersInsideToString(payload)
  });

  if (!mappedPayload.file) {
    delete mappedPayload.file;
  }

  const formData = getFormData(mappedPayload);

  const response = await instance.post('clients', formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });

  const mappedResponse = responseMapper({
    template: templateAddOrganization,
    data: response.data
  });

  const {
    headerResponse: { code },
    payload: responsePayload
  } = mappedResponse;

  if (code !== 200) throw code;

  return responsePayload;
};

export const updateOrganization = async (id, payload) => {
  const mappedPayload = responseMapper({
    template: counterTemplateUpdateOrganization,
    data: allNumbersInsideToString(payload)
  });

  if (!mappedPayload.file) {
    delete mappedPayload.file;
  }

  const formData = getFormData(mappedPayload);

  const response = await instance.patch(`/clients/${id}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });

  const mappedResponse = responseMapper({
    template: templateUpdateOrganization,
    data: response.data
  });

  const {
    headerResponse: { code },
    payload: responsePayload
  } = mappedResponse;

  if (code !== 200) throw code;

  return responsePayload;
};

export const deleteOrganization = async (id) => {
  const response = await instance.delete(`/clients/${id}`);
  const mappedResponse = responseMapper({
    template: templateUpdateOrganization,
    data: response.data
  });

  const {
    headerResponse: { code }
  } = mappedResponse;

  if (code !== 200) throw code;

  return id;
};
