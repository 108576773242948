/* eslint-disable no-underscore-dangle */
import { instance } from '../instance';
import { responseMapper } from 'utils/responseMapper';
import {
  templateUser,
  templateGetUser,
  templateRecoveryUser,
  templateUpdateInfoUser
} from '../mappers';
import { getFormData } from 'utils/helpers';

export const signInUser = async (user) => {
  const formData = await getFormData(user);

  const response = await instance.post('/v1/sign-in', formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });

  const {
    headerResponse: { code, message },
    payload
  } = responseMapper({
    template: templateUser,
    data: response.data
  });

  if (code !== 200) throw new Error(message);

  return payload;
};

export const logOut = async () => {
  const response = await instance.post('/v1/log-out');

  const {
    headerResponse: { code, message },
    payload
  } = responseMapper({
    template: templateUser,
    data: response.data
  });

  if (code !== 200) throw new Error(message);

  return payload;
};

export const getUser = async (id) => {
  const response = await instance.get(`/v1/users/${id}`, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
  const {
    headerResponse: { code, message },
    payload
  } = responseMapper({
    template: templateGetUser,
    data: response.data
  });

  if (code !== 200) throw new Error(message);

  return payload;
};

export const recoveryPassword = async (form, token) => {
  const formData = await getFormData(form);

  const response = await instance.post('/v1/reset-password', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`
    }
  });

  const { headerResponse } = responseMapper({
    template: templateRecoveryUser,
    data: response.data
  });

  return headerResponse;
};

export const changeUserPassword = async (form, token) => {
  const formData = await getFormData(form);

  const response = await instance.patch('/v1/reset-password', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`
    }
  });

  const { headerResponse } = responseMapper({
    template: templateRecoveryUser,
    data: response.data
  });

  return headerResponse;
};

export const updateDataUserConnected = async (data) => {
  const newData = responseMapper({
    template: templateUpdateInfoUser,
    data
  });

  const formData = await getFormData(newData);

  const response = await instance.patch('/v1/users', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

  return response;
};
