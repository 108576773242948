import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Container, Typography, Button } from '@material-ui/core';
import { messages } from './messages';
import './ErrorHTTP.scss';

function ErrorHTTP({ error }) {
  const history = useHistory();
  const handleNavigateToHome = () => history.push('/');

  return (
    <Container className="container-not-found" maxWidth="xl">
      <div>
        <Typography variant="h1">{error}</Typography>
        <Typography variant="h5" color="secondary">
          <FormattedMessage {...messages[`${error}Title`]} />
        </Typography>
        <Typography component="p" variant="subtitle2">
          <FormattedMessage {...messages[`${error}Description`]} />
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleNavigateToHome}
        >
          <FormattedMessage {...messages.button} />
        </Button>
      </div>
    </Container>
  );
}

export { ErrorHTTP };
