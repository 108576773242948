import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.Exhibition.title',
    defaultMessage: 'Exhibición'
  },
  subTitle: {
    id: 'components.Exhibition.subTitle',
    defaultMessage:
      'Listado de productos o servicios para promocionar en un evento.'
  },
  ok: {
    id: 'components.Exhibition.ok',
    defaultMessage: 'Ok'
  },
  successfullyDeleted: {
    id: 'components.Exhibition.success',
    defaultMessage: 'SE HA ELIMINADO EL PRODUCTO'
  },
  page: {
    id: 'components.Coordinators.page',
    defaultMessage: 'Página'
  }
});
