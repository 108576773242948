import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { SwatchesPicker } from 'react-color';
import {
  Button,
  Drawer,
  FormControl,
  Link,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@material-ui/core';
import { useLoader } from '@octopy/react-loader';
import { Images } from 'assets';
import { Close } from '@material-ui/icons';
import { useStyles } from './SideDrawerStyles';
import { messages } from './SideDrawerMessages';
import { TextField } from 'components/CreationForm';
import { FileInput } from 'components/CreationForm/Right/inputs/FileInput/FileInput';
import { useModal } from '@octopy/react-modal';
import { ModalText, ModalButton } from 'components/ModalMessages';
import { useFormik } from 'formik';
import { getValidationSchema } from './helpers';
import { responseMapper } from 'utils/responseMapper';
import { TemplateUpdatePartnersStand } from 'providers/api/mappers/partners';
import { updatePartnerStand } from '../../../providers/api/requests/partners';
import { Editor } from 'components/CreationForm/Right/inputs/Editor/Editor';
import { getPartnerById } from 'providers/api/requests/partners';

export const SideDrawer = ({ show, handleHide, edit, partnerS }) => {
  const [partner, setPartner] = useState({});
  const [hostVideo, sethostVideo] = useState('youtube');

  useEffect(async () => {
    const partnerStand = await getPartnerById(partnerS);

    setPartner(partnerStand);
    sethostVideo(partnerStand.stand?.plataform || '');
    formik.setValues(partnerStand);
  }, [show]);

  const { handleOpenModal } = useModal();
  const handleSuccessModal = () => {
    const modalTitle = (
      <ModalText>{intl.formatMessage(messages.success)}</ModalText>
    );
    const modalActionButtons = (
      <ModalButton>{intl.formatMessage(messages.ok)}</ModalButton>
    );

    handleOpenModal({
      title: modalTitle,
      actionButtons: modalActionButtons,
      configProps: { maxWidth: 'xs' }
    });
  };
  const allowedTypes = ['image/png', 'image/jpg', 'image/jpeg'];
  const { handleShowLoader } = useLoader();

  const handleImageModal = (url) => {
    const modalTitle = (
      <ModalText>
        <img src={url} alt="vista" className={classes.modalImage} />
      </ModalText>
    );
    const modalActionButtons = (
      <ModalButton>{intl.formatMessage(messages.close)}</ModalButton>
    );

    handleOpenModal({
      title: modalTitle,
      actionButtons: modalActionButtons,
      configProps: { maxWidth: 'xs' }
    });
  };

  const intl = useIntl();
  const classes = useStyles();

  const onSubmit = async (values) => {
    const newValues = {
      stand: {
        videoId: values.stand?.videoId.trim() || '',
        plataform: hostVideo || '',
        backgroud: values.stand?.backgroud || '',
        title: values.stand?.title || '',
        description: values.stand?.description || '',
        slogan: values.stand?.slogan || ''
      }
    };

    const mappedValues = responseMapper({
      template: TemplateUpdatePartnersStand,
      data: newValues
    });

    var formData = new FormData();

    for (var key in mappedValues) {
      formData.append(key, mappedValues[key]);
    }

    if (values.fileOne?.name) {
      formData.set(
        'stand_image_1',
        formik.values.fileOne,
        formik.values.fileOne.name
      );

      formik.setValues('fileOne', null);
    }

    if (values.fileTwo?.name) {
      formData.set(
        'stand_image_2',
        formik.values.fileTwo,
        formik.values.fileTwo.name
      );

      formik.setValues('fileTwo', null);
    }

    handleShowLoader(true);
    try {
      await updatePartnerStand(partner.id, formData);
    } catch (error) {
    } finally {
      handleShowLoader(false);
      handleSuccessModal();
      handleHide();
    }
  };

  const [displayColor, setdisplayColor] = useState(false);
  const handleChangeComplete = (color) => {
    formik.setFieldValue('stand.backgroud', color.hex);
    setdisplayColor(!displayColor);
  };
  const handleChangeDisplay = () => {
    setdisplayColor(!displayColor);
  };
  const handleSetHostVideo = (event) => {
    sethostVideo(event.target.value);
  };

  const formik = useFormik({
    initialValues: {},
    validationSchema: getValidationSchema(intl),
    onSubmit
  });

  const showSeeOne = () => {
    let see = null;

    if (partner.stand) {
      if (partner.stand.fileOne) {
        see = (
          <Link
            onClick={() => handleImageModal(partner.stand.fileOne)}
            className={classes.see}
          >
            {intl.formatMessage(messages['seeOne'])}
          </Link>
        );
      }

      return see;
    }
  };

  const showSeeTwo = () => {
    let see = null;

    if (partner.stand) {
      if (partner.stand.fileTwo) {
        see = (
          <Link
            onClick={() => handleImageModal(partner.stand.fileTwo)}
            className={classes.see}
          >
            {intl.formatMessage(messages['seeTwo'])}
          </Link>
        );
      }

      return see;
    }
  };

  return (
    <Drawer anchor="right" open={show} onClose={handleHide}>
      <div className={classes.drawer}>
        <div className={classes.closeIconContainer}>
          <Close className={classes.closeIcon} onClick={handleHide} />
        </div>
        <Typography className={classes.title}>
          {intl.formatMessage(messages[edit ? 'editTitle' : 'title'])}
        </Typography>
        <Typography className={classes.steps}>
          1. {intl.formatMessage(messages.oneStep)}
        </Typography>
        <FormControl className={classes.containerVideo}>
          <TextField
            key={'stand.videoIdInput'}
            name={'stand.videoId'}
            label={intl.formatMessage(messages['standUrlLabel'])}
            error={formik.errors.stand?.videoId}
            value={formik.values.stand?.videoId}
            onBlur={formik.handleBlur}
            touched={formik.touched.stand?.videoId}
            onChange={formik.handleChange}
          />
          <FormControl
            component="fieldset"
            className={classes.radioButtonsContainer}
          >
            <RadioGroup row>
              <div className={classes.centerRadio}>
                <FormControlLabel
                  value="1"
                  control={
                    <Radio
                      size="small"
                      checked={hostVideo === 'youtube'}
                      onChange={handleSetHostVideo}
                      value="youtube"
                      name="youtube"
                    />
                  }
                  labelPlacement="end"
                />
                <img
                  src={Images.youtube}
                  alt="youtube"
                  className={classes.sizeLogo}
                />
              </div>
              <div className={classes.centerRadio}>
                <FormControlLabel
                  value="2"
                  control={
                    <Radio
                      size="small"
                      checked={hostVideo === 'vimeo'}
                      onChange={handleSetHostVideo}
                      value="vimeo"
                      name="vimeo"
                    />
                  }
                  labelPlacement="end"
                />
                <img
                  src={Images.vimeo}
                  alt="vimeo"
                  className={classes.logoVimeo}
                />
              </div>
            </RadioGroup>
          </FormControl>
        </FormControl>
        <Typography className={classes.steps}>
          2. {intl.formatMessage(messages.twoStep)}
        </Typography>
        <TextField
          autoComplete="off"
          key={'stand.BackgroudInput'}
          onClick={handleChangeDisplay}
          value={formik.values.stand?.backgroud}
          label="Hex"
          name={'stand.backgroud'}
        />
        {displayColor ? (
          <SwatchesPicker onChangeComplete={handleChangeComplete} />
        ) : null}
        <Typography className={classes.steps}>
          3. {intl.formatMessage(messages.threeStep)}
        </Typography>
        <Editor
          defaultValue={formik.values.stand?.title}
          controls={[
            'title',
            'bold',
            'italic',
            'underline',
            'numberList',
            'bulletList'
          ]}
          onBlur={(value) => formik?.setFieldValue('stand.title', value)}
          inlineToolbar={true}
          className={classes.textEditor}
        />
        <Typography className={classes.steps}>
          4. {intl.formatMessage(messages.fourStep)}
        </Typography>
        <Editor
          defaultValue={formik.values.stand?.description}
          controls={[
            'title',
            'bold',
            'italic',
            'underline',
            'numberList',
            'bulletList'
          ]}
          onBlur={(value) => formik?.setFieldValue('stand.description', value)}
          inlineToolbar={true}
          className={classes.textEditor}
        />
        <Typography className={classes.steps}>
          5. {intl.formatMessage(messages.fiveStep)}
        </Typography>
        <Editor
          defaultValue={formik.values.stand?.slogan}
          controls={[
            'title',
            'bold',
            'italic',
            'underline',
            'numberList',
            'bulletList'
          ]}
          onBlur={(value) => formik.setFieldValue('stand.slogan', value)}
          inlineToolbar={true}
          className={classes.textEditor}
        />
        <Typography className={classes.steps}>
          6. {intl.formatMessage(messages.sixStep)}
        </Typography>
        {showSeeOne()}

        <FileInput
          id="file1"
          label={intl.formatMessage(messages.uploadButtonLabel)}
          fileName={formik.values.fileOne?.name}
          accept="image/png, image/gif, image/jpeg"
          acceptType={allowedTypes}
          onChange={({ file }) => formik.setFieldValue('fileOne', file)}
        />
        <Typography className={classes.steps}>
          7. {intl.formatMessage(messages.sevenStep)}
        </Typography>
        {showSeeTwo()}
        <FileInput
          id="file2"
          label={intl.formatMessage(messages.uploadButtonLabel)}
          fileName={formik.values.fileTwo?.name}
          accept="image/png, image/gif, image/jpeg"
          acceptType={allowedTypes}
          onChange={({ file }) => formik.setFieldValue('fileTwo', file)}
        />
        <div className={classes.submitButtonContainer}>
          <Button
            variant="contained"
            color="primary"
            className={`${classes.button}`}
            onClick={formik.handleSubmit}
          >
            {intl.formatMessage(
              messages[edit ? 'editSubmitButtonLabel' : 'submitButtonLabel']
            )}
          </Button>
        </div>
      </div>
    </Drawer>
  );
};
