import * as yup from 'yup';
import { messages } from './RightMessages';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const getValidationSchema = (intl) => {
  const requiredErrorMessage = intl.formatMessage(messages.isRequiredError);
  const PhoneErrorMessage = intl.formatMessage(messages.errorPhone);

  return yup.object({
    businessName: yup.string().trim().required(requiredErrorMessage),
    email: yup.string().email().required(requiredErrorMessage),
    contactName: yup.string().trim().required(requiredErrorMessage),
    country: yup.string(),
    contactPhone: yup.string().matches(phoneRegExp, PhoneErrorMessage)
  });
};
