import { useSideDrawer } from 'hooks/useSideDrawer';
import { useState } from 'react';

export const useSideForm = () => {
  const drawer = useSideDrawer();
  const [eventId, setEventId] = useState(null);

  const handleShow = (eventId = null) => {
    setEventId(eventId);
    drawer.handleShow();
  };
  const handleHide = () => {
    drawer.handleHide();
    setEventId(null);
  };

  return {
    visible: drawer.visible,
    handleShow,
    handleHide,
    eventId,
    setEventId
  };
};
