import { Typography } from '@material-ui/core';
import { processFile } from 'utils/helpers';
import { useStyles } from './ImagePickerStyles';

const allowedTypes = ['image/png', 'image/jpg', 'image/jpeg'];

export const ImagePicker = ({
  Icon,
  label,
  onChange,
  imageUrl,
  className,
  disabled,
  error,
  id = '',
  ...props
}) => {
  const classes = useStyles();

  const handleChange = ({ target: { files } }) => {
    files.length &&
      allowedTypes.includes(files[0].type) &&
      onChange(processFile(files[0]));
  };

  return (
    <span className={classes.container}>
      <label
        htmlFor={`fileInput${id}`}
        className={`${classes.picker} ${
          imageUrl && classes.pickerWithImage
        } ${className} ${disabled && 'disabled'} ${!!error && 'error'}`}
      >
        {imageUrl ? (
          <img src={imageUrl} className={classes.image} alt="imageFromUser" />
        ) : (
          <>
            <Icon className={classes.defaultAvatar} />
            <Typography>{label}</Typography>
          </>
        )}
        <input
          accept={allowedTypes.join(', ')}
          type="file"
          id={`fileInput${id}`}
          onChange={handleChange}
          className={classes.fileInput}
          disabled={disabled}
          {...props}
        />
      </label>
      {!!error && <span className={classes.errorMessage}>{error}</span>}
    </span>
  );
};
