import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.Analytics.title',
    defaultMessage: 'Mis Analíticos'
  },
  subtitle: {
    id: 'components.Analytics.subtitle',
    defaultMessage: 'Estadísticas recopiladas del evento más reciente.'
  }
});
