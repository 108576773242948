import * as yup from 'yup';
import { addHours } from '../../../../utils/helpers';
import { messages } from './CreateMessages';

export const initialValues = {
  eventId: '',
  conferences: [
    {
      key: 0,
      image: {
        file: null,
        blobUrl: ''
      },
      speakerName: '',
      speakerResume: '',
      speakerEducation: '',
      speakerBackground: '',
      date: '',
      time: addHours(new Date(), 2),
      description: '',
      streamId: '',
      streamPlatform: 'vimeo',
      chatIframe: ''
    }
  ]
};

export const getValidationSchema = (intl) => {
  const requiredMessage = intl.formatMessage(messages.requiredError);

  return yup.object({
    eventId: yup.string().trim().required(requiredMessage),
    conferences: yup.array().of(
      yup.object({
        speakerName: yup.string().trim().required(requiredMessage),
        speakerResume: yup.string().trim().required(requiredMessage),
        speakerEducation: yup.string().trim().required(requiredMessage),
        speakerBackground: yup.string().required(requiredMessage),
        date: yup.string().required(requiredMessage),
        time: yup.string().required(requiredMessage),
        description: yup.string().required(requiredMessage),
        streamId: yup.string().trim().required(requiredMessage),
        streamPlatform: yup.string().required(requiredMessage),
        chatIframe: yup.string().trim().required(requiredMessage)
      })
    )
  });
};
