import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Typography, Grid, Box, MenuItem, Select } from '@material-ui/core';
import { messages } from './DatabaseMessages';
import { useStyles } from './DatabaseStyles';
import { UploadCard } from './UploadCard';
import { DatabaseCard } from './DatabaseCard';
import { SideForm } from './SideForm';
import { useSideForm } from './SideForm/hooks/useSideForm';
import { useLoader } from '@octopy/react-loader';
import { useModal } from '@octopy/react-modal';
import { getDataError, createErrorModalConfig } from 'utils/helpers';
import { getDatabases } from 'providers/api/requests/database';
import { useNoEvents } from 'components/NoEvents/hooks/useNoEvents';
import { usePagination } from 'hooks/usePagination';
import { Pagination } from '@material-ui/lab';
import { ExpandMore } from '@material-ui/icons';

export const Database = () => {
  const intl = useIntl();
  const classes = useStyles();

  const { show: showNoEvents } = useNoEvents();
  const { handleShowLoader } = useLoader();
  const { handleOpenModal } = useModal();
  const sideForm = useSideForm();
  const {
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage
  } = usePagination();

  const [databases, setDatabases] = useState({});

  useEffect(() => {
    (async () => {
      try {
        handleShowLoader(true);
        const databases = await getDatabases();

        const databasesGroupedByEvents = databases.reduce((databases, file) => {
          const eventId = file.eventId;

          return {
            ...databases,
            [eventId]: [...(databases[eventId] ?? []), file]
          };
        }, {});

        setDatabases(databasesGroupedByEvents);
      } catch (request) {
        const { code, message } = getDataError(request);

        handleOpenModal(createErrorModalConfig(code, message));
      } finally {
        handleShowLoader(false);
      }
    })();
  }, []);

  return (
    <>
      <SideForm {...sideForm} setDatabases={setDatabases} />
      <div className={`${classes.container} ${!showNoEvents && 'minHeight'}`}>
        <div className={classes.header}>
          <Typography className={classes.title}>
            {intl.formatMessage(messages.title)}
          </Typography>
          <Typography className={classes.subtitle}>
            {intl.formatMessage(messages.subtitle)}
          </Typography>
        </div>
        {!showNoEvents && (
          <>
            <Box className={classes.cardsContainer}>
              <Grid container justify="flex-start" spacing={3}>
                {page === 0 && (
                  <UploadCard handleShowSideForm={sideForm.handleShow} />
                )}
                {Object.keys(databases)
                  .slice(
                    page * rowsPerPage - +!!page,
                    page * rowsPerPage + rowsPerPage - 1
                  )
                  .map((eventId) => (
                    <DatabaseCard
                      key={`${eventId}Database`}
                      handleShowSideForm={sideForm.handleShow}
                      eventId={eventId}
                      database={databases[eventId]}
                    />
                  ))}
              </Grid>
            </Box>
            {!!Object.keys(databases).length && (
              <div className={classes.paginationContainer}>
                <Pagination
                  count={Math.ceil(
                    (Object.keys(databases).length + 1) / rowsPerPage
                  )}
                  page={page + 1}
                  onChange={handleChangePage}
                  defaultPage={0}
                  size="small"
                  variant="outlined"
                  shape="rounded"
                />
                <Select
                  className={classes.paginationSelect}
                  onChange={handleChangeRowsPerPage}
                  value={rowsPerPage}
                  IconComponent={ExpandMore}
                >
                  {[3, 9, 15, 21, 27].map((n) => (
                    <MenuItem key={`${n}PaginationOption`} value={n}>
                      {n} / {intl.formatMessage(messages.page)}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};
