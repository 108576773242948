import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.Profile.title',
    defaultMessage: 'Mi perfil'
  },
  subscriptionState: {
    id: 'components.Profile.subscriptionState',
    defaultMessage: 'Estado de suscripción:'
  },
  email: {
    id: 'components.Profile.email',
    defaultMessage: 'Correo electrónico: '
  },
  activationDate: {
    id: 'components.Profile.activationDate',
    defaultMessage: 'Fecha de activación: '
  },
  eventTypes: {
    id: 'components.Profile.eventTypes',
    defaultMessage: 'Tipo de eventos: '
  },
  license: {
    id: 'components.Profile.license',
    defaultMessage: 'Licenciamiento: '
  },
  currentPasswordLabel: {
    id: 'components.Profile.input.currentPassword.label',
    defaultMessage: 'Contraseña actual'
  },
  newPasswordLabel: {
    id: 'components.Profile.input.newPassword.label',
    defaultMessage: 'Nueva contraseña'
  },
  newPasswordConfirmationLabel: {
    id: 'components.Profile.input.newPasswordConfirmation.label',
    defaultMessage: 'Confirmar nueva contraseña'
  },
  requiredError: {
    id: 'components.Profile.input.error.required',
    defaultMessage: 'Este campo es requerido'
  },
  changePasswordButtonLabel: {
    id: 'components.Profile.button.changePassword.label',
    defaultMessage: 'Actualizar'
  },
  closeSessionButtonLabel: {
    id: 'components.Profile.button.closeSession.label',
    defaultMessage: 'Cerrar sesión'
  },
  passwordSuccessfullyChanged: {
    id: 'components.Profile.modal.passwordSuccessfullyChanged',
    defaultMessage: 'La contraseña ha sido actualizada correctamente'
  },
  differentPassword: {
    id: 'components.Profile.modal.differentPassword',
    defaultMessage: 'La contraseña de verificación no coincide'
  },
  invalidEmailError: {
    id: 'components.Profile.modal.invalidEmailError',
    defaultMessage: 'Correo electrónico no válido'
  },
  minLengthError: {
    id: 'components.Profile.modal.minLengthError',
    defaultMessage: 'Mínimo 8 caracteres'
  },
  ok: {
    id: 'components.Profile.modal.ok',
    defaultMessage: 'Ok'
  },
  emailLabel: {
    id: 'components.Profile.modal.emailLabel',
    defaultMessage: 'Correo electrónico'
  },
  changesMadeSuccessfully: {
    id: 'components.Profile.modal.changesMadeSuccessfully',
    defaultMessage: 'LOS DATOS HAN SIDO ACTUALIZADOS CORRECTAMENTE'
  }
});
