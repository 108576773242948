import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
  const iconContainer = {
    flexWrap: 'nowrap',
    cursor: 'pointer',
    '&:hover $actionButtonImageContainer': {
      backgroundColor: theme.palette.grey[200]
    }
  };

  return {
    container: {
      height: '169px',
      boxShadow: '0px 0px 5px #00000029',
      borderRadius: '5px',
      maxWidth: '400px',
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    imageMaskContainer: {
      height: '80px',
      width: '80px',
      borderRadius: '50%',
      backgroundColor: '#fff',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: theme.spacing(2),
      boxShadow: '0px 3px 6px #00000029',
      overflow: 'hidden'
    },
    imageMask: ({ icon }) => ({
      height: '60%',
      width: '60%',
      backgroundColor: '#009FDA',
      transition: 'all 300ms',
      maskImage: `url("${icon}")`,
      maskSize: '41px',
      maskRepeat: 'no-repeat',
      maskPosition: 'center'
    }),
    name: {
      font: 'normal normal bold 15px/20px Roboto',
      marginBottom: theme.spacing(0.5)
    },
    date: {
      font: 'normal normal normal 15px/20px Roboto',
      color: '#989898'
    },
    info: {
      height: '75%',
      display: 'flex',
      padding: theme.spacing(2)
    },
    actions: {
      height: '25%',
      display: 'flex',
      alignItems: 'center',
      borderTop: '1px solid #E6E6E6'
    },
    image: {
      height: '100%',
      width: '100%',
      objectFit: 'cover'
    },
    actionsContainer: {
      paddingLeft: theme.spacing(2)
    },
    edit: {
      color: theme.palette.secondary.main,
      ...iconContainer
    },
    delete: {
      color: '#989898',
      ...iconContainer
    },
    actionButtonImageContainer: {
      borderRadius: '50%',
      padding: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '5px',
      boxSizing: 'content-box',
      transition: 'all 300ms'
    },
    actionButtonImage: {
      width: '21px',
      height: '21px'
    },
    event: {
      color: theme.palette.secondary.main,
      font: 'normal normal normal 15px/20px Roboto'
    },
    points: {
      color: theme.palette.secondary.main,
      font: 'normal normal normal 15px/20px Roboto',
      display: 'flex',
      alignItems: 'center',
      '& img': {
        height: '20px',
        widht: '20px',
        marginRight: '4px'
      },
      '& span': {
        marginTop: '4px',
        boxSizing: 'border-box'
      }
    }
  };
});

export { useStyles };
