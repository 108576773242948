import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: ({ bgLogin }) => ({
    flex: 1,
    width: '100%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundImage: `url("${bgLogin}")`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  }),
  recoveryPassword: {
    color: 'white',
    '&:hover': {
      color: theme.palette.secondary.main
    }
  },
  button: {
    background: theme.palette.secondary.main,
    width: '100%',
    '&:hover': {
      background: theme.palette.secondary.main,
      width: '100%',
      color: theme.palette.common.white
    },
    '& p': {
      color: theme.palette.common.white
    }
  }
}));

export { useStyles };
