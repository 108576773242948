import { useState } from 'react';
import { MenuContext } from './MenuContext';

export const MenuProvider = ({ children }) => {
  const [menuVisibility, setMenuVisibility] = useState(false);

  const hideMenu = () => setMenuVisibility(false);
  const openMenu = () => setMenuVisibility(true);

  const contextValue = {
    menuVisibility,
    hideMenu,
    openMenu
  };

  return (
    <MenuContext.Provider value={contextValue}>{children}</MenuContext.Provider>
  );
};
