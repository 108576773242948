import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.Analytics.PartnersByEvents.title',
    defaultMessage: 'Numero de patrocinadores'
  },
  yTitle: {
    id: 'components.Analytics.PartnersByEvents.yLabel',
    defaultMessage: 'Partners'
  }
});
