import * as yup from 'yup';

export const initialValues = {
  rankingCategories: {
    initial: {
      name: '',
      minScore: 0,
      maxScore: 0
    },
    following: [
      {
        name: '',
        minScore: 0,
        maxScore: 0
      }
    ]
  }
};

export const getValidationSchema = ({ requiredMessage }) =>
  yup.object({
    rankingCategories: yup.object({
      initial: yup.object({
        name: yup.string().required(requiredMessage),
        minScore: yup
          .number()
          .typeError('Debe ser números')
          .required(requiredMessage),
        maxScore: yup
          .number('Debe ser números')
          .required(requiredMessage)
          .moreThan(yup.ref('minScore'), 'Debe ser mayor al valor  mínimo')
      }),
      following: yup.array().of(
        yup.object({
          name: yup.string().required(requiredMessage),
          minScore: yup
            .number()
            .typeError('Debe ser números')
            .required(requiredMessage),
          maxScore: yup
            .number()
            .typeError('Debe ser números')
            .required(requiredMessage)
            .moreThan(yup.ref('minScore'), 'Debe ser mayor al valor  mínimo')
        })
      )
    })
  });
