import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Typography, Grid, Box, Select, MenuItem } from '@material-ui/core';
import { messages } from './EventsMessages';
import { useStyles } from './EventsStyles';
import { Edit as EventCard } from 'components/Events/Cards/Edit';
import { Create as CreateEventCard } from 'components/Events/Cards/Create';
import { useDashboard } from 'components/Dashboard';
import { RefreshControl } from 'components/RefreshControl/RefreshControl';
import { useNoEvents } from 'components/NoEvents/hooks/useNoEvents';
import { Pagination } from '@material-ui/lab';
import { ExpandMore } from '@material-ui/icons';
import { usePagination } from 'hooks/usePagination';

const Events = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { events, fetchEvents, fetchPartners } = useDashboard();
  const { handleNoEventShow, show } = useNoEvents();

  const getData = () => {
    fetchEvents();
    fetchPartners();
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    handleNoEventShow(false);
  }, [show]);

  const {
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage
  } = usePagination();

  return (
    <>
      <Grid>
        <Grid container direction="row" justify="space-between">
          <Grid>
            <Typography className={classes.title}>
              {intl.formatMessage(messages.title)}
            </Typography>
            <Typography className={classes.subTitle}>
              {intl.formatMessage(messages.subTitle)}
            </Typography>
          </Grid>
          <RefreshControl refreshFunction={getData} />
        </Grid>
        <Box className={classes.cardsContainer}>
          <Grid container justify="flex-start" spacing={3}>
            {page === 0 && <CreateEventCard />}

            {events
              .slice(
                page * rowsPerPage - +!!page,
                page * rowsPerPage + rowsPerPage - 1
              )
              .map((eventData, index) => (
                <EventCard
                  key={eventData.id}
                  idEvent={eventData.id}
                  event={eventData}
                  index={index}
                />
              ))}
          </Grid>
        </Box>
        <div className={classes.paginationContainer}>
          <Pagination
            count={Math.ceil((events.length + 1) / rowsPerPage)}
            page={page + 1}
            onChange={handleChangePage}
            defaultPage={0}
            size="small"
            variant="outlined"
            shape="rounded"
          />
          <Select
            className={classes.paginationSelect}
            onChange={handleChangeRowsPerPage}
            value={rowsPerPage}
            IconComponent={ExpandMore}
          >
            {[3, 9, 15, 21, 27].map((n) => (
              <MenuItem key={`${n}PaginationOption`} value={n}>
                {n} / {intl.formatMessage(messages.page)}
              </MenuItem>
            ))}
          </Select>
        </div>
      </Grid>
    </>
  );
};

export { Events };
