import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    height: '266px',
    boxShadow: '0px 0px 5px #00000029',
    borderRadius: '5px',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(2)
  },
  title: {
    font: 'normal normal bold 16px/21px Roboto',
    color: '#323232'
  },
  gridItem: {
    overflow: 'hidden'
  }
}));
