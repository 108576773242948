import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.Principal.CreateConference.title',
    defaultMessage: 'AGREGAR'
  },
  subtitle: {
    id: 'components.Principal.CreateConference.subtitle',
    defaultMessage: 'Conferencia'
  }
});
