import { responseMapper } from 'utils/responseMapper';
import { instance } from '../instance';
import { templateGetAnalytics } from '../mappers';

export const getAnalytics = async () => {
  const response = await instance.get('/analytics');
  const mappedResponse = responseMapper({
    template: templateGetAnalytics,
    data: response.data
  });

  const {
    headerResponse: { code },
    payload
  } = mappedResponse;

  if (code !== 200) throw code;

  return payload;
};
