/* eslint-disable no-underscore-dangle */
import { instance } from '../instance';
import { responseMapper } from 'utils/responseMapper';
import {
  templateGetDataQuizzes as templateGetQuizzes,
  templateGetByIdQuiz as templateGetDataById
} from '../mappers';
import { castRightAnswerQuiz } from 'utils/helpers';

export const getQuizzes = async () => {
  const response = await instance.get('/quizzes');

  const payload = responseMapper({
    template: templateGetQuizzes,
    data: response.data.payload
  });
  const code = response.data.headerResponse.code;

  if (code !== 200) throw new Error(code);

  return payload.quizzes;
};

export const getQuizzById = async (id) => {
  const response = await instance.get(`/events/${id}/quizzes`);
  const code = response.data.headerResponse.code;

  if (code !== 200) throw new Error(code);

  const payload = responseMapper({
    template: templateGetDataById,
    data: response.data.payload.items
  });

  if (payload.length > 0) return payload[0];

  return payload;
};

export const postQuiz = async (payload) => {
  const auxObject = castRightAnswerQuiz(payload);
  const jsonQuiz = JSON.stringify(auxObject);

  const response = await instance.post('/quizzes', jsonQuiz, {
    headers: { 'Content-Type': 'application/json' }
  });

  const code = response.data.headerResponse.code;

  if (code !== 200) throw new Error(code);

  return;
};

export const deleteQuizz = async (id) => {
  const response = await instance.delete(`/quizzes/${id}`);
  const code = response.data.headerResponse.code;

  if (code !== 200) throw new Error(code);

  return {};
};

export const updateQuizById = async (id, payload) => {
  const auxObject = castRightAnswerQuiz(payload);
  const jsonQuiz = JSON.stringify(auxObject);

  const response = await instance.patch(`/quizzes/${id}`, jsonQuiz, {
    headers: { 'Content-Type': 'application/json' }
  });

  const code = response.data.headerResponse.code;

  if (code !== 200) throw new Error(code);

  return {};
};
