import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    marginLeft: 'auto',
    marginRight: 'auto',
    background: '#2AA4D5',
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '400px',
    height: '169px',
    boxShadow: '0px 0px 5px #00000029',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'all 300ms',
    '&:hover': {
      backgroundColor: '#000000',
      '& $imageMask': {
        transition: '.3s ease',
        backgroundColor: '#000000'
      }
    }
  },
  disableLink: {
    textDecoration: 'none'
  },
  content: {
    marginTop: '15px',
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    width: '100%'
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '38px',
    boxShadow: '0px 0px 5px #00000029',
    borderTop: '1px solid #D9E1FF'
  },
  info: {
    marginLeft: '16px'
  },
  imgBox: {
    marginLeft: '14px',
    display: 'flex',
    background: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 3px 6px #00000029',
    height: '80px',
    width: '80px',
    borderRadius: '40px'
  },
  title: {
    font: 'normal normal bold 15px/20px Roboto',
    color: '#FFFFFF'
  },
  subTitle: {
    font: 'normal normal normal 15px/20px Roboto',
    color: '#FFFFFF7D'
  },
  addBox: {
    height: '38px',
    borderRadius: '20px',
    background: 'transparent'
  },
  addIcon: {
    color: 'white',
    width: '35px',
    height: '35px'
  },
  imageMask: ({ icon }) => ({
    transition: 'all 300ms',
    width: '40px',
    height: '45px',
    background: '#2AA4D5',
    maskImage: `url("${icon}")`,
    maskSize: '30px',
    maskRepeat: 'no-repeat',
    maskPosition: 'center'
  })
}));

export { useStyles };
