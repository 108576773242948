/* eslint-disable no-underscore-dangle */
import { instance } from '../instance';
import { responseMapper } from 'utils/responseMapper';
import {
  templateGetDataChallenge as templateGetData,
  templateGetDataChallengeSaved as templateGetDataSaved,
  templateGetHeaderChallenge as templateGetHeader,
  responseTemplateAddPolicies,
  responseTemplateAddCategories,
  responseTemplateAddChallenge,
  responseTemplateUpdateChallenge,
  templateGetDataPolicies,
  templateGetDataCategories
} from '../mappers';

export const getChallenges = async () => {
  const response = await instance.get('/challenges');

  const resultMappped = responseMapper({
    template: templateGetData,
    data: response.data
  });

  const {
    headerResponse: { code },
    payload: { items }
  } = resultMappped;

  if (code !== 200) throw code;

  return items;
};

export const getChallengesSaved = async (id) => {
  const response = await instance.get(`/challenges?event=${id}`);

  const resultMappped = responseMapper({
    template: templateGetDataSaved,
    data: response.data
  });

  const {
    headerResponse: { code },
    payload: { items }
  } = resultMappped;

  if (code !== 200) throw code;

  return items;
};

export const getPolicies = async (id) => {
  const response = await instance.get(`/challenges/policies/${id}`);

  const resultMappped = responseMapper({
    template: templateGetDataPolicies,
    data: response.data
  });

  const {
    headerResponse: { code }
  } = resultMappped;

  if (code !== 200) throw code;

  return resultMappped;
};

export const getCategories = async (id) => {
  const response = await instance.get(`/challenges/categories/${id}`);

  const resultMappped = responseMapper({
    template: templateGetDataCategories,
    data: response.data
  });

  const {
    headerResponse: { code }
  } = resultMappped;

  if (code !== 200) throw code;

  return resultMappped.categories;
};

export const postChallenge = async (payload) => {
  const response = await instance.post('/v1/challenges', payload);
  const {
    headerResponse: { code, message }
  } = responseMapper({
    template: templateGetHeader,
    data: response
  });

  if (code !== 200) throw new Error(message);

  return {};
};

export const deleteChallenge = async (id) => {
  const response = await instance.delete(`/v1/challenges/${id}`);
  const {
    headerResponse: { code, message }
  } = responseMapper({
    template: templateGetHeader,
    data: response
  });

  if (code !== 200) throw new Error(message);

  return {};
};

export const updateChallengeById = async (id, payload) => {
  const response = await instance.patch(
    `/challenges/relation/event/${id}`,
    payload
  );

  const {
    headerResponse: { code }
  } = responseMapper({
    template: responseTemplateUpdateChallenge,
    data: response
  });

  return response;
};

export const addPolicie = async (payload) => {
  const response = await instance.post('/challenges/events/policies', payload);
  const mappedResponse = responseMapper({
    template: responseTemplateAddPolicies,
    data: response.data
  });

  const {
    headerResponse: { code }
  } = mappedResponse;

  if (code !== 200) throw code;

  return response;
};

export const addCategories = async (payload) => {
  const response = await instance.post('/challenges/categories', payload);
  const mappedResponse = responseMapper({
    template: responseTemplateAddCategories,
    data: response.data
  });

  const {
    headerResponse: { code },
    payload: responsePayload
  } = mappedResponse;

  if (code !== 200) throw code;

  return responsePayload;
};

export const addChallenge = async (payload) => {
  const response = await instance.post('/challenges/relation/event', payload);
  const mappedResponse = responseMapper({
    template: responseTemplateAddChallenge,
    data: response.data
  });

  const {
    headerResponse: { code },
    payload: responsePayload
  } = mappedResponse;

  if (code !== 200) throw code;

  return responsePayload;
};
