import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  edit: {
    id: 'component.Organizations.OrganizationCard.actionButton.edit.label',
    defaultMessage: 'Editar'
  },
  delete: {
    id: 'component.Organizations.OrganizationCard.actionButton.delete.label',
    defaultMessage: 'Eliminar'
  },
  areYouSureDelete: {
    id: 'component.Organizations.OrganizationCard.modal.delete.text',
    defaultMessage: '¿Estas seguro de querer eliminar el usuario:'
  }
});
