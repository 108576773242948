import { useEffect, useState } from 'react';
import { FileInput } from 'components/CreationForm';
import { useIntl } from 'react-intl';
import { useStyles } from './AwardPoliciesStyles';
import { messages } from './AwardPoliciesMessages';
import { Editor } from 'components/CreationForm/Right/inputs/Editor/Editor';
import { SubmitButton } from 'components/CreationForm';
import { useDashboard } from 'components/Dashboard';
import { useFormik } from 'formik';
import { getValidationSchema } from './helpers';
import { withRouter } from 'react-router';

export const AwardPolicies = withRouter(({ eventSelect, history }) => {
  const intl = useIntl();
  const classes = useStyles();
  const [showButtonFile, setshowButtonFile] = useState(true);
  const { policies, addNewPolicie, fetchPolicies } = useDashboard();
  const errorMessages = {
    requiredMessage: intl.formatMessage(messages.requiredError)
  };

  useEffect(async () => {
    const response = await fetchPolicies(eventSelect);
  }, [eventSelect]);

  useEffect(() => {
    if (!!policies) {
      formik.setValues({ value: policies.value });
      setshowButtonFile(true);
    } else {
      formik.setValues({});
      setshowButtonFile(false);
    }
  }, [policies]);

  const submit = async (values) => {
    var formData = new FormData();

    formData.append('event', eventSelect);
    formData.append('policies', values.value);

    const error = await addNewPolicie(formData);

    if (!!!error) history.push('/Challenge');
  };

  const formik = useFormik({
    isInitialValid: false,
    enableReinitialize: true,
    validationSchema: getValidationSchema(errorMessages),
    initialValues: {},

    onSubmit: submit
  });

  const setPolicies = async (value) => {
    let valor = JSON.parse(value);

    valor = valor.blocks[0].text;

    if (!valor) {
      setshowButtonFile(false);
    } else {
      setshowButtonFile(true);
    }

    await formik.setValues({ value });
  };

  return (
    <>
      <Editor
        defaultValue={formik.values.value}
        label={intl.formatMessage(messages.editorLabel)}
        controls={[
          'title',
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'numberList',
          'bulletList',
          'save'
        ]}
        onBlur={(value) => setPolicies(value)}
        inlineToolbar={true}
      />

      {showButtonFile && (
        <SubmitButton
          onClick={formik.handleSubmit}
          className={classes.submitButton}
        >
          {intl.formatMessage(messages.submit)}
        </SubmitButton>
      )}
    </>
  );
});
