import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '169px',
    boxShadow: '0px 0px 5px #00000029',
    borderRadius: '5px',
    maxWidth: '400px',
    marginLeft: 'auto',
    cursor: 'pointer',
    marginRight: 'auto'
  },
  iconContainer: {
    height: '80px',
    width: '80px',
    borderRadius: '50%',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(2),
    boxShadow: '0px 3px 6px #00000029',
    overflow: 'hidden'
  },
  textContainer: {
    width: 'calc(100% - 100px)'
  },
  name: {
    font: 'normal normal bold 15px/20px Roboto',
    marginBottom: theme.spacing(0.5),
    textOverflow: 'ellipsis',
    display: 'block',
    overflow: 'hidden'
  },
  date: {
    font: 'normal normal normal 15px/20px Roboto',
    color: '#989898'
  },
  info: {
    height: '75%',
    display: 'flex',
    padding: theme.spacing(2)
  },
  actions: {
    height: '25%',
    display: 'flex',
    alignItems: 'center',
    borderTop: '1px solid #E6E6E6'
  },
  imageMaskContainer: {
    height: '80px',
    width: '80px',
    borderRadius: '50%',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(2),
    boxShadow: '0px 3px 6px #00000029',
    overflow: 'hidden'
  },
  imageMask: ({ icon }) => ({
    height: '60%',
    width: '60%',
    backgroundColor: '#009FDA',
    transition: 'all 300ms',
    maskImage: `url("${icon}")`,
    maskSize: '41px',
    maskRepeat: 'no-repeat',
    maskPosition: 'center'
  }),
  actionsContainer: {
    paddingLeft: theme.spacing(2)
  },
  download: {
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    display: 'flex',
    flexWrap: 'nowrap',
    '&:hover svg': {
      backgroundColor: theme.palette.grey[200]
    }
  },
  icon: {
    borderRadius: '50%',
    padding: '5px',
    fontSize: '39px',
    transition: 'all 200ms',
    marginRight: '5px'
  }
}));

export { useStyles };
