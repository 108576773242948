import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.Analytics.NextEvents.title',
    defaultMessage: 'Próximos eventos'
  },
  country: {
    id: 'components.Analytics.NextEvents.country',
    defaultMessage: 'País: '
  },
  time: {
    id: 'components.Analytics.NextEvents.time',
    defaultMessage: 'Hora: '
  },
  type: {
    id: 'components.Analytics.NextEvents.type',
    defaultMessage: 'Evento: '
  },
  noNextEvents: {
    id: 'components.Analytics.NextEvents.noNextEvents',
    defaultMessage: 'No hay proximos eventos para mostrar'
  }
});
