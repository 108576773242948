import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  logoUrlLabel: {
    id: 'components.Coordinators.Forms.Edit.Right.logoUrlLabel',
    defaultMessage: 'Seleccionar'
  },
  userNameLabel: {
    id: 'components.Coordinators.Forms.Edit.Right.userNameLabel',
    defaultMessage: 'Nombre de usuario'
  },
  userNamePlaceholder: {
    id: 'components.Coordinators.Forms.Edit.Right.userNamePlaceholder',
    defaultMessage: 'Ingresar nombre de usuario'
  },
  completeNameLabel: {
    id: 'components.Coordinators.Forms.Edit.Right.completeNameLabel',
    defaultMessage: 'Nombre completo'
  },
  completeNamePlaceholder: {
    id: 'components.Coordinators.Forms.Edit.Right.completeNamePlaceholder',
    defaultMessage: 'Ingresar razon social'
  },
  contactPhoneLabel: {
    id: 'components.Coordinators.Forms.Edit.Right.contactPhoneLabel',
    defaultMessage: 'Teléfono de contacto'
  },
  contactPhonePlaceholder: {
    id: 'components.Coordinators.Forms.Edit.Right.contactPhonePlaceholder',
    defaultMessage: 'Ingresar teléfono de contacto'
  },
  emailLabel: {
    id: 'components.Coordinators.Forms.Edit.Right.emailLabel',
    defaultMessage: 'Correo electrónico'
  },
  emailPlaceholder: {
    id: 'components.Coordinators.Forms.Edit.Right.emailPlaceholder',
    defaultMessage: 'Ingresar correo electrónico'
  },

  submitButtonLabel: {
    id: 'components.Coordinators.Forms.Edit.Right.submitButtonLabel',
    defaultMessage: 'CREAR'
  },
  editSubmitButtonLabel: {
    id: 'components.Coordinators.Forms.Edit.Right.editSubmitButtonLabel',
    defaultMessage: 'EDITAR'
  },
  isRequiredError: {
    id: 'components.Coordinators.Forms.Edit.Right.isRequiredError',
    defaultMessage: 'Este campo es requerido'
  },
  emailError: {
    id: 'components.Coordinators.Forms.Edit.Right.emailError',
    defaultMessage: 'Correo electrónico inválido'
  },
  minDigitsTelephone: {
    id: 'components.Coordinators.Forms.Edit.Right.minDigitsTelephone',
    defaultMessage: 'Mínimo 6 dígitos'
  },
  success: {
    id: 'components.Coordinators.Forms.Edit.Right.success',
    defaultMessage: 'SE GUARDARON LOS CAMBIOS REALIZADOS EN EL USUARIO'
  },
  ok: {
    id: 'components.Coordinators.Forms.Edit.Right.ok',
    defaultMessage: 'Ok'
  },
  400: {
    id: 'components.Coordinators.Forms.Edit.Right.IncorrentRequest',
    defaultMessage:
      'Uno de los datos del formulario es incorrecto. Intente nuevamente.'
  },
  401: {
    id: 'components.Coordinators.Forms.Edit.Right.notAuthorized',
    defaultMessage:
      'No cuenta con los privilegios suficientes para esta acción.'
  },
  403: {
    id: 'components.Coordinators.Forms.Edit.Right.forbiddenRequest',
    defaultMessage:
      'No cuenta con los privilegios suficientes para esta acción.'
  },
  409: {
    id: 'components.Coordinators.Forms.Edit.Right.conflict',
    defaultMessage:
      'Ha ocurrido un conflicto con los datos. Verifique que el usuario no haya sido registrado previamente.'
  },
  500: {
    id: 'components.Coordinators.Forms.Edit.Right.internalServerError',
    defaultMessage:
      'Ha ocurrido un problema con en el servidor. Vuelve a intentarlo.'
  },
  error: {
    id: 'components.Coordinators.Forms.Edit.Right.error',
    defaultMessage: 'Ha ocurrido un error durante la petición'
  }
});
