/* eslint-disable no-underscore-dangle */
import { instance } from '../instance';
import { responseMapper } from 'utils/responseMapper';
import { getFormData } from 'utils/helpers';
import {
  templateGetDataExhibitions as templateGetData,
  templateSendDataExhibitions as templateSendData,
  templateUpdateDataExhibitions as templateUpdateData,
  templateGetHeaderExhibitions as templateGetHeader
} from '../mappers';

export const getProducts = async () => {
  const response = await instance.get('/products');
  const code = response.data.headerResponse.code;

  if (code !== 200) throw new Error(code);

  const payload = responseMapper({
    template: templateGetData,
    data: response.data.payload.items
  });

  return payload;
};

export const getProductById = async (id) => {
  const response = await instance.get(`/products/${id}`);
  const code = response.data.headerResponse.code;

  if (code !== 200) throw new Error(code);

  const payload = responseMapper({
    template: templateGetData,
    data: response.data.payload
  });

  return payload;
};

export const postProduct = async (data) => {
  const mappedData = responseMapper({
    template: templateSendData,
    data: data
  });

  const formData = getFormData(mappedData);

  const response = await instance.post('/products', formData);
  const code = response.data.headerResponse.code;

  if (code !== 200) throw new Error(code);

  const payload = responseMapper({
    template: templateGetData,
    data: response.data.payload
  });

  return payload;
};

export const updateProduct = async (id, data) => {
  const mappedData = responseMapper({
    template: templateUpdateData,
    data: data
  });

  const formData = getFormData(mappedData);

  const response = await instance.patch(`/products/${id}`, formData);
  const code = response.data.headerResponse.code;

  if (code !== 200) throw new Error(code);

  const payload = responseMapper({
    template: templateGetData,
    data: response.data.payload
  });

  return payload;
};

export const deleteProduct = async (id) => {
  const response = await instance.delete(`/products/${id}`);
  const {
    headerResponse: { code }
  } = responseMapper({
    template: templateGetHeader,
    data: response.data
  });

  if (code !== 200) throw new Error(code);

  return {};
};
