import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.Events.title',
    defaultMessage: 'Eventos'
  },
  subTitle: {
    id: 'components.Events.subTitle',
    defaultMessage: 'Listado de eventos creados en la plataforma'
  },
  error: {
    id: 'components.Events.error',
    defaultMessage: 'Ha ocurrido un error.'
  },
  500: {
    id: 'components.Events.internalServerError',
    defaultMessage:
      'Ha ocurrido un error interno en el servidor. Vuelve a intentarlo'
  },
  successfullyDeleted: {
    id: 'components.Events.successfullyDeleted',
    defaultMessage: 'Exitosamente eliminado el evento: '
  },
  ok: {
    id: 'components.Events.ok',
    defaultMessage: 'Ok'
  },
  page: {
    id: 'components.Events.page',
    defaultMessage: 'página'
  }
});
