import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.Analytics.ActivitiesByEvents.title',
    defaultMessage: 'Numero de actividades'
  },
  yTitle: {
    id: 'components.Analytics.ActivitiesByEvents.yLabel',
    defaultMessage: 'Actividades'
  }
});
