import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  challenge1: {
    id: 'components.ChallengeList.challenge1',
    defaultMessage: 'Ingresar puntaje'
  },
  challengeName: {
    id: 'components.ChallengeList.challengeName',
    defaultMessage: 'Desafío a superar'
  },

  challengeScore: {
    id: 'components.ChallengeList.challengeScore',
    defaultMessage: 'Puntaje'
  },
  challengeRoom: {
    id: 'components.ChallengeList.challengeRoom',
    defaultMessage: 'Desafío a superar'
  },
  challengeEnable: {
    id: 'components.ChallengeList.challengeEnable',
    defaultMessage: 'Habilitar / Deshabilitar'
  },
  challengesDescription: {
    id: 'components.Challenge.CreateForm.FormTabs.challengesDescription',
    defaultMessage:
      'Agrega la cantidad de puntos para los logros o desafios a superar en el evento'
  },
  page: {
    id: 'components.Challenge.CreateForm.FormTabs.page',
    defaultMessage: 'página'
  },
  requiredError: {
    id: 'components.Challenge.CreateForm.input.error.required',
    defaultMessage: 'Este campo es requerido'
  },
  createsubmit: {
    id: 'components.Challenge.CreateForm.createsubmit',
    defaultMessage: 'Crear'
  },
  updatesubmit: {
    id: 'components.Challenge.CreateForm.updatesubmit',
    defaultMessage: 'Actualizar'
  }
});
