import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '80px',
    minHeight: 'calc(100% - 80px)',
    padding: theme.spacing(3),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      marginTop: '15px'
    }
  },
  content: {
    flexGrow: 1
  }
}));
