import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  RadioGroup,
  FormControl,
  ButtonBase,
  FormControlLabel,
  Radio
} from '@material-ui/core';
import { TextField } from 'components/CreationForm';
import { Images } from 'assets';
import { Fragment } from 'react';
import { Field, FieldArray } from 'formik';
import { SubmitButton } from 'components/CreationForm';
import { ExpandMore } from '@material-ui/icons';
import { useStyles } from './QuestionsStyles';
import { messages } from './QuestionsMessages';
import { useIntl } from 'react-intl';

export const Questions = ({
  room,
  title,
  numberAnswers,
  correctAnswers,
  setCorrectAnswer,
  questions
}) => {
  const classes = useStyles();
  const intl = useIntl();
  let flagExistQuestions = false;

  const addAccordion = async ({ question, push, index }) => {
    push({
      key: question.key + 1,
      question: '',
      points: 0,
      room: room,
      answers: [],
      right_answer: 1
    });
  };

  return (
    <div className={classes.container}>
      <FieldArray name={`questions${room}`}>
        {({ remove, push }) => (
          <div>
            {(flagExistQuestions = false)}
            {questions.map((question, index, arr) => (
              <Fragment key={`${question.key}QuestionAccordion${room}`}>
                {(flagExistQuestions = true)}
                <Accordion className={classes.accordion}>
                  <AccordionSummary
                    className={classes.summary}
                    expandIcon={<ExpandMore />}
                  >
                    <Typography className={classes.title}>{`${title} ${index +
                      1}`}</Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.details}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        onChange={(_v, value) => {
                          setCorrectAnswer({
                            ...correctAnswers,
                            [index]: parseInt(value)
                          });
                        }}
                        value={correctAnswers[index]}
                      >
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={4}
                            className={classes.containerField}
                          >
                            <Field name={`questions${room}.${index}.points`}>
                              {({ field, meta: { error, touched } }) => (
                                <TextField
                                  defaultValue={field.value}
                                  name={field.name}
                                  onBlur={(e) => {
                                    field.onBlur(e);
                                    field.onChange(e);
                                  }}
                                  error={error}
                                  touched={touched}
                                  type="number"
                                  className={classes.textField}
                                  label={intl.formatMessage(messages.cardScore)}
                                  inputProps={{ min: 0 }}
                                />
                              )}
                            </Field>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={4}
                            className={classes.containerField}
                          >
                            <Field name={`questions${room}.${index}.question`}>
                              {({ field, meta: { error, touched } }) => (
                                <TextField
                                  defaultValue={field.value}
                                  name={field.name}
                                  onBlur={(e) => {
                                    field.onBlur(e);
                                    field.onChange(e);
                                  }}
                                  error={error}
                                  touched={touched}
                                  className={classes.textField}
                                  label={intl.formatMessage(
                                    messages.insertQuestion
                                  )}
                                />
                              )}
                            </Field>
                            <br />
                            <br />
                          </Grid>
                          <Grid item xs={12} className={classes.titleAnswers}>
                            <Typography className={classes.fontWeightBold}>
                              {intl.formatMessage(messages.answers)}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            className={classes.containerMinimumAnswers}
                          >
                            <Typography
                              variant="caption"
                              className={classes.minimumAnswersError}
                            >
                              {questions[`${index}`].answers.length < 2
                                ? intl.formatMessage(
                                    messages.minimumNumberAnswers
                                  )
                                : null}
                            </Typography>
                          </Grid>
                          {Array.from(Array(numberAnswers).keys()).map(
                            (answerIndex) => (
                              <Grid
                                key={room + answerIndex}
                                item
                                xs={12}
                                sm={5}
                                md={4}
                                className={classes.containerAnswer}
                              >
                                <Grid row fullWidth>
                                  <Field
                                    name={`questions${room}.${index}.answers.${answerIndex}`}
                                  >
                                    {({ field, meta: { error, touched } }) => (
                                      <TextField
                                        defaultValue={field.value}
                                        name={field.name}
                                        onBlur={(e) => {
                                          if (
                                            e.target.value.trim().length &&
                                            answerIndex <=
                                              questions[`${index}`].answers
                                                .length
                                          ) {
                                            field.onBlur(e);
                                            field.onChange(e);
                                          }
                                        }}
                                        touched={touched}
                                        className={classes.textField}
                                        label={`${intl.formatMessage(
                                          messages.answer
                                        )} ${answerIndex + 1}`}
                                      />
                                    )}
                                  </Field>
                                  <Field
                                    name={`questions${room}.${index}.right_answer`}
                                  >
                                    {({ field, meta: { error, touched } }) => (
                                      <Grid
                                        row
                                        fullWidth
                                        className={classes.containerRadioButton}
                                      >
                                        <FormControlLabel
                                          value={parseInt(answerIndex + 1)}
                                          name={field.name}
                                          onBlur={(e) => {
                                            field.onBlur(e);
                                            field.onChange(e);
                                          }}
                                          error={error}
                                          touched={touched}
                                          control={
                                            <Radio
                                              disabled={
                                                questions[`${index}`].answers
                                                  .length <= answerIndex
                                                  ? true
                                                  : false
                                              }
                                            />
                                          }
                                          label={
                                            <Typography variant="caption">
                                              &nbsp;
                                              {intl.formatMessage(
                                                messages.correctAnswer
                                              )}
                                            </Typography>
                                          }
                                        />
                                      </Grid>
                                    )}
                                  </Field>
                                </Grid>
                              </Grid>
                            )
                          )}
                          <Grid
                            xs={12}
                            sm={12}
                            md={12}
                            className={classes.containerSubmitButton}
                          >
                            {questions.length > 0 ? (
                              <div>
                                <SubmitButton
                                  label={intl.formatMessage(
                                    messages.deleteQuestion
                                  )}
                                  onClick={() => {
                                    remove(index);
                                  }}
                                />
                              </div>
                            ) : null}
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </FormControl>
                  </AccordionDetails>
                </Accordion>
                {index === arr.length - 1 && index < 3 && (
                  <div className={classes.containerAddButton}>
                    <ButtonBase
                      onClick={() => addAccordion({ question, push, index })}
                    >
                      <img
                        className={classes.actionButtonImage}
                        src={Images.addCircle}
                        alt="addIcon"
                      />
                    </ButtonBase>
                  </div>
                )}
              </Fragment>
            ))}
            {!flagExistQuestions ? (
              <div className={classes.containerAddButton}>
                <ButtonBase
                  onClick={() =>
                    addAccordion({ question: { key: 0 }, push, index: 0 })
                  }
                >
                  <img
                    className={classes.actionButtonImage}
                    src={Images.addCircle}
                    alt="addIcon"
                  />
                </ButtonBase>
              </div>
            ) : null}
          </div>
        )}
      </FieldArray>
    </div>
  );
};
