import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { withRouter, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import {
  Row,
  RightContainer,
  ImagePicker,
  TextField,
  TextFieldPhone,
  SubmitButton
} from 'components/CreationForm';
import { Person } from '@material-ui/icons';
import { useRowsStyles } from '../RowsStyles';
import { useLoader } from '@octopy/react-loader';
import { useModal } from '@octopy/react-modal';
import { messages } from './RightMessages';
import { useDashboard } from 'components/Dashboard';
import { getValidationSchema } from './helper';
import { getDataError, createErrorModalConfig } from 'utils/helpers';
import { getCoordinatorById } from 'providers/api';
import { useStyles } from './RightStyles';

const Right = withRouter(({ history }) => {
  const { idCoordinator } = useParams();
  const { handleOpenModal } = useModal();
  const { handleShowLoader } = useLoader();
  const rowsClasses = useRowsStyles();
  const classes = useStyles();
  const intl = useIntl();
  const { updateCoordinatorById } = useDashboard();

  const [coordinatorDetails, setCoordinatorDetails] = useState({});

  useEffect(async () => {
    try {
      handleShowLoader(true);
      if (history.location.state.positionInArray !== undefined) {
        const dataUser = await getCoordinatorById(idCoordinator);

        setCoordinatorDetails({
          ...dataUser,
          positionInArray: history.location.state.positionInArray
        });
      }
    } catch (request) {
      const { code, message } = getDataError(request);

      handleOpenModal(createErrorModalConfig(code, message));
      history.push('/Coordinators');
    } finally {
      handleShowLoader(false);
    }
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      logoUrl: coordinatorDetails.logoUrl,
      userName: coordinatorDetails.userName,
      completeName: coordinatorDetails.name,
      contactPhone: coordinatorDetails.phone,
      email: coordinatorDetails.email
    },
    validationSchema: getValidationSchema(intl),
    onSubmit: async (values) => {
      const dataFormCoordinator = {
        ...coordinatorDetails,
        ...values
      };

      const error = await updateCoordinatorById(
        dataFormCoordinator,
        coordinatorDetails.positionInArray
      );

      if (!!!error) history.goBack();
    }
  });

  const submitButton = (
    <Row>
      <SubmitButton
        label={intl.formatMessage(messages.editSubmitButtonLabel)}
        onClick={formik.handleSubmit}
      />
    </Row>
  );

  return (
    <RightContainer submitButton={submitButton}>
      <Row className={rowsClasses[1]}>
        <ImagePicker
          onChange={(logo) => {
            formik.setFieldValue('logoUrl', logo.blobUrl);
            formik.setFieldValue('logo', logo);
          }}
          Icon={Person}
          label={intl.formatMessage(messages.logoUrlLabel)}
          name="logoUrl"
          imageUrl={formik.values.logoUrl || formik.values.logo?.blobUrl}
        />
      </Row>
      <Row className={rowsClasses.boxField}>
        {['userName', 'completeName'].map((name) => (
          <TextField
            className={classes.input}
            key={`${name}Input`}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors[name]}
            value={formik.values[name]}
            touched={formik.touched[name]}
            label={intl.formatMessage(messages[`${name}Label`])}
            name={name}
          />
        ))}
        <TextFieldPhone
          key={`contactPhoneInput`}
          country="mx"
          regions={'america'}
          onChange={(phone) =>
            formik.setFieldValue('contactPhone', phone, true)
          }
          onBlur={formik.handleBlur}
          error={formik.errors['contactPhone']}
          value={formik.values['contactPhone']}
          touched={formik.touched['contactPhone']}
          placeholder={intl.formatMessage(messages['contactPhoneLabel'])}
          name={'contactPhone'}
          className={classes.input}
        />
        <TextField
          className={classes.input}
          key={'emailInput'}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors['email']}
          value={formik.values['email']}
          touched={formik.touched['email']}
          label={intl.formatMessage(messages['emailLabel'])}
          name={'email'}
        />
      </Row>
    </RightContainer>
  );
});

export { Right };
