import { useStyles } from './RowStyles';

export const Row = ({ children, className }) => {
  const classes = useStyles();

  return (
    <div
      className={`${className} ${classes.row} ${
        children?.type?.name === 'Label' && classes.labelRow
      }`}
    >
      {children}
    </div>
  );
};
