import React, { useEffect } from 'react';
import {
  PasswordRecovery as PasswordRecoveryComponent,
  decodeToken
} from '@octopy/react-auth';
import { useHistory, useLocation } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { Images } from 'assets';
import { useModal } from '@octopy/react-modal';
import { useLoader } from '@octopy/react-loader';
import { useIntl } from 'react-intl';
import { messages } from './PasswordRecoveryMessages';
import { useStyles } from './PasswordRecoveryStyles';
import { changeUserPassword } from 'providers/api';
import {
  getDataError,
  createErrorModalConfig,
  createSuccessModalConfig
} from 'utils/helpers';

const PasswordRecovery = () => {
  const intl = useIntl();
  const classes = useStyles({ bgLogin: Images.backgroundLogin });
  const { handleShowLoader } = useLoader();
  const { handleOpenModal } = useModal();
  const history = useHistory();

  const location = useLocation();

  const queryString = new URLSearchParams(location.search);
  const token = queryString.get('token');

  useEffect(() => {
    if (!token) history.push('/');
  }, []);

  const submitNewPassword = async (form) => {
    if (!token) return;

    handleShowLoader(true);
    const { email } = decodeToken(token);

    try {
      const { code, message } = await changeUserPassword(
        { password: form.password },
        token
      );

      if (code === 200) {
        handleOpenModal(
          createSuccessModalConfig(
            messages.passwordSuccessfullyChanged,
            '',
            'ok'
          )
        );
        history.push(`/login/${email}`);
      }
    } catch (response) {
      const { code, message } = getDataError(response);

      handleOpenModal(createErrorModalConfig(code, message));
    } finally {
      handleShowLoader(false);
    }
  };

  return (
    <Box className={classes.container}>
      <PasswordRecoveryComponent
        actions={{ handleSubmit: submitNewPassword }}
      />
    </Box>
  );
};

export { PasswordRecovery };
