import { Grid } from '@material-ui/core';
import { useStyles } from './FormLayoutStyles';
import { Box } from '@material-ui/core';
import { Header } from 'components/Header';
import { Menu } from 'components/Menu';
import { Loader as LibLoader } from '@octopy/react-loader';
import { BasicLoader } from '@octopy/react-loader';
import { Modal as LibModal } from '@octopy/react-modal';
import { GoBack } from 'components/GoBack/GoBack';

export const FormLayout = ({ children }) => {
  const classes = useStyles();

  return (
    <>
      <LibLoader>
        <BasicLoader />
      </LibLoader>
      <LibModal />
      <Header />

      <Box className={classes.container}>
        <Menu />
        <Grid container className={classes.contentContainer}>
          <GoBack containerClassName={classes.goBackIconContainer} />
          <Grid item lg={3} md={5} className={classes.labelsContainer}>
            <div className={classes.labels}>{children[0]}</div>
          </Grid>
          <Grid item lg={9} md={7} xs={12}>
            {children[1]}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
