import { useIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useStyles } from './CreateCardStyles';
import { messages } from './CreateCardMessages';
import { Images } from 'assets';

export const CreateCard = withRouter(({ history }) => {
  const classes = useStyles({ icon: Images.Work });
  const intl = useIntl();

  const onClick = () => history.push('/CreateOrganization');

  return (
    <Grid item lg={4} md={6} sm={12} xs={12} onClick={onClick}>
      <div className={classes.container}>
        <div className={classes.info}>
          <div className={classes.imageMaskContainer}>
            <div className={classes.imageMask} />
          </div>
          <div>
            <Typography className={classes.title}>
              {intl.formatMessage(messages.title)}
            </Typography>
            <Typography className={classes.subtitle}>
              {intl.formatMessage(messages.subtitle)}
            </Typography>
          </div>
        </div>
        <div className={classes.actions}>
          <Grid container justify="center" className={classes.actionsContainer}>
            <Add className={classes.addIcon} />
          </Grid>
        </div>
      </div>
    </Grid>
  );
});
