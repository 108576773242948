export const templateGetDatabases = {
  headerResponse: {
    code: 'headerResponse.code',
    message: 'headerResponse.message'
  },
  payload: [
    'payload.items',
    {
      id: '_id',
      fileUrl: 'file',
      companyId: 'company',
      eventId: 'event',
      lastUpdate: 'updated_at'
    }
  ]
};

export const templateAddDatabase = {
  headerResponse: {
    code: 'headerResponse.code',
    message: 'headerResponse.message'
  },
  payload: {
    id: 'payload._id',
    fileUrl: 'payload.file',
    companyId: 'payload.company',
    eventId: 'payload.event',
    lastUpdate: 'payload.updated_at'
  }
};

export const counterTemplateAddDatabase = {
  file: 'file',
  event: 'eventId'
};

export const templateUpdateDatabase = {
  headerResponse: {
    code: 'headerResponse.code',
    message: 'headerResponse.message'
  },
  payload: {
    id: 'payload._id',
    fileUrl: 'payload.file',
    companyId: 'payload.company',
    eventId: 'payload.event'
  }
};

export const counterTemplateUpdateDatabase = {
  file: 'file',
  event: 'eventId'
};
