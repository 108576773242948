import React from 'react';
import { Box, IconButton } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useStyles } from './RefreshControlStyles';

const RefreshControl = ({ refreshFunction }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <IconButton onClick={refreshFunction} className={classes.button}>
        <RefreshIcon className={classes.icon} />
      </IconButton>
    </Box>
  );
};

export { RefreshControl };
