import { responseMapper } from 'utils/responseMapper';
import { instance } from '../instance';

import { templateAddPartners } from 'providers/api/mappers/partners';

import {
  responseTemplateAddPartners,
  templateGetPartner,
  templateGetHeaderPartner,
  responseTemplateUpdatePartners,
  templateGetPartnerById,
  templateUpdatePartners
} from '../mappers/partners';

const getPartners = async () => {
  const response = await instance.get('/partners');

  const resultMappped = responseMapper({
    template: templateGetPartner,
    data: response.data
  });

  const {
    headerResponse: { code },
    payload: { items }
  } = resultMappped;

  if (code !== 200) throw code;

  return items;
};

export const getPartnerById = async (id) => {
  const response = await instance.get(`/partners/${id}`);

  const mappedResponse = responseMapper({
    template: templateGetPartnerById,
    data: response.data
  });

  const {
    headerResponse: { code },
    payload
  } = mappedResponse;

  if (code !== 200) throw code;

  return payload;
};

const addPartner = async (payload) => {
  const mappedValues = responseMapper({
    template: templateAddPartners,
    data: payload
  });

  var formData = new FormData();

  for (var key in mappedValues) {
    formData.append(key, mappedValues[key]);
  }

  const response = await instance.post('/partners', formData, {
    headers: { 'Content-Type': 'multipart/form-data; boundary=something' }
  });

  const mappedResponse = responseMapper({
    template: responseTemplateAddPartners,
    data: response.data
  });

  const {
    headerResponse: { code },
    payload: responsePayload
  } = mappedResponse;

  if (code !== 200) throw code;

  return responsePayload;
};

const deletePartner = async (id) => {
  const response = await instance.delete(`/partners/${id}`);

  const resultMappped = responseMapper({
    template: templateGetHeaderPartner,
    data: response.data
  });
  const {
    headerResponse: { code, message }
  } = resultMappped;

  if (code !== 200) throw new Error(message);

  return id;
};

const updatePartner = async (id, payload) => {
  const mappedValues = responseMapper({
    template: templateUpdatePartners,
    data: payload
  });

  let formData = new FormData();

  for (let key in mappedValues) {
    formData.append(key, mappedValues[key]);
  }

  const response = await instance.patch(`/partners/${id}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data; boundary=something' }
  });

  const resultMappped = responseMapper({
    template: responseTemplateUpdatePartners,
    data: response.data
  });

  const {
    headerResponse: { code },
    payload: responsePayload
  } = resultMappped;

  if (code !== 200) throw code;

  return { responsePayload };
};

const updatePartnerStand = async (id, payload) => {
  const response = await instance.patch(`/partners/${id}`, payload, {
    headers: { 'Content-Type': 'multipart/form-data; boundary=something' }
  });

  const resultMappped = responseMapper({
    template: responseTemplateUpdatePartners,
    data: response.data
  });

  const {
    headerResponse: { code },
    payload: responsePayload
  } = resultMappped;

  if (code !== 200) throw code;

  return { responsePayload };
};

export {
  getPartners,
  addPartner,
  deletePartner,
  updatePartner,
  updatePartnerStand
};
