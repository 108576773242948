import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  nameLabel: {
    id: 'components.Analytics.EventsTable.table.cell.name.label',
    defaultMessage: 'Nombre del evento'
  },
  fromLabel: {
    id: 'components.Analytics.EventsTable.table.cell.fromDate.label',
    defaultMessage: 'Fecha de inicio'
  },
  toLabel: {
    id: 'components.Analytics.EventsTable.table.cell.toDate.label',
    defaultMessage: 'Fecha de fin'
  },
  limitParticipantsLabel: {
    id: 'components.Analytics.EventsTable.table.cell.limitParticipants.label',
    defaultMessage: 'Limite de participantes'
  },
  page: {
    id: 'components.Analytics.EventsTable.table.paginatiom.select.page',
    defaultMessage: 'página'
  }
});
