import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  logoProductLabel: {
    id: 'components.Exhibition.Form.Create.Right.logoProductLabel',
    defaultMessage: 'Seleccionar imagen'
  },
  videoId: {
    id: 'components.Exhibition.Form.Create.Right.videoId',
    defaultMessage: 'Id del video'
  },
  urlChat: {
    id: 'components.Exhibition.Form.Create.Right.urlChat',
    defaultMessage: 'URL  del chat'
  },
  productName: {
    id: 'components.Exhibition.Form.Create.Right.productName',
    defaultMessage: 'Nombre del producto o servicio'
  },
  productSlogan: {
    id: 'components.Exhibition.Form.Create.Right.productSlogan',
    defaultMessage: 'Eslogan del producto o servicio'
  },
  productDescription: {
    id: 'components.Exhibition.Form.Create.Right.productDescription',
    defaultMessage: 'Descripción breve del producto'
  },
  selectEvent: {
    id: 'components.Exhibition.Form.Create.Right.selectEvent',
    defaultMessage: 'Selecciona un evento'
  },
  create: {
    id:
      'components.Exhibition.Form.Create.Right.input.submitButton.create.label',
    defaultMessage: 'CREAR'
  },
  isRequiredError: {
    id: 'components.Exhibition.Form.Create.Right.isRequiredError',
    defaultMessage: 'Este campo es requerido'
  },
  invalidUrlError: {
    id: 'components.Exhibition.Form.Create.Right.invalidUrlError',
    defaultMessage: 'URL inválida'
  },
  ok: {
    id: 'components.Exhibition.Form.Create.Right.ok',
    defaultMessage: 'Ok'
  }
});
