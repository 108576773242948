export const templateAddOrganization = {
  headerResponse: {
    code: 'headerResponse.code',
    message: 'headerResponse.message'
  },
  payload: {
    id: 'payload._id',
    name: 'payload.business_name',
    date: 'payload.created_at',
    logoUrl: 'payload.image',
    businessName: 'payload.business_name',
    completeName: 'payload.contact_name',
    contactPhone: 'payload.phone',
    email: 'payload.email',
    country: 'payload.country',
    participantsQuantity: 'payload.limit_participants',
    sponsorsQuantity: 'payload.limit_partners',
    eventsQuantity: 'payload.limit_events'
  }
};

export const counterTemplateAddOrganization = {
  file: 'logo.file',
  business_name: 'businessName',
  contact_name: 'completeName',
  phone: 'contactPhone',
  email: 'email',
  country: 'country',
  limit_participants: 'participantsQuantity',
  limit_partners: 'sponsorsQuantity',
  limit_events: 'eventsQuantity'
};

export const templateGetOrganizations = {
  headerResponse: {
    code: 'headerResponse.code',
    message: 'headerResponse.message'
  },
  payload: {
    items: [
      'payload.items',
      {
        id: '_id',
        name: 'business_name',
        date: 'created_at',
        logoUrl: 'image',
        businessName: 'business_name',
        completeName: 'contact_name',
        contactPhone: 'phone',
        email: 'email',
        country: 'country',
        participantsQuantity: 'limit_participants',
        sponsorsQuantity: 'limit_partners',
        eventsQuantity: 'limit_events'
      }
    ]
  }
};

export const templateGetOrganizationById = {
  headerResponse: {
    code: 'headerResponse.code',
    message: 'headerResponse.message'
  },
  payload: {
    id: 'payload._id',
    name: 'payload.business_name',
    date: 'payload.register_date',
    logoUrl: 'payload.file',
    businessName: 'payload.business_name',
    completeName: 'payload.contact_name',
    contactPhone: 'payload.phone',
    email: 'payload.email',
    country: 'payload.country',
    participantsQuantity: 'payload.limit_participants',
    sponsorsQuantity: 'payload.limit_partners',
    eventsQuantity: 'payload.limit_events'
  }
};

export const templateUpdateOrganization = {
  headerResponse: {
    code: 'headerResponse.code',
    message: 'headerResponse.message'
  },
  payload: {
    id: 'payload._id',
    name: 'payload.business_name',
    date: 'payload.register_date',
    logoUrl: 'payload.image',
    businessName: 'payload.business_name',
    completeName: 'payload.contact_name',
    contactPhone: 'payload.phone',
    email: 'payload.email',
    country: 'payload.country',
    participantsQuantity: 'payload.limit_participants',
    sponsorsQuantity: 'payload.limit_partners',
    eventsQuantity: 'payload.limit_events'
  }
};

export const counterTemplateUpdateOrganization = {
  file: 'logo.file',
  business_name: 'businessName',
  contact_name: 'completeName',
  phone: 'contactPhone',
  email: 'email',
  country: 'country',
  limit_participants: 'participantsQuantity',
  limit_partners: 'sponsorsQuantity',
  limit_events: 'eventsQuantity'
};

export const templateDeleteOrganization = {
  headerResponse: {
    code: 'headerResponse.code',
    message: 'headerResponse.message'
  }
};
