import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  row: {
    [theme.breakpoints.down('sm')]: {
      height: 'auto !important',
      marginBottom: '20px',
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap'
    }
  },
  labelRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginLeft: '20px',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
      marginLeft: 0
    }
  }
}));
