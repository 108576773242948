import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  assign: {
    id: 'components.Partners.Partner.assign',
    defaultMessage: 'Eventos asignados'
  },
  countryLabel: {
    id: 'components.Partners.Partner.countryLabel',
    defaultMessage: 'Pais'
  },
  selectLabel: {
    id: 'components.Partners.Partner.selectLabel',
    defaultMessage: 'Nombre del evento'
  },
  edit: {
    id: 'components.Partners.Partner.edit',
    defaultMessage: 'Editar'
  },
  delete: {
    id: 'components.Partners.Partner.delete',
    defaultMessage: 'Eliminar'
  },
  stand: {
    id: 'components.Partners.Partner.stand',
    defaultMessage: 'Stand'
  },
  areYouSureDelete: {
    id: 'components.Partners.Cards.Edit.areYouSureDelete',
    defaultMessage: '¿ESTÁS SEGURO DE QUERER ELIMINAR EL ALIADO:'
  }
});
