import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  text: {
    font: 'normal normal bold 18px/24px Roboto',
    color: '#009FDA',
    textTransform: 'uppercase',
    overflowWrap: 'break-word'
  },
  white: {
    color: 'white !important'
  }
});
