import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { withRouter, useParams } from 'react-router-dom';
import { Images } from 'assets';
import {
  Select as MySelect,
  Avatar,
  Box,
  Chip,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  Link
} from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import 'date-fns';
import { useFormik } from 'formik';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import { useLoader } from '@octopy/react-loader';
import Tooltip from '@material-ui/core/Tooltip';
import { ModalText, ModalButton } from 'components/ModalMessages';
import {
  Row,
  RightContainer,
  TextField,
  SubmitButton,
  Select,
  TimePicker
} from 'components/CreationForm';
import { FileInput } from 'components/CreationForm/Right/inputs/FileInput/FileInput';
import { useStyles } from './RightStyles';
import { useRowsStyles } from '../RowsStyles';
import { messages } from './RightMessages';
import { useModal } from '@octopy/react-modal';
import { createErrorModalConfig } from 'utils/helpers';
import { useNoEvents } from 'components/NoEvents/hooks/useNoEvents';
import { getValidationSchema } from './helpers';
import { getEventById } from 'providers/api';
import { DatePicker } from 'components/CreationForm/Right/inputs/DatePicker/DatePicker';
import { useDashboard } from 'components/Dashboard';

const Right = withRouter(({ history }) => {
  const [selectedValueVideo, setSelectedValueVideo] = useState('youtube');
  const { idEvent } = useParams();
  const { updateEventById, partners } = useDashboard();
  const [eventDetails, setEventDetails] = useState({});
  const { handleNoEventShow } = useNoEvents();
  const [partnerSelected, setpartnerSelected] = useState([]);
  const allowedTypes = ['image/png', 'image/jpg', 'image/jpeg'];
  const intl = useIntl();
  const options = [
    { value: '10', label: intl.formatMessage(messages.optionOne) },
    { value: '20', label: intl.formatMessage(messages.optionTwo) },
    { value: '30', label: intl.formatMessage(messages.optionThree) }
  ];
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };

  const onSubmit = async (values, initialValues) => {
    const valuesChanged = {};

    if (values.name !== initialValues.name) {
      valuesChanged.name = values.name;
    }

    if (values.imageUrl !== initialValues.imageUrl) {
      valuesChanged.file = values.imageUrl;
    }

    if (values.limitParticipants !== initialValues.limitParticipants) {
      valuesChanged.limit_participants = values.limitParticipants;
    }

    if (values.plataform !== initialValues.plataform) {
      valuesChanged.plataform = values.plataform;
    }

    if (values.videoId !== initialValues.videoId) {
      valuesChanged.video_id = values.videoId;
    }

    const x = values.partners.map((partner) => partner._id).join(',');

    valuesChanged.partners = x;
    valuesChanged.id = eventDetails.id;
    if (values.plataform !== 'image') {
      delete valuesChanged.file;
    } else {
      valuesChanged.video_id = '';
    }

    var formData = new FormData();

    for (var key in valuesChanged) {
      formData.append(key, valuesChanged[key]);
    }

    const error = await updateEventById(
      formData,
      valuesChanged.id,
      eventDetails.index
    );

    !error && history.push('/Events');
  };

  const formik = useFormik({
    initialValues: { limitParticipants: '' },
    validationSchema: getValidationSchema(intl),
    onSubmit
  });

  const handleImageModal = (url) => {
    const modalTitle = (
      <ModalText>
        <img src={url} alt="vista" className={classes.modalImage} />
      </ModalText>
    );
    const modalActionButtons = (
      <ModalButton>{intl.formatMessage(messages.close)}</ModalButton>
    );

    handleOpenModal({
      title: modalTitle,
      actionButtons: modalActionButtons,
      configProps: { maxWidth: 'xs' }
    });
  };

  const showSeeImage = () => {
    let see = null;

    if (eventDetails.imageUrl) {
      see = (
        <Link
          onClick={() => handleImageModal(eventDetails.imageUrl)}
          className={classes.see}
        >
          {intl.formatMessage(messages['seeimage'])}
        </Link>
      );
    }

    return see;
  };

  useEffect(async () => {
    handleNoEventShow(false);
    (async () => {
      try {
        handleShowLoader(true);
        if (history.location.state.index !== undefined) {
          const listPartner = [];
          const event = await getEventById(idEvent);

          if (partners.length && event) {
            const eventDetails = {
              ...event,
              amount: event.partners.length,
              index: history.location.state.index
            };

            setSelectedValueVideo(eventDetails.plataform);
            eventDetails.limitParticipants = eventDetails.limitParticipants.toString();

            formik.setValues(eventDetails);
            setEventDetails(eventDetails);
            for (const partnerSearch of partners) {
              if (
                event.partners.find(
                  (partner) => partner._id === partnerSearch.id
                )
              ) {
                listPartner.push(partnerSearch);
              }
            }

            setpartnerSelected(listPartner);
          } else {
            history.push('/Events');
          }
        }
      } catch (code) {
        handleError(code);
        handleShowLoader(false);
        history.push('/Events');
      } finally {
        handleShowLoader(false);
      }
    })();
  }, []);

  const handleError = (code) => {
    const message = code in messages ? messages[code] : messages.error;

    handleOpenModal(createErrorModalConfig(code, message));
  };

  const handleChange = (event, newParter) => {
    const auxPartner = [...formik.values.partners];

    const partnerExists = auxPartner.findIndex(
      (partner) => partner._id === newParter.props.value.id
    );

    if (partnerExists === -1) {
      formik.values.amount = formik.values.amount + 1;
      auxPartner.push({
        _id: newParter.props.value.id,
        business_name: newParter.props.value.businessName
      });
    } else {
      formik.values.amount = formik.values.amount - 1;
      auxPartner.splice(partnerExists, 1);
    }

    setpartnerSelected(event.target.value);
    formik.setFieldValue('partners', auxPartner);
  };

  const classes = useStyles();
  const rowsClasses = useRowsStyles();

  const { handleOpenModal } = useModal();
  const { handleShowLoader } = useLoader();

  const submitButton = (
    <Row>
      <SubmitButton
        label={intl.formatMessage(messages.editSubmitButtonLabel)}
        onClick={formik.handleSubmit}
      />
    </Row>
  );

  const renderPartners = (selected) =>
    selected.map((partner) => (
      <Chip
        size="small"
        avatar={<Avatar alt={partner.businessName} src={partner.logoUrl} />}
        label={partner.businessName}
      />
    ));

  const handleChangeVideo = (event) => {
    setSelectedValueVideo(event.target.value);
    formik.setFieldValue('plataform', event.target.value);
  };

  return (
    <RightContainer submitButton={submitButton}>
      <Row className={rowsClasses[1]}>
        <div className={classes.fileType}>
          {selectedValueVideo !== 'image' ? (
            <TextField
              key={`videoIdInput`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors['videoId']}
              value={formik.values['videoId']}
              touched={formik.touched['videoId']}
              label={intl.formatMessage(messages[`videoIdLabel`])}
              name={'videoId'}
              className={classes.inputVideo}
            />
          ) : (
            <div className={classes.file}>
              {showSeeImage()}
              <FileInput
                id="file"
                label={intl.formatMessage(messages.uploadButtonLabel)}
                fileName={formik.values.imageUrl?.name}
                accept="image/png, image/gif, image/jpeg"
                acceptType={allowedTypes}
                onChange={({ file }) => formik.setFieldValue('imageUrl', file)}
              />
            </div>
          )}
          <FormControl
            component="fieldset"
            className={classes.radioButtonsContainer}
          >
            <RadioGroup row>
              <Tooltip title={intl.formatMessage(messages.youtube)}>
                <div className={classes.centerRadio}>
                  <FormControlLabel
                    value="1"
                    control={
                      <Radio
                        checked={selectedValueVideo === 'youtube'}
                        onChange={handleChangeVideo}
                        value="youtube"
                        name="radio-button-video"
                        className={classes.radio}
                      />
                    }
                    labelPlacement="end"
                  />
                  <img
                    src={Images.youtube}
                    alt="youtube"
                    className={classes.logoYoutube}
                  />
                </div>
              </Tooltip>
              <Tooltip title={intl.formatMessage(messages.vimeo)}>
                <div className={classes.centerRadio}>
                  <FormControlLabel
                    value="2"
                    control={
                      <Radio
                        checked={selectedValueVideo === 'vimeo'}
                        onChange={handleChangeVideo}
                        value="vimeo"
                        name="radio-button-vimeo"
                        className={classes.radio}
                      />
                    }
                    labelPlacement="end"
                  />
                  <img
                    src={Images.vimeo}
                    alt="vimeo"
                    className={classes.logoVimeo}
                  />
                </div>
              </Tooltip>
              <Tooltip title={intl.formatMessage(messages.image)}>
                <div className={classes.centerRadio}>
                  <FormControlLabel
                    value="3"
                    control={
                      <Radio
                        checked={selectedValueVideo === 'image'}
                        onChange={handleChangeVideo}
                        value="image"
                        name="radio-button-imagen"
                        className={classes.radio}
                      />
                    }
                    labelPlacement="end"
                  />
                  <ImageIcon className={classes.logoImagen} />
                </div>
              </Tooltip>
            </RadioGroup>
          </FormControl>
        </div>
      </Row>
      <Row className={rowsClasses[2]}>
        <TextField
          key={`nameInput`}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors['name']}
          value={formik.values['name']}
          touched={formik.touched['name']}
          label={intl.formatMessage(messages[`nameLabel`])}
          name={'name'}
          className={classes.input}
        />
      </Row>

      <Row className={rowsClasses[3]}>
        <Select
          id="limitParticipants"
          key={'limitParticipantsInput'}
          name={'limitParticipants'}
          label={intl.formatMessage(messages['limitParticipantsLabel'])}
          className={classes.input}
          value={formik.values['limitParticipants']}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.limitParticipants}
          touched={formik.touched.limitParticipants}
          options={options}
        />
      </Row>

      <Row className={rowsClasses[4]}>
        <TextField
          key={'amount'}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors['amount']}
          value={formik.values['amount']}
          inputProps={{ type: 'number', min: 0 }}
          touched={formik.touched['amount']}
          disabled
          label={intl.formatMessage(messages['amountLabel'])}
          name={'amount'}
          className={classes.input}
        />
        <FormControl className={classes.input}>
          <MySelect
            id="partners"
            key={'partnersInput'}
            onBlur={formik.handleBlur}
            error={formik.errors.partners}
            touched={formik.touched.partners}
            name={'partners'}
            multiple
            value={partnerSelected}
            onChange={handleChange}
            renderValue={renderPartners}
            MenuProps={MenuProps}
          >
            {partners.map((partner) => (
              <MenuItem key={partner.id} value={partner}>
                <Box className={classes.imgBox}>
                  {partner.logoUrl ? (
                    <img
                      src={partner.logoUrl || Images.handshake}
                      alt="logo"
                      className={classes.logoImage}
                    />
                  ) : (
                    <img src={Images.handshake} alt="logo" />
                  )}
                </Box>
                <ListItemText primary={partner.businessName} />
              </MenuItem>
            ))}
          </MySelect>
          {formik.touched.partners && formik.errors.partners ? (
            <div className={classes.errorDate}>{formik.errors.partners}</div>
          ) : null}
        </FormControl>
      </Row>
      <Row className={rowsClasses[5]}>
        <div className={classes.dateLabel}>
          {intl.formatMessage(messages.beginning)}
        </div>
        <DatePicker
          label={intl.formatMessage(messages[`fromDateLabel`])}
          margin="normal"
          id="fromDate"
          format="dd/MM/yyyy"
          name={'fromDate'}
          error={formik.errors['fromDate']}
          value={formik.values.from}
          className={classes.input}
          disabled={true}
        />
        <TimePicker
          label={intl.formatMessage(messages['fromTimeLabel'])}
          margin="normal"
          id="fromTime"
          name={'fromTime'}
          value={formik.values.from}
          className={classes.input}
          disabled
        />
        <div className={classes.dateLabel}>
          {intl.formatMessage(messages.end)}
        </div>
        <FormControl className={classes.divToDate}>
          <DatePicker
            label={intl.formatMessage(messages['toDateLabel'])}
            margin="normal"
            id="toDate"
            format="dd/MM/yyyy"
            name={'toDate'}
            error={formik.errors['toDate']}
            value={formik.values.to}
            className={classes.inputToDate}
            disabled={true}
          />
          {formik.touched.toDate && formik.errors.toDate ? (
            <div className={classes.errorDate}>{formik.errors.toDate}</div>
          ) : null}
        </FormControl>
        <TimePicker
          label={intl.formatMessage(messages['toTimeLabel'])}
          margin="normal"
          id="toTime"
          name={'toTime'}
          value={formik.values.to}
          className={classes.input}
          disabled
        />
      </Row>
    </RightContainer>
  );
});

export { Right };
/*
 <TimePicker
          label={intl.formatMessage(messages['toTimeLabel'])}
          margin="normal"
          id="toTime"
          name={'toTime'}
          value={formik.values.to}
          className={classes.input}
          disabled
        />
*/
