import { withRouter } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Typography, Grid } from '@material-ui/core';
import { Images } from 'assets';
import { useStyles } from './ChallengeCardStyles';
import { messages } from './ChallengeCardMessages';

const ChallengeCard = withRouter(({ id, eventName, date, points, history }) => {
  const intl = useIntl();
  const classes = useStyles({ icon: Images.PuzzlePiece });

  return (
    <Grid item lg={4} md={6} sm={12} xs={12}>
      <div className={classes.container}>
        <div className={classes.info}>
          <div className={classes.imageMaskContainer}>
            <div className={classes.imageMask} />
          </div>
          <div>
            <Typography className={classes.name}>
              {intl.formatMessage(messages.titleChallenge)}
            </Typography>
            <div className={classes.event}>{eventName}</div>
            <Typography className={classes.date}>{date}</Typography>
            <div className={classes.points}>
              <img alt="puzzlepiece" src={Images.PuzzlePiece} />
              <span>{points}</span>
            </div>
          </div>
        </div>
        <div className={classes.actions}>
          <Grid container className={classes.actionsContainer}>
            <Grid
              className={classes.edit}
              alignItems="center"
              item
              container
              onClick={() =>
                history.push({
                  pathname: `/CreateChallenge`,
                  state: { id, type: 'edit' }
                })
              }
              xs={4}
            >
              <div className={classes.actionButtonImageContainer}>
                <img
                  alt="edit"
                  src={Images.Pencil}
                  className={classes.actionButtonImage}
                />
              </div>
              <Typography component="span">
                {intl.formatMessage(messages.edit)}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    </Grid>
  );
});

export { ChallengeCard };
