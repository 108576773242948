import { useIntl } from 'react-intl';
import { useParams, withRouter } from 'react-router-dom';
import { useFormik } from 'formik';
import {
  Row,
  RightContainer,
  ImagePicker,
  TextField,
  SubmitButton
} from 'components/CreationForm';
import { Person } from '@material-ui/icons';
import { useRowsStyles } from '../RowsStyles';
import { messages } from './RightMessages';
import { allNumbersInsideToString } from 'utils/helpers';
import { getValidationSchema } from './helpers';
import { useStyles } from './RightStyles';
import { useDashboard } from 'components/Dashboard';
import { useEffect, useState } from 'react';
import { getOrganizationById } from 'providers/api';
import { useLoader } from '@octopy/react-loader';
import { useModal } from '@octopy/react-modal';
import {
  ModalErrorMessage,
  ModalButtonMessage
} from 'components/ModalMessages';
import { getDataError } from 'utils/helpers';
import { TextFieldPhone } from 'components/CreationForm/Right/inputs/TextFieldPhone/TextFieldPhone';
import { Select } from 'components/CreationForm/Right/inputs/Select/Select';
import { useCountries } from 'providers/countries/countriesContext';

export const Right = withRouter(({ history, location }) => {
  const { americaCountries: countries } = useCountries();
  const rowsClasses = useRowsStyles();
  const classes = useStyles();
  const intl = useIntl();
  const { handleOpenModal } = useModal();
  const { handleShowLoader } = useLoader();
  const { updateOrganizationById, organizations } = useDashboard();
  const { organizationId } = useParams();

  const [index, setIndex] = useState();

  const onSubmit = async (values) => {
    const error = await updateOrganizationById(values, index);

    !error && history.push('/Organizations');
  };

  const formik = useFormik({
    initialValues: { country: '' },
    validationSchema: getValidationSchema(intl),
    onSubmit
  });

  useEffect(() => {
    if (location.state) {
      const { index, organization } = location.state;

      formik.setValues(allNumbersInsideToString(organization));
      setIndex(index);
    } else if (organizationId) {
      (async () => {
        try {
          handleShowLoader(true);

          const organization = await getOrganizationById(organizationId);

          formik.setValues(allNumbersInsideToString(organization));
        } catch (request) {
          const { code, message } = getDataError(request);

          await handleOpenModal({
            title: <ModalErrorMessage code={code} message={message} />,
            actionButtons: <ModalButtonMessage message="ok" />
          });
          history.push('/Organizations');
        } finally {
          handleShowLoader(false);
        }
      })();

      const index = organizations?.findIndex(
        (organization) => organization.id === organizationId
      );

      setIndex(index);
    }
  }, []);

  return (
    <RightContainer
      submitButton={
        <Row>
          <SubmitButton
            label={intl.formatMessage(messages.submitButtonLabel)}
            onClick={formik.handleSubmit}
          />
        </Row>
      }
    >
      <Row className={rowsClasses[1]}>
        <ImagePicker
          onChange={(logo) => {
            formik.setFieldValue('logoUrl', null);
            formik.setFieldValue('logo', logo);
          }}
          Icon={Person}
          label={intl.formatMessage(messages.logoLabel)}
          imageUrl={formik.values.logoUrl || formik.values.logo?.blobUrl}
        />
      </Row>
      <Row className={rowsClasses[2]}>
        {['businessName', 'completeName', 'contactPhone', 'email'].map((name) =>
          name !== 'contactPhone' ? (
            <TextField
              key={`${name}Input`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors[name]}
              value={formik.values[name]}
              touched={formik.touched[name]}
              label={intl.formatMessage(messages[`${name}Label`])}
              name={name}
              className={classes.input}
            />
          ) : (
            <TextFieldPhone
              key={`${name}Input`}
              onChange={(phone) => formik.setFieldValue(name, phone, true)}
              onBlur={formik.handleBlur}
              error={formik.errors[name]}
              value={formik.values[name]}
              touched={formik.touched[name]}
              name={name}
              className={classes.input}
            />
          )
        )}
      </Row>
      <Row className={rowsClasses[3]}>
        <Select
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.country}
          value={formik.values.country}
          touched={formik.touched.country}
          label={intl.formatMessage(messages.countryLabel)}
          name="country"
          className={classes.input}
          options={countries.map((country) => ({
            value: country,
            label: country
          }))}
        />
      </Row>
      <Row className={rowsClasses[4]}>
        <TextField
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.participantsQuantity}
          value={formik.values.participantsQuantity}
          touched={formik.touched.participantsQuantity}
          label={intl.formatMessage(messages.participantsQuantityLabel)}
          name="participantsQuantity"
          className={classes.input}
          type="number"
          inputProps={{ min: 1, max: 9999 }}
        />
      </Row>
      <Row className={rowsClasses[5]}>
        <TextField
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.sponsorsQuantity}
          value={formik.values.sponsorsQuantity}
          touched={formik.touched.sponsorsQuantity}
          label={intl.formatMessage(messages.sponsorsQuantityLabel)}
          name="sponsorsQuantity"
          className={classes.input}
          type="number"
          inputProps={{ min: 1, max: 999999 }}
        />
      </Row>
    </RightContainer>
  );
});
