import { useEffect, useState } from 'react';
import { Typography, Box, Grid, ButtonBase } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { CreateCoordinatorCard } from './CreateCoordinatorCard';
import { useStyles } from './AnalyticsStyles';
import { messages } from './AnalyticsMessages';
import { useNoEvents } from 'components/NoEvents/hooks/useNoEvents';
import { EventsTable } from './EventsTable';
import { Images } from 'assets';
import { Filter } from './Filter';
import { NextEvents } from './NextEvents';
import { UsersQuantity } from './UsersQuantity/UsersQuantity';
import { EventsQuantity } from './EventsQuantity/EventsQuantity';
import { PartnersByEvent } from './PartnersByEvent/PartnersByEvent';
import { ParticipantsByEvent } from './ParticipantsByEvent/ParticipantsByEvent';
import { ActivitiesByEvent } from './ActivitiesByEvent/ActivitiesByEvent';
import { getAnalytics } from 'providers/api';
import { useLoader } from '@octopy/react-loader';
import { useModal } from '@octopy/react-modal';
import { getDataError, createErrorModalConfig } from 'utils/helpers';
import { formatDate } from 'utils/helpers';
import { useSideDrawer } from 'hooks/useSideDrawer';

export const Analytics = () => {
  const classes = useStyles();
  const intl = useIntl();

  const [data, setData] = useState();

  const { handleNoEventShow } = useNoEvents();
  const { handleOpenModal } = useModal();
  const { handleShowLoader } = useLoader();
  const filter = useSideDrawer();
  const nextEvents = useSideDrawer();

  useEffect(() => handleNoEventShow(false), []);

  useEffect(() => {
    (async () => {
      try {
        handleShowLoader(true);

        const data = await getAnalytics();

        const formattedData = {
          ...data,
          events: data.events.map((event) => ({
            ...event,
            from: formatDate(new Date(event.from)),
            to: formatDate(new Date(event.to))
          }))
        };

        setData(formattedData);
      } catch (request) {
        const { code, message } = getDataError(request);

        handleOpenModal(createErrorModalConfig(code, message));
      } finally {
        handleShowLoader(false);
      }
    })();
  }, []);

  return (
    <>
      <div>
        <div className={classes.header}>
          <Typography className={classes.title}>
            {intl.formatMessage(messages.title)}
          </Typography>
          <Typography className={classes.subtitle}>
            {intl.formatMessage(messages.subtitle)}
          </Typography>
          <div className={classes.buttonsContainer}>
            <ButtonBase
              className={`${classes.button} timeline`}
              onClick={nextEvents.handleSwitch}
            >
              <img src={Images.Timeline} alt="timeline" />
            </ButtonBase>
            {/*<ButtonBase
              className={`${classes.button} filter`}
              onClick={filter.handleSwitch}
            >
              <div className={classes.filterTriangle} />
              <img src={Images.Filter} alt="filter" />
            </ButtonBase>*/}
          </div>
          <Filter {...filter} />
          <NextEvents {...nextEvents} />
        </div>
        {data && (
          <Box className={classes.cardsContainer}>
            <Grid container justify="space-around" spacing={3}>
              <CreateCoordinatorCard />
              <UsersQuantity quantity={data.usersQuantity} />
              <EventsQuantity quantity={data.eventsQuantity} />
              <PartnersByEvent partnersByEvent={data.partnersByEvent} />
              <ParticipantsByEvent
                participantsByEvent={data.participantsByEvent}
              />
              <ActivitiesByEvent activitiesByEvent={data.activitiesByEvent} />
              <EventsTable events={data.events} />
            </Grid>
            {/* <Grid container direction="row" spacing={3}>
              <Grid item lg={4} md={6} xs={12}>
                <Grid
                  container
                  justify="flex-start"
                  direction="column"
                  spacing={3}
                >
                  <CreateCoordinatorCard />
                  <UsersQuantity quantity={data.usersQuantity} />
                  <EventsQuantity quantity={data.eventsQuantity} />
                </Grid>
              </Grid>
              <Grid item lg={4} md={6} xs={12}>
                <Grid
                  container
                  justify="flex-start"
                  direction="column"
                  spacing={3}
                >
                  <PartnersByEvent partnersByEvent={data.partnersByEvent} />
                  <ParticipantsByEvent
                    participantsByEvent={data.participantsByEvent}
                  />
                </Grid>
              </Grid>
              <Grid item lg={4} md={12} xs={12}>
                <Grid
                  className={classes.tableAndChartContainer}
                  container
                  direction="row"
                  justify="center"
                  spacing={3}
                >
                  <ActivitiesByEvent
                    activitiesByEvent={data.activitiesByEvent}
                  />
                  <EventsTable events={data.events} />
                </Grid>
              </Grid>
            </Grid> */}
          </Box>
        )}
      </div>
    </>
  );
};
