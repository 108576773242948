import { makeStyles } from '@material-ui/core';

export const useRowsStyles = makeStyles((theme) => ({
  multimedia: {
    [theme.breakpoints.up('md')]: {
      height: '280px'
    },
    [theme.breakpoints.down('sm')]: {
      height: '240px'
    }
  },
  product: {
    height: '179px'
  },
  users: {
    height: '95px'
  }
}));
