import * as yup from 'yup';
import { messages } from './RightMessages';
import moment from 'moment';

export const initialValues = {
  imageUrl: {
    file: '',
    blobUrl: ''
  },
  videoId: '',
  plataform: 'youtube',
  name: '',
  limitParticipants: '10',
  amount: 1,
  partners: [],
  fromDate: moment(Date.now()).format('YYYY/MM/DD'),
  toDate: moment(Date.now()).format('YYYY/MM/DD')
};
export const getValidationSchema = (intl) => {
  const requiredErrorMessage = intl.formatMessage(messages.isRequiredError);
  const invalidUrlError = intl.formatMessage(messages.invalidUrlError);
  const minError = intl.formatMessage(messages.minPartners);
  const dateError = intl.formatMessage(messages.dateError);
  const minDateError = intl.formatMessage(messages.minDateError);
  const onlyIntegersErrorMessage = intl.formatMessage(messages.onlyIntegers);
  const todayDate = moment(Date.now()).format('YYYY/MM/DD');

  return yup.object({
    videoId: yup.string(),
    plataform: yup.string(),
    name: yup
      .string()
      .trim()
      .required(requiredErrorMessage),
    limitParticipants: yup.string().required(requiredErrorMessage),
    amount: yup
      .number()
      .integer(onlyIntegersErrorMessage)
      .min(1, onlyIntegersErrorMessage),
    partners: yup.array(yup.string()).min(1, minError),
    fromDate: yup
      .date()
      .min(todayDate, minDateError)
      .required(requiredErrorMessage),
    toDate: yup
      .date()
      .min(todayDate, dateError)
      .required(requiredErrorMessage)
  });
};
