import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  activationLabel: {
    id: 'components.Licenses.Cards.Edit.activationLabel',
    defaultMessage: 'Activación'
  },
  totalLabel: {
    id: 'components.Licenses.Cards.Edit.totalLabel',
    defaultMessage: 'Total de usuarios'
  },
  idLabel: {
    id: 'components.Licenses.Cards.Edit.idLabel',
    defaultMessage: 'ID'
  },
  statusLabel: {
    id: 'components.Licenses.Cards.Edit.statusLabel',
    defaultMessage: 'Estatus'
  },
  edit: {
    id: 'components.Licenses.Cards.Edit.edit',
    defaultMessage: 'Editar'
  },
  delete: {
    id: 'components.Licenses.Cards.Edit.delete',
    defaultMessage: 'Eliminar'
  },
  history: {
    id: 'components.Licenses.Cards.Edit.history',
    defaultMessage: 'Historial'
  },
  switchActive: {
    id: 'components.Licenses.Cards.Edit.switchActive',
    defaultMessage: 'Activo'
  },
  switchDisabled: {
    id: 'components.Licenses.Cards.Edit.switchDisabled',
    defaultMessage: 'Inactivo'
  },
  areYouSureDelete: {
    id: 'components.Licenses.Cards.Edit.areYouSureDelete',
    defaultMessage: '¿ESTÁS SEGURO DE QUERER ELIMINAR LA SUSCRIPCIÓN:'
  },
  areYouSureChangeState: {
    id: 'components.Licenses.Cards.Edit.areYouSureChangeState',
    defaultMessage:
      '¿Estás seguro de querer cambiar el estado de esta suscripción?'
  },
  stateSubscriptionLabel: {
    id: 'components.Licenses.Cards.Edit.stateSubscriptionLabel',
    defaultMessage: 'Estado de la suscripción:'
  },
  cancel: {
    id: 'components.Licenses.Cards.Edit.cancel',
    defaultMessage: 'CANCELAR'
  },
  ok: {
    id: 'components.Licenses.Cards.Edit.ok',
    defaultMessage: 'OK'
  },
  deleteButton: {
    id: 'components.Licenses.Cards.Edit.deleteButton',
    defaultMessage: 'ELIMINAR'
  }
});
