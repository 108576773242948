import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  logoUrlLabel: {
    id: 'components.Partners.Forms.Edit.Right.logoUrlLabel',
    defaultMessage: 'Seleccionar logo'
  },
  businessNameLabel: {
    id: 'components.Partners.Forms.Edit.Right.userNameLabel',
    defaultMessage: 'Ingrese razón social'
  },
  contactNameLabel: {
    id: 'components.Partners.Forms.Edit.Right.userNamePlaceholder',
    defaultMessage: 'Nombre del contacto'
  },

  descriptionPlaceholder: {
    id: 'components.Partners.Forms.Edit.Right.completeNamePlaceholder',
    defaultMessage: 'Ingresa una descripción breve de la empresa'
  },
  contactPhoneLabel: {
    id: 'components.Partners.Forms.Edit.Right.contactPhoneLabel',
    defaultMessage: 'Teléfono del contacto'
  },
  contactPhonePlaceholder: {
    id: 'components.Partners.Forms.Edit.Right.contactPhonePlaceholder',
    defaultMessage: 'Ingresar teléfono del contacto'
  },
  emailLabel: {
    id: 'components.Partners.Forms.Edit.Right.emailLabel',
    defaultMessage: 'Correo electrónico'
  },
  emailPlaceholder: {
    id: 'components.Partners.Forms.Edit.Right.emailPlaceholder',
    defaultMessage: 'Ingresar correo electrónico'
  },

  submitButtonLabel: {
    id: 'components.Partners.Forms.Edit.Right.submitButtonLabel',
    defaultMessage: 'CREAR'
  },
  editSubmitButtonLabel: {
    id: 'components.Partners.Forms.Edit.Right.editSubmitButtonLabel',
    defaultMessage: 'GUARDAR'
  },
  isRequiredError: {
    id: 'components.Partners.Forms.Edit.Right.isRequiredError',
    defaultMessage: 'Este campo es requerido'
  },
  invalidUrlError: {
    id: 'components.Partners.Forms.Edit.Right.invalidUrlError',
    defaultMessage: 'url inválida'
  },
  selectCountry: {
    id: 'components.Partners.Forms.Edit.Right.selectCountry',
    defaultMessage: 'País'
  },
  success: {
    id: 'components.Partners.Form.Edit.Right.success',
    defaultMessage: 'LOS DATOS HAN SIDO ACTUALIZADOS CORRECTAMENTE'
  },
  ok: {
    id: 'components.Partners.Form.Edit.Right.ok',
    defaultMessage: 'Ok'
  },
  error: {
    id: 'components.Partners.Form.Edit.Right.error',
    defaultMessage: 'Hubo un error'
  },
  errorPhone: {
    id: 'components.Partners.Form.Edit.Right.errorPhone',
    defaultMessage: 'Teléfono inválido'
  }
});
