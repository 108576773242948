import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  initialTitle: {
    id: 'components.Challenge.CreateForm.RankingCategories.initialTitle',
    defaultMessage: 'Categoría de inicio'
  },
  initialDescription: {
    id: 'components.Challenge.CreateForm.RankingCategories.initialDescription',
    defaultMessage:
      'Ingresa el nombre y el rango de puntos que tiene un usuario al iniciar el evento'
  },
  followingTitle: {
    id: 'components.Challenge.CreateForm.RankingCategories.followingTitle',
    defaultMessage: 'Categorías siguientes'
  },
  followingDescription: {
    id:
      'components.Challenge.CreateForm.RankingCategories.followingDescription',
    defaultMessage:
      'Ingresa el nombre y el rango de puntos de las categorías siguientes a la categoría de inicio'
  },
  nameLabel: {
    id: 'components.Challenge.CreateForm.RankingCategories.input.name.label',
    defaultMessage: 'Nombre de la categoria'
  },
  minScoreLabel: {
    id:
      'components.Challenge.CreateForm.RankingCategories.input.minScore.label',
    defaultMessage: 'Puntaje minimo'
  },
  maxScoreLabel: {
    id:
      'components.Challenge.CreateForm.RankingCategories.input.maxScore.label',
    defaultMessage: 'Puntaje maximo'
  },
  submit: {
    id: 'components.Challenge.CreateForm.RankingCategories.submitButton.label',
    defaultMessage: 'Crear'
  },
  requiredError: {
    id: 'components.Challenge.CreateForm.input.error.required',
    defaultMessage: 'Este campo es requerido'
  }
});
