import { App } from 'components/App';
import { LanguageProvider } from 'components/Language';
import { ThemeProvider } from 'components/Theme';
import { NoEventsProvider } from 'components/NoEvents';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { AuthProvider } from '@octopy/react-auth';
import { ModalProvider as LibModalProvider } from '@octopy/react-modal';
import { LoaderProvider as LibLoaderProvider } from '@octopy/react-loader';
import { config } from 'providers/config';
import { translations } from './translations';
import { DashboardProvider } from 'components/Dashboard/DashboardProvider';
import { MenuProvider } from 'components/Menu';
import { CountriesProvider } from 'providers/countries/countriesContext';

export function Root() {
  return (
    <>
      <ThemeProvider defaultTheme={config.siteConfig.defaultTheme}>
        <LanguageProvider
          locale={config.siteConfig.languageCode}
          translations={translations}
        >
          <LibLoaderProvider>
            <LibModalProvider>
              <AuthProvider>
                <CountriesProvider>
                  <MenuProvider>
                    <NoEventsProvider>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DashboardProvider>
                          <App />
                        </DashboardProvider>
                      </MuiPickersUtilsProvider>
                    </NoEventsProvider>
                  </MenuProvider>
                </CountriesProvider>
              </AuthProvider>
            </LibModalProvider>
          </LibLoaderProvider>
        </LanguageProvider>
      </ThemeProvider>
    </>
  );
}
