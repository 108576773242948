import { useIntl } from 'react-intl';
import { Box, Typography } from '@material-ui/core';
import { PanTool, ArrowForwardRounded } from '@material-ui/icons';
import { withRouter } from 'react-router-dom';
import { useStyles } from './NoEventsStyles';
import { messages } from './NoEventsMessages';
import { useNoEvents } from './hooks/useNoEvents';
import { useCallback } from 'react';

export const NoEvents = withRouter(({ history }) => {
  const classes = useStyles();
  const intl = useIntl();
  const { handleNoEventShow } = useNoEvents();

  const gotoEvents = useCallback(() => {
    handleNoEventShow(false);
    history.push('/Events');
  }, [handleNoEventShow]);

  return (
    <Box className={classes.container} onClick={gotoEvents}>
      <PanTool className={classes.handIcon} />
      <Box className={classes.content}>
        <Typography className={classes.title}>
          {intl.formatMessage(messages.title)}
        </Typography>
        <Typography className={classes.subtitle}>
          {intl.formatMessage(messages.subtitle)}
        </Typography>
      </Box>
      <ArrowForwardRounded className={classes.arrowIcon} fontSize="large" />
    </Box>
  );
});
