import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Button, Drawer, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useModal } from '@octopy/react-modal';
import {
  createSuccessModalConfig,
  createErrorModalConfig,
  getDataError
} from 'utils/helpers';
import { useStyles } from './SideFormStyles';
import { messages } from './SideFormMessages';
import { FileInput, Select } from 'components/CreationForm';
import { addDatabase } from 'providers/api';
import { useLoader } from '@octopy/react-loader';
import { useDashboard } from 'components/Dashboard';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { formatDate } from 'utils/helpers';
import databaseExample from '../database_example.csv';

export const SideForm = ({
  visible,
  handleHide,
  setDatabases,
  eventId = ''
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const { handleOpenModal } = useModal();
  const { handleShowLoader } = useLoader();

  const edit = !!eventId;
  const { events } = useDashboard();

  const onSubmit = async (values, { setFieldError }) => {
    if (!values.file)
      return setFieldError('file', intl.formatMessage(messages.requiredError));

    try {
      handleShowLoader(true);

      const response = await addDatabase(values);

      const responseWithLastUpdate = {
        ...response,
        lastUpdate: formatDate(new Date())
      };

      setDatabases((databases) => ({
        ...databases,
        [response.eventId]: [
          ...(databases[eventId] ?? []),
          responseWithLastUpdate
        ]
      }));

      const event = events.find((event) => event.id === response.eventId);

      handleOpenModal(
        createSuccessModalConfig(
          messages[`databaseSuccessfully${edit ? 'Updated' : 'Loaded'}`],
          event.name,
          'ok'
        )
      );

      handleHide();
    } catch (request) {
      const { code, message } = getDataError(request);

      handleOpenModal(createErrorModalConfig(code, message));
    } finally {
      handleShowLoader(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      file: null,
      eventId: ''
    },
    validationSchema: yup.object({
      eventId: yup.string().required(intl.formatMessage(messages.requiredError))
    }),
    onSubmit
  });

  useEffect(() => formik.setFieldValue('eventId', eventId), [eventId]);

  useEffect(() => {
    if (!visible) {
      formik.resetForm();
    }
  }, [visible]);

  return (
    <Drawer anchor="right" open={visible} onClose={handleHide}>
      <div className={classes.drawer}>
        <div className={classes.closeIconContainer}>
          <Close className={classes.closeIcon} onClick={handleHide} />
        </div>
        <Typography className={classes.title}>
          {intl.formatMessage(messages[edit ? 'editTitle' : 'title'])}
        </Typography>
        <Select
          className={classes.eventSelect}
          label={intl.formatMessage(messages.eventIdLabel)}
          name="eventId"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.eventId}
          error={formik.errors.eventId}
          value={formik.values.eventId}
          disabled={edit}
          options={events.map((event) => ({
            value: event.id,
            label: event.name
          }))}
        />

        <Typography className={classes.body}>
          {intl.formatMessage(messages.body)}
        </Typography>

        <div className={classes.downloadExample}>
          <a
            href={databaseExample}
            onClick={(event) => event.stopPropagation()}
            download
          >
            {intl.formatMessage(messages.downloadExample)}
          </a>
        </div>

        <FileInput
          label={intl.formatMessage(messages.uploadButtonLabel)}
          fileName={formik.values.file?.name}
          error={formik.errors.file}
          onChange={({ file }) => formik.setFieldValue('file', file)}
          accept={'.csv'}
          acceptType={['text/csv', '.csv']}
        />

        <div className={classes.submitButtonContainer}>
          <Button
            variant="contained"
            color="primary"
            className={`${classes.button}`}
            onClick={formik.handleSubmit}
          >
            {intl.formatMessage(
              messages[edit ? 'editSubmitButtonLabel' : 'submitButtonLabel']
            )}
          </Button>
        </div>
      </div>
    </Drawer>
  );
};
