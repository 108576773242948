import { withRouter } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useFormik } from 'formik';
import { Typography } from '@material-ui/core';
import { Images } from 'assets';
import {
  Row,
  RightContainer,
  ImagePicker,
  TextField,
  TextArea,
  SubmitButton,
  TextFieldPhone,
  Select
} from 'components/CreationForm';
import { Person } from '@material-ui/icons';
import { useStyles } from './RightStyles';
import { useRowsStyles } from '../RowsStyles';
import { messages } from './RightMessages';
import { getValidationSchema, initialValues } from './helpers';
import { useDashboard } from 'components/Dashboard';
import { useCountries } from 'providers/countries/countriesContext';

const Right = withRouter(({ history }) => {
  const { americaCountries: countries } = useCountries();

  const { addNewPartner } = useDashboard();
  const options = [];

  countries.map((country) => options.push({ value: country, label: country }));

  const onSubmit = async (values) => {
    const newValues = {
      ...values,
      videoId:'',
      plataform:'youtube',
      stand: {
        Url: '',
        Title: '',
        Slogan: '',
        Description: '',
        Backgroud: ''
      }
    };
    const error = await addNewPartner(newValues);

    !error && history.push('/Partners');
  };

  const classes = useStyles();
  const rowsClasses = useRowsStyles();
  const intl = useIntl();

  const formik = useFormik({
    initialValues,
    validationSchema: getValidationSchema(intl),
    onSubmit
  });

  const submitButton = (
    <Row>
      <SubmitButton
        label={intl.formatMessage(messages.submitButtonLabel)}
        onClick={formik.handleSubmit}
      />
    </Row>
  );

  return (
    <>
      <RightContainer submitButton={submitButton}>
        <Row className={rowsClasses[1]}>
          <ImagePicker
            onChange={(url) => formik.setFieldValue('logoUrl', url)}
            Icon={Person}
            label={intl.formatMessage(messages.logoUrlLabel)}
            name="logoUrl"
            imageUrl={formik.values.logoUrl?.blobUrl}
          />
        </Row>
        <Row className={rowsClasses[2]}>
          <TextField
            key={'businessNameInput'}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors['businessName']}
            value={formik.values['businessName']}
            touched={formik.touched['businessName']}
            label={intl.formatMessage(messages['businessNameLabel'])}
            name={'businessName'}
            className={classes.input}
          />
          <TextField
            key={'emailInput'}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors['email']}
            value={formik.values['email']}
            touched={formik.touched['email']}
            label={intl.formatMessage(messages['emailLabel'])}
            name={'email'}
            className={classes.input}
          />
          <TextField
            key={'contactNameInput'}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors['contactName']}
            value={formik.values['contactName']}
            touched={formik.touched['contactName']}
            label={intl.formatMessage(messages['contactNameLabel'])}
            name={'contactName'}
            className={classes.input}
          />

          <TextFieldPhone
            key={`contactPhoneInput`}
            country="mx"
            regions={'america'}
            onChange={(e) => formik.setFieldValue('contactPhone', e)}
            onBlur={formik.handleBlur('contactPhone')}
            touched={formik.touched['contactPhone']}
            error={formik.errors['contactPhone']}
            value={formik.values.contactPhone}
            placeholder={intl.formatMessage(messages['contactPhoneLabel'])}
            name={'contactPhone'}
            size="small"
            className={classes.input}
          />

          <Select
            options={options}
            name={'country'}
            className={classes.input}
            onBlur={formik.handleBlur}
            value={formik.values['country']}
            touched={formik.touched['country']}
            error={formik.errors['country']}
            onChange={formik.handleChange}
          />
          <TextArea
            className={classes.input}
            id="description"
            key={'descriptionInput'}
            name={'description'}
            value={formik.values['description']}
            onChange={formik.handleChange}
            label={intl.formatMessage(messages.descriptionLabel)}
          />
        </Row>
        <Row className={classes.imagesBox}>
          <img src={Images.Scheme} alt="scheme" className={classes.scheme} />
          <Typography className={classes.notice}>
            {intl.formatMessage(messages.notice)}
          </Typography>
        </Row>
      </RightContainer>
    </>
  );
});

export { Right };
