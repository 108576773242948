import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '443px',
    padding: '35px 50px 20px 50px',
    boxSizing: 'border-box',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: '17px 25px 33px 25px'
    },
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flexGrow: 1
  },
  closeIconContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: '20px',
    color: theme.palette.secondary.main
  },
  closeIcon: {
    cursor: 'pointer',
    transition: 'color 300ms',
    padding: '5px',
    boxSizing: 'content-box',
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  title: {
    font: 'normal normal bold 21px/28px Roboto',
    color: theme.palette.primary.main,
    marginBottom: '15px'
  },
  subscriptionState: {
    font: 'normal normal normal 16px/21px Roboto',
    color: theme.palette.secondary.main,
    textAlign: 'right'
  },
  dataContainer: {
    backgroundColor: '#F9F9F9',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '8px',
    marginTop: '100px',
    paddingTop: '100px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center'
  },
  name: {
    font: 'normal normal bold 18px/24px Roboto',
    color: theme.palette.secondary.main
  },
  text: {
    font: 'normal normal normal 14px/19px Roboto',
    color: theme.palette.primary.main,
    marginTop: '5px'
  },
  grayText: {
    font: 'normal normal normal 15px/20px Roboto',
    color: '#00040F7F'
  },
  imageContainer: {
    width: '158px',
    height: '158px',
    position: 'absolute',
    top: '-79px',
    borderRadius: '50%',
    border: '2px solid #2AA4D5',
    overflow: 'hidden',
    backgroundColor: '#F9F9F9',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  image: {
    height: '100%',
    width: '100%',
    objectFit: 'cover'
  },
  altImage: {
    height: '55%',
    width: '55%'
  },
  inputContainer: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  emailInput: {
    width: '100%'
  },
  input: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: theme.spacing(2)
  },
  button: {
    width: 'auto !important'
  }
}));
