import { useIntl } from 'react-intl';
import { Box, Grid, Typography, Select, MenuItem } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { messages } from './QuizMessages';
import { useStyles } from './QuizStyles';
import { Create, Edit } from './Cards';
import { useNoEvents } from 'components/NoEvents/hooks/useNoEvents';
import { RefreshControl } from 'components/RefreshControl/RefreshControl';
import { useEffect, useState } from 'react';
import { changeFormatDate } from 'utils/helpers';
import { useLoader } from '@octopy/react-loader';
import { useModal } from '@octopy/react-modal';
import {
  getDataError,
  createErrorModalConfig,
  createSuccessModalConfig
} from 'utils/helpers';
import { getQuizzes, deleteQuizz } from 'providers/api';

const Quiz = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { show } = useNoEvents();
  const { handleShowLoader } = useLoader();
  const { handleOpenModal } = useModal();

  const [quizzes, setQuizzes] = useState([]);
  const [eventsWithQuiz, setEventsWithQuiz] = useState([]);

  const [itemsPerPage, setItemsPerPage] = useState(9);
  const [paginationData, setPaginationData] = useState({
    totalQuizzes: 0,
    activePage: 1
  });
  const { activePage, totalQuizzes } = paginationData;

  const handleChangePage = (_, value) => {
    setPaginationData({
      ...paginationData,
      activePage: value
    });
  };

  const getData = async () => {
    try {
      handleShowLoader(true);
      const quizzes = await getQuizzes();
      const eventsWithQuiz = quizzes.map((quiz) => quiz.id);

      setEventsWithQuiz(eventsWithQuiz);
      setQuizzes(quizzes);
    } catch (request) {
      const { code, message } = getDataError(request);

      handleOpenModal(createErrorModalConfig(code, message));
    } finally {
      handleShowLoader(false);
    }
  };

  const deleteQuizById = async (idQuiz, nameEvent) => {
    try {
      handleShowLoader(true);
      await deleteQuizz(idQuiz);
      await setQuizzes((quizzes) =>
        quizzes.filter((quiz) => quiz.quizId !== idQuiz)
      );
      await getData();

      handleOpenModal(
        createSuccessModalConfig(messages.quizDeleted, nameEvent, 'ok')
      );
    } catch (request) {
      const { code, message } = getDataError(request);

      handleOpenModal(createErrorModalConfig(code, message));
    } finally {
      handleShowLoader(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setPaginationData({
      ...paginationData,
      totalQuizzes: quizzes.length
    });
  }, [quizzes]);

  return (
    <>
      <Grid>
        <Grid container direction="row" justify="space-between">
          <Grid>
            <Typography className={classes.title}>
              {intl.formatMessage(messages.title)}
            </Typography>
            <Typography className={classes.subTitle}>
              {intl.formatMessage(messages.subTitle)}
            </Typography>
          </Grid>
          <RefreshControl refreshFunction={getData} />
        </Grid>
        {!show && (
          <Box className={classes.cardsContainer}>
            <Grid container justify="flex-start" spacing={3}>
              {activePage === 1 ? (
                <Create idEventsWithQuiz={eventsWithQuiz} />
              ) : null}
              {quizzes
                .slice(
                  (activePage - 1) * itemsPerPage - (activePage > 1 ? 1 : 0),
                  (activePage - 1) * itemsPerPage + (itemsPerPage - 1)
                )
                .map((quiz, index) => (
                  <Edit
                    key={`quiz${index}`}
                    quiz={{
                      idQuiz: quiz.quizId,
                      name: quiz.quizName,
                      event: `${quiz.name}`,
                      date: changeFormatDate(
                        quiz.from !== undefined ? quiz.from : ''
                      ),
                      logo: quiz.imageUrl
                    }}
                    idEvent={quiz.id}
                    deleteQuizById={deleteQuizById}
                  />
                ))}
            </Grid>
            <Box display="flex" justifyContent="center">
              <Pagination
                onChange={handleChangePage}
                count={Math.ceil((totalQuizzes + 1) / itemsPerPage)}
                color="primary"
                page={activePage}
              />
              <Select
                className={classes.paginationSelect}
                size="small"
                onChange={(e) => {
                  setItemsPerPage(e.target.value);
                  setPaginationData({
                    ...paginationData,
                    activePage: 1
                  });
                }}
                value={itemsPerPage}
              >
                {[3, 9, 15, 21].map((n) => (
                  <MenuItem key={`${n}`} value={n}>
                    {n} / {intl.formatMessage(messages.page)}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
        )}
      </Grid>
    </>
  );
};

export { Quiz };
