import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1
  },
  menuButton: {
    marginRight: theme.spacing(2),
    '& > svg': {
      fontSize: '30px'
    }
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  link: {
    display: 'flex',
    color: 'inherit',
    textDecoration: 'none'
  },
  roomsLogo: {
    width: '147px',
    height: '30px',
    [theme.breakpoints.down('xs')]: {
      width: '103px',
      height: '21px'
    }
  },
  appBar: {
    background:
      'transparent linear-gradient(90deg, #00040F 0%, #040D1A 100%) 0% 0% no-repeat',
    height: '50px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  avatar: {
    width: '30px',
    height: '30px'
  },
  userCompany: {
    font: 'normal normal normal 16px/21px Roboto',
    textAlign: 'right',
    letterSpacing: '0px',
    color: theme.palette.common.white,
    marginRight: '13px',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  avatarContainer: {
    width: '30px',
    height: '30px',
    borderRadius: '15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    overflow: 'hidden'
  },
  profileButton: {
    padding: '4px',
    borderRadius: '20px'
  }
}));

export { useStyles };
