import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  logoUrlLabel: {
    id: 'components.Licenses.Forms.Edit.Right.logoUrlLabel',
    defaultMessage: 'Seleccionar logo'
  },
  businessNameLabel: {
    id: 'components.Licenses.Forms.Edit.Right.userNameLabel',
    defaultMessage: 'Razón social'
  },
  contactNameLabel: {
    id: 'components.Licenses.Forms.Edit.Right.userNamePlaceholder',
    defaultMessage: 'Nombre del contacto'
  },
  descriptionPlaceholder: {
    id: 'components.Licenses.Forms.Edit.Right.completeNamePlaceholder',
    defaultMessage: 'Ingresa una descripción breve de la empresa'
  },
  contactPhoneLabel: {
    id: 'components.Licenses.Forms.Edit.Right.contactPhoneLabel',
    defaultMessage: 'Teléfono del contacto'
  },
  contactPhonePlaceholder: {
    id: 'components.Licenses.Forms.Edit.Right.contactPhonePlaceholder',
    defaultMessage: 'Ingresar teléfono del contacto'
  },
  emailLabel: {
    id: 'components.Licenses.Forms.Edit.Right.emailLabel',
    defaultMessage: 'Correo electrónico'
  },
  emailPlaceholder: {
    id: 'components.Licenses.Forms.Edit.Right.emailPlaceholder',
    defaultMessage: 'Ingresar correo electrónico'
  },
  submitButtonLabel: {
    id: 'components.Licenses.Forms.Edit.Right.input.submitButton.Edit.label',
    defaultMessage: 'CREAR'
  },
  editSubmitButtonLabel: {
    id: 'components.Licenses.Forms.Edit.Right.input.submitButton.edit.label',
    defaultMessage: 'EDITAR'
  },
  isRequiredError: {
    id: 'components.Licenses.Forms.Edit.Right.isRequiredError',
    defaultMessage: 'Este campo es requerido'
  },
  onlyIntegers: {
    id: 'components.Licenses.Forms.Edit.Right.onlyIntegers',
    defaultMessage: 'Sólo números enteros'
  },
  emailError: {
    id: 'components.Licenses.Forms.Edit.Right.emailError',
    defaultMessage: 'Correo electrónico inválido'
  },
  selectCountry: {
    id: 'components.Licenses.Forms.Edit.Right.selectCountry',
    defaultMessage: 'País'
  },
  limitClientsLabel: {
    id: 'components.Licenses.Forms.Edit.Right.limitClientsLabel',
    defaultMessage: 'Cantidad de organizaciones permitidas'
  },
  limitOrganizations: {
    id: 'components.Licenses.Forms.Edit.Right.limitOrganizations',
    defaultMessage: 'Sólo se permiten hasta 999 organizaciones'
  },
  minLimitOrganizations: {
    id: 'components.Licenses.Forms.Edit.Right.minLimitOrganizations',
    defaultMessage: 'Mínimo 1 organización'
  },
  minDigitsTelephone: {
    id: 'components.Licenses.Forms.Edit.Right.minDigitsTelephone',
    defaultMessage: 'Mínimo 6 dígitos'
  },
  statusLabel: {
    id: 'components.Licenses.Forms.Edit.Right.amountLabel',
    defaultMessage: 'Cantidad de organizaciones permitidas'
  },
  statusLicenseLabel: {
    id: 'components.Licenses.Forms.Edit.Right.statusLicenseLabel',
    defaultMessage: 'Estado de licencia'
  },
  switchActive: {
    id: 'components.Licenses.Forms.Edit.Right.switchActive',
    defaultMessage: 'Activa'
  },
  switchDisabled: {
    id: 'components.Licenses.Forms.Edit.Right.switchDisabled',
    defaultMessage: 'Inactivo'
  },
  activationLabel: {
    id: 'components.Licenses.Forms.Edit.Right.activationLabel',
    defaultMessage: 'Activación'
  },
  success: {
    id: 'components.Licenses.Forms.Edit.Right.success',
    defaultMessage: 'LOS DATOS HAN SIDO ACTUALIZADOS CORRECTAMENTE'
  },
  ok: {
    id: 'components.Licenses.Forms.Edit.Right.ok',
    defaultMessage: 'Ok'
  },
  error: {
    id: 'components.Licenses.Forms.Edit.Right.error',
    defaultMessage: 'Ha ocurrido un error durante la petición'
  },
  areYouSureChangeState: {
    id: 'components.Licenses.Forms.Edit.Right.areYouSureChangeState',
    defaultMessage:
      '¿Estás seguro de querer cambiar el estado de esta suscripción?'
  },
  cancel: {
    id: 'components.Licenses.Forms.Edit.Right.cancel',
    defaultMessage: 'CANCELAR'
  }
});
