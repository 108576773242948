import MUIRichTextEditor from 'mui-rte';
import { convertToRaw } from 'draft-js';
import { useRef } from 'react';

export const Editor = ({ onBlur, onChange, ...props }) => {
  const valueRef = useRef();

  return (
    <MUIRichTextEditor
      {...props}
      onChange={(state) => {
        const raw = convertToRaw(state.getCurrentContent());
        const value = JSON.stringify(raw);

        valueRef.current = value;

        onChange && onChange(value);
      }}
      onBlur={() => onBlur && onBlur(valueRef.current)}
    />
  );
};
