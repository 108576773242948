/* eslint-disable no-param-reassign */
import { useIntl } from 'react-intl';
import { Typography, Box, Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { messages } from './EditMessages';
import { useStyles } from './EditStyles';
import { Images } from 'assets';
import { useModal } from '@octopy/react-modal';
import { ModalButton, ModalText } from 'components/ModalMessages';

const Edit = withRouter(({ quiz, idEvent, deleteQuizById }) => {
  const history = useHistory();
  const intl = useIntl();
  const classes = useStyles();
  const { handleOpenModal } = useModal();

  const handleDeleteClick = () => {
    const modalTitle = (
      <ModalText>
        {intl.formatMessage(messages.areYouSureDelete)}
        <ModalText white>&nbsp;{quiz.event}</ModalText>?
      </ModalText>
    );
    const modalActionButtons = (
      <ModalButton onClick={() => deleteQuizById(quiz.idQuiz, quiz.event)}>
        {intl.formatMessage(messages.deleteText)}
      </ModalButton>
    );

    handleOpenModal({
      title: modalTitle,
      actionButtons: modalActionButtons,
      configProps: { maxWidth: 'xs' }
    });
  };

  return (
    <Grid item lg={4} md={6} sm={12} xs={12}>
      <Box className={classes.container}>
        <Box className={classes.content}>
          <Box className={classes.imgBox}>
            <img
              src={quiz.logo ? quiz.logo : Images.Question}
              alt="logo"
              className={quiz.logo ? classes.img : classes.imgDefault}
              onLoad={(e) => {
                const nameImage = e.target.currentSrc.split('/');
                const iconQuiz = Images.Question.split('/');

                if (
                  nameImage[nameImage.length - 1] ===
                  iconQuiz[iconQuiz.length - 1]
                ) {
                  e.target.className = classes.img;
                }
              }}
              onError={(e) => {
                e.target.src = Images.Question;
                e.target.className = classes.imgDefault;
                e.target.onerror = true;
              }}
            />
          </Box>
          <Box className={classes.info}>
            <Typography className={classes.title}>{quiz.name}</Typography>
            <Typography className={classes.assign}>
              {`${intl.formatMessage(messages.assign)} ${quiz.event}`}
            </Typography>
            <Typography className={classes.date}>
              {`${intl.formatMessage(messages.date)} ${quiz.date}`}
            </Typography>
          </Box>
        </Box>
        <div className={classes.actions}>
          <Grid container className={classes.actionsContainer}>
            <Grid
              className={classes.edit}
              alignItems="center"
              item
              container
              onClick={() => history.push(`/EditQuiz/${idEvent}`)}
              xs={4}
            >
              <div className={classes.actionButtonImageContainer}>
                <img
                  alt="edit"
                  src={Images.Pencil}
                  className={classes.actionButtonImage}
                />
              </div>
              <Typography component="span">
                {intl.formatMessage(messages.edit)}
              </Typography>
            </Grid>
            <Grid
              className={classes.delete}
              alignItems="center"
              item
              container
              xs={4}
              onClick={handleDeleteClick}
            >
              <div className={classes.actionButtonImageContainer}>
                <img
                  alt="delete"
                  src={Images.Trash}
                  className={classes.actionButtonImage}
                />
              </div>
              <Typography component="span">
                {intl.formatMessage(messages.deleteText)}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Box>
    </Grid>
  );
});

export { Edit };
