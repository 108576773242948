import {
  FormControl,
  InputLabel,
  Select as SelectMUI,
  FormHelperText,
  MenuItem,
  Typography
} from '@material-ui/core';
import { useStyles } from './SelectStyles';

export const Select = ({
  label,
  options,
  error,
  touched,
  value,
  onChange,
  onBlur,
  name,
  className,
  showHelperText = true,
  selectProps = {},
  ...props
}) => {
  const classes = useStyles();

  return (
    <FormControl
      error={touched && Boolean(error)}
      className={`${classes.formControl} ${className}`}
      {...props}
    >
      <InputLabel>{label}</InputLabel>
      <SelectMUI
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        className={classes.menu}
        displayEmpty
        {...selectProps}
      >
        {options?.map((option) => (
          <MenuItem key={`option ${option.value}`} value={option.value}>
            <Typography>{option.label}</Typography>
          </MenuItem>
        ))}
      </SelectMUI>
      {showHelperText && touched && error && (
        <FormHelperText>{error}</FormHelperText>
      )}
    </FormControl>
  );
};
