import React from 'react';
import PropTypes from 'prop-types';
import { SwatchesPicker } from 'react-color';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TextField } from 'components/CreationForm';
import { messages } from './ColorFormMessages';
import { useStyles } from './ColorFormStyles';
import { useIntl } from 'react-intl';
import { useState, useEffect } from 'react';

const ColorForm = ({ setValue, edit, initialColors }) => {
  const intl = useIntl();
  const classes = useStyles();

  useEffect(() => {
    if (edit) {
      setTitle(initialColors.title);
      setcolorSubTitle(initialColors.subtitle);
      setcolorSubTitle2(initialColors.subtitle2);
      setDefaultButton(initialColors.button);
      setHover(initialColors.hover);
    }
  }, [initialColors]);

  const [title, setTitle] = useState(initialColors.title);
  const [colorSubTitle, setcolorSubTitle] = useState(initialColors.subtitle);
  const [colorSubTitle2, setcolorSubTitle2] = useState(initialColors.subtitle2);
  const [defaultButton, setDefaultButton] = useState(initialColors.button);
  const [hover, setHover] = useState(initialColors.hover);

  const [displayTitle, setdisplayTitle] = useState(false);
  const [displaySubTitle, setdisplaydisplaySubTitle] = useState(false);
  const [displaySubTitle2, setdisplaySubTitle2] = useState(false);
  const [displayDefault, setdisplayDefault] = useState(false);
  const [displayHover, setdisplayHover] = useState(false);

  const handleChangeComplete = (title) => {
    setValue('title', title.hex);
    setTitle(title.hex);
    setdisplayTitle(!displayTitle);
  };
  const handleChangeDisplay = () => {
    setdisplayTitle(!displayTitle);
  };

  const handleChangeCompleteSubtitle = (colorSubTitle) => {
    setValue('subtitle', colorSubTitle.hex);
    setcolorSubTitle(colorSubTitle.hex);
    setdisplaydisplaySubTitle(!displaySubTitle);
  };
  const handleChangeDisplaySubtitle = () => {
    setdisplaydisplaySubTitle(!displaySubTitle);
  };
  const handleChangeCompleteSubtitle2 = (colorSubTitle2) => {
    setValue('subtitle2', colorSubTitle2.hex);
    setcolorSubTitle2(colorSubTitle2.hex);
    setdisplaySubTitle2(!displaySubTitle2);
  };
  const handleChangeDisplaySubtitle2 = () => {
    setdisplaySubTitle2(!displaySubTitle2);
  };
  const handleChangeCompletedefaultButton = (colordefaultButton) => {
    setValue('button', colordefaultButton.hex);
    setDefaultButton(colordefaultButton.hex);
    setdisplayDefault(!displayDefault);
  };
  const handleChangeDisplayDefault = () => {
    setdisplayDefault(!displayDefault);
  };
  const handleChangeCompleteHover = (colorHover) => {
    setValue('hover', colorHover.hex);
    setHover(colorHover.hex);
    setdisplayHover(!displayHover);
  };
  const handleChangeDisplayHover = () => {
    setdisplayHover(!displayHover);
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="accordionColorForm"
      >
        <Typography className={classes.title}>
          {intl.formatMessage(messages[edit ? 'editTitle' : 'title'])}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className={classes.container}>
          <div className={classes.containerTile}>
            <Typography className={classes.colorTexts}>
              {intl.formatMessage(messages.colorTexts)}
            </Typography>
          </div>
          <div className={classes.titles}>
            <TextField
              autoComplete="off"
              key={'title'}
              onClick={handleChangeDisplay}
              value={title}
              label={intl.formatMessage(messages.titles)}
              name={'title'}
              className={classes.inputs}
            />
            {displayTitle ? (
              <div className={classes.swatchesPickerContainer}>
                <SwatchesPicker onChangeComplete={handleChangeComplete} />
              </div>
            ) : null}
          </div>
          <div className={classes.titles}>
            <TextField
              autoComplete="off"
              key={'subtitle'}
              onClick={handleChangeDisplaySubtitle}
              value={colorSubTitle}
              label={intl.formatMessage(messages.subtitles1)}
              name={'subtitle'}
              className={classes.inputs}
            />
            {displaySubTitle ? (
              <div className={classes.swatchesPickerContainer}>
                <SwatchesPicker
                  onChangeComplete={handleChangeCompleteSubtitle}
                />
              </div>
            ) : null}
          </div>
          <div className={classes.titles}>
            <TextField
              autoComplete="off"
              key={'subtitle2'}
              onClick={handleChangeDisplaySubtitle2}
              value={colorSubTitle2}
              label={intl.formatMessage(messages.subtitles2)}
              name={'subtitle2'}
              className={classes.inputs}
            />
            {displaySubTitle2 ? (
              <div className={classes.swatchesPickerContainer}>
                <SwatchesPicker
                  onChangeComplete={handleChangeCompleteSubtitle2}
                />
              </div>
            ) : null}
          </div>
          <div className={classes.containerTile}>
            <Typography className={classes.colorTexts}>
              {intl.formatMessage(messages.colorBottoms)}
            </Typography>
          </div>
          <div className={classes.titles}>
            <TextField
              autoComplete="off"
              key={'default'}
              onClick={handleChangeDisplayDefault}
              value={defaultButton}
              label={intl.formatMessage(messages.default)}
              name={'default'}
              className={classes.inputs}
            />
            {displayDefault ? (
              <div className={classes.swatchesPickerContainer}>
                <SwatchesPicker
                  onChangeComplete={handleChangeCompletedefaultButton}
                />
              </div>
            ) : null}
          </div>
          <div className={classes.titles}>
            <TextField
              autoComplete="off"
              key={'hover'}
              onClick={handleChangeDisplayHover}
              value={hover}
              label={intl.formatMessage(messages.hover)}
              name={'hover'}
              className={classes.inputs}
            />
            {displayHover ? (
              <div className={classes.swatchesPickerContainer}>
                <SwatchesPicker onChangeComplete={handleChangeCompleteHover} />
              </div>
            ) : null}
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

ColorForm.propTypes = {
  setValue: PropTypes.func.isRequired,
  edit: PropTypes.bool
};

export { ColorForm };
