import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Typography, Grid, Box } from '@material-ui/core';
import { messages } from './ChallengeMessages';
import { useStyles } from './ChallengeStyles';
import { ChallengeCard } from './ChallengeCard';
import { CreateCard } from './CreateCard';
import { useNoEvents } from 'components/NoEvents/hooks/useNoEvents';
import { useDashboard } from 'components/Dashboard';
import { RefreshControl } from 'components/RefreshControl/RefreshControl';
import { formatDate, formatTime } from 'utils/helpers';

const Challenge = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { events, fetchEvents } = useDashboard();

  const { show } = useNoEvents();

  const getData = async () => {
    await fetchEvents();
  };

  useEffect(() => {}, []);

  useEffect(() => {
    getData();
  }, []);

  const dateAndTime = (dateTime, type) => {
    if (dateTime) {
      const dateUpdate = new Date(dateTime);

      if (type === 'time') {
        let timeEvent = formatTime(dateUpdate);
        let time = `${timeEvent} hrs.`;

        return time;
      } else {
        return formatDate(dateUpdate);
      }
    } else {
      return null;
    }
  };

  return (
    <>
      <div>
        <div className={classes.header}>
          <Grid container direction="row" justify="space-between">
            <Grid>
              <Typography className={classes.title}>
                {intl.formatMessage(messages.title)}
              </Typography>
              <Typography className={classes.subTitle}>
                {intl.formatMessage(messages.subtitle)}
              </Typography>
            </Grid>
            <RefreshControl refreshFunction={getData} />
          </Grid>
        </div>
        {!show && (
          <Box className={classes.cardsContainer}>
            <Grid container justify="flex-start" spacing={3}>
              <CreateCard />
              {events.map((event) => (
                <ChallengeCard
                  id={event.id}
                  eventName={event.name}
                  date={dateAndTime(event.from, 'date')}
                />
              ))}
            </Grid>
          </Box>
        )}
      </div>
    </>
  );
};

export { Challenge };
