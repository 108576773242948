import { changeUserPassword, updateDataUserConnected } from 'providers/api';

export const organizedUserInformation = (object, emailUser) => {
  const actions = [];
  const dataUser = { payload: {} };

  if (object.hasOwnProperty('password')) {
    actions.push({
      type: 'updatePassword',
      payload: { password: object.password }
    });
  }

  ['file', 'email'].map((prop) => {
    if (object.hasOwnProperty(prop)) {
      dataUser.type = 'updateInformation';
      dataUser.payload[prop] = object[prop];
      if (prop === 'file') dataUser.payload.email = emailUser;
    }
  });
  if (Object.keys(dataUser).length > 1) actions.push(dataUser);

  return actions;
};

export const callToApi = (arrayActions, token) =>
  arrayActions.map(async ({ type, payload }) => {
    try {
      if (type === 'updatePassword') {
        await changeUserPassword(payload, token);
      } else if (type === 'updateInformation') {
        await updateDataUserConnected(payload);
      }
    } catch (request) {
      throw request;
    }
  });
