import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  answers: {
    id: 'components.Quiz.Forms.Create.Tabs.Questions.answers',
    defaultMessage: 'Respuestas'
  },
  answer: {
    id: 'components.Quiz.Forms.Create.Tabs.Questions.answer',
    defaultMessage: 'Respuesta'
  },
  correctAnswer: {
    id: 'components.Quiz.Forms.Create.Tabs.Questions.correctAnswer',
    defaultMessage: 'Asignar como respuesta correcta'
  },
  cardScore: {
    id: 'components.Quiz.Forms.Create.Tabs.Questions.scoreField',
    defaultMessage: 'Ingresar puntaje de la tarjeta'
  },
  insertQuestion: {
    id: 'components.Quiz.Forms.Create.Tabs.Questions.cardQuestion',
    defaultMessage: 'Ingresar pregunta'
  },
  isRequiredError: {
    id: 'components.Quiz.Forms.Create.Tabs.Questions.isRequiredError',
    defaultMessage: 'Este campo es requerido'
  },
  deleteQuestion: {
    id: 'components.Quiz.Forms.Create.Tabs.Questions.deleteQuestion',
    defaultMessage: 'ELIMINAR'
  },
  minimumNumberAnswers: {
    id: 'components.Quiz.Forms.Create.Tabs.Questions.minimumNumberAnswers',
    defaultMessage: 'Escribe mínimo 2 respuestas'
  }
});
