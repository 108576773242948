import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    minHeight: '100vh',
    paddingTop: '50px'
  },
  contentContainer: {
    maxHeight: 'calc(100vh - 50px)',
    width: 'calc(100% - 113px)',
    position: 'relative',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  labelsContainer: {
    minHeight: 'calc(100vh - 50px)',
    [theme.breakpoints.down('sm')]: {
      minHeight: 9,
      borderBottomLeftRadius: '10px',
      borderBottomRightRadius: '10px'
    },
    backgroundColor: '#040D1A'
  },
  labels: {
    color: '#fff'
  },
  goBackIconContainer: {
    top: '50px !important',
    right: '30px !important',
    [theme.breakpoints.down('sm')]: {
      right: '9px !important',
      top: '15px !important',
      '& svg': {
        color: 'white'
      }
    }
  }
}));
