import { getFormData } from 'utils/helpers';
import { responseMapper } from 'utils/responseMapper';
import { instance } from '../instance';
import {
  templateGetDatabases,
  templateUpdateDatabase,
  templateAddDatabase,
  counterTemplateAddDatabase
} from '../mappers';

export const getDatabases = async () => {
  const response = await instance.get('/database');
  const mappedResponse = responseMapper({
    template: templateGetDatabases,
    data: response.data
  });

  const {
    headerResponse: { code },
    payload
  } = mappedResponse;

  if (code !== 200) throw code;

  return payload;
};

export const addDatabase = async (payload) => {
  const mappedPayload = responseMapper({
    template: counterTemplateAddDatabase,
    data: payload
  });

  const formData = getFormData(mappedPayload);

  const response = await instance.post('/database', formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });

  const mappedResponse = responseMapper({
    template: templateAddDatabase,
    data: response.data
  });

  const {
    headerResponse: { code },
    payload: responsePayload
  } = mappedResponse;

  if (code !== 200) throw code;

  return responsePayload;
};

export const updateDatabase = async () => {
  const response = await instance.put('/database');
  const mappedResponse = responseMapper({
    template: templateUpdateDatabase,
    data: response.data
  });

  const {
    headerResponse: { code }
  } = mappedResponse;

  if (code !== 200) throw code;
};
