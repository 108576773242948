import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import { useStyles } from './ActivitiesByEventStyles';
import { messages } from './ActivitiesByEventMessages';
import { Chart } from 'components/Chart';

export const ActivitiesByEvent = ({ activitiesByEvent }) => {
  const classes = useStyles();
  const intl = useIntl();

  const options = {
    values: activitiesByEvent.map((value) => ({
      x: value.eventName,
      y: value.activitiesQuantity
    })),
    color: '#eaab00',
    yTitle: intl.formatMessage(messages.yTitle)
  };

  return (
    <Grid item lg={6} md={6} className={classes.gridItem}>
      <div className={classes.container}>
        <div className={classes.title}>
          {intl.formatMessage(messages.title)}
        </div>
        <Chart {...options} key={3} />
      </div>
    </Grid>
  );
};
