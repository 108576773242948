import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    height: '169px',
    boxShadow: '0px 0px 5px #00000029',
    borderRadius: '5px',
    maxWidth: '400px',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    padding: theme.spacing(2)
  },
  imageMaskContainer: {
    height: '80px',
    width: '80px',
    borderRadius: '50%',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(2),
    boxShadow: '0px 3px 6px #00000029',
    overflow: 'hidden'
  },
  imageMask: ({ icon }) => ({
    height: '100%',
    width: '100%',
    backgroundColor: '#009FDA',
    transition: 'all 300ms',
    maskImage: `url("${icon}")`,
    maskSize: '41px',
    maskRepeat: 'no-repeat',
    maskPosition: 'center'
  }),
  title: {
    font: 'normal normal bold 20px/26px Roboto',
    color: '#00040F'
  },
  quantity: {
    font: 'normal normal normal 40px/53px Roboto',
    color: '#00040F7F'
  }
}));
