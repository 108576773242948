import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.Principal.title',
    defaultMessage: 'Sala Principal'
  },
  subtitle: {
    id: 'components.Principal.subtitle',
    defaultMessage:
      'Conferencias, webinars principales que tendrán lugar en un evento.'
  },
  conferencesQuantity: {
    id: 'components.Principal.conferencesQuantity',
    defaultMessage: 'Total de conferencias:'
  },
  deletedSuccessfully: {
    id: 'components.Principal.modal.delete.success',
    defaultMessage: 'Se han eliminado exitosamente las conferencias del evento:'
  },
  ok: {
    id: 'components.Principal.modal.ok',
    defaultMessage: 'Ok'
  },
  page: {
    id: 'components.Principal.pagination.select.page',
    defaultMessage: 'página'
  }
});
