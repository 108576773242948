import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.Licenses.Forms.Create.Left.title',
    defaultMessage: 'Crear licenciamiento'
  },
  imageLabel: {
    id: 'components.Licenses.Forms.Create.Left.imageLabel',
    defaultMessage: 'Seleccionar una imagen'
  },
  dataLabel: {
    id: 'components.Licenses.Forms.Create.Left.dataLabel',
    defaultMessage: 'Agrega los datos necesarios para crear un registro'
  },
  typeLabel: {
    id: 'components.Licenses.Forms.Create.Left.typeLabel',
    defaultMessage: 'Tipo registro'
  },
  amountLabel: {
    id: 'components.Licenses.Forms.Create.Left.amountLabel',
    defaultMessage: 'Cantidad de organizaciones'
  }
});
