import { useStyles } from './FileInputStyles';
import { CloudUpload } from '@material-ui/icons';
import { processFile } from 'utils/helpers';

export const FileInput = ({
  label,
  fileName,
  className,
  error,
  icon,
  onChange,
  containerClassName,
  id = '',
  acceptType = [],
  ...props
}) => {
  const classes = useStyles();

  const handleChange = ({ target: { files } }) => {
    if (files[0]) {
      !acceptType.length
        ? onChange(processFile(files[0]))
        : (acceptType.includes(files[0].type) ||
            acceptType.some((type) => files[0].name.endsWith(type))) &&
          onChange(processFile(files[0]));
    }
  };

  return (
    <div className={`${classes.upload} ${containerClassName}`}>
      <label
        className={`${classes.button} ${classes.uploadButton}`}
        htmlFor={`fileInput${id}`}
      >
        {icon || <CloudUpload fontSize="small" />}
        <span>{label}</span>
      </label>
      <input
        className={`${classes.fileInput} ${className}`}
        onChange={handleChange}
        id={`fileInput${id}`}
        type="file"
        {...props}
      />
      <span className={classes[error ? 'error' : 'fileName']}>
        {error || fileName}
      </span>
    </div>
  );
};
