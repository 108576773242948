import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';
import { Select } from 'components/CreationForm';
import { useStyles } from './CreateFormStyles';
import { messages } from './CreateFormMessages';
import { FormTabs } from './FormTabs';
import { FormikProvider, useFormik } from 'formik';
import { getValidationSchema, initialValues } from './helpers';
import { GoBack } from 'components/GoBack';
import { useDashboard } from 'components/Dashboard';
import { withRouter } from 'react-router-dom';

export const CreateForm = withRouter(({ history }) => {
  const classes = useStyles();
  const intl = useIntl();
  const { events, fetchEvents } = useDashboard();
  const [edit] = useState(history.location.state.id);
  const [eventSelect, setEventSelect] = useState(
    history.location.state.id || events[0].id
  );

  const options = [];
  const errorMessages = {
    requiredMessage: intl.formatMessage(messages.requiredError)
  };

  const formik = useFormik({
    isInitialValid: false,
    enableReinitialize: true,
    validationSchema: getValidationSchema(errorMessages),
    initialValues
  });
  const getData = async () => {
    await fetchEvents();
  };

  const handleSelectEvent = (event) => {
    setEventSelect(event.target.value);
  };

  useEffect(async () => {
    await getData();
    await getDataChallenge();
  }, []);

  useEffect(async () => {
    await events.map((event) => {
      options.push({ label: event.name, value: event.id });
    });
  }, [events]);

  const { fetchChallenges } = useDashboard();

  const getDataChallenge = async () => {
    await fetchChallenges();
  };

  return (
    <FormikProvider value={formik}>
      <div className={classes.container}>
        <div className={classes.header}>
          <Typography className={classes.title}>
            {intl.formatMessage(messages.title)}
          </Typography>
          <Typography className={classes.subtitle}>
            {intl.formatMessage(messages.subtitle)}
          </Typography>
          <GoBack />
          <Select
            name="optionRooms"
            value={eventSelect}
            onChange={(value) => handleSelectEvent(value)}
            className={classes.selectEvents}
            showHelperText={false}
            options={events.map((event) => ({
              value: event.id,
              label: event.name
            }))}
            disabled={edit ? 'disabled' : null}
          ></Select>
        </div>
        <FormTabs formik={formik} eventSelect={eventSelect} />
      </div>
    </FormikProvider>
  );
});
