import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: '3px',
    marginBottom: '25px'
  },
  accordion: {
    boxShadow: '0px 3px 4px #00000029'
  },
  summary: {
    borderBottom: '1px solid #E6E6E6'
  },
  details: {
    padding: '20px 30px 30px 30px',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap'
    },
    minHeight: '251px',
    [theme.breakpoints.down('xs')]: {
      padding: '10px 15px 15px 15px'
    }
  },
  title: {
    font: 'normal normal normal 15px/20px Roboto',
    color: theme.palette.primary.main,
    '&.disabled': {
      color: theme.palette.text.disabled
    }
  },
  actionButtonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px'
  },
  actionButton: {
    marginLeft: '10px',
    borderRadius: '50%'
  },
  actionButtonImage: {
    width: '28px',
    height: '28px'
  },
  left: {
    marginRight: '40px'
  },
  rightBottomRow: {
    marginTop: '15px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start'
  },
  innerRow: {
    width: '100%'
  },
  input: {
    width: '290px',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      width: '80%'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  radioGroup: {
    display: 'inline-flex',
    height: '45px',
    alignItems: 'center'
  },
  radio: {
    marginLeft: '15px'
  },
  platformLogoImage: {
    width: '23px',
    height: '23px',
    marginLeft: '6px'
  }
}));
