import { useState } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Typography, Container, Box, Grid, Switch } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { messages } from './EditMessages';
import { useStyles } from './EditStyles';
import { Images } from 'assets';
import { useModal } from '@octopy/react-modal';
import { ModalText, ModalButton } from 'components/ModalMessages';
import { useDashboard } from 'components/Dashboard';

const Edit = withRouter(
  ({
    idCard,
    idLicense,
    positionInArray,
    status,
    activation,
    handleHistoryClick,
    licenseDetails
  }) => {
    const history = useHistory();
    const intl = useIntl();
    const classes = useStyles({ icon: Images.PersonLicense });
    const { handleOpenModal, handleCloseModal } = useModal();
    const [idLicenseCard] = useState(idLicense);
    const [checked, setChecked] = useState(status);
    const { deleteLicenseById, updateLicenseStatusById } = useDashboard();

    const handleDeleteClick = () => {
      const modalTitle = (
        <ModalText>
          {intl.formatMessage(messages.areYouSureDelete)}
          <ModalText white>&nbsp;{licenseDetails.businessName}</ModalText>?
        </ModalText>
      );
      const modalActionButtons = (
        <ModalButton
          onClick={() => deleteLicenseById(licenseDetails, positionInArray)}
        >
          {intl.formatMessage(messages.deleteButton)}
        </ModalButton>
      );

      handleOpenModal({
        title: modalTitle,
        actionButtons: modalActionButtons,
        configProps: {
          maxWidth: 'xs'
        }
      });
    };

    const handleChangeStateLicenseClick = () => {
      const modalBody = (
        <div>
          <Typography variant="body1" className={classes.modalStatusTitle}>
            {intl.formatMessage(messages.areYouSureChangeState)}
          </Typography>
          <br />
        </div>
      );
      const modalActionButtons = (
        <div>
          <ModalButton onClick={handleCloseModal}>
            {intl.formatMessage(messages.cancel)}
          </ModalButton>
          <ModalButton
            onClick={async () => {
              await updateLicenseStatusById(licenseDetails, positionInArray);
            }}
          >
            {intl.formatMessage(messages.ok)}
          </ModalButton>
        </div>
      );

      handleOpenModal({
        body: modalBody,
        actionButtons: modalActionButtons,
        configProps: {
          width: 'lg'
        }
      });
    };

    return (
      <Grid item lg={4} md={6} sm={12} xs={12}>
        <Container className={classes.Container}>
          <Box className={classes.Content}>
            <Box className={classes.imgBox}>
              <img
                className={
                  !!licenseDetails.logoUrl ? classes.logo : classes.iconPerson
                }
                src={
                  !!licenseDetails.logoUrl
                    ? licenseDetails.logoUrl
                    : Images.PersonLicense
                }
                alt="logo"
              />
            </Box>
            <Box className={classes.info}>
              <Typography className={classes.title}>
                {licenseDetails.businessName}
              </Typography>
              <Typography className={classes.id}>
                {intl.formatMessage(messages.idLabel)}: {idCard}
              </Typography>
              <Typography className={classes.id}>
                {intl.formatMessage(messages.statusLabel)}:
                <FormControlLabel
                  className={classes.switch}
                  control={
                    <Switch
                      name="active"
                      checked={checked}
                      onClick={handleChangeStateLicenseClick}
                      size="small"
                    />
                  }
                  labelPlacement="start"
                  label={
                    checked
                      ? intl.formatMessage(messages.switchActive)
                      : intl.formatMessage(messages.switchDisabled)
                  }
                />
              </Typography>
              <Typography className={classes.assign}>
                {intl.formatMessage(messages.activationLabel)}: {activation}
              </Typography>
              <Typography className={classes.assign}>
                {intl.formatMessage(messages.totalLabel)}:{' '}
                {licenseDetails.limitClients}
              </Typography>
            </Box>
          </Box>
          <div className={classes.actions}>
            <Grid container className={classes.actionsContainer}>
              <Grid
                className={`${classes.edit} ${!status && classes.disabled}`}
                alignItems="center"
                item
                container
                onClick={() =>
                  !licenseDetails.deleted && status
                    ? history.push({
                        pathname: `/EditLicense/${idLicenseCard}`,
                        state: {
                          positionInArray: positionInArray
                        }
                      })
                    : null
                }
                xs={4}
              >
                <div className={classes.actionButtonImageContainer}>
                  <img
                    alt="edit"
                    src={Images.Pencil}
                    className={classes.actionButtonImage}
                  />
                </div>
                <Typography component="span">
                  {intl.formatMessage(messages.edit)}
                </Typography>
              </Grid>
              <Grid
                className={classes.delete}
                alignItems="center"
                item
                container
                xs={4}
                onClick={!licenseDetails.deleted ? handleDeleteClick : null}
              >
                <div className={classes.actionButtonImageContainer}>
                  <img
                    alt="delete"
                    src={Images.Trash}
                    className={classes.actionButtonImage}
                  />
                </div>
                <Typography component="span">
                  {intl.formatMessage(messages.delete)}
                </Typography>
              </Grid>
              <Grid
                className={classes.history}
                alignItems="center"
                item
                container
                xs={4}
                onClick={() => handleHistoryClick(idLicense)}
              >
                <div className={classes.actionButtonImageContainer}>
                  <img
                    alt="history"
                    src={Images.history}
                    className={classes.actionButtonImage}
                  />
                </div>
                <Typography component="span">
                  {intl.formatMessage(messages.history)}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Container>
      </Grid>
    );
  }
);

Edit.propTypes = {
  idCard: PropTypes.string,
  idLicense: PropTypes.string,
  positionInArray: PropTypes.number,
  status: PropTypes.bool,
  activation: PropTypes.string,
  handleHistoryClick: PropTypes.func,
  licenseDetails: PropTypes.object
};

export { Edit };
