import { useIntl } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useStyles } from './UploadCardStyles';
import { messages } from './UploadCardMessages';
import { Images } from 'assets';
import databaseExample from '../database_example.csv';

export const UploadCard = ({ handleShowSideForm }) => {
  const classes = useStyles({ icon: Images.Database });
  const intl = useIntl();

  return (
    <Grid
      item
      lg={4}
      md={6}
      sm={12}
      xs={12}
      onClick={() => handleShowSideForm()}
    >
      <div className={classes.container}>
        <div className={classes.info}>
          <div>
            <div className={classes.imageMaskContainer}>
              <div className={classes.imageMask} />
            </div>
          </div>
          <div>
            <Typography className={classes.title}>
              {intl.formatMessage(messages.title)}
            </Typography>
            <Typography className={classes.subtitle}>
              {intl.formatMessage(messages.subtitle)}
            </Typography>
            <a
              href={databaseExample}
              onClick={(event) => event.stopPropagation()}
              download
              className={classes.downloadExample}
            >
              {intl.formatMessage(messages.downloadExample)}
            </a>
          </div>
        </div>
        <div className={classes.actions}>
          <Grid container justify="center" className={classes.actionsContainer}>
            <Add className={classes.addIcon} />
          </Grid>
        </div>
      </div>
    </Grid>
  );
};
