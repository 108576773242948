import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  textField: {
    width: '291px',
    marginBottom: '0px',
    marginRight: '10px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '80%',
      marginBottom: '20px'
    }
  },
  inputPhoneStyle: {
    width: '291px !important'
  },
  buttonPhoneStyle: {
    height: '45px',
    border: '0'
  },
  submitButton: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10%',
      width: '80%'
    }
  },
  settings: {
    cursor: 'pointer',
    marginRight: '15px'
  },
  input: {
    width: '290px',
    marginLeft: '6px',
    marginRight: '6px',
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      width: '212px'
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      width: '80%'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  radioButtonsContainer: {
    marginLeft: '6px',
    marginRight: '6px',
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      marginLeft: 0
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: 0
    }
  },
  radioButtonLabel: {
    marginLeft: 0,
    '& .MuiTypography-root': {
      marginLeft: '6px',
      font: 'normal normal normal 12px/16px Roboto'
    }
  },
  containerBrandingColors: {
    [theme.breakpoints.only('sm')]: {
      width: '80%',
      marginLeft: '10%'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  hiddenElement: {
    display: 'none'
  }
}));

export { useStyles };
