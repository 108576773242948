export const templateGetDataChallenge = {
  headerResponse: {
    code: 'headerResponse.code',
    message: 'headerResponse.message'
  },
  payload: {
    items: [
      'payload.items',
      {
        id: '_id',
        name: 'name',
        score: 'score',
        room: 'room',
        enable: 'enable',
        event: 'event',
        idAction: 'id_action'
      }
    ]
  }
};

export const templateGetDataChallengeSaved = {
  headerResponse: {
    code: 'headerResponse.code',
    message: 'headerResponse.message'
  },
  payload: {
    items: [
      'payload.items',
      {
        idRel: '_id',
        deleted: 'deleted',
        challenges: {
          id: 'challenges._id',
          name: 'challenges.name',
          score: 'challenges.score',
          room: 'challenges.room',
          enable: 'challenges.enable',
          id_action: 'challenges.id_action'
        },
        event: 'event'
      }
    ]
  }
};

export const templateGetDataPolicies = {
  headerResponse: {
    code: 'headerResponse.code',
    message: 'headerResponse.message'
  },
  deleted: 'payload.deleted',
  id: 'payload._id',
  value: 'payload.policies',
  event: 'payload.event',
  file: 'payload.file'
};

export const templateGetDataCategories = {
  headerResponse: {
    code: 'headerResponse.code',
    message: 'headerResponse.message'
  },
  categories: [
    'payload.categories',
    {
      id: '_id',
      name: 'name',
      minScore: 'min_score',
      maxScore: 'max_score'
    }
  ]
};

export const responseTemplateAddPolicies = {
  headerResponse: {
    code: 'headerResponse.code',
    message: 'headerResponse.message'
  },
  file: 'payload.file',
  policies: 'payload.policies',
  event: 'payload.event'
};

export const templateAddCategories = {
  name: 'name',
  min_score: 'minScore',
  max_score: 'maxScore'
};

export const templateAddChallenge = {
  name: 'name',
  score: 'score',
  room: 'room',
  enable: 'enable',
  id_action: 'id_action',
  event: 'event'
};

export const responseTemplateAddChallenge = {
  headerResponse: {
    code: 'headerResponse.code',
    message: 'headerResponse.message'
  },
  challenges: [
    'payload.challenges',
    {
      id: '_id',
      name: 'name',
      score: 'score',
      room: 'room',
      enable: 'enable',
      idAction: 'id_action'
    }
  ]
};

export const responseTemplateUpdateChallenge = {
  headerResponse: {
    code: 'headerResponse.code',
    message: 'headerResponse.message'
  },
  id: 'payload._id',
  name: 'payload.name',
  score: 'payload.score',
  room: 'payload.room',
  enable: 'payload.enable',
  id_action: 'payload.id_action',
  event: 'payload.event'
};

export const responseTemplateAddCategories = {
  headerResponse: {
    code: 'headerResponse.code',
    message: 'headerResponse.message'
  },
  payload: {
    id: '_id',
    deleted: 'deleted',
    categories: [
      'payload.categories',
      {
        id: '_id',
        category: 'name',
        minScore: 'min_score',
        maxScore: 'max_score'
      }
    ]
  }
};

export const templateGetHeaderChallenge = {
  headerResponse: {
    code: 'headerResponse.code',
    message: 'headerResponse.message'
  }
};
