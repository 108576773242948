import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';
import { Select, SubmitButton } from 'components/CreationForm';
import { FormikProvider, useFormik, Field } from 'formik';
import { useStyles } from './CreateStyles';
import { messages } from './CreateMessages';
import { Conferences } from './Conferences';
import { initialValues, getValidationSchema } from './helpers';
import { GoBack } from 'components/GoBack/GoBack';
import { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { formatDate, formatTime, getDatesBetween } from 'utils/helpers';
import { useLoader } from '@octopy/react-loader';
import { addConference } from 'providers/api';
import { useModal } from '@octopy/react-modal';
import {
  getDataError,
  createErrorModalConfig,
  createSuccessModalConfig
} from 'utils/helpers';
import { useDashboard } from 'components/Dashboard';

export const Create = withRouter(({ history }) => {
  const classes = useStyles();
  const intl = useIntl();
  const { events, addNumberOfEventConferences } = useDashboard();
  const [loadLimitExceeded, setLoadLimitExceeded] = useState(false);
  const [eventsWithoutConferences, setEventsWithoutConferences] = useState([]);
  const [availableDates, setAvailableDates] = useState([]);
  const { handleShowLoader } = useLoader();
  const { handleOpenModal } = useModal();

  useEffect(() => {
    const timeout = setTimeout(() => setLoadLimitExceeded(true), 5000);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (events.length || loadLimitExceeded) {
      try {
        const eventsWithoutConferences = events.filter(
          (event) =>
            !event.conferencesQuantity || event.id === formik.values.eventId
        );

        setEventsWithoutConferences(eventsWithoutConferences);

        if (!eventsWithoutConferences.length) {
          handleOpenModal(
            createErrorModalConfig('noEventsWithoutConferences', '')
          );

          throw new Error();
        }
      } catch {
        history.push('/Principal');
      }
    }
  }, [events, loadLimitExceeded]);

  const onSubmit = async (values) => {
    const formattedValues = {
      ...values,
      conferences: values.conferences.map((conference) => ({
        ...conference,
        date: new Date(
          `${conference.date} ${formatTime(conference.time)}`
        ).toString(),
        eventId: values.eventId
      }))
    };

    const { conferences } = formattedValues;
    const lastConference = conferences[conferences.length - 1];

    try {
      handleShowLoader(true);
      if (!lastConference.pushed) {
        await addConference(lastConference);
        addNumberOfEventConferences(values.eventId, +1);

        const eventName = eventsWithoutConferences.find(
          (event) => event.id === values.eventId
        ).name;

        handleOpenModal(
          createSuccessModalConfig(messages.success, eventName, 'ok')
        );
      }
    } catch (request) {
      const { code, message } = getDataError(request);

      handleOpenModal(createErrorModalConfig(code, message));
    } finally {
      handleShowLoader(false);
      history.push('/Principal');
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: getValidationSchema(intl),
    onSubmit
  });

  useEffect(() => {
    const { eventId } = formik.values;

    if (eventId) {
      const event = eventsWithoutConferences.find(
        (event) => event.id === eventId
      );

      const availableDates = getDatesBetween(
        new Date(event.from),
        new Date(event.to)
      );

      const formattedAvailableDates = availableDates.map((date) =>
        formatDate(date)
      );

      setAvailableDates(formattedAvailableDates);

      for (let i = 0; i < formik.values.conferences.length; i++) {
        formik.setFieldValue(`conferences.${i}.date`, '');
      }
    }
  }, [formik.values.eventId]);

  return (
    <FormikProvider value={formik}>
      <div className={classes.container}>
        <div className={classes.accordionContainer}>
          <div className={classes.header}>
            <Typography className={classes.title}>
              {intl.formatMessage(messages.title)}
            </Typography>
            <Typography className={classes.subtitle}>
              {intl.formatMessage(messages.subtitle)}
            </Typography>
            <GoBack />
            <Field name="eventId">
              {({ field, meta: { error, touched } }) => (
                <Select
                  label={intl.formatMessage(messages.eventIdLabel)}
                  className={classes.eventSelect}
                  {...field}
                  error={error}
                  touched={touched}
                  disabled={Boolean(formik.values.hasPushed)}
                  options={eventsWithoutConferences.map((event) => ({
                    value: event.id,
                    label: event.name
                  }))}
                />
              )}
            </Field>
          </div>
          {formik.values.eventId && (
            <Conferences
              eventId={formik.values.eventId}
              conferences={formik.values.conferences}
              setFieldValue={formik.setFieldValue}
              availableDates={availableDates}
              errors={formik.errors.conferences}
            />
          )}
        </div>
        <div>
          <SubmitButton
            onClick={formik.handleSubmit}
            className={classes.submitButton}
          >
            {intl.formatMessage(messages.submit)}
          </SubmitButton>
        </div>
      </div>
    </FormikProvider>
  );
});
