import logo from './svg/logo.svg';
import jsIcon from './svg/javascript-icon.svg';
import reactIcon from './svg/react-js-icon.svg';
import scssIcon from './svg/sass-icon.svg';
import svgIcon from './svg/sun-icon.svg';
import folderIcon from './svg/file-manager.svg';
import folderOpenIcon from './svg/folder-open-icon.svg';
import imageIcon from './svg/image-icon.svg';
import bracketsIcon from './svg/brackets-icon.svg';
import calendar from './svg/Icon material-event.svg';
import add from './svg/add.svg';
import handshake from './svg/Icon awesome-handshake.svg';
import OctopyRooms from './svg/octopy-rooms.svg';
import userAvatar from './svg/user_avatar.svg';
import event from './svg/event.svg';
import BD from './svg/Icon awesome-database.svg';
import loader from './svg/loader.svg';
import addCircle from './svg/Icon material-add-circle.svg';
import removeCircle from './svg/Icon material-remove-circle.svg';
import exhibitionIcon from './svg/exhibicion-icon.svg';
import Trash from './svg/trash.svg';
import Pencil from './svg/Icon awesome-pencil-alt.svg';
import Person from './svg/Icon material-supervisor-account.svg';
import Database from './svg/Icon awesome-database.svg';
import Medal from './svg/Icon awesome-medal.svg';
import Work from './svg/Icon material-work.svg';
import File from './svg/awesome-file-alt.svg';
import Star from './svg/material-star.svg';
import Scheme from './svg/esquema-landing.svg';
import Settings from './svg/settings.svg';
import List from './svg/list.svg';
import Chart from './svg/Icon awesome-chart-bar.svg';
import Chair from './svg/Icon awesome-chair.svg';
import Question from './svg/Icon awesome-question.svg';
import PuzzlePiece from './svg/Icon awesome-puzzle-piece.svg';
import CalendarDay from './svg/Icon awesome-calendar-day.svg';
import Licenses from './svg/Icon material-account-circle.svg';
import history from './svg/Icon awesome-history.svg';
import FirstPosition from './svg/position1.svg';
import SecondPosition from './svg/position2.svg';
import ThirdPosition from './svg/position3.svg';
import Timeline from './svg/Icon material-timeline.svg';
import Filter from './svg/Icon awesome-filter.svg';
import Eye from './svg/eye.svg';
import EyeSlash from './svg/eye-slash.svg';
import PersonLicense from './svg/person_license.svg';
import backgroundLogin from './svg/bg-plataforma.svg';
import vimeo from './svg/vimeoLogo.svg';
import youtube from './svg/youtubeLogo.svg';

const Images = {
  logo,
  jsIcon,
  backgroundLogin,
  reactIcon,
  exhibitionIcon,
  scssIcon,
  svgIcon,
  folderIcon,
  folderOpenIcon,
  imageIcon,
  bracketsIcon,
  calendar,
  add,
  handshake,
  OctopyRooms,
  userAvatar,
  event,
  BD,
  removeCircle,
  addCircle,
  Trash,
  loader,
  Pencil,
  Person,
  Work,
  Database,
  Medal,
  File,
  Star,
  Scheme,
  Settings,
  List,
  Chart,
  Chair,
  Question,
  PuzzlePiece,
  CalendarDay,
  Licenses,
  history,
  FirstPosition,
  SecondPosition,
  ThirdPosition,
  Timeline,
  Filter,
  Eye,
  EyeSlash,
  PersonLicense,
  vimeo,
  youtube
};

export { Images };
