import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { messages } from './ModalErrorMessageMessages';
import { useStyles } from './ModalErrorMessageStyles';
import { ModalText } from '../messageComponents/ModalText';

const ModalErrorMessage = ({ code, message }) => {
  const intl = useIntl();
  const classes = useStyles();

  let messageToShow;

  if (!!code && !!message && [400, 409, 500].includes(code)) {
    messageToShow = message;
  } else if (code in messages) {
    messageToShow = messages[code];
  } else {
    messageToShow = messages.error;
  }

  messageToShow = Array.isArray(messageToShow)
    ? messageToShow.join(',\n')
    : messageToShow;

  return (
    <ModalText>
      {typeof messageToShow === 'object'
        ? intl.formatMessage(messageToShow)
        : messageToShow}
    </ModalText>
  );
};

ModalErrorMessage.propTypes = {
  code: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
};

export { ModalErrorMessage };
