import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  awardPoliciesDescription: {
    id: 'components.Challenge.CreateForm.FormTabs.awardPoliciesDescription',
    defaultMessage: 'Escribe las políticas de premiación para el evento'
  },
  requiredError: {
    id: 'components.Challenge.CreateForm.FormTabs.error.required',
    defaultMessage: 'Este campo es requerido'
  },
  challenges: {
    id: 'components.Challenge.CreateForm.FormTabs.challenges',
    defaultMessage: 'Logros / Desafíos'
  },
  policies: {
    id: 'components.Challenge.CreateForm.FormTabs.policies',
    defaultMessage: 'Políticas de premiación'
  },
  categories: {
    id: 'components.Challenge.CreateForm.FormTabs.categories',
    defaultMessage: 'Categorías ranking'
  }
});
