import * as yup from 'yup';
import { Drawer } from '@material-ui/core';
import { DatePicker } from 'components/CreationForm';
import { Select } from 'components/CreationForm';
import { SubmitButton } from 'components/CreationForm';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import { messages } from './FilterMessages';
import { useStyles } from './FilterStyles';

export const Filter = ({ visible, handleHide }) => {
  const intl = useIntl();
  const classes = useStyles();

  const requiredErrorMessage = intl.formatMessage(messages.requiredError);
  const formik = useFormik({
    initialValues: {
      event: '',
      date: new Date()
    },
    validationSchema: yup.object({
      event: yup.string().required(requiredErrorMessage),
      date: yup.string().required(requiredErrorMessage)
    }),
    onSubmit: (values) => alert(JSON.stringify(values, null, 2))
  });

  return (
    <Drawer
      p={2}
      ModalProps={{ className: classes.modal }}
      PaperProps={{ className: classes.container }}
      anchor="right"
      onClose={handleHide}
      open={visible}
    >
      <div className={classes.title}>{intl.formatMessage(messages.title)}</div>
      <div className={classes.description}>
        {intl.formatMessage(messages.description)}
      </div>
      <div className={classes.line} />
      <div className={classes.form}>
        <Select
          name="event"
          value={formik.values.event}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.event}
          touched={formik.touched.event}
          label={intl.formatMessage(messages.eventLabel)}
          className={classes.input}
          showHelperText={false}
          options={[
            { label: 'Evento 1', value: 'event1' },
            { label: 'Evento 2', value: 'event2' },
            { label: 'Evento 3', value: 'event3' },
            { label: 'Evento 4', value: 'event4' }
          ]}
        />
        <DatePicker
          value={formik.values.date}
          onChange={(value) => formik.setFieldValue('date', value)}
          error={formik.errors.date}
          touched={formik.touched.date}
          className={classes.input}
          label={intl.formatMessage(messages.dateLabel)}
        />
        <SubmitButton
          className={classes.button}
          onClick={formik.handleSubmit}
          label={intl.formatMessage(messages.submitButtonLabel)}
        />
      </div>
    </Drawer>
  );
};
