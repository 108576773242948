import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.Principal.ConferenceForms.Create.title',
    defaultMessage: 'Agregar conferencias'
  },
  subtitle: {
    id: 'components.Principal.ConferenceForms.Create.subtitle',
    defaultMessage:
      'Conferencias, webinars principales que tendrán lugar en un evento.'
  },
  eventIdLabel: {
    id: 'components.Principal.ConferenceForms.Create.input.eventId.label',
    defaultMessage: 'Nombre del evento'
  },
  submit: {
    id: 'components.Principal.ConferenceForms.Create.input.submitButton.label',
    defaultMessage: 'Crear'
  },
  requiredError: {
    id: 'components.Principal.ConferenceForms.Create.input.error.required',
    defaultMessage: 'Este campo es requerido'
  },
  success: {
    id: 'components.Principal.ConferenceForms.Create.modal.success',
    defaultMessage:
      'Se han agregado exitosamente las conferencias para el evento: '
  }
});
