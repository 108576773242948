import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Box, Switch, Typography, MenuItem } from '@material-ui/core';
import { messages } from './ChallengeListMessages';
import { useStyles } from './ChallengeListStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import { useDashboard } from 'components/Dashboard';
import { Select } from 'components/CreationForm/Right/inputs/Select/Select';
import { Select as SelectUI } from '@material-ui/core';
import { usePagination } from 'hooks/usePagination';
import { Pagination } from '@material-ui/lab';
import { ExpandMore } from '@material-ui/icons';
import { FieldArray } from 'formik';
import { Field } from 'formik';
import { FormikProvider } from 'formik';
import { SubmitButton } from 'components/CreationForm/Right/inputs/SubmitButton/SubmitButton';
import { withRouter } from 'react-router';

const ChallengeList = withRouter(
  ({ eventSelect, formik, step, setStep, idRel, history }) => {
    const intl = useIntl();
    const classes = useStyles();

    const {
      challenges,

      addNewChallenge,
      updateChallengeByIdSaved
    } = useDashboard();
    const [challengeList, setchallengeList] = useState([]);

    const [roomSelect, setRoomSelect] = useState('Todas');
    let sizeArray = 0;

    const [roomList, setRoomList] = useState([]);

    const scoreList = [
      { value: 100 },
      { value: 200 },
      { value: 300 },
      { value: 400 },
      { value: 500 },
      { value: 600 },
      { value: 700 },
      { value: 800 },
      { value: 900 },
      { value: 1000 }
    ];
    const errorMessages = {
      requiredMessage: intl.formatMessage(messages.requiredError)
    };

    let options = [];

    const {
      page,
      rowsPerPage,
      handleChangePage,
      handleChangeRowsPerPage
    } = usePagination(0, 10);

    const TableCell = withStyles({
      root: {
        borderBottom: 'none',
        borderTop: 'none',
        border: 0
      }
    })(MuiTableCell);

    const handleSelectRoom = (event) => {
      setRoomSelect(event.target.value);
    };

    const changeList = () => {
      if (formik.values.challengeList) {
        formik.values.challengeList.forEach((element) => {
          if (!options.includes(element.room)) {
            options.push(element.room);
          }
        });
      }

      setRoomList(['Todas', ...options]);
      setRoomSelect('Todas');
    };

    useEffect(() => {
      changeList();
    }, [formik.values.challengeList]);

    const handleSwitch = (event, id) => {
      formik.handleChange(event);
      const index = formik.values.challengeList.findIndex(
        (item) => item.id === id
      );

      formik.setFieldValue(
        `challengeList[${index}].switch`,
        !formik.values.challengeList[index].switch
      );
    };

    const handleSelectScore = (event, id) => {
      formik.handleChange(event);

      const index = formik.values.challengeList.findIndex(
        (item) => item.id === id
      );

      formik.setFieldValue(`challengeList[${index}].select`, true);
    };

    function findById(id) {
      const challenge = formik.values.challengeList.find(
        (item) => item.id === id
      );

      return challenge.enable;
    }

    const createSubmit = async () => {
      const challenges = formik.values.challengeList;
      let newList = [];
      let createNewList = {};

      challenges
        .filter((challenge) => challenge.enable === true)
        .map((challenge) => {
          newList.push(challenge.id);
        });
      createNewList.challenges = newList;
      createNewList.event = eventSelect;

      const error = await addNewChallenge(createNewList);

      if (!!!error) setStep('Update');

      formik.setFieldValue(
        'challengeList',
        challenges.filter((challenge) => challenge.enable === true)
      );
    };

    function mapperSaved(challenges) {
      let challengesSaved = [];

      challenges.map((challenge) => {
        challengesSaved.push({
          name: challenge.name,
          score: challenge.score,
          room: challenge.room,
          enable: challenge.enable,
          id_action: challenge.id_action
        });
      });

      return challengesSaved;
    }

    const updateSubmit = async () => {
      var formData = new FormData();

      formData.append('id', idRel);
      formData.append('challenges', formik.values.challengeList);

      const error = await updateChallengeByIdSaved(idRel, {
        challenges: mapperSaved(formik.values.challengeList)
      });

      if (!!!error) history.push('/Challenge');
    };

    return (
      <>
        <Box className={classes.boxChallenge}>
          <Typography className={classes.challengesDescription}>
            {intl.formatMessage(messages.challengesDescription)}
          </Typography>

          <Select
            name="optionRooms"
            value={roomSelect}
            onChange={(value) => handleSelectRoom(value)}
            className={classes.selectRooms}
            showHelperText={false}
            options={roomList.map((room) => ({
              value: room,
              label: room
            }))}
          />
        </Box>
        <div>
          <FormikProvider value={formik}>
            <TableContainer>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow className={classes.tableHead}>
                    <TableCell align="left">
                      {intl.formatMessage(messages['challengeName'])}
                    </TableCell>
                    {step === 'Update' ? (
                      <TableCell align="center">
                        {intl.formatMessage(messages['challengeScore'])}
                      </TableCell>
                    ) : null}

                    <TableCell align="center">
                      {intl.formatMessage(messages['challengeRoom'])}
                    </TableCell>
                    <TableCell align="center">
                      {intl.formatMessage(messages['challengeEnable'])}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <FieldArray name="challengeList">
                  <TableBody>
                    {!!formik.values?.challengeList
                      ? formik.values?.challengeList
                          .filter((c) =>
                            roomSelect === 'Todas'
                              ? c.room !== 'Todas'
                              : c.room === roomSelect
                          )
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((challenge, index, { length }) => {
                            sizeArray = length;

                            return (
                              <>
                                <TableRow key={challenge.id}>
                                  <TableCell align="left">
                                    {challenge.name}
                                  </TableCell>
                                  {step === 'Update' ? (
                                    <TableCell align="center">
                                      <Field
                                        name={`challengeList.${formik.values.challengeList.findIndex(
                                          ({ id }) => challenge.id === id
                                        )}.score`}
                                      >
                                        {({
                                          field,
                                          meta: { error, touched }
                                        }) => (
                                          <Select
                                            {...field}
                                            onChange={(event) =>
                                              handleSelectScore(
                                                event,
                                                challenge.id
                                              )
                                            }
                                            className={classes.selectScore}
                                            options={scoreList.map((score) => ({
                                              value: score.value,
                                              label: `${score.value} pts`
                                            }))}
                                          />
                                        )}
                                      </Field>
                                    </TableCell>
                                  ) : null}

                                  <TableCell align="center">
                                    {challenge.room}
                                  </TableCell>
                                  <TableCell align="center">
                                    <Field
                                      name={`challengeList.${formik.values.challengeList.findIndex(
                                        ({ id }) => challenge.id === id
                                      )}.enable`}
                                    >
                                      {({
                                        field,
                                        meta: { error, touched }
                                      }) => (
                                        <Switch
                                          {...field}
                                          checked={findById(challenge.id)}
                                          onChange={(event) =>
                                            handleSwitch(event, challenge.id)
                                          }
                                          size="small"
                                        />
                                      )}
                                    </Field>
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })
                      : null}
                  </TableBody>
                </FieldArray>
              </Table>
            </TableContainer>
            {step === 'Create' ? (
              <SubmitButton
                onClick={() => createSubmit()}
                className={classes.submitButton}
              >
                {intl.formatMessage(messages.createsubmit)}
              </SubmitButton>
            ) : (
              <SubmitButton
                onClick={() => updateSubmit()}
                className={classes.submitButton}
              >
                {intl.formatMessage(messages.updatesubmit)}
              </SubmitButton>
            )}
          </FormikProvider>
          <div className={classes.paginationContainer}>
            <Pagination
              count={
                roomSelect === 'Todas'
                  ? Math.ceil(
                      (formik.values?.challengeList.length + 1) / rowsPerPage
                    )
                  : Math.ceil(sizeArray / rowsPerPage)
              }
              page={page + 1}
              onChange={handleChangePage}
              defaultPage={0}
              size="small"
              variant="outlined"
              shape="rounded"
            />
            <SelectUI
              className={classes.paginationSelect}
              onChange={handleChangeRowsPerPage}
              value={rowsPerPage}
              IconComponent={ExpandMore}
            >
              {[10, 20, 30].map((n) => (
                <MenuItem key={`${n}PaginationOption`} value={n}>
                  {n} / {intl.formatMessage(messages.page)}
                </MenuItem>
              ))}
            </SelectUI>
          </div>
        </div>
      </>
    );
  }
);

ChallengeList.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { ChallengeList };
