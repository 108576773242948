import * as yup from 'yup';

export const initialValues = {
  value: ''
};

export const getValidationSchema = ({ requiredMessage }) =>
  yup.object({
    value: yup.string()
  });
