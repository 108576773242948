import { useIntl } from 'react-intl';
import { Typography, Grid } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import { useStyles } from './DatabaseCardStyles';
import { messages } from './DatabaseCardMessages';
import { Images } from 'assets';
import { useDashboard } from 'components/Dashboard';
import { useEffect, useState } from 'react';
import {
  counterFormatDate,
  downloadFiles,
  formatDate,
  getMaxDate
} from 'utils/helpers';

const DatabaseCard = ({ eventId, database, handleShowSideForm }) => {
  const intl = useIntl();
  const classes = useStyles({ icon: Images.Database });
  const { events } = useDashboard();
  const [event, setEvent] = useState(null);

  useEffect(
    () =>
      events.length && setEvent(events.find((event) => event.id === eventId)),
    [events]
  );

  const onDownloadClick = (event) => {
    event.stopPropagation();

    const fileUrls = database.map((file) => file.fileUrl);

    downloadFiles(fileUrls);
  };

  return (
    <Grid
      onClick={() => handleShowSideForm(eventId)}
      item
      lg={4}
      md={6}
      sm={12}
      xs={12}
    >
      <div className={classes.container}>
        <div className={classes.info}>
          <div className={classes.imageMaskContainer}>
            <div className={classes.imageMask} />
          </div>
          <div className={classes.textContainer}>
            <Typography className={classes.name}>
              {event?.name} Database
            </Typography>
            <Typography className={classes.date}>
              {intl.formatMessage(messages.lastUpdate)}
              <br />
              {formatDate(
                getMaxDate(
                  database.map((file) => counterFormatDate(file.lastUpdate))
                )
              )}
            </Typography>
          </div>
        </div>
        <div className={classes.actions}>
          <Grid container className={classes.actionsContainer}>
            <Grid
              className={classes.download}
              alignItems="center"
              item
              onClick={onDownloadClick}
              container
              xs={4}
            >
              <CloudDownload className={classes.icon} />
              <Typography component="span">
                {intl.formatMessage(messages.download)}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    </Grid>
  );
};

export { DatabaseCard };
