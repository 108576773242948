import {
  FormControl,
  FormHelperText,
  InputLabel,
  TextareaAutosize
} from '@material-ui/core';
import { useState } from 'react';
import { useStyles } from './TextAreaStyles';

export const TextArea = ({
  error,
  touched,
  value,
  onChange,
  onFocus,
  onBlur,
  placeholder,
  label,
  formControlProps = {},
  className,
  ...props
}) => {
  const classes = useStyles();
  const [innerValue, setInnerValue] = useState('');
  const [focused, setFocused] = useState(false);

  return (
    <FormControl
      className={className}
      {...formControlProps}
      error={touched && Boolean(error)}
    >
      <InputLabel
        className={`${classes.label} ${focused && 'focused'}`}
        shrink={Boolean(value) || Boolean(innerValue) || focused}
      >
        {label || placeholder}
      </InputLabel>
      <TextareaAutosize
        {...props}
        className={classes.textArea}
        defaultValue={value}
        variant="filled"
        onChange={(event) => {
          setInnerValue(event.target.value);
          onChange && onChange(event);
        }}
        onFocus={(event) => {
          setFocused(true);
          onFocus && onFocus(event);
        }}
        onBlur={(event) => {
          setFocused(false);
          onBlur && onBlur(event);
        }}
        style={{
          overflow: 'auto'
        }}
      />
      <FormHelperText>{touched && error}</FormHelperText>
    </FormControl>
  );
};
