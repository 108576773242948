import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.Challenge.CreateForm.title',
    defaultMessage: 'Crear challenge'
  },
  name: {
    id: 'components.Challenge.CreateForm.name',
    defaultMessage: 'Nombre del Challenge'
  },
  subtitle: {
    id: 'components.Challenge.CreateForm.subtitle',
    defaultMessage:
      'Listado de desafíos y políticas de premiación para algún evento.'
  },
  eventNameLabel: {
    id: 'components.Challenge.CreateForm.input.eventName.label',
    defaultMessage: 'Nombre del evento'
  },
  submit: {
    id: 'components.Challenge.CreateForm.input.submitButton.label',
    defaultMessage: 'Crear'
  },
  requiredError: {
    id: 'components.Challenge.CreateForm.input.error.required',
    defaultMessage: 'Este campo es requerido'
  }
});
