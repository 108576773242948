import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  input: {
    width: '290px',
    marginLeft: '6px',
    marginRight: '6px',
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      width: '212px'
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      width: '80%'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },

  dateLabel: {
    marginTop: '11px',
    marginBottom: '-5px',
    paddingLeft: '18px',
    font: 'normal normal normal 15px/20px Roboto',
    color: '#989898',
    width: '80%',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  errorDate: {
    color: theme.palette.error['main'],
    marginTop: '5px',
    marginLeft: '30px',
    marginBottom: '-13px',
    fontSize: '12px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '22px'
    }
  },
  divToDate: {
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      width: '212px'
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      width: '80%'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  inputToDate: {
    width: '290px',
    marginLeft: '6px',
    marginRight: '6px',
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      width: '212px'
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      width: '100%'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  imgBox: {
    marginLeft: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 3px 6px #00000029',
    height: 20,
    width: 20,
    borderRadius: '50%',
    marginRight: theme.spacing(2),
    overflow: 'hidden'
  },
  logoImage: {
    height: '100%',
    width: '100%',
    objectFit: 'cover'
  },
  errorTime: {
    color: theme.palette.error['main'],
    marginTop: '5px',
    marginLeft: '30px',
    marginBottom: '-13px',
    fontSize: '12px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '22px'
    }
  },
  centerRadio: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '60px',
    marginLeft: '10px',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '60px'
    },
    [theme.breakpoints.down('xs')]: {
      width: '40px'
    }
  },
  logoYoutube: {
    marginLeft: '-13px',
    width: '30px',
    height: '30px'
  },
  logoVimeo: {
    marginLeft: '-12px',
    width: '25px',
    height: '25px'
  },
  logoImagen: {
    marginLeft: '-12px',
    width: '30px',
    height: '30px'
  },
  radio: {
    marginLeft: '10px'
  },
  fileType: {
    display: 'flex',
    flexDirection: 'row',
    width: '490px',
    [theme.breakpoints.down('md')]: {},
    [theme.breakpoints.down('sm')]: {
      width: '80%'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  file: {
    display: 'flex',
    alignItems: 'center',
    width: '301px',
    [theme.breakpoints.down('md')]: {
      width: '224px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '206px'
    },
    [theme.breakpoints.down('xs')]: {
      width: '206px'
    }
  },
  radioButtonsContainer: {
    width: '200px',
    [theme.breakpoints.down('md')]: {},
    [theme.breakpoints.down('sm')]: {
      minWidth: '210px'
    },
    [theme.breakpoints.down('xs')]: {}
  },
  inputVideo: {
    width: '290px',
    marginLeft: '6px',
    marginRight: '6px',
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      width: '212px'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      width: '200px'
    },
    [theme.breakpoints.down('xs')]: {
      width: '200px'
    }
  },
  see: {
    font: 'normal normal normal 15px/20px Roboto',
    cursor: 'pointer',
    marginRight: '5px'
  },
  modalImage: {
    height: '75%',
    width: '75%'
  }
}));

export { useStyles };
