import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  header: {
    position: 'relative'
  },
  title: {
    font: 'normal normal bold 21px/28px Roboto',
    color: '#0E0E0E',
    marginBottom: theme.spacing(1)
  },
  subtitle: {
    font: 'normal normal normal 15px/20px Roboto',
    color: '#0E0E0E80'
  },
  buttonsContainer: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    [theme.breakpoints.down('sm')]: {
      position: 'initial',
      display: 'flex',
      justifyContent: 'flex-end'
    }
  },
  button: {
    height: '30px',
    width: '30px',
    padding: '8px',
    boxSizing: 'content-box',
    borderRadius: '50%',
    transition: 'all 300ms',
    '&:hover': {
      backgroundColor: theme.palette.grey[200]
    },
    '&.timeline': {
      marginRight: '15px'
    },
    '&.filter': {
      zIndex: 1480,
      [theme.breakpoints.down('sm')]: {
        zIndex: 0
      }
    }
  },
  filterTriangle: {
    height: 0,
    width: 0,
    borderLeft: '6px solid transparent',
    borderRight: '6px solid transparent',
    borderTop: '4px solid #2AA4D5',
    marginRight: '2px'
  },
  cardsContainer: {
    paddingTop: theme.spacing(2)
  },
  tableAndChartContainer: {
    [theme.breakpoints.only('md')]: {
      flexWrap: 'nowrap'
    }
  }
}));
