import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.Licenses.Forms.Edit.Left.title',
    defaultMessage: 'Editar licenciamiento'
  },
  imageLabel: {
    id: 'components.Licenses.Forms.Edit.Left.imageLabel',
    defaultMessage: 'Seleccionar una imagen'
  },
  dataLabel: {
    id: 'components.Licenses.Forms.Edit.Left.dataLabel',
    defaultMessage: 'Agregar los datos necesarios para crear un registro'
  },
  typeLabel: {
    id: 'components.Licenses.Forms.Edit.Left.typeLabel',
    defaultMessage: 'Tipo registro'
  },
  amountLabel: {
    id: 'components.Licenses.Forms.Edit.Left.amountLabel',
    defaultMessage: 'Cantidad de organizaciones'
  }
});
