import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.Principal.ConferenceForms.Edit.title',
    defaultMessage: 'Editar conferencias'
  },
  subtitle: {
    id: 'components.Principal.ConferenceForms.Edit.subtitle',
    defaultMessage:
      'Conferencias, webinars principales que tendrán lugar en un evento.'
  },
  eventIdLabel: {
    id: 'components.Principal.ConferenceForms.Edit.input.eventId.label',
    defaultMessage: 'Nombre del evento'
  },
  submit: {
    id: 'components.Principal.ConferenceForms.Edit.input.submitButton.label',
    defaultMessage: 'Editar'
  },
  requiredError: {
    id: 'components.Principal.ConferenceForms.Edit.input.error.required',
    defaultMessage: 'Este campo es requerido'
  },
  successfullyUpdatedMessage: {
    id:
      'components.Principal.ConferenceForms.Edit.modal.successfullyUpdatedMessage',
    defaultMessage: 'Se ha editado exitosamente la conferencia para el evento: '
  },
  successfullyCreatedMessage: {
    id:
      'components.Principal.ConferenceForms.Edit.modal.successfullyCreatedMessage',
    defaultMessage: 'Se ha creado exitosamente la conferencia para el evento: '
  },
  ok: {
    id: 'components.Principal.ConferenceForms.Edit.modal.ok',
    defaultMessage: 'Ok'
  }
});
