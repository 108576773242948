const templateGetEvent = {
  headerResponse: {
    code: 'headerResponse.code',
    message: 'headerResponse.message'
  },
  payload: {
    items: [
      'payload.items',
      {
        partners: 'partners',
        id: '_id',
        name: 'name',
        from: 'from',
        to: 'to',
        imageUrl: 'image',
        limitParticipants: 'limit_participants',
        company: {
          configuration: {
            title: 'title',
            subtitle: 'subtitle',
            subtitle2: 'subtitle2',
            button: 'button',
            hover: 'hover'
          },
          partners: 'partners',
          clients: 'company.clients',
          id: '_id',
          type: 'company.type',
          limitParticipants: 'limit_participants',
          contactName: 'company.contact_name',
          businessName: 'company.business_name',
          phone: 'company.phone',
          limitPartners: 'company.limit_partners',
          country: 'company.country',
          email: 'company.email',
          description: 'company.description',
          limitEvents: 'company.limit_events'
        },
        conferencesQuantity: 'total_activities'
      }
    ]
  }
};

const templateAddEvents = {
  video_id: 'videoId',
  plataform: 'plataform',
  file: 'imageUrl',
  name: 'name',
  from: 'from',
  to: 'to',
  limit_participants: 'limitParticipants',
  partners: 'partners'
};

const responseTemplateAddEvents = {
  headerResponse: {
    code: 'headerResponse.code',
    message: 'headerResponse.message'
  },
  payload: {
    id: 'payload._id',
    name: 'payload.name',
    from: 'payload.from',
    to: 'payload.to',
    imageUrl: 'payload.image',
    videoId: 'payload.video_id',
    plataform: 'payload.plataform',
    limitParticipants: 'payload.limit_participants',
    partners: 'payload.partners',
    company: 'payload.company'
  }
};

const TemplateUpdateEvents = {
  file: 'imageUrl',
  video_id: 'videoId',
  plataform: 'plataform',
  name: 'name',
  from: 'from',
  to: 'to',
  limit_participants: 'limitParticipants',
  partners: 'partners'
};

const responseTemplateUpdateEvents = {
  headerResponse: {
    code: 'headerResponse.code',
    message: 'headerResponse.message'
  },
  payload: {
    id: 'payload._id',
    name: 'payload.name',
    from: 'payload.from',
    to: 'payload.to',
    imageUrl: 'payload.file',
    videoId: 'payload.video_id',
    plataform: 'payload.plataform',
    limitParticipants: 'payload.limit_participants',
    partners: 'payload.partners',
    company: 'payload.company'
  }
};
const templateGetHeaderEvent = {
  headerResponse: {
    code: 'headerResponse.code',
    message: 'headerResponse.message'
  }
};

const templateGetEventById = {
  headerResponse: {
    code: 'headerResponse.code',
    message: 'headerResponse.message'
  },
  payload: {
    partners: 'payload.partners',
    id: 'payload._id',
    name: 'payload.name',
    from: 'payload.from',
    to: 'payload.to',
    imageUrl: 'payload.image',
    videoId: 'payload.video_id',
    plataform: 'payload.plataform',
    limitParticipants: 'payload.limit_participants',
    company: 'payload.company'
  }
};

const templateGetEventActivities = {
  headerResponse: {
    code: 'headerResponse.code',
    message: 'headerResponse.message'
  },
  payload: {
    items: [
      'payload.items',
      {
        id: '_id',
        streamId: 'video_id',
        streamPlatform: 'plataform',
        time: 'hour',
        chatIframe: 'chat_iframe',
        speakerName: 'name',
        speakerResume: 'resume',
        speakerBackground: 'background',
        speakerEducation: 'education',
        date: 'date',
        event: 'event',
        room: 'room',
        description: 'description',
        imageUrl: 'image',
        videoId: 'video_id',
        plataform: 'plataform',
        speaker: 'speaker'
      }
    ]
  }
};

const templateGetEventProducts = {
  headerResponse: {
    code: 'headerResponse.code',
    message: 'headerResponse.message'
  },
  payload: {
    items: [
      'payload.items',
      {
        partners: 'partners',
        id: '_id',
        name: 'name',
        from: 'from',
        to: 'to',
        imageUrl: 'image',
        videoId: 'video_id',
        plataform: 'plataform',
        limitParticipants: 'limit_participants',
        company: {
          configuration: {
            title: 'title',
            subtitle: 'subtitle',
            subtitle2: 'subtitle2',
            button: 'button',
            hover: 'hover'
          },
          partners: 'partners',
          clients: 'clients',
          id: '_id',
          type: 'type',
          limitParticipants: 'limit_participants',
          contactName: 'contact_name',
          businessName: 'business_name',
          phone: 'phone',
          limitPartners: 'limit_partners',
          country: 'country',
          email: 'email',
          description: 'description',
          limitEvents: 'limit_events'
        },
        conferencesQuantity: 'total_activities'
      }
    ]
  }
};

const templateGetEventQuizzes = {
  headerResponse: {
    code: 'headerResponse.code',
    message: 'headerResponse.message'
  },
  payload: {
    items: [
      'payload.items',
      {
        id: '_id',
        questions: [
          {
            answers: 'answers',
            id: '_id',
            question: 'question',
            points: 'points',
            room: 'room',
            rightAnswer: 'right_answer'
          }
        ],
        event: 'event'
      }
    ]
  }
};

export {
  templateGetEvent,
  templateAddEvents,
  responseTemplateAddEvents,
  templateGetHeaderEvent,
  TemplateUpdateEvents,
  responseTemplateUpdateEvents,
  templateGetEventActivities,
  templateGetEventProducts,
  templateGetEventQuizzes,
  templateGetEventById
};
