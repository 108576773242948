import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  titleLabel: {
    id: 'components.Events.Form.Edit.Left.titleLabel',
    defaultMessage: 'Editar evento'
  },
  imageLabel: {
    id: 'components.Events.Form.Edit.Left.imageLabel',
    defaultMessage: 'Seleccionar la imagen o video de portada del evento'
  },
  dataLabel: {
    id: 'components.Events.Form.Edit.Left.dataLabel',
    defaultMessage: 'Agregar los datos necesarios para crear un evento'
  },
  tipoLabel: {
    id: 'components.Events.Form.Edit.Left.tipoLabel',
    defaultMessage: 'Tipo de evento por total de participantes'
  },
  partnersLabel: {
    id: 'components.Events.Form.Edit.Left.partnersLabel',
    defaultMessage: 'Partners'
  },
  dateLabel: {
    id: 'components.Events.Form.Edit.Left.dateLabel',
    defaultMessage: 'Fecha del evento'
  }
});
