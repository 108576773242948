import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useFormik } from 'formik';
import { withRouter, useParams } from 'react-router-dom';
import {
  Typography,
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch
} from '@material-ui/core';
import {
  Row,
  RightContainer,
  ImagePicker,
  TextField,
  TextFieldPhone,
  SubmitButton
} from 'components/CreationForm';
import { ColorForm } from '../../ColorForm';
import { Person } from '@material-ui/icons';
import { useStyles } from './RightStyles';
import { useRowsStyles } from '../RowsStyles';
import { messages } from './RightMessages';
import { useLoader } from '@octopy/react-loader';
import { getLicenseById } from 'providers/api';
import { changeFormatDate, changeFormatDateYYYYMMDD } from 'utils/helpers';
import { getValidationSchema } from './helpers';
import { getDataError, createErrorModalConfig } from 'utils/helpers';
import { useModal } from '@octopy/react-modal';
import { ModalButton } from 'components/ModalMessages';
import { useDashboard } from 'components/Dashboard';

const Right = withRouter(({ history }) => {
  const { idLicense } = useParams();
  const classes = useStyles();
  const { handleShowLoader } = useLoader();
  const rowsClasses = useRowsStyles();
  const { updateSpecificLicenseById, updateLicenseStatusById } = useDashboard();
  const { handleOpenModal, handleCloseModal } = useModal();

  const intl = useIntl();

  const [licenseDetails, setLicenseDetails] = useState({});
  const [selectedValue, setSelectedValue] = useState('');
  const [checked, setChecked] = useState(true);
  const [colors, setColors] = useState({});

  const getLicenseData = async () => {
    try {
      handleShowLoader(true);
      if (history.location.state.positionInArray !== undefined) {
        const license = await getLicenseById(idLicense);

        setChecked(license.status);

        setLicenseDetails({
          ...license,
          positionInArray: history.location.state.positionInArray
        });
      }
    } catch (request) {
      const { code, message } = getDataError(request);

      handleOpenModal(createErrorModalConfig(code, message));
      history.push('/Licenses');
    } finally {
      handleShowLoader(false);
    }
  };

  useEffect(() => {
    getLicenseData();
  }, []);

  useEffect(async () => {
    if (licenseDetails.status !== undefined && !licenseDetails.status)
      await history.push('/Licenses');
  }, [licenseDetails]);

  useEffect(() => {
    setSelectedValue(licenseDetails.registerType);
    const colorsExtracted = (({
      title,
      subtitle,
      subtitle2,
      button,
      hover
    }) => ({
      title,
      subtitle,
      subtitle2,
      button,
      hover
    }))(licenseDetails);

    setColors(colorsExtracted);
  }, [licenseDetails]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      logoUrl: licenseDetails.logoUrl,
      businessName: licenseDetails.businessName,
      email: licenseDetails.email,
      contactName: licenseDetails.contactName,
      contactPhone: licenseDetails.contactPhone,
      limitClients: licenseDetails.limitClients,
      typeLicense: selectedValue,
      title: licenseDetails.title || '',
      subtitle: licenseDetails.subtitle || '',
      subtitle2: licenseDetails.subtitle2 || '',
      button: licenseDetails.button || '',
      hover: licenseDetails.hover || ''
    },
    validationSchema: getValidationSchema(intl),
    onSubmit: async (values) => {
      const valuesToSend = {
        ...values,
        id: licenseDetails.id,
        positionInArray: licenseDetails.positionInArray,
        licenseDate: changeFormatDateYYYYMMDD(licenseDetails.licenseDate)
      };

      const error = await updateSpecificLicenseById(
        valuesToSend,
        licenseDetails.positionInArray
      );

      if (!!!error) history.goBack();
    }
  });

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleChangeStateLicenseClick = () => {
    const modalBody = (
      <div>
        <Typography variant="body1" className={classes.modalStatusTitle}>
          {intl.formatMessage(messages.areYouSureChangeState)}
        </Typography>
        <br />
      </div>
    );
    const modalActionButtons = (
      <div>
        <ModalButton onClick={handleCloseModal}>
          {intl.formatMessage(messages.cancel)}
        </ModalButton>
        <ModalButton
          onClick={async () => {
            const codeError = await updateLicenseStatusById(
              licenseDetails,
              licenseDetails.positionInArray
            );

            if (!!!codeError) {
              setChecked(false);
              getLicenseData();
            }
          }}
        >
          {intl.formatMessage(messages.ok)}
        </ModalButton>
      </div>
    );

    handleOpenModal({
      body: modalBody,
      actionButtons: modalActionButtons,
      configProps: {
        width: 'lg'
      }
    });
  };

  const submitButton = (
    <SubmitButton
      className={classes.containerSubmitButton}
      label={intl.formatMessage(messages.editSubmitButtonLabel)}
      onClick={formik.handleSubmit}
    />
  );

  return (
    <RightContainer submitButton={submitButton}>
      <Row className={rowsClasses.rowConfig}>
        <ImagePicker
          id="editLicenseLogo"
          onChange={(logo) => {
            formik.setFieldValue('logoUrl', logo.blobUrl);
            formik.setFieldValue('logo', logo);
          }}
          Icon={Person}
          label={intl.formatMessage(messages.logoUrlLabel)}
          name="logoUrl"
          imageUrl={formik.values.logoUrl || formik.values.logo?.blobUrl}
        />
        <Box p={2}>
          <Typography className={classes.activation}>
            {intl.formatMessage(messages.activationLabel)}:&nbsp;
            {changeFormatDate(licenseDetails.licenseDate || '')}
          </Typography>
          <Typography className={classes.status}>
            {intl.formatMessage(messages.statusLicenseLabel)}:
            <FormControlLabel
              className={classes.switch}
              control={
                <Switch
                  name="active"
                  checked={checked}
                  onChange={handleChangeStateLicenseClick}
                  size="small"
                />
              }
              labelPlacement="start"
              label={
                checked
                  ? intl.formatMessage(messages.switchActive)
                  : intl.formatMessage(messages.switchDisabled)
              }
            />
          </Typography>
        </Box>
      </Row>
      <Row className={rowsClasses[2]}>
        <TextField
          className={classes.input}
          key={'businessNameInput'}
          onChange={(e) => formik.setFieldValue('businessName', e.target.value)}
          onBlur={formik.handleBlur}
          error={formik.errors['businessName']}
          value={formik.values['businessName']}
          touched={formik.touched['businessName']}
          label={intl.formatMessage(messages['businessNameLabel'])}
          name={'businessName'}
        />
        <TextField
          className={classes.input}
          key={'emailInput'}
          onChange={(e) => formik.setFieldValue('email', e.target.value)}
          onBlur={formik.handleBlur}
          error={formik.errors['email']}
          value={formik.values['email']}
          touched={formik.touched['email']}
          label={intl.formatMessage(messages['emailLabel'])}
          name={'email'}
        />
        <TextField
          className={classes.input}
          key={'contactNameInput'}
          onChange={(e) => formik.setFieldValue('contactName', e.target.value)}
          onBlur={formik.handleBlur}
          error={formik.errors['contactName']}
          value={formik.values['contactName']}
          touched={formik.touched['contactName']}
          label={intl.formatMessage(messages['contactNameLabel'])}
          name={'contactName'}
        />
        <TextFieldPhone
          className={classes.input}
          key={`contactPhoneInput`}
          country="mx"
          regions={'america'}
          onChange={(phone) => formik.setFieldValue('contactPhone', phone)}
          onBlur={formik.handleBlur('contactPhone')}
          error={formik.errors['contactPhone']}
          value={formik.values['contactPhone']}
          placeholder={intl.formatMessage(messages['contactPhoneLabel'])}
          name={'contactPhone'}
        />
      </Row>
      <Row className={rowsClasses[3]}>
        <FormControl
          component="fieldset"
          className={classes.radioButtonsContainer}
        >
          <RadioGroup row>
            <FormControlLabel
              className={classes.radioButtonLabel}
              value="1"
              control={
                <Radio
                  checked={selectedValue === 'license'}
                  onChange={handleChange}
                  value="license"
                  name="license"
                />
              }
              label="Licenciamiento"
              labelPlacement="end"
            />
            <FormControlLabel
              className={`${classes.radioButtonLabel} ${classes.hiddenElement}`}
              value="2"
              control={
                <Radio
                  checked={selectedValue === 'membership'}
                  onChange={handleChange}
                  value="membership"
                  name="membership"
                  disabled
                />
              }
              label="Membresía"
              labelPlacement="end"
            />
          </RadioGroup>
        </FormControl>
      </Row>
      <Row className={rowsClasses[4]}>
        <TextField
          className={classes.input}
          key={'limitClientsInput'}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors['limitClients']}
          value={formik.values['limitClients']}
          touched={formik.touched['limitClients']}
          label={intl.formatMessage(messages['limitClientsLabel'])}
          name={'limitClients'}
          type="number"
          inputProps={{ min: 1 }}
        />
      </Row>
      <div className={classes.containerBrandingColors}>
        <ColorForm
          edit={true}
          initialColors={colors}
          setValue={formik.setFieldValue}
        />
      </div>
      <br />
    </RightContainer>
  );
});

export { Right };
