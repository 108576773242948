import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  edit: {
    id: 'component.Principal.ConferenceCard.edit',
    defaultMessage: 'Editar'
  },
  deleteElement: {
    id: 'component.Principal.ConferenceCard.deleteElement',
    defaultMessage: 'Eliminar'
  },
  areYouSureDelete: {
    id: 'component.Principal.ConferenceCard.modal.delete.text',
    defaultMessage:
      '¿Estas seguro de querer eliminar las conferencias del evento: '
  }
});
