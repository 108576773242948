import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { messages } from './ModalButtonMessageMessages';
import { useStyles } from './ModalButtonMessageStyles';
import { ModalButton } from '../../ModalMessages/messageComponents/ModalButton';

const ModalButtonMessage = ({ message }) => {
  const intl = useIntl();
  const classes = useStyles();
  const messageToShow = messages[message] ? messages[message] : message;

  return <ModalButton>{intl.formatMessage(messageToShow)}</ModalButton>;
};

ModalButtonMessage.propTypes = {
  message: PropTypes.string.isRequired
};

export { ModalButtonMessage };
