import * as yup from 'yup';
import { messages } from './RightMessages';
import moment from 'moment';

export const initialValuesForm = {
  deleted: 'false',
  logoUrl: '',
  businessName: '',
  email: '',
  contactName: '',
  contactPhone: '',
  typeLicense: '',
  limitClients: '',
  licenseDate: moment().format('YYYY/MM/DD'),
  title: '',
  subtitle: '',
  subtitle2: '',
  button: '',
  hover: ''
};

export const getValidationSchema = (intl) => {
  const requiredErrorMessage = intl.formatMessage(messages.isRequiredError);
  const emailErrorMessage = intl.formatMessage(messages.emailError);
  const onlyIntegersErrorMessage = intl.formatMessage(messages.onlyIntegers);
  const limitOrganizations = intl.formatMessage(messages.limitOrganizations);
  const minLimitOrganizations = intl.formatMessage(
    messages.minLimitOrganizations
  );
  const minDigitsTelephone = intl.formatMessage(messages.minDigitsTelephone);

  return yup.object({
    logoUrl: yup.string(),
    businessName: yup
      .string()
      .trim()
      .required(requiredErrorMessage),
    email: yup
      .string()
      .email(emailErrorMessage)
      .required(requiredErrorMessage),
    contactName: yup
      .string()
      .trim()
      .required(requiredErrorMessage),
    contactPhone: yup
      .string()
      .min(6, minDigitsTelephone)
      .required(requiredErrorMessage),
    limitClients: yup
      .number()
      .min(1, minLimitOrganizations)
      .max(999, limitOrganizations)
      .integer(onlyIntegersErrorMessage)
      .required(requiredErrorMessage),
    title: yup.string(),
    subtitle: yup.string(),
    subtitle2: yup.string(),
    button: yup.string(),
    hover: yup.string()
  });
};
