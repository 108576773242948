import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.Organizations.CreateCard.title',
    defaultMessage: 'AGREGAR'
  },
  subtitle: {
    id: 'components.Organizations.CreateCard.subtitle',
    defaultMessage: 'Organizacion'
  }
});
