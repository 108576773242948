import { instance } from '../instance';
import { getFormData } from 'utils/helpers';
import { responseMapper } from 'utils/responseMapper';
import {
  templateGetDataCoordinator as templateGetData,
  templateSendDataCoordinator as templateSendData,
  templateUpdateDataCoordinator as templateUpdateData,
  templateGetHeaderCoordinator as templateGetHeader
} from '../mappers';

export const getCoordinators = async () => {
  const response = await instance.get('/v1/users?role=coordinator');
  const code = response.data.headerResponse.code;

  if (code !== 200) throw new Error(code);

  const payload = responseMapper({
    template: templateGetData,
    data: response.data.payload.items
  });

  return payload;
};

export const getCoordinatorById = async (id) => {
  const response = await instance.get(`/v1/users/${id}`);
  const code = response.data.headerResponse.code;

  if (code !== 200) throw new Error(code);

  const payload = responseMapper({
    template: templateGetData,
    data: response.data.payload
  });

  return payload;
};

export const postCoordinator = async (data) => {
  const mappedData = responseMapper({
    template: templateSendData,
    data: data
  });

  const formData = getFormData(mappedData);

  const response = await instance.post('/v1/users', formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
  const code = response.data.headerResponse.code;

  if (code !== 200) throw new Error(code);

  const payload = responseMapper({
    template: templateGetData,
    data: response.data.payload
  });

  return payload;
};

export const deleteCoordinator = async (id) => {
  const response = await instance.delete(`/v1/users/${id}`);
  const {
    headerResponse: { code }
  } = responseMapper({
    template: templateGetHeader,
    data: response.data
  });

  if (code !== 200) throw new Error(code);

  return {};
};

export const updateCurrentUser = async (payload) => {
  const mappedData = responseMapper({
    template: templateSendData,
    data: payload
  });

  const response = await instance.patch('/v1/users', mappedData);

  const {
    headerResponse: { code }
  } = responseMapper({
    template: templateGetHeader,
    data: response.data
  });

  if (code !== 200) throw new Error(code);

  return {};
};

export const updateSpecificUser = async (id, data) => {
  const mappedData = responseMapper({
    template: templateUpdateData,
    data: data
  });

  const formData = getFormData(mappedData);

  const response = await instance.patch(`/v1/users/${id}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });

  const code = response.data.headerResponse.code;

  if (code !== 200) throw new Error(code);

  const payload = responseMapper({
    template: templateGetData,
    data: response.data.payload
  });

  return { ...payload, id: id, createdAt: data.createdAt };
};
