import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useStyles } from './ModalSuccessMessageStyles';
import { ModalText } from '../messageComponents/ModalText';

const ModalSuccessMessage = ({ messageSuccess, targetName }) => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <ModalText>
      {intl.formatMessage(messageSuccess)}{' '}
      {targetName && <ModalText white>{targetName}</ModalText>}
    </ModalText>
  );
};

ModalSuccessMessage.propTypes = {
  messageSuccess: PropTypes.string.isRequired,
  targetName: PropTypes.string.isRequired
};

export { ModalSuccessMessage };
