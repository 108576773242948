import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import 'date-fns';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Row,
  RightContainer,
  TextField,
  SubmitButton,
  TextArea,
  Select,
  ImagePicker
} from 'components/CreationForm';
import { useRowsStyles } from '../RowsStyles';
import { useStyles } from './RightStyles';
import { messages } from './RightMessages';
import { useDashboard } from 'components/Dashboard';
import { Hidden, Radio } from '@material-ui/core';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import { Images } from 'assets';

const Right = ({ history }) => {
  const rowsClasses = useRowsStyles();
  const classes = useStyles();
  const intl = useIntl();
  const requiredErrorMessage = intl.formatMessage(messages.isRequiredError);
  const invalidUrlError = intl.formatMessage(messages.invalidUrlError);
  const { addNewExhibition, events } = useDashboard();
  const [optionsForSelect, setOptionsForSelect] = useState([]);
  const [hostVideo, sethostVideo] = useState('youtube');

  useEffect(() => {
    const options = events.map((event) => ({
      value: event.id,
      label: event.name
    }));

    setOptionsForSelect(options);
  }, []);

  const handleSetHostVideo = (event) => {
    sethostVideo(event.target.value);
  };

  const formik = useFormik({
    initialValues: {
      logoUrl: '',
      videoId: '',
      urlChat: '',
      productName: '',
      description: '',
      slogan: '',
      event: ''
    },
    validationSchema: yup.object({
      logoUrl: yup.string().required(requiredErrorMessage),
      videoId: yup
        .string()
        .trim()
        .required(requiredErrorMessage),
      urlChat: yup
        .string()
        .url(invalidUrlError)
        .required(requiredErrorMessage),
      productName: yup
        .string()
        .trim()
        .required(requiredErrorMessage),
      description: yup.string().trim(),
      slogan: yup
        .string()
        .trim()
        .required(requiredErrorMessage),
      event: yup.string().required(requiredErrorMessage)
    }),
    onSubmit: (values) => {
      const completeValues = { ...values, plataform: hostVideo };

      addNewExhibition(completeValues);

      history.goBack();
    }
  });
  const submitButton = (
    <Row>
      <SubmitButton
        label={intl.formatMessage(messages.create)}
        onClick={formik.handleSubmit}
      />
    </Row>
  );

  return (
    <RightContainer submitButton={submitButton}>
      <Row className={rowsClasses.multimedia}>
        <Row className={classes.fullwidth}>
          <ImagePicker
            id="createExhibition"
            onChange={(logo) => {
              formik.setFieldValue('logoUrl', logo.blobUrl);
              formik.setFieldValue('logo', logo);
            }}
            error={
              formik.errors['logoUrl'] && !!!formik.values.logoUrl
                ? formik.errors['logoUrl']
                : undefined
            }
            Icon={PhotoCameraIcon}
            label={intl.formatMessage(messages.logoProductLabel)}
            name="logoUrl"
            imageUrl={formik.values.logoUrl || formik.values.logo?.blobUrl}
          />
        </Row>
        <div className={classes.multimediaRow}>
          <div className={classes.containerVideo}>
            <TextField
              className={classes.input}
              key={`videoIdInput`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors['videoId']}
              value={formik.values['videoId']}
              touched={formik.touched['videoId']}
              label={intl.formatMessage(messages.videoId)}
              name={'videoId'}
            />
            <div className={`${classes.radioGroup}`}>
              <Radio
                size="small"
                checked={hostVideo === 'youtube'}
                onChange={handleSetHostVideo}
                value="youtube"
                name="youtube"
              />
              <img
                src={Images.youtube}
                alt="youtube"
                className={classes.platformLogoImage}
              />
              &nbsp;&nbsp;
              <Radio
                size="small"
                checked={hostVideo === 'vimeo'}
                onChange={handleSetHostVideo}
                value="vimeo"
                name="vimeo"
              />
              <img
                src={Images.vimeo}
                alt="vimeo"
                className={classes.platformLogoImage}
              />
            </div>
          </div>
          <TextField
            className={classes.input}
            key={`urlChatInput`}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors['urlChat']}
            value={formik.values['urlChat']}
            touched={formik.touched['urlChat']}
            label={intl.formatMessage(messages.urlChat)}
            name={'urlChat'}
          />
        </div>
      </Row>
      <Row className={rowsClasses.product}>
        <TextField
          className={classes.input}
          key={`eventNameInput`}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors['productName']}
          value={formik.values['productName']}
          touched={formik.touched['productName']}
          label={intl.formatMessage(messages.productName)}
          name={'productName'}
        />
        <TextField
          className={classes.input}
          key={`esloganInput`}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors['slogan']}
          value={formik.values['slogan']}
          touched={formik.touched['slogan']}
          label={intl.formatMessage(messages.productSlogan)}
          name={'slogan'}
        />
        <Hidden smDown>
          <div style={{ flexGrow: 1 }} />
        </Hidden>
        <TextArea
          key={`descriptionInput`}
          onChange={formik.handleChange}
          error={formik.errors['description']}
          value={formik.values['description']}
          touched={formik.touched['description']}
          className={classes.input}
          rowsMin={1}
          label={intl.formatMessage(messages.productDescription)}
          name={`description`}
        />
      </Row>
      <Row>
        <Select
          className={classes.input}
          key={`event`}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors['event']}
          value={formik.values['event']}
          touched={formik.touched['event']}
          label={intl.formatMessage(messages.selectEvent)}
          name={'event'}
          options={optionsForSelect}
        />
      </Row>
    </RightContainer>
  );
};

export { Right };
