import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  label: {
    '&.focused': {
      color: theme.palette.secondary.main
    }
  },
  textArea: {
    width: '100%',
    height: '100%',
    background: '#F9F9F9 0% 0% no-repeat padding-box',
    borderRadius: '5px',
    borderWidth: '0px',
    textAlign: 'left',
    padding: '21px 8px 25px 22px',
    font: 'normal normal normal 12px/16px Roboto',
    '&:focus': {
      outline: 'none'
    },
    resize: 'vertical',
    '&:disabled': {
      resize: 'none',
      color: theme.palette.text.disabled
    },
    '&::-webkit-scrollbar': {
      backgroundColor: '#F9F9F9',
      borderTopRightRadius: '5px',
      borderBottomRightRadius: '5px'
    }
  }
}));
