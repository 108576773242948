import * as yup from 'yup';

export const initialValues = {
  challengeList: [{}]
};

export const getValidationSchema = ({ requiredMessage }) =>
  yup.object({
    challengeList: yup.array().of(
      yup.object({
        challenge: yup.string(),
        score: yup.number(),
        enable: yup.boolean()
      })
    )
  });
