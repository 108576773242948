import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  assign: {
    id: 'components.Quiz.Cards.Edit.assign',
    defaultMessage: 'Nombre del evento:'
  },
  date: {
    id: 'components.Quiz.Cards.Edit.date',
    defaultMessage: 'Fecha:'
  },
  edit: {
    id: 'components.Quiz.Cards.Edit.edit',
    defaultMessage: 'Editar'
  },
  deleteText: {
    id: 'components.Quiz.Cards.Edit.deleteText',
    defaultMessage: 'Eliminar'
  },
  areYouSureDelete: {
    id: 'components.Quiz.Cards.Edit.areYouSureDelete',
    defaultMessage: '¿ESTÁS SEGURO DE QUERER ELIMINAR EL QUIZ DEL EVENTO: '
  }
});
