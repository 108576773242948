import * as yup from 'yup';
import { messages } from './SideDrawerMessages';

export const getValidationSchema = (intl) =>
  yup.object({
    stand: yup.object({
      videoId: yup.string(),
      plataform: yup.string(),
      backgroud: yup.string(),
      title: yup.object({
        type: yup.string(),
        value: yup.string()
      }),
      description: yup.object({
        type: yup.string(),
        value: yup.string()
      }),
      slogan: yup.object({
        type: yup.string(),
        value: yup.string()
      })
    })
  });
