import { Typography } from '@material-ui/core';
import { useStyles } from './TitleStyles';

export const Title = ({ children }) => {
  const classes = useStyles();

  return (
    <Typography component="div" className={classes.title}>
      {children}
    </Typography>
  );
};
