import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => {
  const imageMask = {
    height: '18px',
    width: '18px',
    backgroundColor: '#E6E6E6',
    transition: 'all 300ms',
    maskRepeat: 'no-repeat',
    maskPosition: 'center'
  };

  return {
    tabs: {
      width: '100%',
      borderBottom: '2px solid #2AA4D5',
      '& .MuiTabs-indicator': {
        height: '1px',
        backgroundColor: theme.palette.secondary.main
      }
    },
    label: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      font: 'normal normal normal 18px/24px Roboto',
      color: '#E6E6E6',
      transition: 'color 300ms',
      textTransform: 'initial',
      '&.active': {
        color: theme.palette.secondary.main,
        '& $imageMaskContainer > div': {
          backgroundColor: theme.palette.secondary.main
        }
      }
    },
    imageMaskContainer: {
      display: 'inline-block',
      marginRight: theme.spacing(1)
    },
    fileImageMask: ({ file }) => ({
      ...imageMask,
      maskImage: `url("${file}")`
    }),
    starImageMask: ({ star }) => ({
      ...imageMask,
      maskImage: `url("${star}")`
    }),
    medalImageMask: ({ medal }) => ({
      ...imageMask,
      maskImage: `url("${medal}")`
    })
  };
});
