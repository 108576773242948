export const templateGetDataLicenses = {
  registerType: 'register_type',
  history: 'history',
  deleted: 'deleted',
  id: '_id',
  limitClients: 'limit_clients',
  licenseDate: 'license_date',
  businessName: 'company.business_name',
  contactName: 'company.contact_name',
  email: 'company.email',
  contactPhone: 'company.phone',
  logoUrl: 'company.image',
  status: 'status'
};

export const templateGetDataLicenseById = {
  registerType: 'register_type',
  history: 'history',
  deleted: 'deleted',
  status: 'status',
  id: '_id',
  limitClients: 'limit_clients',
  licenseDate: 'license_date',
  businessName: 'company.business_name',
  contactName: 'company.contact_name',
  email: 'company.email',
  contactPhone: 'company.phone',
  logoUrl: 'company.image',
  title: 'company.configuration.title',
  subtitle: 'company.configuration.subtitle',
  subtitle2: 'company.configuration.subtitle2',
  button: 'company.configuration.button',
  hover: 'company.configuration.hover'
};

export const templateSendDataLicenses = {
  status: 'status',
  file: 'logo.file',
  business_name: 'businessName',
  email: 'email',
  contact_name: 'contactName',
  phone: 'contactPhone',
  register_type: 'typeLicense',
  limit_clients: 'limitClients',
  license_date: 'licenseDate',
  title: 'title',
  subtitle: 'subtitle',
  subtitle2: 'subtitle2',
  button: 'button',
  hover: 'hover'
};

export const templateGetHeaderLicenses = {
  code: 'headerResponse.code',
  message: 'headerResponse.message'
};

export const templeateSendStatusLicenses = {
  status: 'status'
};

export const templateUpdateStatusLicense = {
  delete: 'delete'
};

export const templateRenovationLicense = {
  register_type: 'registerType'
};
