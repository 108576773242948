import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  lastUpdate: {
    id: 'components.Database.DatabaseCard.lastUpdate',
    defaultMessage: 'Ultima actualizacion:'
  },
  download: {
    id: 'components.Database.DatabaseCard.download',
    defaultMessage: 'Descargar'
  }
});
