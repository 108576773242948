import { makeStyles } from '@material-ui/core';

export const useRowsStyles = makeStyles((theme) => ({
  1: {
    height: '195px'
  },
  2: {
    height: '260px'
  },
  3: {
    height: '310px'
  }
}));
