import { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Images } from 'assets';
import {
  ListItemText,
  MenuItem,
  Box,
  Select as MySelect,
  Chip,
  Avatar,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl
} from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import 'date-fns';
import { useFormik } from 'formik';
import { responseMapper } from 'utils/responseMapper';
import { templateAddEvents } from 'providers/api/mappers/events';
import { useNoEvents } from 'components/NoEvents/hooks/useNoEvents';
import { useStyles } from './RightStyles';
import { useRowsStyles } from '../RowsStyles';
import { ModalText, ModalButton } from 'components/ModalMessages';
import { useModal } from '@octopy/react-modal';
import { messages } from './RightMessages';
import Tooltip from '@material-ui/core/Tooltip';
import {
  Row,
  RightContainer,
  TextField,
  SubmitButton,
  Select
} from 'components/CreationForm';
import { FileInput } from 'components/CreationForm/Right/inputs/FileInput/FileInput';
import { getValidationSchema, initialValues } from './helpers';
import moment from 'moment';
import { DatePicker } from 'components/CreationForm/Right/inputs/DatePicker/DatePicker';
import { TimePicker } from 'components/CreationForm/Right/inputs/TimePicker/TimePicker';
import { useDashboard } from 'components/Dashboard';

const Right = withRouter(({ history }) => {
  const { addNewEvent, partners } = useDashboard();
  const [selectedValueVideo, setSelectedValueVideo] = useState('youtube');
  const intl = useIntl();
  const options = [
    { value: '10', label: intl.formatMessage(messages.optionOne) },
    { value: '20', label: intl.formatMessage(messages.optionTwo) },
    { value: '30', label: intl.formatMessage(messages.optionThree) }
  ];
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };

  const { handleNoEventShow } = useNoEvents();
  const [partnerSelected, setpartnerSelected] = useState([]);

  const handleChange = (event, newParter) => {
    const auxPartner = [...formik.values.partners];

    const partnerExists = auxPartner.findIndex(
      (actualPartnerId) => actualPartnerId === newParter.props.value.id
    );

    if (partnerExists === -1) {
      auxPartner.push(newParter.props.value.id);
    } else {
      auxPartner.splice(partnerExists, 1);
    }

    setpartnerSelected(event.target.value);
    formik.setFieldValue('partners', auxPartner);
  };

  const [selectedDateStart, setSelectedDateStart] = useState(Date.now());

  const handleDateChangeStart = (date) => {
    setSelectedDateStart(date);
    formik.values['fromDate'] = date;
    const dateFrom = moment(date).format('YYYY/MM/DD');
    const dateTo = moment(selectedDateEnd).format('YYYY/MM/DD');
    const timeFrom = moment(selectedTimeStart).format(' HH:mm');
    const timeTo = moment(selectedTimeEnd).format(' HH:mm');

    if (
      dateFrom.toString() === dateTo.toString() &&
      timeFrom.toString() >= timeTo.toString()
    ) {
      setErrorTime(true);
    } else {
      setErrorTime(false);
    }
  };
  const [selectedDateEnd, setSelectedDateEnd] = useState(Date.now());
  const allowedTypes = ['image/png', 'image/jpg', 'image/jpeg'];

  const handleDateChangeEnd = (date) => {
    setSelectedDateEnd(date);
    formik.values['toDate'] = date;
    const dateFrom = moment(selectedDateStart).format('YYYY/MM/DD');
    const dateTo = moment(date).format('YYYY/MM/DD');
    const timeFrom = moment(selectedTimeStart).format(' HH:mm');
    const timeTo = moment(selectedTimeEnd).format(' HH:mm');

    if (
      dateFrom.toString() === dateTo.toString() &&
      timeFrom.toString() >= timeTo.toString()
    ) {
      setErrorTime(true);
    } else {
      setErrorTime(false);
    }
  };

  const [selectedTimeStart] = useState('2021-01-01 00:01:00');
  const [errorTime, setErrorTime] = useState(false);

  const [selectedTimeEnd] = useState('2021-01-01 23:59:00');

  const classes = useStyles();
  const rowsClasses = useRowsStyles();
  const { handleCloseModal, handleOpenModal } = useModal();

  const onSubmit = async (values) => {
    if (!errorTime) {
      const dateFrom = moment(values.fromDate).format('YYYY/MM/DD');
      const dateTo = moment(values.toDate).format('YYYY/MM/DD');
      const timeFrom = moment(selectedTimeStart).format(' HH:mm');
      const timeTo = moment(selectedTimeEnd).format(' HH:mm');
      let from = new Date(dateFrom.toString() + timeFrom.toString()).toString();
      let to = new Date(dateTo.toString() + timeTo.toString()).toString();
      const newValues = {
        videoId: values.videoId,
        plataform: values.plataform,
        name: values.name,
        limitParticipants: values.limitParticipants.toString(),
        from: from,
        to: to,
        partners: values.partners.join(',')
      };

      const mappedValues = responseMapper({
        template: templateAddEvents,
        data: newValues
      });

      var formData = new FormData();

      for (var key in mappedValues) {
        formData.append(key, mappedValues[key]);
      }

      if (values.imageUrl?.name) {
        formData.set(
          'file',
          formik.values.imageUrl,
          formik.values.imageUrl.name
        );
      }

      const error = await addNewEvent(formData);

      !error && history.push('/Events');
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: getValidationSchema(intl),
    onSubmit
  });
  const submitButton = (
    <Row>
      <SubmitButton
        label={intl.formatMessage(messages.submitButtonLabel)}
        onClick={formik.handleSubmit}
      />
    </Row>
  );

  const errorPartner = () => {
    handleCloseModal();
    const modalTitle = (
      <ModalText>{intl.formatMessage(messages.errorPartners)}</ModalText>
    );

    const modalActionButtons = (
      <ModalButton>{intl.formatMessage(messages.ok)} </ModalButton>
    );

    handleOpenModal({
      title: modalTitle,
      actionButtons: modalActionButtons,
      configProps: { maxWidth: 'xs' }
    });
  };

  useEffect(() => {
    handleNoEventShow(false);
    if (partners.length !== 0) {
      setpartnerSelected([partners[0]]);
      formik.setFieldValue('partners', [partners[0].id]);
    } else {
      errorPartner();
      history.push('/Partners');
    }
  }, []);

  const renderPartners = (selected) =>
    selected.map((partner) => (
      <Chip
        size="small"
        avatar={<Avatar alt={partner.businessName} src={partner.logoUrl} />}
        label={partner.businessName}
      />
    ));

  const handleChangeVideo = (event) => {
    setSelectedValueVideo(event.target.value);
    formik.setFieldValue('plataform', event.target.value);
  };

  return (
    <RightContainer submitButton={submitButton}>
      <Row className={rowsClasses[1]}>
        <div className={classes.fileType}>
          {selectedValueVideo !== 'image' ? (
            <TextField
              key={`videoIdInput`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors['videoId']}
              value={formik.values['videoId']}
              touched={formik.touched['videoId']}
              label={intl.formatMessage(messages[`videoIdLabel`])}
              className={classes.inputVideo}
              name={'videoId'}
            />
          ) : (
            <div className={classes.file}>
              <FileInput
                id="imageUrl"
                label={intl.formatMessage(messages.uploadButtonLabel)}
                fileName={formik.values.imageUrl?.name}
                accept="image/png, image/gif, image/jpeg"
                acceptType={allowedTypes}
                onChange={({ file }) => formik.setFieldValue('imageUrl', file)}
              />
            </div>
          )}

          <FormControl
            component="fieldset"
            className={classes.radioButtonsContainer}
          >
            <RadioGroup row>
              <Tooltip title={intl.formatMessage(messages.youtube)}>
                <div className={classes.centerRadio}>
                  <FormControlLabel
                    value="1"
                    control={
                      <Radio
                        checked={selectedValueVideo === 'youtube'}
                        onChange={handleChangeVideo}
                        value="youtube"
                        name="radio-button-video"
                        className={classes.radio}
                      />
                    }
                    labelPlacement="end"
                  />
                  <img
                    src={Images.youtube}
                    alt="youtube"
                    className={classes.logoYoutube}
                  />
                </div>
              </Tooltip>
              <Tooltip title={intl.formatMessage(messages.vimeo)}>
                <div className={classes.centerRadio}>
                  <FormControlLabel
                    value="2"
                    control={
                      <Radio
                        checked={selectedValueVideo === 'vimeo'}
                        onChange={handleChangeVideo}
                        value="vimeo"
                        name="radio-button-vimeo"
                        className={classes.radio}
                      />
                    }
                    labelPlacement="end"
                  />
                  <img
                    src={Images.vimeo}
                    alt="vimeo"
                    className={classes.logoVimeo}
                  />
                </div>
              </Tooltip>
              <Tooltip title={intl.formatMessage(messages.image)}>
                <div className={classes.centerRadio}>
                  <FormControlLabel
                    value="3"
                    control={
                      <Radio
                        checked={selectedValueVideo === 'image'}
                        onChange={handleChangeVideo}
                        value="image"
                        name="radio-button-imagen"
                        className={classes.radio}
                      />
                    }
                    labelPlacement="end"
                  />
                  <ImageIcon className={classes.logoImagen} />
                </div>
              </Tooltip>
            </RadioGroup>
          </FormControl>
        </div>
      </Row>
      <Row className={rowsClasses[2]}>
        <TextField
          key={`nameInput`}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors['name']}
          value={formik.values['name']}
          touched={formik.touched['name']}
          label={intl.formatMessage(messages[`nameLabel`])}
          className={classes.input}
          name={'name'}
        />
      </Row>
      <Row className={rowsClasses[3]}>
        <Select
          id="limitParticipants"
          key={`limitParticipantsInput`}
          name={'limitParticipants'}
          className={classes.input}
          onBlur={formik.handleBlur}
          value={formik.values['limitParticipants']}
          touched={formik.touched['limitParticipants']}
          error={formik.errors['limitParticipants']}
          onChange={formik.handleChange}
          options={options}
        />
      </Row>
      <Row className={rowsClasses[4]}>
        <TextField
          key={'amount'}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors['amount']}
          value={formik.values['amount']}
          inputProps={{ type: 'number' }}
          touched={formik.touched['amount']}
          label={intl.formatMessage(messages['amountLabel'])}
          className={classes.input}
          name={'amount'}
          type="number"
        />
        <FormControl className={classes.input}>
          <MySelect
            id="partners"
            key={'partnersInput'}
            onBlur={formik.handleBlur}
            error={formik.errors['partners']}
            touched={formik.touched['partners']}
            name={'partners'}
            multiple
            value={partnerSelected}
            onChange={handleChange}
            renderValue={partners.length ? renderPartners : null}
            MenuProps={MenuProps}
          >
            {partners.map((partner) => (
              <MenuItem key={partner.id} value={partner}>
                <Box className={classes.imgBox}>
                  {partner.logoUrl ? (
                    <img
                      src={partner.logoUrl || Images.handshake}
                      alt="logo"
                      className={classes.logoImage}
                    />
                  ) : (
                    <img src={Images.handshake} alt="logo" />
                  )}
                </Box>
                <ListItemText primary={partner.businessName} />
              </MenuItem>
            ))}
          </MySelect>
          {formik.touched.partners && formik.errors.partners ? (
            <div className={classes.errorDate}>{formik.errors.partners}</div>
          ) : null}
        </FormControl>
      </Row>
      <Row className={rowsClasses[5]}>
        <div className={classes.dateLabel}>
          {intl.formatMessage(messages.beginning)}
        </div>
        <DatePicker
          label={intl.formatMessage(messages[`fromDateLabel`])}
          margin="normal"
          id="fromDate"
          format="dd/MM/yyyy"
          name={'fromDate'}
          onChange={handleDateChangeStart}
          onBlur={formik.handleBlur}
          error={formik.errors['fromDate']}
          value={selectedDateStart}
          className={classes.input}
        />
        <TimePicker
          label={intl.formatMessage(messages['fromTimeLabel'])}
          margin="normal"
          id="fromTime"
          name={'fromTime'}
          value={selectedTimeStart}
          className={classes.input}
          disabled
        />
        <div className={classes.dateLabel}>
          {intl.formatMessage(messages.end)}
        </div>
        <FormControl className={classes.divToDate}>
          <DatePicker
            label={intl.formatMessage(messages['toDateLabel'])}
            margin="normal"
            id="toDate"
            format="dd/MM/yyyy"
            name={'toDate'}
            onChange={handleDateChangeEnd}
            onBlur={formik.handleBlur}
            error={formik.errors['toDate']}
            value={selectedDateEnd}
            className={classes.inputToDate}
          />
          {formik.touched.toDate && formik.errors.toDate ? (
            <div id="errorToDate" className={classes.errorDate}>
              {formik.errors.toDate}
            </div>
          ) : null}
        </FormControl>
        <FormControl className={classes.divToDate}>
          <TimePicker
            label={intl.formatMessage(messages['toTimeLabel'])}
            margin="normal"
            id="toTime"
            name={'toTime'}
            value={selectedTimeEnd}
            className={classes.inputToDate}
            disabled
          />
          {errorTime ? (
            <div id="errorTime" className={classes.errorTime}>
              {intl.formatMessage(messages['timeError'])}
            </div>
          ) : null}
        </FormControl>
      </Row>
    </RightContainer>
  );
});

export { Right };
