import { useState } from 'react';
import { Typography, Container, Box, Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { messages } from './EditMessages';
import PersonIcon from '@material-ui/icons/Person';
import { useStyles } from './EditStyles';
import { Images } from 'assets';
import { useModal } from '@octopy/react-modal';
import { ModalText, ModalButton } from 'components/ModalMessages';
import { changeFormatDate } from 'utils/helpers';
import { useDashboard } from 'components/Dashboard';

const Edit = withRouter(({ id, positionInArray, coordinatorDetails }) => {
  const history = useHistory();
  const intl = useIntl();
  const classes = useStyles();
  const [idCoordinator] = useState(id);
  const { handleOpenModal } = useModal();
  const { deleteCoordinatorById } = useDashboard();

  const handleDeleteClick = () => {
    const modalTitle = (
      <ModalText>
        {intl.formatMessage(messages.areYouSureDelete)}
        <ModalText white>&nbsp;{coordinatorDetails.name}</ModalText>?
      </ModalText>
    );
    const modalActionButtons = (
      <ModalButton
        onClick={() =>
          deleteCoordinatorById(coordinatorDetails, positionInArray)
        }
      >
        {intl.formatMessage(messages.delete)}
      </ModalButton>
    );

    handleOpenModal({
      title: modalTitle,
      actionButtons: modalActionButtons,
      configProps: {
        maxWidth: 'xs'
      }
    });
  };

  return (
    <Grid item lg={4} md={6} sm={12} xs={12}>
      <Container className={classes.Container}>
        <Box className={classes.Content}>
          <Box className={classes.imgBox}>
            {coordinatorDetails.logoUrl ? (
              <img
                className={classes.imageProfile}
                src={coordinatorDetails.logoUrl}
                alt="logo"
              />
            ) : (
              <PersonIcon className={classes.iconPerson} />
            )}
          </Box>
          <Box className={classes.info}>
            <Typography className={classes.name}>
              {coordinatorDetails.name}
            </Typography>
            <Typography className={classes.date}>
              {intl.formatMessage(messages.date)}:
              {changeFormatDate(coordinatorDetails.createdAt || '')}
            </Typography>
          </Box>
        </Box>
        <div className={classes.actions}>
          <Grid container className={classes.actionsContainer}>
            <Grid
              className={classes.edit}
              alignItems="center"
              item
              container
              onClick={() =>
                history.push({
                  pathname: `/EditCoordinator/${idCoordinator}`,
                  state: { positionInArray }
                })
              }
              xs={4}
            >
              <div className={classes.actionButtonImageContainer}>
                <img
                  alt="edit"
                  src={Images.Pencil}
                  className={classes.actionButtonImage}
                />
              </div>
              <Typography component="span">
                {intl.formatMessage(messages.edit)}
              </Typography>
            </Grid>
            <Grid
              className={classes.delete}
              alignItems="center"
              item
              container
              xs={4}
              onClick={handleDeleteClick}
            >
              <div className={classes.actionButtonImageContainer}>
                <img
                  alt="delete"
                  src={Images.Trash}
                  className={classes.actionButtonImage}
                />
              </div>
              <Typography component="span">
                {intl.formatMessage(messages.delete)}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Container>
    </Grid>
  );
});

export { Edit };
