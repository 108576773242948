import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
  const iconContainer = {
    flexWrap: 'nowrap',
    cursor: 'pointer',
    '&:hover $actionButtonImageContainer': {
      backgroundColor: theme.palette.grey[200]
    }
  };

  return {
    Container: {
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      maxWidth: '400px',
      height: '169px',
      boxShadow: '0px 0px 5px #00000029',
      borderRadius: '5px'
    },
    Content: {
      paddingTop: '16px',
      display: 'flex',
      justifyContent: 'flex-start',
      height: '149px'
    },
    iconCalendar: {
      width: '32px',
      height: '36px',
      color: theme.palette.secondary.main
    },
    info: {
      margin: '0 20px 0 20px',
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    },
    imgBox: {
      marginLeft: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: '0px 3px 6px #00000029',
      height: 80,
      width: 80,
      borderRadius: '50%',
      marginRight: theme.spacing(2),
      overflow: 'hidden'
    },
    logoImage: {
      height: '100%',
      width: '100%',
      objectFit: 'cover'
    },
    title: {
      font: 'normal normal bold 15px/20px Roboto',
      color: '#00040F',
      maxWidth: '250px',
      overflow: 'hidden',
      whiteSpace: 'noWrap',
      textOverflow: 'ellipsis'
    },
    country: {
      font: 'normal normal normal 15px/20px Roboto',
      color: theme.palette.secondary.main
    },
    time: {
      font: 'normal normal normal 15px/20px Roboto',
      color: theme.palette.secondary.main
    },
    date: {
      font: 'normal normal normal 15px/20px Roboto',
      color: '#989898'
    },
    assign: {
      textAlign: 'left',
      font: 'normal normal normal 15px/20px Roboto',
      color: '#989898'
    },
    actions: {
      height: '25%',
      display: 'flex',
      alignItems: 'center',
      borderTop: '1px solid #E6E6E6'
    },
    image: {
      height: '100%',
      width: '100%',
      objectFit: 'cover'
    },
    actionsContainer: {
      paddingLeft: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 0
      }
    },
    edit: {
      color: theme.palette.secondary.main,
      ...iconContainer
    },
    delete: {
      color: '#989898',
      ...iconContainer
    },
    stand: {
      color: '#989898',
      ...iconContainer
    },
    actionButtonImageContainer: {
      borderRadius: '50%',
      padding: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '5px',
      [theme.breakpoints.down('xs')]: {
        marginRight: 0
      },
      boxSizing: 'content-box',
      transition: 'all 300ms'
    },
    actionButtonImage: {
      width: '21px',
      height: '21px'
    },
    actionButtonIcon: {
      fontSize: '27px'
    }
  };
});

export { useStyles };
