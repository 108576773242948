import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.Quiz.Cards.Create.title',
    defaultMessage: 'CREAR'
  },
  subTitle: {
    id: 'components.Quiz.Cards.Create.subTitle',
    defaultMessage: 'Quiz'
  }
});
