import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  logoProductLabel: {
    id: 'components.Exhibition.Form.Edit.Right.logoProductLabel',
    defaultMessage: 'Seleccionar imagen'
  },
  videoId: {
    id: 'components.Exhibition.Form.Edit.Right.videoId',
    defaultMessage: 'Id del video'
  },
  invalidUrlError: {
    id: 'components.Exhibition.Form.Edit.Right.invalidUrlError',
    defaultMessage: 'URL inválido'
  },
  urlChatLabel: {
    id: 'components.Exhibition.Form.Edit.Right.urlChatLabel',
    defaultMessage: 'URL del chat'
  },
  productName: {
    id: 'components.Exhibition.Form.Edit.Right.productName',
    defaultMessage: 'Nombre del producto o servicio'
  },
  productSlogan: {
    id: 'components.Exhibition.Form.Edit.Right.productSlogan',
    defaultMessage: 'Eslogan del producto o servicio'
  },
  productDescription: {
    id: 'components.Exhibition.Form.Edit.Right.productDescription',
    defaultMessage: 'Descripción breve del producto'
  },
  selectEvent: {
    id: 'components.Exhibition.Form.Edit.Right.selectEvent',
    defaultMessage: 'Seleccione un evento'
  },
  saveChanges: {
    id: 'components.Exhibition.Form.Edit.Right.input.submitButton.save.label',
    defaultMessage: 'GUARDAR'
  },
  isRequiredError: {
    id: 'components.Exhibition.Form.Edit.Right.isRequiredError',
    defaultMessage: 'Este campo es requerido'
  },
  error: {
    id: 'components.Exhibition.Form.Edit.Right.error',
    defaultMessage: 'Ha ocurrido un error durante la petición'
  },
  ok: {
    id: 'components.Exhibition.Form.Edit.Right.ok',
    defaultMessage: 'Ok'
  },
  success: {
    id: 'components.Exhibition.Form.Edit.Right.success',
    defaultMessage: 'LOS DATOS HAN SIDO ACTUALIZADOS CORRECTAMENTE'
  }
});
