import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.Analytics.CreateCoordinatorCard.title',
    defaultMessage: 'AGREGAR'
  },
  subtitle: {
    id: 'components.Analytics.CreateCoordinatorCard.subtitle',
    defaultMessage: 'Coordinador'
  }
});
