import { Button } from '@material-ui/core';
import { useStyles } from './SubmitButtonStyles';

export const SubmitButton = ({ label, children, className, ...otherProps }) => {
  const classes = useStyles();

  return (
    <Button
      className={`${classes.submitButton} ${className}`}
      variant="contained"
      color="primary"
      {...otherProps}
    >
      {label || children}
    </Button>
  );
};
