import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  logoLabel: {
    id: 'components.Organizations.EditForm.Right.input.logo.label',
    defaultMessage: 'Seleccionar logo'
  },
  businessNameLabel: {
    id: 'components.Organizations.EditForm.Right.input.businessName.label',
    defaultMessage: 'Razon social'
  },
  completeNameLabel: {
    id: 'components.Organizations.EditForm.Right.input.completeName.label',
    defaultMessage: 'Nombre completo'
  },
  contactPhoneLabel: {
    id: 'components.Organizations.EditForm.Right.input.contactPhone.label',
    defaultMessage: 'Telefono de contacto'
  },
  emailLabel: {
    id: 'components.Organizations.EditForm.Right.input.email.label',
    defaultMessage: 'Correo electronico'
  },
  countryLabel: {
    id: 'components.Organizations.CreateForm.Right.input.country.label',
    defaultMessage: 'Pais'
  },
  participantsQuantityLabel: {
    id:
      'components.Organizations.EditForm.Right.input.participantsQuantity.label',
    defaultMessage: 'Cantidad de participantes'
  },
  sponsorsQuantityLabel: {
    id: 'components.Organizations.EditForm.Right.input.sponsorsQuantity.label',
    defaultMessage: 'Cantidad de patrocinadores'
  },
  submitButtonLabel: {
    id: 'components.Organizations.EditForm.Right.input.submitButton.edit.label',
    defaultMessage: 'EDITAR'
  },
  isRequiredError: {
    id: 'components.Organizations.EditForm.Right.input.error.isRequired',
    defaultMessage: 'Este campo es requerido'
  },
  minError: {
    id: 'components.Organizations.EditForm.Right.input.error.min',
    defaultMessage: 'Minimo 1'
  },
  max9999999999Error: {
    id: 'components.Organizations.EditForm.Right.input.error.max9999999999',
    defaultMessage: 'Maximo 9.999.999.999'
  },
  max9999Error: {
    id: 'components.Organizations.EditForm.Right.input.error.max9999',
    defaultMessage: 'Maximo 9.999'
  },
  emailError: {
    id: 'components.Organizations.EditForm.Right.input.error.email',
    defaultMessage: 'Correo electronico invalido'
  },
  success: {
    id: 'components.Organizations.EditForm.Right.modal.successfullyUpdated',
    defaultMessage: 'Se guardaron los cambios realizados en el usuario: '
  },
  ok: {
    id: 'components.Organizations.EditForm.Right.modal.ok',
    defaultMessage: 'Ok'
  }
});
