import { Button } from '@material-ui/core';
import { useStyles } from './ModalButtonStyles';
import { useModal } from '@octopy/react-modal';

export const ModalButton = ({ children, onClick }) => {
  const classes = useStyles();
  const { handleCloseModal } = useModal();

  const handleOnClick = () => {
    onClick && onClick();
    handleCloseModal();
  };

  return (
    <Button className={classes.button} onClick={handleOnClick}>
      {children}
    </Button>
  );
};
