import { useDashboard } from 'components/Dashboard';
import { PasswordRecovery } from '../components/PasswordRecovery';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { accessDictionary, intialPahts } from './accessDictionary';
import { routes } from './routes';
import { Login } from 'components/Login/Login';
import { FullScreenLayout } from 'layouts/FullScreenLayout/FullScreenLayout';
import { ErrorHTTP } from 'views/ErrorHTTP/ErrorHTTP';
import history from './history.js';

function Navigation() {
  const { user } = useDashboard();

  const validAuth = (path) =>
    user && accessDictionary[user.role.name].includes(path);

  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path="/"
          render={(props) =>
            user ? (
              <Redirect to={intialPahts[user.role.name]} />
            ) : (
              <FullScreenLayout>
                <Login {...props} />
              </FullScreenLayout>
            )
          }
        />

        <Route
          exact
          path="/password-recovery"
          render={(props) =>
            user ? (
              <Redirect to={intialPahts[user.role.name]} />
            ) : (
              <FullScreenLayout>
                <PasswordRecovery {...props} />
              </FullScreenLayout>
            )
          }
        />

        <Route
          exact
          path="/404"
          render={(props) => (
            <FullScreenLayout>
              <ErrorHTTP error="404" {...props} />
            </FullScreenLayout>
          )}
        />

        <Route
          exact
          path="/401"
          render={(props) => (
            <FullScreenLayout>
              <ErrorHTTP error="401" {...props} />
            </FullScreenLayout>
          )}
        />

        {user ? (
          routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              render={(props) =>
                validAuth(route.path) ? (
                  <route.layout form={route.form}>
                    {typeof route.component === 'object' ? (
                      route.component.map((PartComponent, i) => (
                        <PartComponent key={`${i}PartComponent`} {...props} />
                      ))
                    ) : (
                      <route.component {...props} />
                    )}
                  </route.layout>
                ) : (
                  <Redirect to="/401" />
                )
              }
            />
          ))
        ) : (
          <Redirect to="/" />
        )}
      </Switch>
    </Router>
  );
}

export { Navigation };
