import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    minHeight: '100vh',
    paddingTop: '50px'
  },
  content: {
    width: 'calc(100% - 113px)',
    maxHeight: 'calc(100vh - 50px)',
    overflow: 'auto',
    paddingLeft: '32px',
    paddingRight: '32px',
    paddingTop: '19px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      paddingLeft: '15px',
      paddingRight: '15px'
    }
  }
}));

export { useStyles };
