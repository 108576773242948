import {
  ModalErrorMessage,
  ModalButtonMessage,
  ModalSuccessMessage
} from 'components/ModalMessages';

export const isBlank = (str) => !str || /^\s*$/.test(str);

export const downloadFiles = (urls) => {
  const download = (urls) => {
    const url = urls.pop();

    const a = document.createElement('a');

    a.setAttribute('href', url);
    a.setAttribute('download', '');
    a.setAttribute('target', '_blank');
    a.click();

    urls.length === 0 && clearInterval(interval);
  };

  const interval = setInterval(download, 300, urls);
};

export const getFormData = (object) => {
  const formData = new FormData();

  for (let key in object) {
    formData.append(key, object[key]);
  }

  return formData;
};

export const allNumbersInsideToString = (object) => {
  const newObject = {};

  for (let key in object) {
    const value = object[key];

    newObject[key] = typeof value === 'number' ? String(value) : value;
  }

  return newObject;
};

export const processFile = (file) => {
  const blobUrl = URL.createObjectURL(file);

  return { blobUrl, file };
};

// (Date Object, Date Object) => Date Object array
export const getDatesBetween = (startDate, endDate) => {
  const addDays = (date, days) => {
    const newDate = new Date(date.valueOf());

    newDate.setDate(date.getDate() + days);

    return newDate;
  };

  const dateArray = [];
  let currentDate = startDate;

  while (currentDate <= endDate) {
    dateArray.push(new Date(currentDate));

    currentDate = addDays(currentDate, 1);
  }

  return dateArray;
};

// Date Object => AAAA/MM/DD
export const formatDate = (date) => {
  const offset = date.getTimezoneOffset();
  const newDate = new Date(date.getTime() - offset * 60 * 1000);
  const formattedDate = newDate
    .toISOString()
    .split('T')[0]
    .replace(/-/g, '/');

  return formattedDate;
};

// AAAA/MM/DD => Date Object
export const counterFormatDate = (date) => new Date(date);

// Date Object => HH:mm
export const formatTime = (date) => {
  const hour = date.getHours();
  const minutes = date.getMinutes();

  const formattedTime = `${hour}:${minutes < 10 ? `0${minutes}` : minutes}`;

  return formattedTime;
};

// HH:mm => Date Object
export const counterFormatTime = (time) => {
  const timeSplitted = time.split(':');
  const hours = timeSplitted[0];
  const minutes = timeSplitted[1];

  return new Date(null, null, null, hours, minutes);
};

export const getMaxDate = (dates) => new Date(Math.max.apply(null, dates));

export const verifyHourAnticipation = (date, hours = 1) => {
  const now = new Date();

  now.setHours(now.getHours() + hours);

  const error = date < now;

  return error;
};

export const addHours = (date, hours = 1) =>
  new Date(date.setHours(date.getHours() + hours));

export const changeFormatDate = (oldDate) =>
  oldDate
    .split('T')[0]
    .split('-')
    .reverse()
    .join('/');

export const changeFormatDateYYYYMMDD = (oldDate) =>
  oldDate
    .split('T')[0]
    .split('-')
    .join('/');

export const castRightAnswerQuiz = (object) => {
  const questions = object.questions;
  const questionsIntegerRightAnswer = questions.map((question) => {
    let room = question.room;

    if (room === 'mainRoom') {
      room = 'main_room';
    } else if (room === 'exhibitionRoom') {
      room = 'exhibition_room';
    } else if (room === 'partnerRoom') {
      room = 'partner_stage';
    } else if (room === 'coffeeRoom') {
      room = 'coffe_room';
    }

    return {
      ...question,
      right_answer: parseInt(question.right_answer),
      room
    };
  });

  return { ...object, questions: questionsIntegerRightAnswer };
};

export const mappedToFormikStructure = ({ id, idEvent, questions }) => {
  const mappedQuestions = {
    idQuiz: id,
    event: idEvent,
    questionshall: [],
    questionsmainRoom: [],
    questionsexhibitionRoom: [],
    questionspartnerRoom: [],
    questionschallenge: [],
    questionscoffeeRoom: []
  };
  const correctAnswers = {
    hall: { 0: 1, 1: 1, 2: 1, 3: 1 },
    main: { 0: 1, 1: 1, 2: 1, 3: 1 },
    exhibition: { 0: 1, 1: 1, 2: 1, 3: 1 },
    partner: { 0: 1, 1: 1, 2: 1, 3: 1 },
    challenge: { 0: 1, 1: 1, 2: 1, 3: 1 },
    coffee: { 0: 1, 1: 1, 2: 1, 3: 1 }
  };

  questions.forEach((question) => {
    if (question.room === 'hall') {
      correctAnswers.hall[`${mappedQuestions.questionshall.length}`] =
        question.right_answer;
      mappedQuestions.questionshall.push({
        ...question,
        key: mappedQuestions.questionshall.length
      });
    } else if (question.room === 'main_room') {
      correctAnswers.main[`${mappedQuestions.questionsmainRoom.length}`] =
        question.right_answer;
      mappedQuestions.questionsmainRoom.push({
        ...question,
        room: 'mainRoom',
        key: mappedQuestions.questionsmainRoom.length
      });
    } else if (question.room === 'exhibition_room') {
      correctAnswers.exhibition[
        `${mappedQuestions.questionsexhibitionRoom.length}`
      ] = question.right_answer;
      mappedQuestions.questionsexhibitionRoom.push({
        ...question,
        room: 'exhibitionRoom',
        key: mappedQuestions.questionsexhibitionRoom.length
      });
    } else if (question.room === 'partner_stage') {
      correctAnswers.partner[`${mappedQuestions.questionspartnerRoom.length}`] =
        question.right_answer;
      mappedQuestions.questionspartnerRoom.push({
        ...question,
        room: 'partnerRoom',
        key: mappedQuestions.questionspartnerRoom.length
      });
    } else if (question.room === 'challenge') {
      correctAnswers.challenge[`${mappedQuestions.questionschallenge.length}`] =
        question.right_answer;
      mappedQuestions.questionschallenge.push({
        ...question,
        key: mappedQuestions.questionschallenge.length
      });
    } else if (question.room === 'coffe_room') {
      correctAnswers.coffee[`${mappedQuestions.questionscoffeeRoom.length}`] =
        question.right_answer;
      mappedQuestions.questionscoffeeRoom.push({
        ...question,
        room: 'coffeeRoom',
        key: mappedQuestions.questionscoffeeRoom.length
      });
    }
  });

  return { mappedQuestions, correctAnswers };
};

export const getDataError = (request) =>
  !!request ? request.data.headerResponse : { code: 'Network Error' };

export const createSuccessModalConfig = (
  messageSuccess,
  targetName,
  buttonMessage
) => ({
  title: (
    <ModalSuccessMessage
      messageSuccess={messageSuccess}
      targetName={targetName}
    />
  ),
  actionButtons: <ModalButtonMessage message={buttonMessage} />,
  configProps: {
    maxWidth: 'xs'
  }
});

export const createErrorModalConfig = (code, message) => ({
  title: <ModalErrorMessage code={code} message={message} />,
  actionButtons: <ModalButtonMessage message="ok" />,
  configProps: {
    maxWidth: 'xs'
  }
});

export const removeNullValuesInObject = (object) => {
  const objectToArray = Object.entries(object);

  return Object.fromEntries(
    objectToArray.filter(
      ([key, value]) =>
        (value !== (null || undefined) && typeof value !== 'object') ||
        (typeof value === 'object' && !!value.file)
    )
  );
};
