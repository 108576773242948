import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import { useStyles } from './PartnersByEventStyles';
import { messages } from './PartnersByEventMessages';
import { Chart } from 'components/Chart';

export const PartnersByEvent = ({ partnersByEvent }) => {
  const classes = useStyles();
  const intl = useIntl();

  const options = {
    values: partnersByEvent.map((value) => ({
      x: value.eventName,
      y: value.partnersQuantity
    })),
    color: '#90EE7E',
    yTitle: intl.formatMessage(messages.yTitle)
  };

  return (
    <Grid item lg={6} md={6} className={classes.gridItem}>
      <div className={classes.container}>
        <div className={classes.title}>
          {intl.formatMessage(messages.title)}
        </div>
        <Chart {...options} key={1} />
      </div>
    </Grid>
  );
};
