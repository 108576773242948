import { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useFormik } from 'formik';
import {
  Row,
  RightContainer,
  ImagePicker,
  TextField,
  TextFieldPhone,
  SubmitButton
} from 'components/CreationForm';
import {
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio
} from '@material-ui/core';
import { ColorForm } from '../../ColorForm';
import { Person } from '@material-ui/icons';
import { useStyles } from './RightStyles';
import { useRowsStyles } from '../RowsStyles';
import { messages } from './RightMessages';
import { initialValuesForm, getValidationSchema } from './helper';
import { useDashboard } from 'components/Dashboard';

const Right = withRouter(({ history }) => {
  const classes = useStyles();
  const rowsClasses = useRowsStyles();
  const intl = useIntl();
  const { addNewLicence } = useDashboard();

  const [selectedValue, setSelectedValue] = useState('license');

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const formik = useFormik({
    initialValues: initialValuesForm,
    validationSchema: getValidationSchema(intl),
    onSubmit: async (values) => {
      const valuesToSend = {
        ...values,
        typeLicense: selectedValue,
        status: true
      };

      const error = await addNewLicence(valuesToSend);

      if (!!!error) history.goBack();
    }
  });

  const submitButton = (
    <SubmitButton
      className={classes.submitButton}
      label={intl.formatMessage(messages.submitButtonLabel)}
      onClick={formik.handleSubmit}
    />
  );

  return (
    <>
      <RightContainer submitButton={submitButton}>
        <Row className={rowsClasses[1]}>
          <ImagePicker
            id="createLicenseLogo"
            onChange={(logo) => {
              formik.setFieldValue('logoUrl', logo.blobUrl);
              formik.setFieldValue('logo', logo);
            }}
            Icon={Person}
            label={intl.formatMessage(messages.logoUrlLabel)}
            name="logoUrl"
            imageUrl={formik.values.logoUrl || formik.values.logo?.blobUrl}
          />
        </Row>
        <Row className={rowsClasses[2]}>
          <TextField
            className={classes.input}
            key={'businessNameInput'}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors['businessName']}
            value={formik.values['businessName']}
            touched={formik.touched['businessName']}
            label={intl.formatMessage(messages['businessName'])}
            name={'businessName'}
          />
          <TextField
            className={classes.input}
            key={'emailInput'}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors['email']}
            value={formik.values['email']}
            touched={formik.touched['email']}
            label={intl.formatMessage(messages['emailLabel'])}
            name={'email'}
          />
          <TextField
            className={classes.input}
            key={'contactNameInput'}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors['contactName']}
            value={formik.values['contactName']}
            touched={formik.touched['contactName']}
            label={intl.formatMessage(messages['contactNameLabel'])}
            name={'contactName'}
          />
          <TextFieldPhone
            key={`contactPhoneInput`}
            country="mx"
            regions={'america'}
            onChange={(phone) =>
              formik.setFieldValue('contactPhone', phone, true)
            }
            onBlur={formik.handleBlur}
            error={formik.errors['contactPhone']}
            value={formik.values['contactPhone']}
            touched={formik.touched['contactPhone']}
            placeholder={intl.formatMessage(messages['contactPhoneLabel'])}
            name={'contactPhone'}
            className={classes.input}
          />
        </Row>
        <Row className={rowsClasses[3]}>
          <FormControl
            component="fieldset"
            className={classes.radioButtonsContainer}
          >
            <RadioGroup row>
              <FormControlLabel
                className={classes.radioButtonLabel}
                value="1"
                control={
                  <Radio
                    checked={selectedValue === 'license'}
                    onChange={handleChange}
                    value="license"
                    name="radio-button-license"
                  />
                }
                label={intl.formatMessage(messages.licenseLabel)}
                labelPlacement="end"
              />

              <FormControlLabel
                className={`${classes.radioButtonLabel} ${classes.hiddenElement}`}
                value="2"
                control={
                  <Radio
                    checked={selectedValue === 'membership'}
                    onChange={handleChange}
                    value="membership"
                    name="radio-button-membership"
                  />
                }
                label={intl.formatMessage(messages.membershipLabel)}
                labelPlacement="end"
              />
            </RadioGroup>
          </FormControl>
        </Row>
        <Row className={rowsClasses[4]}>
          <TextField
            className={classes.input}
            key={'limitClientsInput'}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors['limitClients']}
            value={formik.values['limitClients']}
            touched={formik.touched['limitClients']}
            label={intl.formatMessage(messages.limitClientsOrgLabel)}
            name={'limitClients'}
            type="number"
            inputProps={{ min: 1 }}
          />
        </Row>
        <div className={classes.containerBrandingColors}>
          <ColorForm
            edit={false}
            setValue={formik.setFieldValue}
            initialColors={{
              title: '',
              subtitle: '',
              subtitle2: '',
              button: '',
              hover: ''
            }}
          />
        </div>
        <br />
      </RightContainer>
    </>
  );
});

export { Right };
