import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.NoEvents.title',
    defaultMessage: 'No se encontraron eventos:'
  },
  subtitle: {
    id: 'components.NoEvents.subtitle',
    defaultMessage:
      'Debes tener eventos creados para poder navegar en esta sección.'
  }
});
