import { useStyles } from './ModalTextStyles';

export const ModalText = ({ white, children, block }) => {
  const classes = useStyles();
  let className = classes.text;

  if (white) className += ` ${classes.white}`;

  return block ? (
    <div className={className}>{children}</div>
  ) : (
    <span className={className}>{children}</span>
  );
};
