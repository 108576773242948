import 'react-phone-input-2/lib/style.css';
import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { withRouter, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { Box } from '@material-ui/core';
import {
  Row,
  RightContainer,
  ImagePicker,
  TextField,
  TextFieldPhone,
  TextArea,
  SubmitButton,
  Select
} from 'components/CreationForm';
import { useLoader } from '@octopy/react-loader';
import { Person } from '@material-ui/icons';
import { useStyles } from './RightStyles';
import { useRowsStyles } from '../RowsStyles';
import { messages } from './RightMessages';
import { Images } from 'assets';
import { SideDrawer } from '../../../SideDrawer';
import { getValidationSchema } from './helpers';
import { useModal } from '@octopy/react-modal';
import { getDataError, createErrorModalConfig } from 'utils/helpers';
import { useNoEvents } from 'components/NoEvents/hooks/useNoEvents';
import { getPartnerById } from 'providers/api/requests/partners';
import { useDashboard } from 'components/Dashboard';
import { useCountries } from 'providers/countries/countriesContext';

const Right = withRouter(({ history }) => {
  const { americaCountries: countries } = useCountries();
  const { handleNoEventShow } = useNoEvents();
  const { idPartner } = useParams();
  const { updatePartnerById } = useDashboard();
  const [partnersDetails, setPartnersDetails] = useState({});
  const { handleOpenModal } = useModal();
  const { handleShowLoader } = useLoader();
  const options = [];

  countries.map((country) => options.push({ value: country, label: country }));
  const onSubmit = async (values, initialValues) => {
    const valuesChanged = {};

    if (values.businessName !== initialValues.businessName)
      valuesChanged.businessName = values.businessName;
    if (values.contactName !== initialValues.contactName)
      valuesChanged.contactName = values.contactName;
    if (values.email !== initialValues.email)
      valuesChanged.email = values.email;
    if (values.contactPhone !== initialValues.contactPhone)
      valuesChanged.contactPhone = values.contactPhone;
    if (values.country !== initialValues.country)
      valuesChanged.country = values.country;
    if (values.description !== initialValues.description)
      valuesChanged.description = values.description;
    if (values.file) valuesChanged.file = values.file.file;

    valuesChanged.videoId = values.stand.videoId;
    valuesChanged.plataform = values.stand.plataform;

    const partnerWithId = {
      id: idPartner,
      ...valuesChanged
    };

    const error = await updatePartnerById(partnerWithId, idPartner);

    !error && history.push('/Partners');
  };

  const classes = useStyles();
  const rowsClasses = useRowsStyles();
  const intl = useIntl();

  const inputPhoneStyle = {
    backgroundColor: '#F9F9F9',
    border: 0,
    height: '45px',
    font: 'normal normal normal 12px/16px Roboto',
    color: '#00040F'
  };

  const buttonPhoneStyle = { height: '45px', border: 0 };
  const initialValues = {
    logoUrl: partnersDetails.logoUrl,
    businessName: partnersDetails.businessName,
    contactName: partnersDetails.contactName,
    country: '',
    contactPhone: partnersDetails.contactPhone,
    email: partnersDetails.email,
    description: partnersDetails.description,
    videoId: partnersDetails.videoId,
    plataform: partnersDetails.plataform
  };

  const formik = useFormik({
    initialValues,
    validationSchema: getValidationSchema(intl),
    onSubmit
  });

  useEffect(async () => {
    handleNoEventShow(false);
    (async () => {
      try {
        handleShowLoader(true);

        if (history.location.state.index !== undefined) {
          const partner = await getPartnerById(idPartner);
          const partnerDetails = {
            ...partner,
            index: history.location.state.index
          };

          setPartnersDetails(partnerDetails);
          formik.setValues(partnerDetails);
        }
      } catch (request) {
        const { code, message } = getDataError(request);

        handleOpenModal(createErrorModalConfig(code, message));
      } finally {
        handleShowLoader(false);
      }
    })();
  }, []);

  const submitButton = (
    <Row>
      <SubmitButton
        label={intl.formatMessage(messages.editSubmitButtonLabel)}
        onClick={formik.handleSubmit}
      />
    </Row>
  );

  const [showSideDrawer, setShowSideDrawer] = useState(false);
  const handleShowSideDrawer = () => setShowSideDrawer(true);
  const handleHideSideDrawer = () => setShowSideDrawer(false);

  return (
    <>
      <SideDrawer
        handleHide={handleHideSideDrawer}
        show={showSideDrawer}
        edit={true}
        partnerS={idPartner}
      />
      <RightContainer submitButton={submitButton}>
        <Row className={rowsClasses[1]}>
          <ImagePicker
            onChange={(logo) => {
              formik.setFieldValue('logoUrl', null);
              formik.setFieldValue('file', logo);
            }}
            Icon={Person}
            label={intl.formatMessage(messages.logoUrlLabel)}
            name="logoUrl"
            imageUrl={formik.values.logoUrl || formik.values.file?.blobUrl}
          />
        </Row>
        <Row className={rowsClasses[2]}>
          <TextField
            className={classes.input}
            key={'businessNameInput'}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors['businessName']}
            value={formik.values['businessName']}
            touched={formik.touched['businessName']}
            label={intl.formatMessage(messages['businessNameLabel'])}
            name={'businessName'}
          />
          <TextField
            className={classes.input}
            key={'emailInput'}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors['email']}
            value={formik.values['email']}
            touched={formik.touched['email']}
            label={intl.formatMessage(messages['emailLabel'])}
            name={'email'}
          />
          <TextField
            className={classes.input}
            key={'contactNameInput'}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors['contactName']}
            value={formik.values['contactName']}
            touched={formik.touched['contactName']}
            label={intl.formatMessage(messages['contactNameLabel'])}
            name={'contactName'}
          />

          <TextFieldPhone
            key={`contactPhoneInput`}
            country="mx"
            regions={'america'}
            onChange={(e) => formik.setFieldValue('contactPhone', e)}
            onBlur={formik.handleBlur('contactPhone')}
            error={formik.errors['contactPhone']}
            value={formik.values.contactPhone}
            placeholder={intl.formatMessage(messages['contactPhoneLabel'])}
            name={'contactPhone'}
            size="small"
            className={classes.input}
            inputStyle={inputPhoneStyle}
            buttonStyle={buttonPhoneStyle}
          />

          <Select
            label={intl.formatMessage(messages.selectCountry)}
            name={'country'}
            className={classes.input}
            onBlur={formik.handleBlur}
            value={formik.values.country}
            touched={formik.touched.country}
            error={formik.errors.country}
            onChange={formik.handleChange}
            options={options}
          />
          <TextArea
            className={classes.input}
            rowsMin={2}
            id="description"
            key={'descriptionInput'}
            name={'description'}
            value={formik.values['description']}
            onChange={formik.handleChange}
            placeholder={intl.formatMessage(messages.descriptionPlaceholder)}
          />
        </Row>
        <Row className={classes.imagesBox}>
          <img src={Images.Scheme} alt="scheme" className={classes.scheme} />
          <Box onClick={handleShowSideDrawer} className={classes.settings}>
            <img src={Images.Settings} alt="settings" />
          </Box>
        </Row>
      </RightContainer>
    </>
  );
});

export { Right };
