import * as yup from 'yup';
import { messages } from './RightMessages';

export const getValidationSchema = (intl) => {
  const requiredErrorMessage = intl.formatMessage(messages.isRequiredError);
  const emailErrorMessage = intl.formatMessage(messages.emailError);
  const max9999999999ErrorMessage = intl.formatMessage(
    messages.max9999999999Error
  );
  const max9999ErrorMessage = intl.formatMessage(messages.max9999Error);
  const minErrorMessage = intl.formatMessage(messages.minError);

  return yup.object({
    businessName: yup.string().trim().required(requiredErrorMessage),
    completeName: yup.string().trim().required(requiredErrorMessage),
    contactPhone: yup
      .string()
      .min(6, requiredErrorMessage)
      .required(requiredErrorMessage),
    email: yup.string().email(emailErrorMessage).required(requiredErrorMessage),
    country: yup.string().required(requiredErrorMessage),
    participantsQuantity: yup
      .number()
      .min(1, minErrorMessage)
      .max(9999999999, max9999999999ErrorMessage)
      .required(requiredErrorMessage),
    sponsorsQuantity: yup
      .number()
      .min(1, minErrorMessage)
      .max(9999, max9999ErrorMessage)
      .required(requiredErrorMessage)
  });
};
