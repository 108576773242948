import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  countrySelect: {
    width: '290px',
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      width: '212px'
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      width: '80%'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  description: {
    minWidth: '292px',
    textAlign: 'left',
    font: 'normal normal normal 12px/16px Roboto',
    color: '#00040F7F',
    width: '290px',
    background: '#F9F9F9',
    border: 0
  },
  settings: {
    cursor: 'pointer'
  },
  errorPhone: {
    color: theme.palette.error['main'],
    marginTop: '3px',
    marginBottom: '-13px',
    fontSize: '12px',
    marginLeft: '30px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '22px'
    }
  },
  input: {
    width: '290px',
    marginLeft: '6px',
    marginRight: '6px',
    marginTop: theme.spacing(1),
    maxHeight: '120px',
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      width: '212px'
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      width: '80%'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  textArea: {
    minHeight: '79px',
    [theme.breakpoints.down('md')]: {
      minWidth: '100%'
    }
  },
  MuiTypographyBody1: {
    lineHeight: 0
  },
  notice: {
    width: '100%',
    marginLeft: '6px',
    font: 'normal normal normal 12px/16px Roboto',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },

  imagesBox: {
    display: 'flex',
    flexDirection: 'row'
  }
}));

export { useStyles };
