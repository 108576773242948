import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.Organizations.title',
    defaultMessage: 'Usuarios de Rooms'
  },
  subtitle: {
    id: 'components.Organizations.subtitle',
    defaultMessage:
      'Listado de usuarios que han sido creados para acceder a la plataforma'
  },
  successfullyDeleted: {
    id: 'components.Organizations.modal.delete.title',
    defaultMessage: 'Exitosamente eliminado el usuario: '
  },
  ok: {
    id: 'components.Organizations.modal.ok',
    defaultMessage: 'Ok'
  },
  page: {
    id: 'components.Organizations.pagination.select.page',
    defaultMessage: 'página'
  }
});
