import { ButtonBase } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { TextField } from 'components/CreationForm';
import { useStyles } from './RankingCategoriesStyles';
import { messages } from './RankingCategoriesMessages';
import { Images } from 'assets';
import { Field, FieldArray } from 'formik';
import { SubmitButton } from 'components/CreationForm';
import { responseMapper } from 'utils/responseMapper';
import { templateAddCategories } from 'providers/api/mappers/challenge';
import { useDashboard } from 'components/Dashboard';
import { FormikProvider, useFormik } from 'formik';
import { getValidationSchema, initialValues } from './helpers';
import { useEffect } from 'react';
import { withRouter } from 'react-router';

const inputNames = ['name', 'minScore', 'maxScore'];

export const RankingCategories = withRouter(({ eventSelect, history }) => {
  const { categories, addNewCategories, fetchCategories } = useDashboard();
  const intl = useIntl();
  const classes = useStyles();
  const errorMessages = {
    requiredMessage: intl.formatMessage(messages.requiredError)
  };

  useEffect(async () => {
    const response = await fetchCategories(eventSelect);
  }, [eventSelect]);

  useEffect(() => {
    const rankingCategories = { initial: {}, following: [] };

    if (categories.length > 0) {
      categories.map((category) => {
        rankingCategories.following.push(category);
      });

      rankingCategories.initial = rankingCategories.following[0];
      rankingCategories.following.shift();

      formik.setValues({
        rankingCategories: {
          initial: rankingCategories.initial,
          following: rankingCategories.following
        }
      });
    } else {
      formik.setValues(initialValues);
    }
  }, [categories]);

  const submit = async (values) => {
    let categories = [
      values.rankingCategories.initial,
      ...values.rankingCategories.following
    ];

    categories = categories.map(function (cat) {
      return {
        name: cat.name,
        minScore: parseInt(cat.minScore, 10),
        maxScore: parseInt(cat.maxScore, 10)
      };
    });

    const mappedValues = responseMapper({
      template: templateAddCategories,
      data: categories
    });

    const newCategories = {
      categories: mappedValues,
      event: eventSelect
    };

    const error = await addNewCategories(newCategories);

    if (!!!error) history.push('/Challenge');
  };

  const formik = useFormik({
    isInitialValid: false,
    enableReinitialize: true,
    validationSchema: getValidationSchema(errorMessages),

    onSubmit: submit
  });

  const handleChangeMaxScore = (indexF, position, event) => {
    if (position === 'initial') {
      if (formik.values.rankingCategories.initial.maxScore !== '') {
        formik.setFieldValue('rankingCategories', {
          ...formik.values.rankingCategories,
          following: formik.values.rankingCategories.following.map(
            (category, index) =>
              index === 0
                ? {
                    ...category,
                    minScore:
                      parseInt(
                        formik.values.rankingCategories.initial.maxScore
                      ) + 1
                  }
                : category
          )
        });
      }
    } else {
      if (formik.values.rankingCategories.following[indexF].maxScore !== '') {
        const followingList = formik.values.rankingCategories.following;

        if (followingList.length - 1 > indexF) {
          console.log('entro');
          followingList[indexF + 1].minScore =
            parseInt(
              formik.values.rankingCategories.following[indexF].maxScore
            ) + 1;
          formik.setFieldValue('rankingCategories', {
            ...formik.values.rankingCategories,
            following: followingList
          });
        }
      }
    }
  };

  return (
    <>
      {formik.values ? (
        <FormikProvider value={formik}>
          <div>
            <div className={classes.title}>
              {intl.formatMessage(messages.initialTitle)}
            </div>
            <div className={classes.description}>
              {intl.formatMessage(messages.initialDescription)}
            </div>
            <div>
              {inputNames.map((name) => (
                <Field
                  key={`${name}InitialCategoryInput`}
                  name={`rankingCategories.initial.${name}`}
                >
                  {({ field, meta: { error, touched } }) => (
                    <TextField
                      value={field.value}
                      disabled={name === 'minScore' ? 'disabled' : null}
                      name={field.name}
                      type={name === 'maxScore' ? 'number' : 'text'}
                      onBlur={(event) => {
                        field.onBlur(event);
                        name === 'maxScore' &&
                          handleChangeMaxScore(0, 'initial', event);
                      }}
                      onChange={field.onChange}
                      error={error}
                      touched={touched}
                      className={classes.input}
                      label={intl.formatMessage(messages[`${name}Label`])}
                    />
                  )}
                </Field>
              ))}
            </div>
          </div>
          <div className={classes.followingCategoriesContainer}>
            <div className={classes.title}>
              {intl.formatMessage(messages.followingTitle)}
            </div>
            <div className={classes.description}>
              {intl.formatMessage(messages.followingDescription)}
            </div>
            <FieldArray name="rankingCategories.following">
              {({ remove, push }) =>
                formik.values.rankingCategories.following.map(
                  (_, index, arr) => (
                    <div
                      key={`${index}FollowingFieldArray`}
                      className={classes.followingCategorieContainer}
                    >
                      {inputNames.map((name) => (
                        <Field
                          key={`${index}${name}FollowingCategoryInput`}
                          name={`rankingCategories.following.${index}.${name}`}
                        >
                          {({ field, meta: { error, touched } }) => (
                            <TextField
                              value={field.value}
                              name={field.name}
                              type={name === 'maxScore' ? 'number' : 'text'}
                              disabled={name === 'minScore' ? 'disabled' : null}
                              onBlur={(event) => {
                                field.onBlur(event);
                                name === 'maxScore' &&
                                  handleChangeMaxScore(0, 'following', event);
                              }}
                              onChange={field.onChange}
                              error={error}
                              touched={touched}
                              className={classes.input}
                              label={intl.formatMessage(
                                messages[`${name}Label`]
                              )}
                            />
                          )}
                        </Field>
                      ))}
                      {index === arr.length - 1 && (
                        <span className={classes.actionButtonsContainer}>
                          {formik.values.rankingCategories.following.length >
                            1 && (
                            <ButtonBase
                              onClick={() => remove(index)}
                              className={classes.actionButton}
                            >
                              <img
                                className={classes.actionButtonImage}
                                src={Images.removeCircle}
                                alt="removeIcon"
                              />
                            </ButtonBase>
                          )}
                          {formik.isValid ? (
                            <ButtonBase
                              onClick={() =>
                                push({
                                  name: '',
                                  minScore:
                                    parseInt(
                                      formik.values.rankingCategories.following[
                                        index
                                      ].maxScore,
                                      10
                                    ) + 1,
                                  maxScore: ''
                                })
                              }
                              className={classes.actionButton}
                            >
                              <img
                                className={classes.actionButtonImage}
                                src={Images.addCircle}
                                alt="addIcon"
                              />
                            </ButtonBase>
                          ) : null}
                        </span>
                      )}
                    </div>
                  )
                )
              }
            </FieldArray>
            {formik.isValid ? (
              <SubmitButton
                onClick={formik.handleSubmit}
                className={classes.submitButton}
              >
                {intl.formatMessage(messages.submit)}
              </SubmitButton>
            ) : null}
          </div>
        </FormikProvider>
      ) : null}
    </>
  );
});
