import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Typography, Grid, Box, Select, MenuItem } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { messages } from './CoordinatorsMessages';
import { useStyles } from './CoordinatorsStyles';
import { Edit as CoordinatorCard } from 'components/Coordinators/Cards/Edit';
import { Create as CreateCoordinatorCard } from 'components/Coordinators/Cards/Create';
import { useNoEvents } from 'components/NoEvents/hooks/useNoEvents';
import { useDashboard } from 'components/Dashboard';
import { RefreshControl } from 'components/RefreshControl/RefreshControl';
import { v4 as uuidv4 } from 'uuid';

const Coordinators = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { show } = useNoEvents();
  const { coordinators, fetchCoordinators } = useDashboard();

  const [itemsPerPage, setItemsPerPage] = useState(9);
  const [paginationData, setPaginationData] = useState({
    totalCoordinators: 0,
    activePage: 1
  });
  const { activePage, totalCoordinators } = paginationData;

  const getInformationComplete = () => {
    fetchCoordinators();
  };
  const handleChangePage = (_, value) => {
    setPaginationData({
      ...paginationData,
      activePage: value
    });
  };

  useEffect(() => {
    getInformationComplete();
  }, []);
  useEffect(() => {
    setPaginationData({
      ...paginationData,
      totalCoordinators: coordinators.length
    });
  }, [coordinators]);

  return (
    <>
      <Grid className={classes.container}>
        <Grid container direction="row" justify="space-between">
          <Grid>
            <Typography className={classes.title}>
              {intl.formatMessage(messages.title)}
            </Typography>
            <Typography className={classes.subTitle}>
              {intl.formatMessage(messages.subTitle)}
            </Typography>
          </Grid>
          <RefreshControl refreshFunction={getInformationComplete} />
        </Grid>
        {!show && (
          <Box className={classes.cardsContainer}>
            <Grid container justify="flex-start" spacing={3}>
              {activePage === 1 ? <CreateCoordinatorCard /> : null}
              {coordinators
                .slice(
                  (activePage - 1) * itemsPerPage - (activePage > 1 ? 1 : 0),
                  (activePage - 1) * itemsPerPage + (itemsPerPage - 1)
                )
                .map((coordinator, index) => (
                  <CoordinatorCard
                    key={uuidv4()}
                    id={coordinator.id}
                    positionInArray={
                      index -
                      (activePage > 1 ? 1 : 0) +
                      itemsPerPage * (activePage - 1)
                    }
                    coordinatorDetails={coordinator}
                  />
                ))}
            </Grid>
            <br />
            <Box display="flex" justifyContent="center">
              <Pagination
                onChange={handleChangePage}
                count={Math.ceil((totalCoordinators + 1) / itemsPerPage)}
                color="primary"
                page={activePage}
              />
              <Select
                className={classes.paginationSelect}
                size="small"
                onChange={(e) => {
                  setItemsPerPage(e.target.value);
                  setPaginationData({
                    ...paginationData,
                    activePage: 1
                  });
                }}
                value={itemsPerPage}
              >
                {[3, 9, 15, 21, 27].map((n) => (
                  <MenuItem key={`${n}`} value={n}>
                    {n} / {intl.formatMessage(messages.page)}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <br />
          </Box>
        )}
      </Grid>
    </>
  );
};

export { Coordinators };
