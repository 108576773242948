import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  edit: {
    id: 'component.Challenge.ChallengeCard.edit',
    defaultMessage: 'Editar'
  },
  delete: {
    id: 'component.Challenge.ChallengeCard.delete',
    defaultMessage: 'Eliminar'
  },
  areYouSureDelete: {
    id: 'component.Challenge.ChallengeCard.modal.delete.text',
    defaultMessage: '¿Estas seguro de querer eliminar el challenge del evento:'
  },
  titleChallenge: {
    id: 'component.Challenge.ChallengeCard.titleChallenge',
    defaultMessage: 'Challenge del evento'
  },
  titleMsgEvent: {
    id: 'component.Challenge.ChallengeCard.titleMsgEvent',
    defaultMessage: 'EVENTO'
  }
});
