import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
  const iconContainer = {
    flexWrap: 'nowrap',
    cursor: 'pointer',
    '&:hover $actionButtonImageContainer': {
      backgroundColor: theme.palette.grey[200]
    }
  };

  return {
    Container: {
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      maxWidth: '400px',
      height: '169px',
      boxShadow: '0px 0px 5px #00000029',
      borderRadius: '5px'
    },
    Content: {
      display: 'flex',
      justifyContent: 'flex-start',
      height: '149px',
      width: '100%',
      paddingTop: '16px'
    },
    iconPerson: {
      width: '50px',
      height: '50px',
      color: theme.palette.secondary.main
    },
    imageProfile: {
      objectFit: 'cover',
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      color: theme.palette.secondary.main
    },
    info: {
      marginLeft: 20,
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    },
    imgBox: {
      marginLeft: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: '0px 3px 6px #00000029',
      height: 80,
      width: 80,
      borderRadius: '50%'
    },
    name: {
      font: 'normal normal bold 15px/20px Roboto',
      color: theme.palette.primary,
      maxWidth: '250px',
      overflow: 'hidden',
      whiteSpace: 'noWrap',
      textOverflow: 'ellipsis'
    },
    date: {
      font: 'normal normal normal 15px/20px Roboto',
      color: '#989898'
    },
    actions: {
      height: '25%',
      display: 'flex',
      alignItems: 'center',
      borderTop: '1px solid #E6E6E6'
    },
    image: {
      height: '100%',
      width: '100%',
      objectFit: 'cover'
    },
    actionsContainer: {
      paddingLeft: theme.spacing(2)
    },
    edit: {
      color: theme.palette.secondary.main,
      ...iconContainer
    },
    delete: {
      color: '#989898',
      ...iconContainer
    },
    actionButtonImageContainer: {
      borderRadius: '50%',
      padding: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '5px',
      boxSizing: 'content-box',
      transition: 'all 300ms'
    },
    actionButtonImage: {
      width: '21px',
      height: '21px'
    }
  };
});

export { useStyles };
