import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.Exhibition.Form.Create.Left.title',
    defaultMessage: 'Agregar producto'
  },
  selectMultimedia: {
    id: 'components.Exhibition.Form.Create.Left.selectMultimedia',
    defaultMessage: 'Seleccionar material multimedia del producto'
  },
  data: {
    id: 'components.Exhibition.Form.Create.Left.data',
    defaultMessage: 'Agrega los datos necesarios para agregar un producto'
  },
  user: {
    id: 'components.Exhibition.Form.Create.Left.user',
    defaultMessage: 'Usuario Comercial'
  }
});
