import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  tableHead: {
    width: '1202',
    height: '47',
    backgroundColor: '#007CBA0A',
    borderBottom: 'none',
    border: 'none',
    borderRadius: 0
  },

  score: {
    width: '140',
    backgroundColor: '#F9F9F9',
    border: 'none'
  },
  boxChallenge: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  challengesDescription: {
    font: 'normal normal normal 15px/20px Roboto',
    color: '#0E0E0E80'
  },
  selectRooms: {
    width: '290px',
    height: '45px',
    font: 'normal normal normal 12px/16px Roboto',
    color: '#00040F'
  },

  selectScore: {
    width: '120px',
    height: '45px',
    font: 'normal normal normal 12px/16px Roboto',
    color: '#00040F',
    background: '#F9F9F9',
    border: 'none'
  },
  saveIcon: {
    cursor: 'pointer',
    color: '#2AA4D5'
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    },
    '& .MuiPagination-ul > li > button.Mui-selected': {
      backgroundColor: '#000',
      border: 'none'
    }
  },
  paginationSelect: {
    border: 0,
    borderRadius: '4px',
    outline: 'none',
    boxShadow: '0px 0px 2px #4D4F5C80',
    backgroundColor: '#fff',
    height: '23px',
    '& > .MuiSelect-root': {
      color: '#808495'
    },
    padding: '0',
    font: 'normal normal normal 10px/14px Roboto',
    marginTop: theme.spacing(2),
    '& .MuiSelect-icon': {
      fontSize: '23px',
      paddingRight: '10px 0 0 0',
      margin: 0,
      color: '#808495'
    },
    '& .MuiSelect-iconOpen': {
      fontSize: '23px',
      paddingRight: '10px 0 0 0',
      margin: 0
    }
  },
  submitButton: {
    margin: '20px 0 25px 0',
    width: '136px',
    height: '40px'
  }
}));

export { useStyles };
