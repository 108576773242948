import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  logoUrlLabel: {
    id: 'components.Licenses.Forms.Create.Right.logoUrlLabel',
    defaultMessage: 'Seleccionar logo'
  },
  businessName: {
    id: 'components.Licenses.Forms.Create.Right.businessName',
    defaultMessage: 'Ingresar razón social'
  },
  contactNameLabel: {
    id: 'components.Licenses.Forms.Create.Right.userNamePlaceholder',
    defaultMessage: 'Nombre del contacto'
  },
  descriptionPlaceholder: {
    id: 'components.Licenses.Forms.Create.Right.completeNamePlaceholder',
    defaultMessage: 'Ingresa una descripción breve de la empresa'
  },
  contactPhoneLabel: {
    id: 'components.Licenses.Forms.Create.Right.contactPhoneLabel',
    defaultMessage: 'Teléfono del contacto'
  },
  contactPhonePlaceholder: {
    id: 'components.Licenses.Forms.Create.Right.contactPhonePlaceholder',
    defaultMessage: 'Ingresar teléfono del contacto'
  },
  emailLabel: {
    id: 'components.Licenses.Forms.Create.Right.emailLabel',
    defaultMessage: 'Correo electrónico'
  },
  emailPlaceholder: {
    id: 'components.Licenses.Forms.Create.Right.emailPlaceholder',
    defaultMessage: 'Ingresar correo electrónico'
  },
  submitButtonLabel: {
    id:
      'components.Licenses.Forms.Create.Right.input.submitButton.create.label',
    defaultMessage: 'CREAR'
  },
  editSubmitButtonLabel: {
    id: 'components.Licenses.Forms.Create.Right.input.submitButton.edit.label',
    defaultMessage: 'EDITAR'
  },
  isRequiredError: {
    id: 'components.Licenses.Forms.Create.Right.isRequiredError',
    defaultMessage: 'Este campo es requerido'
  },
  limitOrganizations: {
    id: 'components.Licenses.Forms.Create.Right.limitOrganizations',
    defaultMessage: 'Sólo se permiten hasta 999 organizaciones'
  },
  minLimitOrganizations: {
    id: 'components.Licenses.Forms.Create.Right.minLimitOrganizations',
    defaultMessage: 'Mínimo 1 organización'
  },
  minDigitsTelephone: {
    id: 'components.Licenses.Forms.Create.Right.minDigitsTelephone',
    defaultMessage: 'Mínimo 6 dígitos'
  },
  emailError: {
    id: 'components.Licenses.Forms.Create.Right.emailError',
    defaultMessage: 'Correo electrónico inválido'
  },
  onlyIntegers: {
    id: 'components.Licenses.Forms.Create.Right.onlyIntegers',
    defaultMessage: 'Sólo números positivos'
  },
  selectCountry: {
    id: 'components.Licenses.Forms.Create.Right.selectCountry',
    defaultMessage: 'País'
  },
  limitClientsOrgLabel: {
    id: 'components.Licenses.Forms.Create.Right.limitClientsOrgLabel',
    defaultMessage: 'Cantidad de organizaciones permitidas'
  },
  statusLabel: {
    id: 'components.Licenses.Forms.Create.Right.statusLabel',
    defaultMessage: 'Estado de licencia'
  },
  switchActive: {
    id: 'components.Licenses.Forms.Create.Right.switchActive',
    defaultMessage: 'Activo'
  },
  switchDisabled: {
    id: 'components.Licenses.Forms.Create.Right.switchDisabled',
    defaultMessage: 'Inactivo'
  },
  activationLabel: {
    id: 'components.Licenses.Forms.Create.Right.activationLabel',
    defaultMessage: 'Activación'
  },
  licenseLabel: {
    id: 'components.Licenses.Forms.Create.Right.licenseLabel',
    defaultMessage: 'Licenciamiento'
  },
  membershipLabel: {
    id: 'components.Licenses.Forms.Create.Right.membershipLabel',
    defaultMessage: 'Membresía'
  },
  success: {
    id: 'components.Licenses.Forms.Create.Right.success',
    defaultMessage: 'SE HA CREADO EXITOSAMENTE LA LICENCIA'
  },
  ok: {
    id: 'components.Licenses.Forms.Create.Right.ok',
    defaultMessage: 'ok'
  }
});
