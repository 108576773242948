import { DatePicker as DatePickerMUI } from '@material-ui/pickers';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Images } from 'assets';

export const DatePicker = ({ error, touched, disabled, ...props }) => {
  const endAdornment = (
    <InputAdornment>
      <IconButton disabled={disabled}>
        <img src={Images.CalendarDay} alt="calendarday" />
      </IconButton>
    </InputAdornment>
  );

  return (
    <DatePickerMUI
      autoOk
      disabled={disabled}
      InputProps={{ endAdornment }}
      error={Boolean(error)}
      helperText={touched && error}
      {...props}
    />
  );
};
