import * as yup from 'yup';

export const initialValues = {
  eventName: '',
  challenges: [
    {
      challenge: '',
      score: ''
    }
  ],
  awardPolicies: { type: '', value: '' },
  rankingCategories: {
    initial: {
      category: '',
      minScore: 0,
      maxScore: 0
    },
    following: [
      {
        category: '',
        minScore: 0,
        maxScore: 0
      }
    ]
  }
};

export const getValidationSchema = ({ requiredMessage }) =>
  yup.object({
    //eventName: yup.string().required(requiredMessage),
    /*challenges: yup.array().of(
      yup.object({
        challenge: yup.string().required(requiredMessage),
        score: yup.string().required(requiredMessage)
      })
    ),*/
    awardPolicies: yup.object({
      type: yup.string().required(requiredMessage),
      value: yup.string().required(requiredMessage)
    }),
    rankingCategories: yup.object({
      initial: yup.object({
        category: yup.string().required(requiredMessage),
        minScore: yup.number().required(requiredMessage),
        maxScore: yup.number().required(requiredMessage)
      }),
      following: yup.array().of(
        yup.object({
          category: yup.string().required(requiredMessage),
          minScore: yup.number().required(requiredMessage),
          maxScore: yup.number().required(requiredMessage)
        })
      )
    })
  });
