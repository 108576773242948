import { useIntl } from 'react-intl';
import { useStyles } from './ExhibitionRoomStyles';
import { messages } from './ExhibitionRoomMessages';
import { Questions } from '../Questions/Questions';

export const ExhibitionRoom = ({
  questions,
  correctAnswers,
  setCorrectAnswers
}) => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Questions
        room="exhibitionRoom"
        title={intl.formatMessage(messages.accordionTitle)}
        numberAnswers={5}
        correctAnswers={correctAnswers}
        setCorrectAnswer={setCorrectAnswers}
        questions={questions}
      />
    </div>
  );
};
