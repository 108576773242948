import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  events: {
    id: 'components.RoomsMenu.events',
    defaultMessage: 'Eventos'
  },
  principal: {
    id: 'components.RoomsMenu.principal',
    defaultMessage: 'Principal'
  },
  exhibition: {
    id: 'components.RoomsMenu.exhibition',
    defaultMessage: 'Exhibición'
  },
  partners: {
    id: 'components.RoomsMenu.partners',
    defaultMessage: 'Partners'
  },
  challenge: {
    id: 'components.RoomsMenu.challenge',
    defaultMessage: 'Challenge'
  },
  quiz: {
    id: 'components.RoomsMenu.quiz',
    defaultMessage: 'Quiz'
  },
  database: {
    id: 'components.RoomsMenu.database',
    defaultMessage: 'Base datos'
  },
  organizations: {
    id: 'components.RoomsMenu.organizations',
    defaultMessage: 'Organizaciones'
  },
  analytics: {
    id: 'components.RoomsMenu.analytics',
    defaultMessage: 'Analíticos'
  },
  coordinators: {
    id: 'components.RoomsMenu.coordinators',
    defaultMessage: 'Coordinadores'
  },
  licenses: {
    id: 'components.RoomsMenu.licenses',
    defaultMessage: 'Licencias'
  }
});
