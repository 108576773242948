import { Box, Grid, Typography } from '@material-ui/core';
import { useStyles } from './CreateStyles';
import { Images } from 'assets';
import { useIntl } from 'react-intl';
import { messages } from './CreateMessages';
import { Link } from 'react-router-dom';

const Create = () => {
  const classes = useStyles({ icon: Images.exhibitionIcon });
  const intl = useIntl();

  return (
    <Grid item lg={4} md={6} sm={12} xs={12}>
      <Link to="/CreateExhibition" className={classes.disableLink}>
        <Box className={classes.container}>
          <Box className={classes.content}>
            <Box className={classes.imgBox}>
              <Box className={classes.imageMask} />
            </Box>
            <Box className={classes.info}>
              <Typography className={classes.title}>
                {intl.formatMessage(messages.title)}
              </Typography>
              <Typography className={classes.subTitle}>
                {intl.formatMessage(messages.subTitle)}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.actions}>
            <Box className={classes.addBox}>
              <Box className={classes.imgAddBox}>
                <img src={Images.add} className={classes.addIcon} alt="add" />
              </Box>
            </Box>
          </Box>
        </Box>
      </Link>
    </Grid>
  );
};

export { Create };
