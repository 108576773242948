import { Modal as LibModal } from '@octopy/react-modal';
import PropTypes from 'prop-types';
import './FullScreenLayout.scss';
import { Loader as LibLoader } from '@octopy/react-loader';
import { BasicLoader } from '@octopy/react-loader';

function FullScreenLayout({ children }) {
  return (
    <>
      <LibLoader>
        <BasicLoader />
      </LibLoader>
      <LibModal />
      <div className="full-screen-layout">{children}</div>
    </>
  );
}

FullScreenLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export { FullScreenLayout };
