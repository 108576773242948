import * as yup from 'yup';
import { messages } from './RightMessages';

export const initialValuesForm = {
  logoUrl: '',
  userName: '',
  completeName: '',
  contactPhone: '',
  email: ''
};

export const getValidationSchema = (intl) => {
  const requiredErrorMessage = intl.formatMessage(messages.isRequiredError);
  const emailErrorMessage = intl.formatMessage(messages.emailError);
  const minDigitsTelephone = intl.formatMessage(messages.minDigitsTelephone);

  return yup.object({
    logoUrl: yup.string(),
    userName: yup
      .string()
      .trim()
      .required(requiredErrorMessage),
    completeName: yup
      .string()
      .trim()
      .required(requiredErrorMessage),
    contactPhone: yup
      .string()
      .min(6, minDigitsTelephone)
      .required(requiredErrorMessage),
    email: yup
      .string()
      .email(emailErrorMessage)
      .required(requiredErrorMessage)
  });
};
