import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  videoIdLabel: {
    id: 'components.Events.Forms.Edit.Right.videoIdLabel',
    defaultMessage: 'ID del video'
  },
  userNamePlaceholder: {
    id: 'components.Events.Forms.Edit.Right.userNamePlaceholder',
    defaultMessage: 'Ingresar nombre de usuario'
  },
  nameLabel: {
    id: 'components.Events.Forms.Edit.Right.nameLabel',
    defaultMessage: 'Ingresa el nombre del evento virtual'
  },
  completeNamePlaceholder: {
    id: 'components.Events.Forms.Edit.Right.completeNamePlaceholder',
    defaultMessage: 'Ingresar razon social'
  },
  urlEventLabel: {
    id: 'components.Events.Forms.Edit.Right.urlEventLabel',
    defaultMessage: 'Url del evento'
  },
  contactPhonePlaceholder: {
    id: 'components.Events.Forms.Edit.Right.contactPhonePlaceholder',
    defaultMessage: 'Ingresar teléfono de contacto'
  },
  limitParticipantsLabel: {
    id: 'components.Events.Forms.Edit.Right.limitParticipantsLabel',
    defaultMessage: 'No. de participantes'
  },
  amountLabel: {
    id: 'components.Events.Forms.Edit.Right.amountLabel',
    defaultMessage: 'Cantidad de partners'
  },
  amountPartnersLabel: {
    id: 'components.Events.Forms.Edit.Right.amountPartnersLabel',
    defaultMessage: 'Partners'
  },
  fromDateLabel: {
    id: 'components.Events.Forms.Edit.Right.fromDateLabel',
    defaultMessage: 'Fecha de inicio'
  },
  fromTimeLabel: {
    id: 'components.Events.Forms.Edit.Right.fromTimeLabel',
    defaultMessage: 'Hora de inicio'
  },
  toDateLabel: {
    id: 'components.Events.Forms.Edit.Right.toDateLabel',
    defaultMessage: 'Fecha de termino'
  },
  toTimeLabel: {
    id: 'components.Events.Forms.Edit.Right.toTimeLabel',
    defaultMessage: 'Hora de termino'
  },
  submitButtonLabel: {
    id: 'components.Events.Forms.Edit.Right.submitButtonLabel',
    defaultMessage: 'CREAR'
  },
  editSubmitButtonLabel: {
    id: 'components.Events.Forms.Edit.Right.editSubmitButtonLabel',
    defaultMessage: 'EDITAR'
  },
  isRequiredError: {
    id: 'components.Events.Form.Edit.Right.isRequiredError',
    defaultMessage: 'Este campo es requerido'
  },
  invalidUrlError: {
    id: 'components.Events.Forms.Edit.Right.invalidUrlError',
    defaultMessage: 'Url inválida'
  },
  success: {
    id: 'components.Events.Forms.Edit.Right.success',
    defaultMessage: 'LOS DATOS HAN SIDO ACTUALIZADOS CORRECTAMENTE'
  },
  ok: {
    id: 'components.Events.Forms.Edit.Right.ok',
    defaultMessage: 'Ok'
  },
  error: {
    id: 'components.Events.Forms.Edit.Right.error',
    defaultMessage: 'Ha ocurrido un error.'
  },
  500: {
    id: 'components.Events.Forms.Edit.Right.internalServerError',
    defaultMessage:
      'Ha ocurrido un error interno en el servidor. Vuelve a intentarlo'
  },
  409: {
    id: 'components.Events.Forms.Edit.Right.duplicatedError',
    defaultMessage: 'El registro ya se encuentra en la base de datos.'
  },
  beginning: {
    id: 'component.Events.Forms.Edit.Right.beginning',
    defaultMessage: 'Inicio'
  },
  end: {
    id: 'component.Events.Forms.Edit.Right.end',
    defaultMessage: 'Fin'
  },
  optionOne: {
    id: 'component.Events.Forms.Edit.Right.optionOne',
    defaultMessage: 'Evento mediano (1 - 4,999) participantes'
  },
  optionTwo: {
    id: 'component.Events.Forms.Edit.Right.optionTwo',
    defaultMessage: 'Evento grande (5000 - 9,999) participantes'
  },
  optionThree: {
    id: 'component.Events.Forms.Edit.Right.optionThree',
    defaultMessage: 'Evento masivo (10000 - 39,999) participantes'
  },
  minPartners: {
    id: 'component.Events.Forms.Edit.Right.minPartners',
    defaultMessage: 'Debe ingresar mínimo un Partner'
  },
  onlyIntegers: {
    id: 'components.Events.Forms.Edit.Right.onlyIntegers',
    defaultMessage: 'Sólo números positivos'
  },
  youtube: {
    id: 'component.Events.Forms.Create.Right.youtube',
    defaultMessage: 'youtube'
  },
  vimeo: {
    id: 'component.Events.Forms.Create.Right.vimeo',
    defaultMessage: 'vimeo'
  },
  image: {
    id: 'component.Events.Forms.Create.Right.image',
    defaultMessage: 'imagen'
  },
  uploadButtonLabel: {
    id: 'components.Events.Forms.Create.Right.uploadButtonLabel',
    defaultMessage: 'SUBIR'
  },
  seeimage: {
    id: 'components.Events.Forms.Create.Right.seeimage',
    defaultMessage: 'Ver imagen'
  },
  close: {
    id: 'components.Events.Forms.Create.Right.close',
    defaultMessage: 'CERRAR'
  }
});
