import { useIntl } from 'react-intl';
import { Row, Title, LeftContainer } from 'components/CreationForm';
import { useRowsStyles } from '../RowsStyles';
import { messages } from './LeftMessages';
import { Label } from 'components/CreationForm/Left/Label/Label';

const Left = () => {
  const intl = useIntl();
  const rowsClasses = useRowsStyles();

  return (
    <LeftContainer>
      <Title>{intl.formatMessage(messages.title)}</Title>
      <Row className={rowsClasses.multimedia}>
        <Label>{intl.formatMessage(messages.selectMultimedia)}</Label>
      </Row>
      <Row className={rowsClasses.product}>
        <Label>{intl.formatMessage(messages.data)}</Label>
      </Row>
      <Row className={rowsClasses.users}></Row>
    </LeftContainer>
  );
};

export { Left };
