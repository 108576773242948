import { useStyles } from './RightContainerStyles';

export const RightContainer = ({ children, submitButton }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.container}>
        <div className={classes.content}>{children}</div>
        <div>{submitButton}</div>
      </div>
    </>
  );
};
