export const templateAddConference = {
  headerResponse: {
    code: 'headerResponse.code',
    message: 'headerResponse.message'
  },
  payload: {
    id: 'payload._id',
    streamIframe: 'payload.stream_iframe',
    chatIframe: 'payload.chat_iframe',
    speakerName: 'payload.name',
    date: 'payload.date',
    description: 'payload.description',
    eventId: 'payload.event',
    imageUrl: 'payload.image'
  }
};

export const counterTemplateAddConference = {
  file: 'image.file',
  video_id: 'streamId',
  plataform: 'streamPlatform',
  chat_iframe: 'chatIframe',
  name: 'speakerName',
  background: 'speakerBackground',
  education: 'speakerEducation',
  resume: 'speakerResume',
  date: 'date',
  description: 'description',
  event: 'eventId'
};

export const templateUpdateConference = {
  headerResponse: {
    code: 'headerResponse.code',
    message: 'headerResponse.message'
  },
  payload: {
    id: 'payload._id',
    streamIframe: 'payload.stream_iframe',
    chatIframe: 'payload.chat_iframe',
    speakerName: 'payload.name',
    date: 'payload.date',
    description: 'payload.description',
    eventId: 'payload.event',
    imageUrl: 'payload.image',
    creatorId: 'payload.created_by',
    updaterId: 'payload.updated_by',
    createdAt: 'payload.created_at',
    updatedAt: 'payload.updated_at'
  }
};

export const counterTemplateUpdateConference = {
  id: 'id',
  file: 'image.file',
  video_id: 'streamId',
  plataform: 'streamPlatform',
  chat_iframe: 'chatIframe',
  name: 'speakerName',
  background: 'speakerBackground',
  education: 'speakerEducation',
  resume: 'speakerResume',
  date: 'date',
  description: 'description',
  event: 'eventId'
};

export const templateDeleteConference = {
  headerResponse: {
    code: 'headerResponse.code',
    message: 'headerResponse.message'
  }
};
