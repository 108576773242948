import { useStyles } from './TabPanelStyles';

export const TabPanel = ({ children, description, value, index }) => {
  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
    >
      {value === index && <div className={classes.container}>{children}</div>}
    </div>
  );
};
