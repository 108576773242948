import { useIntl } from 'react-intl';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  ButtonBase,
  Radio
} from '@material-ui/core';
import { ExpandMore, Person } from '@material-ui/icons';
import { useStyles } from './ConferencesStyles';
import {
  ImagePicker,
  TextField,
  Select,
  TimePicker
} from 'components/CreationForm';
import { messages } from './ConferencesMessages.js';
import { Images } from 'assets';
import { Field, FieldArray } from 'formik';
import { TextArea } from 'components/CreationForm/Right/inputs/TextArea/TextArea';
import { Fragment } from 'react';
import {
  addHours,
  formatDate,
  formatTime,
  verifyHourAnticipation
} from 'utils/helpers';
import { useModal } from '@octopy/react-modal';
import { ModalText, ModalButton } from 'components/ModalMessages';
import { getDataError, createErrorModalConfig } from 'utils/helpers';
import { addConference } from 'providers/api';
import { useLoader } from '@octopy/react-loader';
import { useDashboard } from 'components/Dashboard';

export const Conferences = ({
  conferences,
  setFieldValue,
  eventId,
  availableDates = [],
  errors
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const { handleOpenModal } = useModal();
  const { handleShowLoader } = useLoader();
  const { addNumberOfEventConferences } = useDashboard();

  const addAccordion = async ({ conference, push, index }) => {
    if (!errors) {
      const formattedConference = {
        ...conference,
        time: formatTime(conference.time),
        date: new Date(
          `${conference.date} ${formatTime(conference.time)}`
        ).toString(),
        eventId
      };

      try {
        handleShowLoader(true);

        await addConference(formattedConference);
        addNumberOfEventConferences(eventId, +1);

        push();

        setFieldValue(`conferences.${index}.pushed`, true);
        setFieldValue('hasPushed', true);
      } catch (request) {
        const { code, message } = getDataError(request);

        handleOpenModal(createErrorModalConfig(code, message));
      } finally {
        handleShowLoader(false);
      }
    } else {
      setFieldValue(`conferences.${index}.triedToPush`, true);
    }
  };

  const handleAddAccordion = ({ conference, push, ...props }) => {
    if (conference.pushed) return push();

    const modalTitle = (
      <ModalText>{intl.formatMessage(messages.doYouWantToAdd)}</ModalText>
    );

    const modalActionButtons = (
      <ModalButton onClick={() => addAccordion({ conference, push, ...props })}>
        {intl.formatMessage(messages.yes)}
      </ModalButton>
    );

    handleOpenModal({
      title: modalTitle,
      actionButtons: modalActionButtons,
      configProps: { maxWidth: 'xs' }
    });
  };

  return (
    <div className={classes.container}>
      <FieldArray name="conferences">
        {({ remove, push }) =>
          conferences.map((conference, index, arr) => (
            <Fragment key={`${conference.key}ConferenceAccordion`}>
              <Accordion className={classes.accordion}>
                <AccordionSummary
                  className={classes.summary}
                  expandIcon={<ExpandMore />}
                >
                  <Typography
                    className={`${classes.title} ${conference.pushed &&
                      'disabled'}`}
                  >
                    {intl.formatMessage(messages.accordionLabel)}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  <div className={classes.left}>
                    <Field name={`conferences.${index}.image`}>
                      {({ field: { value, name } }) => (
                        <ImagePicker
                          label={intl.formatMessage(messages.imageLabel)}
                          imageUrl={value.blobUrl}
                          onChange={(image) => setFieldValue(name, image)}
                          Icon={Person}
                          id={conference.key}
                          disabled={conference.pushed}
                        />
                      )}
                    </Field>
                  </div>
                  <div className={classes.right}>
                    <div className={classes.rightTopRow}>
                      <div className={classes.innerRow}>
                        {[
                          'speakerName',
                          'speakerEducation',
                          'speakerBackground'
                        ].map((name) => (
                          <Field name={`conferences.${index}.${name}`}>
                            {({ field, meta: { error, touched } }) => (
                              <TextField
                                defaultValue={field.value}
                                name={field.name}
                                onBlur={(event) => {
                                  field.onBlur(event);
                                  field.onChange(event);
                                }}
                                error={error}
                                touched={touched || conference.triedToPush}
                                className={classes.input}
                                label={intl.formatMessage(
                                  messages[`${name}Label`]
                                )}
                                disabled={conference.pushed}
                              />
                            )}
                          </Field>
                        ))}
                      </div>
                      <div className={classes.innerRow}>
                        <Field name={`conferences.${index}.speakerResume`}>
                          {({ field, meta: { error, touched } }) => (
                            <TextArea
                              value={field.value}
                              name={field.name}
                              onBlur={(event) => {
                                field.onBlur(event);
                                field.onChange(event);
                              }}
                              error={error}
                              touched={touched || conference.triedToPush}
                              className={classes.input}
                              label={intl.formatMessage(
                                messages.speakerResumeLabel
                              )}
                              disabled={conference.pushed}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className={classes.rightBottomRow}>
                      <div className={classes.innerRow}>
                        <Field name={`conferences.${index}.date`}>
                          {({ field, meta: { error, touched } }) => (
                            <Select
                              {...field}
                              error={error}
                              touched={touched || conference.triedToPush}
                              className={classes.input}
                              label={intl.formatMessage(messages.dateLabel)}
                              options={availableDates.map((date) => ({
                                label: date,
                                value: date
                              }))}
                              disabled={conference.pushed}
                            />
                          )}
                        </Field>
                        <Field
                          validate={(value) => {
                            const selectedDateIsToday =
                              formatDate(new Date()) === conference.date;

                            const error =
                              selectedDateIsToday &&
                              verifyHourAnticipation(value, 0);

                            return (
                              error &&
                              intl.formatMessage(messages.anticipationError)
                            );
                          }}
                          name={`conferences.${index}.time`}
                        >
                          {({
                            field: { value, name },
                            meta: { error, touched },
                            form: { setFieldValue }
                          }) => (
                            <TimePicker
                              className={classes.input}
                              value={value}
                              error={error}
                              touched={touched || conference.triedToPush}
                              onChange={(value) => setFieldValue(name, value)}
                              label={intl.formatMessage(messages.timeLabel)}
                              disabled={conference.pushed}
                            />
                          )}
                        </Field>
                      </div>
                      <div className={classes.innerRow}>
                        <Field name={`conferences.${index}.streamId`}>
                          {({ field, meta: { error, touched } }) => (
                            <TextField
                              defaultValue={field.value}
                              name={field.name}
                              onBlur={(event) => {
                                field.onBlur(event);
                                field.onChange(event);
                              }}
                              error={error}
                              touched={touched || conference.triedToPush}
                              className={classes.input}
                              label={intl.formatMessage(
                                messages['streamIdLabel']
                              )}
                              disabled={conference.pushed}
                            />
                          )}
                        </Field>
                        <Field name={`conferences.${index}.streamPlatform`}>
                          {({ field, form }) => (
                            <div
                              className={`${classes.input} ${classes.radioGroup}`}
                            >
                              <Radio
                                className={classes.radio}
                                checked={field.value === 'youtube'}
                                value="youtube"
                                name={field.name}
                                onChange={form.handleChange}
                              />
                              <img
                                src={Images.youtube}
                                alt="youtube"
                                className={classes.platformLogoImage}
                              />
                              <Radio
                                className={classes.radio}
                                checked={field.value === 'vimeo'}
                                value="vimeo"
                                name={field.name}
                                onChange={form.handleChange}
                              />
                              <img
                                src={Images.vimeo}
                                alt="vimeo"
                                className={classes.platformLogoImage}
                              />
                            </div>
                          )}
                        </Field>
                      </div>
                      <div className={classes.innerRow}>
                        {['chatIframe', 'description'].map((name) => (
                          <Field
                            key={`${name}Input`}
                            name={`conferences.${index}.${name}`}
                          >
                            {({ field, meta: { error, touched } }) => (
                              <TextArea
                                value={field.value}
                                name={field.name}
                                onBlur={(event) => {
                                  field.onBlur(event);
                                  field.onChange(event);
                                }}
                                error={error}
                                touched={touched || conference.triedToPush}
                                className={classes.input}
                                placeholder={intl.formatMessage(
                                  messages[`${name}Label`]
                                )}
                                disabled={conference.pushed}
                              />
                            )}
                          </Field>
                        ))}
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              {index === arr.length - 1 && (
                <div className={classes.actionButtonsContainer}>
                  {conferences.length > 1 && !conference.pushed && (
                    <ButtonBase
                      onClick={() => remove(index)}
                      className={classes.actionButton}
                    >
                      <img
                        className={classes.actionButtonImage}
                        src={Images.removeCircle}
                        alt="removeIcon"
                      />
                    </ButtonBase>
                  )}
                  <ButtonBase
                    onClick={() =>
                      handleAddAccordion({
                        index,
                        conference,
                        push: () =>
                          push({
                            key: conference.key + 1,
                            image: {
                              file: null,
                              blobUrl: ''
                            },
                            speakerName: '',
                            speakerResume: '',
                            speakerEducation: '',
                            speakerBackground: '',
                            date: '',
                            time: addHours(new Date(), 2),
                            description: '',
                            streamId: '',
                            streamPlatform: 'vimeo',
                            chatIframe: ''
                          })
                      })
                    }
                    className={classes.actionButton}
                  >
                    <img
                      className={classes.actionButtonImage}
                      src={Images.addCircle}
                      alt="addIcon"
                    />
                  </ButtonBase>
                </div>
              )}
            </Fragment>
          ))
        }
      </FieldArray>
    </div>
  );
};
