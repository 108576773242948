import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Drawer, Hidden, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useStyles } from './NextEventsStyles';
import { messages } from './NextEventsMessages';
import {
  Timeline,
  TimelineContent,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector
} from '@material-ui/lab';
import { useDashboard } from 'components/Dashboard';
import { formatDate, formatTime } from 'utils/helpers';
import { Images } from 'assets';

export const NextEvents = ({ visible, handleHide }) => {
  const intl = useIntl();
  const classes = useStyles();

  const { events } = useDashboard();
  const [nextEvents, setNextEvents] = useState([]);

  const [resize, setResize] = useState(window.screen.width);

  useEffect(() => {
    setResize(window.innerWidth);
    window.onresize = () => {
      setResize(window.innerWidth);
    };
  }, []);

  useEffect(() => {
    if (events.length) {
      const filteredEvents = events.filter(
        (event) => new Date(event.from) > new Date()
      );

      const sortedEvents = filteredEvents
        .sort((a, b) => new Date(b.from) - new Date(a.from))
        .reverse();

      setNextEvents(sortedEvents);
    }
  }, [events]);

  return (
    <Drawer
      className={classes.drawer}
      anchor="right"
      open={visible}
      onClose={handleHide}
    >
      <div className={classes.container}>
        <div className={classes.header}>
          <Typography className={classes.title} component="div">
            {intl.formatMessage(messages.title)}
          </Typography>
          <div className={classes.closeIconContainer}>
            <Close className={classes.closeIcon} onClick={handleHide} />
          </div>
        </div>
        {nextEvents.length ? (
          <Timeline
            className={classes.timeline}
            align={resize < 600 ? '' : 'alternate'}
          >
            {nextEvents.map(
              (event, i) =>
                new Date(event.from) > new Date() && (
                  <TimelineItem key={`timelineItem${i}`}>
                    <TimelineOppositeContent>
                      <Hidden xsDown>
                        <div className={classes.date}>
                          {formatDate(new Date(event.from))}
                        </div>
                      </Hidden>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot className={classes.dot} />
                      {!(nextEvents.length - 1 === i) && (
                        <TimelineConnector className={classes.connector} />
                      )}
                    </TimelineSeparator>
                    <TimelineContent>
                      <Hidden smUp>
                        <div className={classes.date}>
                          {formatDate(new Date(event.from))}
                        </div>
                      </Hidden>
                      <div className={classes.dataContainer}>
                        <div className={classes.name}>{event.name}</div>
                        {event.country && (
                          <div className={classes.info}>
                            {intl.formatMessage(messages.country)}{' '}
                            {event.country}
                          </div>
                        )}
                        <div className={classes.info}>
                          {intl.formatMessage(messages.time)}{' '}
                          {formatTime(new Date(event.from))}
                        </div>
                        {event.type && (
                          <div className={classes.info}>
                            {intl.formatMessage(messages.type)} {event.type}
                          </div>
                        )}
                      </div>
                    </TimelineContent>
                  </TimelineItem>
                )
            )}
          </Timeline>
        ) : (
          <div className={classes.noNextEvents}>
            <img src={Images.calendar} alt="calendar" />
            <span>{intl.formatMessage(messages.noNextEvents)}</span>
          </div>
        )}
      </div>
    </Drawer>
  );
};
