export const accessDictionary = {
  root: ['/Licenses', '/CreateLicense', '/EditLicense/:idLicense'],
  admin: [
    '/Organizations',
    '/CreateOrganization',
    '/EditOrganization/:organizationId'
  ],
  coordinator: [
    '/Database',
    '/Partners',
    '/CreatePartner',
    '/EditPartner/:idPartner',
    '/Exhibition',
    '/CreateExhibition',
    '/Challenge',
    '/CreateChallenge',
    '/EditExhibition/:idProduct',
    '/Principal',
    '/CreateConferences',
    '/EditConferences/:eventId',
    '/Quiz',
    '/CreateQuiz',
    '/EditQuiz/:idEvent',
    '/Challenge',
    '/CreateChallenge'
  ],
  client: [
    '/Events',
    '/CreateEvent',
    '/EditEvent/:idEvent',
    '/Organizations',
    '/CreateOrganization',
    '/EditOrganization/:organizationId',
    '/Database',
    '/Coordinators',
    '/CreateCoordinator',
    '/EditCoordinator/:idCoordinator',
    '/Partners',
    '/CreatePartner',
    '/EditPartner/:idPartner',
    '/Exhibition',
    '/CreateExhibition',
    '/EditExhibition/:idProduct',
    '/Principal',
    '/CreateConferences',
    '/EditConferences/:eventId',
    '/Quiz',
    '/CreateQuiz',
    '/EditQuiz/:idEvent',
    '/Licenses',
    '/CreateLicense',
    '/EditLicense/:idLicense',
    '/Analytics',
    '/Challenge',
    '/CreateChallenge'
  ]
};

export const intialPahts = {
  root: '/Licenses',
  admin: '/Organizations',
  coordinator: '/Partners',
  client: '/Events'
};
