import { useMediaQuery, useTheme } from '@material-ui/core';
import { Images } from 'assets';
import ChartRAC from 'react-apexcharts';
import { useIntl } from 'react-intl';
import { messages } from './CharMessages';
import { useStyles } from './ChartStyles';

export const Chart = ({ values, color, yTitle, type = 'bar' }) => {
  const classes = useStyles();
  const intl = useIntl();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const sm = useMediaQuery(theme.breakpoints.only('sm'));
  const md = useMediaQuery(theme.breakpoints.only('md'));
  const lg = useMediaQuery(theme.breakpoints.only('lg'));
  const xl = useMediaQuery(theme.breakpoints.only('xl'));

  const maxValues = xs ? 6 : sm ? 7 : md ? 10 : lg ? 14 : xl && 18;

  const options = {
    colors: [color],
    plotOptions: {
      bar: {
        borderRadius: 5,
        columnWidth: '60%'
      }
    },
    tooltip: {
      x: {
        formatter: (_, { dataPointIndex }) => values[dataPointIndex].x
      }
    },
    xaxis: {
      categories: values.map(
        ({ x: value }) => `${value.slice(0, 7)}${value.length > 7 ? '...' : ''}`
      ),
      labels: {
        rotate: 0,
        hideOverlappingLabels: false,
        style: {
          cssClass: classes.xLabels
        }
      }
    },
    yaxis: {
      title: {
        text: yTitle,
        style: {
          cssClass: classes.yTitle
        }
      },
      labels: {
        formatter: (value) => value.toFixed(0)
      }
    },
    dataLabels: {
      enabled: false
    }
  };

  const series = [
    {
      name: yTitle,
      data: values.map((value) => value.y)
    }
  ];

  const valuesLength = values.length;
  const chartWidth = valuesLength * 52;

  return valuesLength ? (
    <>
      <div className={classes.chartBrother} />
      <ChartRAC
        options={options}
        series={series}
        type={type}
        width={valuesLength < maxValues ? '100%' : chartWidth}
        height={valuesLength < maxValues ? '100%' : '90%'}
      />
    </>
  ) : (
    <div className={classes.noData}>
      <img src={Images.Chart} alt="chart" />
      <span>{intl.formatMessage(messages.noData)}</span>
    </div>
  );
};
