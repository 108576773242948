import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.License.title',
    defaultMessage: 'Licencias a Rooms'
  },
  subTitle: {
    id: 'components.License.subTitle',
    defaultMessage:
      'Listado de empresas que adquirieron un licenciamiento de la plataforma Rooms.'
  },
  error: {
    id: 'components.License.error',
    defaultMessage: 'Ha ocurrido un error durante la petición'
  },
  ok: {
    id: 'components.License.ok',
    defaultMessage: 'Ok'
  },
  page: {
    id: 'components.License.page',
    defaultMessage: 'Página'
  }
});
