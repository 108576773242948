const americaCountries = ['México', 'Usa', 'Canada', 'Venezuela', 'Colombia'];
const europaCountries = ['España', 'Francia', 'Italia', 'Rusia'];
const asiaCountries = ['China', 'India', 'Japón'];
const africaCountries = ['Nigeria', 'Ghana', 'Sudáfrica'];

const availableCountry = [
  ...americaCountries,
  ...europaCountries,
  ...asiaCountries,
  ...africaCountries
];

export {
  availableCountry,
  americaCountries,
  europaCountries,
  asiaCountries,
  africaCountries
};
