import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.Quiz.Forms.Create.title',
    defaultMessage: 'Crear quiz'
  },
  subtitle: {
    id: 'components.Quiz.Forms.Create.subtitle',
    defaultMessage: 'Listado de trivias asociadas a algún evento.'
  },
  placeholderNameEvent: {
    id: 'components.Quiz.Forms.Create.placeholderName',
    defaultMessage: 'Nombre del evento'
  },
  hall: {
    id: 'components.Quiz.Forms.Create.hall',
    defaultMessage: 'Hall'
  },
  hallAbbreviation: {
    id: 'components.Quiz.Forms.Create.hallAbbreviation',
    defaultMessage: 'H'
  },
  mainHall: {
    id: 'components.Quiz.Forms.Create.mainHall',
    defaultMessage: 'Sala principal'
  },
  mainHallAbbreviation: {
    id: 'components.Quiz.Forms.Create.mainHallAbbreviation',
    defaultMessage: 'SP'
  },
  exhibitionHall: {
    id: 'components.Quiz.Forms.Create.exhibitionHall',
    defaultMessage: 'Sala de exibición'
  },
  exhibitionHallAbbreviation: {
    id: 'components.Quiz.Forms.Create.exhibitionHallAbbreviation',
    defaultMessage: 'SE'
  },
  partnerHall: {
    id: 'components.Quiz.Forms.Create.partnerHall',
    defaultMessage: 'Sala partner'
  },
  partnerHallAbbreviation: {
    id: 'components.Quiz.Forms.Create.partnerHallAbbreviation',
    defaultMessage: 'PS'
  },
  challenge: {
    id: 'components.Quiz.Forms.Create.Challenge',
    defaultMessage: 'Challenge'
  },
  challengeAbbreviation: {
    id: 'components.Quiz.Forms.Create.challengeAbbreviation',
    defaultMessage: 'CH'
  },
  coffeeHall: {
    id: 'components.Quiz.Forms.Create.coffeeHall',
    defaultMessage: 'Sala de café'
  },
  coffeeHallAbbreviation: {
    id: 'components.Quiz.Forms.Create.ccoffeeHallAbbreviation',
    defaultMessage: 'SC'
  },
  submit: {
    id: 'components.Quiz.Forms.input.submitButton.label',
    defaultMessage: 'Crear'
  },
  requiredError: {
    id: 'components.Quiz.Forms.input.error.required',
    defaultMessage: 'Este campo es requerido'
  },
  minScoreError: {
    id: 'components.Quiz.Forms.Create.minScoreError',
    defaultMessage: 'El puntaje mínimo es 50'
  },
  isNotIntegerError: {
    id: 'components.Quiz.Forms.Create.isNotIntegerError',
    defaultMessage: 'Sólo números enteros'
  },
  newQuizMessage: {
    id: 'components.Quiz.Forms.newQuizMessage',
    defaultMessage: 'SE HA CREADO EXITOSAMENTE EL QUIZ PARA EL EVENTO:'
  }
});
