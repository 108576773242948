import { useState } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Typography, Container, Box, Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import WebIcon from '@material-ui/icons/Web';
import { messages } from './EditMessages';
import { useStyles } from './EditStyles';
import { Images } from 'assets';
import { SideDrawer } from '../../SideDrawer';
import { useModal } from '@octopy/react-modal';
import { ModalButton, ModalText } from 'components/ModalMessages';
import { useDashboard } from 'components/Dashboard';

const Edit = withRouter(({ idPartner, partner, index }) => {
  const history = useHistory();
  const intl = useIntl();
  const classes = useStyles();
  const { handleOpenModal } = useModal();
  const { deletePartnerById } = useDashboard();
  const [idPartnerCard] = useState(idPartner);
  const [showSideDrawer, setShowSideDrawer] = useState(false);
  const handleShowSideDrawer = () => setShowSideDrawer(true);
  const handleHideSideDrawer = () => setShowSideDrawer(false);

  const handleDeletePartner = async () => {
    await deletePartnerById(partner, index);
  };

  const handleDeleteClick = () => {
    const modalTitle = (
      <ModalText>
        <ModalText>
          {intl.formatMessage(messages.areYouSureDelete)}
          <ModalText white> {partner.businessName}</ModalText>
          {' ?'}
        </ModalText>
      </ModalText>
    );
    const modalActionButtons = (
      <ModalButton onClick={handleDeletePartner}>
        {intl.formatMessage(messages.delete)}
      </ModalButton>
    );

    handleOpenModal({
      title: modalTitle,
      actionButtons: modalActionButtons,
      configProps: { maxWidth: 'xs' }
    });
  };

  return (
    <>
      <SideDrawer
        handleHide={handleHideSideDrawer}
        show={showSideDrawer}
        edit={true}
        partnerS={idPartner}
      />
      <Grid item lg={4} md={6} sm={12} xs={12}>
        <Container className={classes.Container}>
          <Box className={classes.Content}>
            <Box className={classes.imgBox}>
              {partner.logoUrl ? (
                <img
                  src={partner.logoUrl || Images.handshake}
                  alt="logo"
                  className={classes.logoImage}
                />
              ) : (
                <img src={Images.handshake} alt="logo" />
              )}
            </Box>
            <Box className={classes.info}>
              <Typography className={classes.title}>
                {partner.businessName}
              </Typography>
              <Typography className={classes.country}>
                {intl.formatMessage(messages.countryLabel)}: {partner.country}
              </Typography>
            </Box>
          </Box>
          <div className={classes.actions}>
            <Grid container className={classes.actionsContainer}>
              <Grid
                className={classes.edit}
                alignItems="center"
                item
                container
                onClick={() =>
                  history.push({
                    pathname: `/EditPartner/${idPartnerCard}`,
                    state: { index }
                  })
                }
                xs={4}
              >
                <div className={classes.actionButtonImageContainer}>
                  <img
                    alt="edit"
                    src={Images.Pencil}
                    className={classes.actionButtonImage}
                  />
                </div>
                <Typography component="span">
                  {intl.formatMessage(messages.edit)}
                </Typography>
              </Grid>
              <Grid
                className={classes.delete}
                alignItems="center"
                item
                container
                xs={4}
                onClick={handleDeleteClick}
              >
                <div className={classes.actionButtonImageContainer}>
                  <img
                    alt="delete"
                    src={Images.Trash}
                    className={classes.actionButtonImage}
                  />
                </div>
                <Typography component="span">
                  {intl.formatMessage(messages.delete)}
                </Typography>
              </Grid>
              <Grid
                className={classes.stand}
                alignItems="center"
                item
                container
                xs={4}
                onClick={handleShowSideDrawer}
              >
                <div className={classes.actionButtonImageContainer}>
                  <WebIcon className={classes.actionButtonIcon} />
                </div>
                <Typography component="span">
                  {intl.formatMessage(messages.stand)}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Container>
      </Grid>
    </>
  );
});

Edit.propTypes = {
  name: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired
};

export { Edit };
