import * as yup from 'yup';
import { messages } from './RightMessages';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const initialValues = {
  logoUrl: {
    file: '',
    blobUrl: ''
  },
  businessName: '',
  contactName: '',
  contactPhone: '',
  email: '',
  description: '',
  country: 'México'
};

export const getValidationSchema = (intl) => {
  const requiredErrorMessage = intl.formatMessage(messages.isRequiredError);
  const emailErrorMessage = intl.formatMessage(messages.invalidEmailError);
  const PhoneErrorMessage = intl.formatMessage(messages.errorPhone);

  return yup.object({
    businessName: yup.string().trim().required(requiredErrorMessage),
    email: yup.string().email(emailErrorMessage).required(requiredErrorMessage),
    contactName: yup.string().trim().required(requiredErrorMessage),
    country: yup.string(),
    description: yup.string(),
    contactPhone: yup.string().matches(phoneRegExp, PhoneErrorMessage)
  });
};
