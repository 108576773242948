import { useState } from 'react';

export const useSideDrawer = (initialVisible = false) => {
  const [visible, setVisible] = useState(initialVisible);

  const handleShow = () => setVisible(true);
  const handleHide = () => setVisible(false);
  const handleSwitch = () => setVisible((visible) => !visible);

  return {
    visible,
    handleShow,
    handleHide,
    handleSwitch
  };
};
