export const templateUpdateInfoUser = {
  file: 'file',
  email: 'email'
};

export const templateUser = {
  headerResponse: {
    code: 'headerResponse.code',
    message: 'headerResponse.message'
  },
  payload: {
    access_token: 'payload.access_token',
    user: {
      id: 'payload.user.id',
      name: 'payload.user.name',
      email: 'payload.user.email',
      role: {
        name: 'payload.user.role.name'
      }
    }
  }
};

export const templateGetUser = {
  headerResponse: {
    code: 'headerResponse.code',
    message: 'headerResponse.message'
  },
  payload: {
    id: 'payload._id',
    availability: 'payload.availability',
    email: 'payload.email',
    name: 'payload.name',
    company: 'payload.company',
    avatar: 'payload.avatar',
    role: {
      name: 'payload.role.name'
    },
    phone: 'payload.phone',
    created_at: 'payload.created_at'
  }
};

export const templateRecoveryUser = {
  headerResponse: {
    code: 'headerResponse.code',
    message: 'headerResponse.message'
  }
};
