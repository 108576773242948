import * as yup from 'yup';
import { messages } from './RightMessages';

export const getValidationSchema = (intl) => {
  const requiredErrorMessage = intl.formatMessage(messages.isRequiredError);
  const urlErrorMessage = intl.formatMessage(messages.invalidUrlError);

  return yup.object({
    logoUrl: yup.string().required(requiredErrorMessage),
    videoId: yup
      .string()
      .trim()
      .required(requiredErrorMessage),
    urlChat: yup
      .string()
      .url(urlErrorMessage)
      .required(requiredErrorMessage),
    productName: yup
      .string()
      .trim()
      .required(requiredErrorMessage),
    description: yup.string(),
    slogan: yup
      .string()
      .trim()
      .required(requiredErrorMessage),
    event: yup.string().required(requiredErrorMessage)
  });
};
