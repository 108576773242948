import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.Quiz.title',
    defaultMessage: 'Quiz'
  },
  subTitle: {
    id: 'components.Quiz.subTitle',
    defaultMessage: 'Listado de trivias asociadas a algún evento.'
  },
  quizDeleted: {
    id: 'components.Quiz.quizDeleted',
    defaultMessage: 'SE HA ELIMINADO EL QUIZ DEL EVENTO: '
  },
  page: {
    id: 'components.Quiz.page',
    defaultMessage: 'Página'
  }
});
