import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Drawer, Hidden, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useStyles } from './LicenseHistoryStyles';
import { messages } from './LicenseHistoryMessages';
import {
  Timeline,
  TimelineContent,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector
} from '@material-ui/lab';

export const LicenseHistory = ({ historyLicense = [], show, handleHide }) => {
  const intl = useIntl();
  const classes = useStyles();

  const [resize, setResize] = useState(window.screen.width);

  useEffect(() => {
    setResize(window.innerWidth);
    window.onresize = () => {
      setResize(window.innerWidth);
    };
  }, []);
  const changeFormatDate = (oldDate) =>
    oldDate.split('T')[0].split('-').reverse().join('/');

  return (
    <Drawer
      className={classes.drawer}
      anchor="right"
      open={show}
      onClose={handleHide}
    >
      <div className={classes.container}>
        <div className={classes.header}>
          <Typography className={classes.title} component="div">
            {intl.formatMessage(messages.title)}
          </Typography>
          <div className={classes.closeIconContainer}>
            <Close className={classes.closeIcon} onClick={handleHide} />
          </div>
        </div>
        <Timeline
          className={classes.timeline}
          align={resize < 600 ? '' : 'alternate'}
        >
          {historyLicense.map((action, i, { length }) => {
            let actionTypeName = '';
            const actionDate = changeFormatDate(action.date);

            switch (action.action) {
              case 'suspensión':
                actionTypeName = 'suspension';
                break;
              case 'reactivación':
                actionTypeName = 'reactivation';
                break;
              case 'creación':
                actionTypeName = 'activation';
                break;
              case 'renovación':
                actionTypeName = 'renewal';
                break;
              default:
                actionTypeName = 'renewal';
                break;
            }

            return (
              <TimelineItem key={`timelineItem${i}`}>
                <TimelineOppositeContent>
                  <Hidden xsDown>
                    <div className={classes.date}>{actionDate}</div>
                  </Hidden>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot className={`${classes.dot} ${actionTypeName}`} />
                  {i < length - 1 && (
                    <TimelineConnector className={classes.connector} />
                  )}
                </TimelineSeparator>
                <TimelineContent>
                  <Hidden smUp>
                    <div className={classes.date}>{actionDate}</div>
                  </Hidden>
                  <div className={classes.dataContainer}>
                    <div className={classes.type}>
                      {intl.formatMessage(messages[`${actionTypeName}Title`])}
                    </div>
                    <div className={classes.info}>
                      {intl.formatMessage(
                        messages[`${actionTypeName}Description`]
                      )}
                    </div>
                  </div>
                </TimelineContent>
              </TimelineItem>
            );
          })}
        </Timeline>
      </div>
    </Drawer>
  );
};
