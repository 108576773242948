import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: '3px',
    marginBottom: '25px'
  },
  containerAddButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '10px'
  },
  accordion: {
    boxShadow: '0px 3px 4px #00000029'
  },
  summary: {
    borderBottom: '1px solid #E6E6E6',
    padding: '0px 30px'
  },
  details: {
    padding: '20px 30px 30px 30px',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      padding: '10px 15px 15px 15px'
    }
  },
  title: {
    font: 'normal normal normal 15px/20px Roboto',
    color: theme.palette.primary,
    fontWeight: theme.typography.fontWeightMedium
  },
  textField: {
    margin: 0,
    width: '80%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: '15px'
    }
  },
  actionButtonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px'
  },
  actionButton: {
    marginLeft: '10px',
    borderRadius: '50%'
  },
  actionButtonImage: {
    width: '28px',
    height: '28px'
  },
  containerField: {
    [theme.breakpoints.up('sm')]: {
      marginRight: '5px'
    }
  },
  titleAnswers: {
    marginBottom: '10px'
  },
  fontWeightBold: {
    fontWeight: 'bold'
  },
  containerAnswer: {
    marginBottom: '10px',
    [theme.breakpoints.down('sm')]: {
      marginRight: '30px'
    }
  },
  containerRadioButton: {
    marginLeft: '10px'
  },
  containerSubmitButton: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  minimumAnswersError: {
    color: theme.palette.error.main
  },
  containerMinimumAnswers: {
    marginBottom: '5px'
  }
}));
