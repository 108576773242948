import React, { useState } from 'react';
import { Login as LoginComponent } from '@octopy/react-auth';
import { Box } from '@material-ui/core';
import { useStyles } from './LoginStyles';
import { Images } from 'assets';
import { useDashboard } from 'components/Dashboard';
import { useHistory } from 'react-router';
import { recoveryPassword } from 'providers/api';
import { Alert, useModal } from '@octopy/react-modal';
import { messages } from './LoginMessages';
import { useIntl } from 'react-intl';
import { intialPahts } from 'routes/accessDictionary';

const Login = () => {
  const [disableButton, setDisableButton] = useState(false);
  const classes = useStyles({ bgLogin: Images.backgroundLogin });
  const { user: savedUser, loginUser } = useDashboard();
  const history = useHistory();
  const { handleCloseModal, handleOpenModal } = useModal();
  const intl = useIntl();

  const handleSubmit = async (user) => {
    setDisableButton(true);
    await loginUser(user);
    setDisableButton(false);

    if (savedUser) {
      const destiny = intialPahts[savedUser.role.name];

      history.push(destiny);
    }
  };

  const handleSubmitEmailToRecovery = async ({ email }) => {
    const responseRecoveryPass = await recoveryPassword({ email });

    if (responseRecoveryPass.code === 200) {
      handleCloseModal();

      handleOpenModal({
        body: (
          <Alert message={intl.formatMessage(messages.checkEmailMessage)} />
        ),
        configProps: {
          maxWidth: 'xs'
        }
      });
    }
  };

  return (
    <Box className={classes.container}>
      <LoginComponent
        actions={{ handleSubmit, handleSubmitEmailToRecovery }}
        buttonSubmitProps={{
          className: classes.button,
          disabled: disableButton
        }}
        passwordRecoveryClassName={classes.recoveryPassword}
      />
    </Box>
  );
};

export { Login };
