import { makeStyles } from '@material-ui/core';

export const useRowsStyles = makeStyles({
  1: {
    height: '195px'
  },
  2: {
    height: '170px'
  },
  3: {
    height: '90px'
  },
  4: {
    height: '90px'
  },
  5: {
    height: '90px'
  }
});
