import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';
import { Select } from 'components/CreationForm';
import { FormikProvider, useFormik, Field } from 'formik';
import { useStyles } from './EditStyles';
import { messages } from './EditMessages';
import { Conferences } from './Conferences';
import { getValidationSchema } from './helpers';
import { GoBack } from 'components/GoBack/GoBack';
import { useEffect, useState } from 'react';
import {
  addConference,
  getEventConferences,
  updateConference
} from 'providers/api';
import { formatDate, formatTime, getDatesBetween } from 'utils/helpers';
import { useLoader } from '@octopy/react-loader';
import { useModal } from '@octopy/react-modal';
import {
  getDataError,
  createErrorModalConfig,
  createSuccessModalConfig
} from 'utils/helpers';
import { useParams, withRouter } from 'react-router';
import { useDashboard } from 'components/Dashboard';

export const Edit = withRouter(({ history }) => {
  const classes = useStyles();
  const intl = useIntl();
  const { handleOpenModal } = useModal();
  const { handleShowLoader } = useLoader();

  const { events, addNumberOfEventConferences } = useDashboard();

  const { eventId } = useParams();
  const [event, setEvent] = useState();
  const [availableDates, setAvailableDates] = useState([]);

  const handleUpdateConference = async (values, index) => {
    formik.submitForm();

    if (!!formik.errors && formik.errors.hasOwnProperty('conferences')) {
      if (!!formik.errors.conferences[index]) return;
    }

    handleShowLoader(true);

    const formattedConference = {
      ...values,
      date: new Date(`${values.date} ${formatTime(values.time)}`),
      eventId: formik.values.eventId
    };

    try {
      const { id: conferenceId, ...conference } = formattedConference;

      if (conferenceId) {
        await updateConference(conferenceId, conference);

        handleOpenModal(
          createSuccessModalConfig(
            messages.successfullyUpdatedMessage,
            event.name,
            'ok'
          )
        );
      } else {
        const createdConference = await addConference(conference);

        formik.setFieldValue(
          'conferences',
          formik.values.conferences.map((conference, currentIndex) =>
            index === currentIndex
              ? { ...createdConference, ...conference }
              : conference
          )
        );

        addNumberOfEventConferences(conference.eventId, +1);

        handleOpenModal(
          createSuccessModalConfig(
            messages.successfullyCreatedMessage,
            event.name,
            'ok'
          )
        );
      }
    } catch (e) {
      const { code, message } = getDataError(e);

      handleOpenModal(createErrorModalConfig(code, message));
    }

    handleShowLoader(false);
  };

  const formik = useFormik({
    initialValues: { eventId },
    validationSchema: getValidationSchema(intl),
    onSubmit: () => {}
  });

  const getConferences = async () => {
    try {
      handleShowLoader(true);

      const conferences = await getEventConferences(eventId);

      !conferences.length && history.push('/Principal');

      const formattedConferences = conferences.map((conference) => {
        const time = new Date(conference.date);
        const date = formatDate(new Date(conference.date));

        return { ...conference, time, date };
      });

      formik.setFieldValue('conferences', formattedConferences);
    } catch (request) {
      const { code, message } = getDataError(request);

      handleOpenModal(createErrorModalConfig(code, message));
    } finally {
      handleShowLoader(false);
    }
  };

  useEffect(() => getConferences(), []);

  const findEvent = () =>
    setEvent(events.find((event) => event?.id === eventId));

  useEffect(() => events.length && findEvent(), [events]);

  const findAvailableDates = () => {
    const availableDates = getDatesBetween(
      new Date(event.from),
      new Date(event.to)
    );

    const formattedAvailableDates = availableDates.map((date) =>
      formatDate(date)
    );

    setAvailableDates(formattedAvailableDates);
  };

  useEffect(() => event && findAvailableDates(), [event]);

  return (
    <FormikProvider value={formik}>
      <div className={classes.container}>
        <div className={classes.accordionContainer}>
          <div className={classes.header}>
            <Typography className={classes.title}>
              {intl.formatMessage(messages.title)}
            </Typography>
            <Typography className={classes.subtitle}>
              {intl.formatMessage(messages.subtitle)}
            </Typography>
            <GoBack />
            <Field name="eventId">
              {({ field, meta: { error, touched } }) => (
                <Select
                  label={intl.formatMessage(messages.eventIdLabel)}
                  className={classes.eventSelect}
                  {...field}
                  error={error}
                  touched={touched}
                  options={[
                    {
                      value: event?.id,
                      label: event?.name
                    }
                  ]}
                  selectProps={{ disabled: true }}
                />
              )}
            </Field>
          </div>
          {formik.values?.conferences && (
            <Conferences
              conferences={formik.values.conferences}
              setFieldValue={formik.setFieldValue}
              availableDates={availableDates}
              eventId={eventId}
              updateConference={handleUpdateConference}
            />
          )}
        </div>
      </div>
    </FormikProvider>
  );
});
