import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  accordionLabel: {
    id: 'components.Principal.ConferencesForms.Edit.Conferences.accordionLabel',
    defaultMessage: 'Conferencia programada'
  },
  imageLabel: {
    id:
      'components.Principal.ConferencesForms.Edit.Conferences.input.image.label',
    defaultMessage: 'Seleccionar foto'
  },
  speakerNameLabel: {
    id:
      'components.Principal.ConferencesForms.Edit.Conferences.input.speakerName.label',
    defaultMessage: 'Nombre del ponente'
  },
  speakerResumeLabel: {
    id:
      'components.Principal.ConferencesForms.Edit.Conferences.input.speakerResume.label',
    defaultMessage: 'Resumen del ponente'
  },
  speakerEducationLabel: {
    id:
      'components.Principal.ConferencesForms.Edit.Conferences.input.speakerEducation.label',
    defaultMessage: 'Educacion del ponente'
  },
  speakerBackgroundLabel: {
    id:
      'components.Principal.ConferencesForms.Edit.Conferences.input.speakerBackground.label',
    defaultMessage: 'Dedicacion del ponente'
  },
  dateLabel: {
    id:
      'components.Principal.ConferencesForms.Edit.Conferences.input.date.label',
    defaultMessage: 'Fecha del evento'
  },
  timeLabel: {
    id:
      'components.Principal.ConferencesForms.Edit.Conferences.input.time.label',
    defaultMessage: 'Hora del evento'
  },
  descriptionLabel: {
    id:
      'components.Principal.ConferencesForms.Edit.Conferences.input.description.label',
    defaultMessage: 'Descripcion'
  },
  streamIdLabel: {
    id:
      'components.Principal.ConferencesForms.Edit.Conferences.input.streamId.label',
    defaultMessage: 'ID del stream'
  },
  chatIframeLabel: {
    id:
      'components.Principal.ConferencesForms.Edit.Conferences.input.chatIframe.label',
    defaultMessage: 'Iframe del chat'
  },
  yes: {
    id: 'components.Principal.ConferencesForms.Edit.Conferences.modal.yes',
    defaultMessage: 'Sí'
  },
  delete: {
    id: 'components.Principal.ConferencesForms.Edit.Conferences.button.delete',
    defaultMessage: 'Eliminar'
  },
  save: {
    id: 'components.Principal.ConferencesForms.Edit.Conferences.button.save',
    defaultMessage: 'Guardar'
  },
  anticipationError: {
    id:
      'components.Principal.ConferencesForms.Edit.Conferences.anticipationError',
    defaultMessage: 'Debe tener una hora de anticipacion'
  }
});
