import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    font: 'normal normal bold 21px/28px Roboto',
    color: '#0E0E0E',
    marginBottom: theme.spacing(1)
  },
  subtitle: {
    font: 'normal normal normal 15px/20px Roboto',
    color: '#0E0E0E80'
  },
  challengesQuantity: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    '& img': {
      color: theme.palette.secondary.main,
      height: '17px',
      marginRight: '4px'
    },
    '& span': {
      font: 'normal normal normal 15px/20px Roboto',
      color: '#00040F7F',
      marginTop: '3px',
      boxSizing: 'border-box'
    }
  },
  cardsContainer: {
    paddingTop: theme.spacing(2)
  }
}));

export { useStyles };
