import { TimePicker as TimePickerMUI } from '@material-ui/pickers';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Images } from 'assets';
import WatchLaterIcon from '@material-ui/icons/WatchLater';

export const TimePicker = ({ error, disabled, ...props }) => {
  const endAdornment = (
    <InputAdornment>
      <IconButton disabled={disabled}>
        <WatchLaterIcon />
      </IconButton>
    </InputAdornment>
  );

  return (
    <TimePickerMUI
      disabled={disabled}
      InputProps={{ endAdornment }}
      error={Boolean(error)}
      helperText={error}
      {...props}
    />
  );
};
