import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import { useStyles } from './UsersQuantityStyles';
import { messages } from './UsersQuantityMessages';
import { Images } from 'assets';

export const UsersQuantity = ({ quantity }) => {
  const classes = useStyles({ icon: Images.Person });
  const intl = useIntl();

  return (
    <Grid item lg={4} md={6} xs={12}>
      <div className={classes.container}>
        <div>
          <div className={classes.imageMaskContainer}>
            <div className={classes.imageMask} />
          </div>
        </div>
        <div>
          <p className={classes.title}>{intl.formatMessage(messages.title)}</p>
          <span className={classes.quantity}>{quantity}</span>
        </div>
      </div>
    </Grid>
  );
};
