import { responseMapper } from 'utils/responseMapper';
import { getFormData } from 'utils/helpers';
import { instance } from '../instance';
import {
  templateGetDataLicenses as templateGetData,
  templateGetDataLicenseById as templateGetDataById,
  templateGetHeaderLicenses as templateGetHeader,
  templeateSendStatusLicenses as templateSendStatus,
  templateSendDataLicenses as templateSendData
} from '../mappers';

export const getLicenses = async () => {
  const response = await instance.get('/licenses');

  const { code } = response.data.headerResponse;

  if (code !== 200) throw new Error(code);

  const payload = responseMapper({
    template: templateGetData,
    data: response.data.payload.items
  });

  return payload.filter((license) => license.deleted !== true);
};

export const getLicenseById = async (id) => {
  const response = await instance.get(`/licenses/${id}`);
  const { code } = response.data.headerResponse;

  if (code !== 200) throw new Error(code);
  const payload = responseMapper({
    template: templateGetDataById,
    data: response.data.payload
  });

  return payload;
};

export const postLicense = async (data) => {
  const mappedData = responseMapper({
    template: templateSendData,
    data: data
  });

  const formData = getFormData(mappedData);

  const response = await instance.post('/licenses', formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
  const { code } = response.data.headerResponse;

  if (code !== 200) throw new Error(code);

  const payload = responseMapper({
    template: templateGetData,
    data: response.data.payload
  });

  return payload;
};

export const updateSpecificLicense = async (id, data) => {
  const mappedData = responseMapper({
    template: templateSendData,
    data: data
  });

  const formData = getFormData(mappedData);

  const response = await instance.patch(`/licenses/${id}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });

  const code = response.data.headerResponse.code;

  if (code !== 200) throw new Error(code);
  const payload = responseMapper({
    template: templateGetData,
    data: response.data.payload
  });

  return payload;
};

export const deleteLicense = async (id) => {
  const response = await instance.delete(`/licenses/${id}`);

  const { code } = responseMapper({
    template: templateGetHeader,
    data: response.data
  });

  if (code !== 200) throw new Error(code);

  return {};
};

export const updateLicenseStatus = async (id, data) => {
  const dataToSend = responseMapper({
    template: templateSendStatus,
    data: data
  });

  const formData = getFormData(dataToSend);

  const response = await instance.patch(`/licenses/status/${id}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });

  const { code } = response.data.headerResponse;

  if (code !== 200) throw new Error(code);

  let payload = {};

  if (response.data.payload)
    payload = responseMapper({
      template: templateGetData,
      data: response.data.payload
    });

  return payload;
};

export const updateLicenseRenovation = async (id, data) => {
  const response = await instance.patch(`/licenses/renovate/${id}`, data);
  const {
    headerResponse: { code }
  } = responseMapper({
    template: templateGetHeader,
    data: response
  });

  if (code !== 200) throw new Error(code);

  return {};
};
