import { Link } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Box,
  Typography,
  ButtonBase,
  Hidden
} from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import { useStyles } from './HeaderStyles';
import { Images } from 'assets';
import { Profile } from 'components/Profile/Profile';
import { useMenu } from 'components/Menu';
import { useSideDrawer } from 'hooks/useSideDrawer';
import { useDashboard } from 'components/Dashboard';

const Header = () => {
  const classes = useStyles();
  const { openMenu } = useMenu();
  const { user } = useDashboard();
  const profile = useSideDrawer();

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.root}>
          <Hidden smUp>
            <ButtonBase className={classes.menuButton} onClick={openMenu}>
              <Menu />
            </ButtonBase>
          </Hidden>
          <Box className={classes.title}>
            <Link to="/" className={classes.link}>
              <img
                src={Images.OctopyRooms}
                className={classes.roomsLogo}
                alt="rooms"
              />
            </Link>
          </Box>
          <ButtonBase
            onClick={profile.handleShow}
            className={classes.profileButton}
          >
            <Typography className={classes.userCompany}>{user.name}</Typography>
            <Box className={classes.avatarContainer}>
              <img
                src={user.avatar ? user.avatar : Images.PersonLicense}
                alt="avatar"
                className={classes.avatar}
              />
            </Box>
          </ButtonBase>
        </Toolbar>
      </AppBar>
      <Profile {...profile} />
    </>
  );
};

export { Header };
