import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  videoIdLabel: {
    id: 'components.Events.Forms.Create.Right.videoIdLabel',
    defaultMessage: 'ID del video'
  },
  nameLabel: {
    id: 'components.Events.Forms.Create.Right.nameLabel',
    defaultMessage: 'Nombre del evento virtual'
  },
  urlEventLabel: {
    id: 'components.Events.Forms.Create.Right.urlEventLabel',
    defaultMessage: 'Url del evento'
  },
  amountParticipantsLabel: {
    id: 'components.Events.Forms.Create.Right.amountParticipantsLabel',
    defaultMessage: 'No. de participantes'
  },
  amountLabel: {
    id: 'components.Events.Forms.Create.Right.amountLabel',
    defaultMessage: 'Cantidad de partners'
  },
  partnersLabel: {
    id: 'components.Events.Forms.Create.Right.partnersLabel',
    defaultMessage: 'Partners'
  },
  fromDateLabel: {
    id: 'components.Events.Forms.Create.Right.fromDateLabel',
    defaultMessage: 'Fecha de inicio'
  },
  fromTimeLabel: {
    id: 'components.Events.Forms.Create.Right.fromTimeLabel',
    defaultMessage: 'Hora de inicio'
  },
  toDateLabel: {
    id: 'components.Events.Forms.Create.Right.toDateLabel',
    defaultMessage: 'Fecha de termino'
  },
  toTimeLabel: {
    id: 'components.Events.Forms.Create.Right.toTimeLabel',
    defaultMessage: 'Hora de termino'
  },

  submitButtonLabel: {
    id: 'components.Events.Forms.Create.Right.submitButtonLabel',
    defaultMessage: 'CREAR'
  },
  editSubmitButtonLabel: {
    id: 'components.Events.Forms.Create.Right.editSubmitButtonLabel',
    defaultMessage: 'EDITAR'
  },
  isRequiredError: {
    id: 'components.Events.Forms.Create.Right.isRequiredError',
    defaultMessage: 'Este campo es requerido'
  },
  invalidUrlError: {
    id: 'components.Events.Forms.Create.Right.invalidUrlError',
    defaultMessage: 'Url inválida'
  },
  success: {
    id: 'components.Events.Forms.Create.Right.success',
    defaultMessage: 'SE HA CREADO EXITOSAMENTE EL EVENTO'
  },
  ok: {
    id: 'components.Events.Forms.Create.Right.ok',
    defaultMessage: 'ok'
  },
  error: {
    id: 'components.Events.Forms.Create.Right.error',
    defaultMessage: 'Ha ocurrido un error.'
  },
  internalServerError: {
    id: 'components.Events.Forms.Create.Right.internalServerError',
    defaultMessage:
      'Ha ocurrido un error interno en el servidor. Vuelve a intentarlo'
  },
  duplicatedError: {
    id: 'components.Events.Forms.Create.Right.duplicatedError',
    defaultMessage: 'El registro ya se encuentra en la base de datos.'
  },
  beginning: {
    id: 'component.Events.Forms.Create.Right.beginning',
    defaultMessage: 'Inicio'
  },
  end: {
    id: 'component.Events.Forms.Create.Right.end',
    defaultMessage: 'Fin'
  },
  optionOne: {
    id: 'component.Events.Forms.Create.Right.optionOne',
    defaultMessage: 'Evento mediano (1 - 4,999) participantes'
  },
  optionTwo: {
    id: 'component.Events.Forms.Create.Right.optionTwo',
    defaultMessage: 'Evento grande (5000 - 9,999) participantes'
  },
  optionThree: {
    id: 'component.Events.Forms.Create.Right.optionThree',
    defaultMessage: 'Evento masivo (10000 - 39,999) participantes'
  },
  minPartners: {
    id: 'component.Events.Forms.Create.Right.minPartners',
    defaultMessage: 'Debe ingresar mínimo un Partner'
  },
  minDateError: {
    id: 'component.Events.Forms.Create.Right.minDateError',
    defaultMessage: 'La fecha de inicio no puede ser menor al día actual'
  },
  dateError: {
    id: 'component.Events.Forms.Create.Right.dateError',
    defaultMessage: 'La fecha debe ser mayor a la inicial'
  },
  onlyIntegers: {
    id: 'components.Events.Forms.Create.Right.onlyIntegers',
    defaultMessage: 'Sólo números positivos'
  },
  errorPartners: {
    id: 'components.Events.Forms.Create.Right.errorPartners',
    defaultMessage: 'Debe agregar partners antes de crear un evento'
  },
  timeError: {
    id: 'component.Events.Forms.Create.Right.timeError',
    defaultMessage: 'La hora debe ser mayor a la inicial'
  },
  youtube: {
    id: 'component.Events.Forms.Create.Right.youtube',
    defaultMessage: 'youtube'
  },
  vimeo: {
    id: 'component.Events.Forms.Create.Right.vimeo',
    defaultMessage: 'vimeo'
  },
  image: {
    id: 'component.Events.Forms.Create.Right.image',
    defaultMessage: 'imagen'
  },
  uploadButtonLabel: {
    id: 'components.Events.Forms.Create.Right.uploadButtonLabel',
    defaultMessage: 'SUBIR'
  }
});
