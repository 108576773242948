import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.Challenge.title',
    defaultMessage: 'Challenge'
  },
  subtitle: {
    id: 'components.Challenge.subtitle',
    defaultMessage:
      'Listado de desafíos y políticas de premiación para algún evento.'
  },
  challengesQuantity: {
    id: 'components.Challenge.challengesQuantity',
    defaultMessage: 'Total de desafios: '
  }
});
