import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.Partners.Forms.Create.Left.title',
    defaultMessage: 'Agregar partner'
  },
  imageLabel: {
    id: 'components.Partners.Forms.Create.Left.imageLabel',
    defaultMessage: 'Seleccionar una imagen'
  },
  dataLabel: {
    id: 'components.Partners.Forms.Create.Left.dataLabel',
    defaultMessage: 'Agregar los datos necesarios para crear un registro'
  },
  landingLabel: {
    id: 'components.Partners.Forms.Create.Left.landingLabel',
    defaultMessage: 'Landing page'
  },
  esquemaLabel: {
    id: 'components.Partners.Forms.Create.Left.esquemaLabel',
    defaultMessage: 'Esquema'
  }
});
