import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '133px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    boxShadow: '0px 3px 6px #00000029',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    boxSizing: 'border-box',
    maxHeight: 'calc(100vh - 50px)',
    overflow: 'auto',
    [theme.breakpoints.down('xs')]: {
      boxShadow: '0px 0px 0px',
      width: '250px',
      maxWidth: '90vw',
      maxHeight: 'unset'
    }
  },
  boxOption: {
    width: '100%'
  },
  title: {
    marginTop: '5px',
    color: '#2AA4D5',
    textAlign: 'center',
    font: 'normal normal normal 12px/13px Roboto',
    [theme.breakpoints.down('xs')]: {
      font: 'normal normal normal 14px/15px Roboto',
      fontWeight: '400'
    }
  },
  imgMenu: {
    color: '#2AA4D5',
    textDecoration: 'none'
  },
  quizImg: {
    width: '30px',
    height: '40px'
  },
  option: {
    padding: '10px 0 10px 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'background-color 300ms',
    '&.active': {
      backgroundColor: '#2AA4D533'
    },
    '&:hover': {
      backgroundColor: '#2AA4D520'
    },
    color: '#2AA4D5',
    textDecoration: 'none',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      padding: '15px 25px',
      marginRight: '10px',
      borderTopRightRadius: '15px',
      borderBottomRightRadius: '15px'
    }
  },
  icon: {
    width: '28px',
    height: '23px',
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(3),
      width: '25px',
      height: '20px'
    }
  }
}));

export { useStyles };
