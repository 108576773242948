import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  importTitle: {
    id: 'components.Challenge.CreateForm.AwardPolicies.title',
    defaultMessage: 'Importar política'
  },
  importDescription: {
    id: 'components.Challenge.CreateForm.AwardPolicies.description',
    defaultMessage:
      'Si lo prefieres puedes importar la política de premiación seleccionando el archivo PDF, recuerda que debe contener las condiciones y políticas del challenge'
  },
  editorLabel: {
    id: 'components.Challenge.CreateForm.AwardPolicies.editor.label',
    defaultMessage: 'Escribe tu texto aquí...'
  },
  submit: {
    id: 'components.Challenge.CreateForm.AwardPolicies.submitButton.label',
    defaultMessage: 'Crear'
  },
  requiredError: {
    id: 'components.Challenge.CreateForm.input.error.required',
    defaultMessage: 'Este campo es requerido'
  }
});
