import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '90%',
    padding: '10px 10px 0px',
    boxSizing: 'border-box',
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '17px 25px 33px 25px'
    }
  },
  containerTile: {
    width: '100%'
  },
  title: {
    font: 'normal normal bold 16px/20px Roboto',
    color: theme.palette.primary.main
  },
  colorTexts: {
    font: 'normal normal normal 15px/20px Roboto',
    color: theme.palette.secondary.main,
    marginBottom: '8px'
  },
  titles: {
    font: 'normal normal normal 15px/20px Roboto',
    color: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: '20px',
    marginRight: '10px',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  inputs: {
    width: '291px',
    height: '45px',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  swatchesPickerContainer: {
    zIndex: '1000',
    position: 'absolute',
    backgroundColor: 'white'
  }
}));

export { useStyles };
