import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme();

export const common = {
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },
  direction: 'ltr',
  mixins: {
    toolbar: {
      minHeight: 56,
      '@media (min-width:0px) and (orientation: landscape)': {
        minHeight: 48
      },
      '@media (min-width:600px)': {
        minHeight: 64
      }
    }
  },
  overrides: {
    MuiFormHelperText: {
      root: {
        paddingLeft: '22px'
      }
    },
    MuiInput: {
      root: {
        borderRadius: '5px',
        height: '45px',
        background: '#F9F9F9 0% 0% no-repeat padding-box',
        '& input': {
          font: 'normal normal normal 12px/16px Roboto',
          textAlign: 'left',
          padding: '20px 15px 6px 22px'
        },
        '&.Mui-disabled img': {
          opacity: theme.palette.action.disabledOpacity
        },
        '&.MuiInput-underline.Mui-disabled:before': {
          border: 0
        }
      },
      underline: {
        '&::before': {
          borderBottom: 'none'
        },
        '&::after': {
          borderBottom: 'none'
        },
        '&:hover:not($disabled):before': {
          borderBottom: 'none'
        }
      },
      formControl: {
        'label + &': {
          marginTop: '0px'
        }
      }
    },
    MuiInputLabel: {
      root: {
        pointerEvents: 'none',
        font: 'normal normal normal 12px/16px Roboto',
        textAlign: 'left',
        transformOrigin: 'left',
        color: '#00040F80',
        transition: 'all 300ms',
        '&$focused': {
          color: '#2AA4D5'
        },
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        width: '80%',
        display: 'block',
        overflow: 'hidden'
      },
      formControl: {
        zIndex: '1',
        transform: 'translate(22px,16px)',
        transformOrigin: 'left'
      },
      shrink: {
        transform: 'translate(22px,9px) scale(0.75)',
        transformOrigin: 'left'
      }
    },
    MuiRadio: {
      root: {
        height: '18px',
        width: '18px'
      },
      colorPrimary: {
        color: '#2AA4D5'
      },
      colorSecondary: {
        color: '#C7C2BA',
        '&:hover': {
          backgroundColor: 'rgba(42,164,213,0.4)'
        }
      }
    },
    MuiSelect: {
      root: {
        '& p': {
          font: 'normal normal normal 12px/16px Roboto',
          textAlign: 'left',
          display: 'block',
          textOverflow: 'ellipsis',
          overflow: 'hidden'
        },
        padding: '14px 15px 13px 22px'
      },
      select: {
        '&:focus': {
          backgroundColor: '#ffffff00'
        },
        '&$select': {
          paddingRight: '41px'
        }
      },
      icon: {
        top: '0px',
        color: '#00040F',
        right: '0px',
        fontSize: '45px',
        position: 'absolute',
        pointerEvents: 'none'
      }
    },
    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: '#00040F',
          color: '#fff'
        },
        '& p,& span': {
          font: 'normal normal normal 12px/16px Roboto',
          textAlign: 'left'
        },
        paddingLeft: '22px',
        height: '45px'
      },
      button: {
        '&:hover': {
          backgroundColor: '#000000',
          color: '#fff'
        }
      },
      gutters: {
        paddingLeft: '22px'
      }
    },
    MuiPopover: {
      paper: {
        background: '#F9F9F9 0% 0% no-repeat padding-box'
      }
    },
    MuiPickersModal: {
      dialogRoot: {
        backgroundColor: '#00040F'
      }
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(13,13,13,0.85)'
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#0E0E0E'
      }
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: 'rgba(42,164,213,0.4)'
        }
      }
    },
    MuiPickersBasePicker: {
      container: {
        backgroundColor: '#0E0E0E'
      },
      pickerView: {
        backgroundColor: '#00040F',
        '& span, & p': {
          color: '#fff'
        }
      }
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: '#2AA4D5'
      }
    },
    MuiDialogActions: {
      root: {
        '& span': {
          color: '#2AA4D5'
        },
        backgroundColor: '#00040F'
      }
    },
    MuiPickersClock: {
      clock: {
        backgroundColor: '#2AA4D51A'
      },
      pin: {
        backgroundColor: '#2AA4D5'
      }
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: '#2AA4D5'
      },
      thumb: {
        border: '14px solid #2AA4D5'
      },
      noPoint: {
        backgroundColor: '#2AA4D5'
      }
    },
    MuiDialog: {
      container: {
        '& .MuiPaper-root': {
          color: 'white',
          padding: theme.spacing(2),
          backgroundColor: '#00040F'
        },

        '& .MuiPaper-root span,h2,h,.MuiTypography-colorPrimary': {
          color: '#2AA4D5'
        },

        '& .MuiButtonBase-root': {
          background: 'transparent',
          '& p': {
            color: '#2AA4D5'
          }
        }
      }
    },
    MuiPagination: {
      root: {
        display: 'inline-block',
        marginTop: theme.spacing(2)
      },
      ul: {
        display: 'flex',
        flexWrap: 'no-wrap',
        '& > li': {
          marginRight: '5px',
          '& > button': {
            border: 0,
            borderRadius: '4px',
            boxShadow: '0px 0px 2px #4D4F5C80',
            backgroundColor: '#fff',
            width: '23px',
            height: '23px',
            color: '#4D4F5C50',
            font: 'normal normal normal 10px/14px Roboto',
            '&.Mui-selected': {
              backgroundColor: '#2AA4D5',
              border: '1px solid #2AA4D5',
              color: 'white',
              '&:hover': {
                backgroundColor: '#2AA4D5'
              }
            }
          }
        },
        '& > li:first-child > button, & > li:nth-last-child(1) > button': {
          color: '#808495',
          '& svg': {
            fontSize: '1.5rem !important'
          }
        },
        '& > li > .MuiPaginationItem-ellipsis': {
          width: '23px !important',
          height: '23px !important',
          color: '#4D4F5C50',
          padding: '0 0 8px 0',
          margin: 0,
          textAlign: 'center'
        }
      }
    },
    MUIRichTextEditor: {
      root: {
        width: '550px',
        maxWidth: '100%',
        borderRadius: '10px',
        boxShadow: '0px 3px 6px #00000029',
        borderLeft: '1px solid #00000029',
        borderRight: '1px solid #00000029'
      },
      editor: {
        overflow: 'auto',
        height: '140px',
        padding: '0px 10px 10px 10px'
      },
      placeHolder: {
        padding: '0px 10px 10px 10px',
        height: '132px'
      },
      toolbar: {
        backgroundColor: '#404247',
        borderRadius: '5px',
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
          justifyContent: 'flex-end'
        },
        flexWrap: 'wrap',
        '& svg': {
          height: '17px',
          width: '17px',
          color: '#C7C2BA'
        },
        '& #mui-rte-Save-button': {
          marginLeft: 'auto',
          marginRight: '10px',
          [theme.breakpoints.down('xs')]: {
            marginRight: 0,
            marginLeft: '5px'
          },
          '& svg': {
            color: '#fff !important'
          }
        },
        '& #mui-rte-Bold-button': {
          marginLeft: '20px',
          position: 'relative',
          [theme.breakpoints.down('xs')]: {
            marginLeft: '5px'
          },
          '&:before': {
            content: '""',
            position: 'absolute',
            left: '-10px',
            bottom: '13px',
            top: '13px',
            width: '1px',
            backgroundColor: '#C7C2BA',
            [theme.breakpoints.down('xs')]: {
              display: 'none'
            }
          }
        },
        '& #mui-rte-OL-button': {
          marginLeft: '20px',
          position: 'relative',
          [theme.breakpoints.down('xs')]: {
            marginLeft: '5px'
          },
          '&:before': {
            content: '""',
            position: 'absolute',
            left: '-10px',
            bottom: '13px',
            top: '13px',
            width: '1px',
            backgroundColor: '#C7C2BA',
            [theme.breakpoints.down('xs')]: {
              display: 'none'
            }
          }
        }
      }
    }
  },
  props: {
    MuiBreadcrumbs: {
      expandText: 'Mostrar ruta'
    },
    MuiTablePagination: {
      backIconButtonText: 'Página anterior',
      labelRowsPerPage: 'Filas por página:',
      nextIconButtonText: 'Siguiente página'
    },
    MuiRating: {
      emptyLabelText: 'Vacío'
    },
    MuiAutocomplete: {
      clearText: 'Limpiar',
      closeText: 'Cerrar',
      loadingText: 'Cargando…',
      noOptionsText: 'Sin opciones',
      openText: 'Abierto'
    },
    MuiAlert: {
      closeText: 'Cerrar'
    },
    MuiPagination: {
      'aria-label': 'Paginador'
    }
  },
  shadows: [
    'none',
    '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
    '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
    '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
    '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
    '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)'
  ],
  typography: {
    htmlFontSize: 16,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 300,
      fontSize: '6rem',
      lineHeight: 1.167,
      letterSpacing: '-0.01562em'
    },
    h2: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 300,
      fontSize: '3.75rem',
      lineHeight: 1.2,
      letterSpacing: '-0.00833em'
    },
    h3: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '3rem',
      lineHeight: 1.167,
      letterSpacing: '0em'
    },
    h4: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '2.125rem',
      lineHeight: 1.235,
      letterSpacing: '0.00735em'
    },
    h5: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '1.5rem',
      lineHeight: 1.334,
      letterSpacing: '0em'
    },
    h6: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: '1.25rem',
      lineHeight: 1.6,
      letterSpacing: '0.0075em'
    },
    subtitle1: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.75,
      letterSpacing: '0.00938em'
    },
    subtitle2: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: 1.57,
      letterSpacing: '0.00714em'
    },
    body1: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.5,
      letterSpacing: '0.00938em'
    },
    body2: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: 1.43,
      letterSpacing: '0.01071em'
    },
    button: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: 1.75,
      letterSpacing: '0.02857em',
      textTransform: 'uppercase'
    },
    caption: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 1.66,
      letterSpacing: '0.03333em'
    },
    overline: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 2.66,
      letterSpacing: '0.08333em',
      textTransform: 'uppercase'
    }
  },
  shape: {
    borderRadius: 4
  },
  transitions: {
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)'
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195
    }
  },
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1450,
    drawer: 1500,
    modal: 1500,
    snackbar: 1400,
    tooltip: 1500
  }
};
