import { responseMapper } from 'utils/responseMapper';
import { instance } from '../instance';
import {
  responseTemplateAddEvents,
  templateGetEvent,
  responseTemplateUpdateEvents,
  templateGetEventActivities,
  templateGetEventById
} from '../mappers/events';

export const getEvents = async () => {
  const response = await instance.get('/events');

  const resultMappped = responseMapper({
    template: templateGetEvent,
    data: response.data
  });

  const {
    headerResponse: { code },
    payload: { items }
  } = resultMappped;

  if (code !== 200) throw code;

  return items;
};

export const getEventById = async (id) => {
  const response = await instance.get(`events/${id}`);

  const mappedResponse = responseMapper({
    template: templateGetEventById,
    data: response.data
  });

  const {
    headerResponse: { code },
    payload
  } = mappedResponse;

  if (code !== 200) throw code;

  return payload;
};

export const addEvent = async (payload) => {
  const response = await instance.post('events', payload);
  const mappedResponse = responseMapper({
    template: responseTemplateAddEvents,
    data: response.data
  });

  const {
    headerResponse: { code },
    payload: responsePayload
  } = mappedResponse;

  if (code !== 200) throw code;

  return responsePayload;
};

export const deleteEvent = async (id) => {
  const response = await instance.delete(`events/${id}`);

  const resultMappped = responseMapper({
    template: responseTemplateUpdateEvents,
    data: response.data
  });

  const {
    headerResponse: { code }
  } = resultMappped;

  if (code !== 200) throw code;

  return id;
};

export const updateEvent = async (id, payload) => {
  const response = await instance.patch(`/events/${id}`, payload);
  const resultMappped = responseMapper({
    template: responseTemplateUpdateEvents,
    data: response.data
  });

  const {
    headerResponse: { code },
    payload: responsePayload
  } = resultMappped;

  if (code !== 200) throw code;

  return responsePayload;
};

export const getEventConferences = async (id) => {
  const response = await instance.get(`events/${id}/activities`);

  const mappedResponse = responseMapper({
    template: templateGetEventActivities,
    data: response.data
  });

  const {
    headerResponse: { code },
    payload: { items }
  } = mappedResponse;

  if (code !== 200) throw code;

  return items;
};
