import { getFormData } from 'utils/helpers';
import { responseMapper } from 'utils/responseMapper';
import { instance } from '../instance';
import {
  counterTemplateAddConference,
  counterTemplateUpdateConference,
  templateAddConference,
  templateUpdateConference
} from '../mappers';

export const addConference = async (payload) => {
  const mappedPayload = responseMapper({
    template: counterTemplateAddConference,
    data: payload
  });

  const formData = getFormData(mappedPayload);

  const response = await instance.post('/activities', formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });

  const mappedResponse = responseMapper({
    template: templateAddConference,
    data: response.data
  });

  const {
    headerResponse: { code },
    payload: responsePayload
  } = mappedResponse;

  if (code !== 200) throw code;

  return responsePayload;
};

export const updateConference = async (id, payload) => {
  const mappedPayload = responseMapper({
    template: counterTemplateUpdateConference,
    data: payload
  });

  const formData = getFormData(mappedPayload);

  const response = await instance.patch(`/activities/${id}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });

  const mappedResponse = responseMapper({
    template: templateUpdateConference,
    data: response.data
  });

  const {
    headerResponse: { code },
    payload: responsePayload
  } = mappedResponse;

  if (code !== 200) throw code;

  return responsePayload;
};

export const deleteConference = async (id) => {
  const response = await instance.delete(`/activities/${id}`);
  const mappedResponse = responseMapper({
    template: templateUpdateConference,
    data: response.data
  });

  const {
    headerResponse: { code }
  } = mappedResponse;

  if (code !== 200) throw code;

  return id;
};
