import { withRouter } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Typography, Grid } from '@material-ui/core';
import { useStyles } from './OrganizationCardStyles';
import { messages } from './OrganizationCardMessages';
import { Images } from 'assets';
import { useModal } from '@octopy/react-modal';
import { ModalButton, ModalText } from 'components/ModalMessages';
import { useDashboard } from 'components/Dashboard';
import { formatDate } from 'utils/helpers';

const OrganizationCard = withRouter(({ history, organization, index }) => {
  const intl = useIntl();
  const classes = useStyles({ icon: Images.Work });
  const { handleOpenModal } = useModal();
  const { deleteOrganizationById } = useDashboard();

  const handleDeleteClick = () => {
    const modalTitle = (
      <ModalText>
        {intl.formatMessage(messages.areYouSureDelete)}{' '}
        <ModalText white>{organization.name}</ModalText>?
      </ModalText>
    );

    const modalActionButtons = (
      <ModalButton onClick={() => deleteOrganizationById(organization, index)}>
        {intl.formatMessage(messages.delete)}
      </ModalButton>
    );

    handleOpenModal({
      title: modalTitle,
      actionButtons: modalActionButtons,
      configProps: { maxWidth: 'xs' }
    });
  };

  return (
    <Grid item lg={4} md={6} sm={12} xs={12}>
      <div className={classes.container}>
        <div className={classes.info}>
          {organization.logoUrl ? (
            <div className={classes.avatarContainer}>
              <img
                className={classes.avatarImage}
                src={organization.logoUrl}
                alt="avatar"
              />
            </div>
          ) : (
            <div className={classes.imageMaskContainer}>
              <div className={classes.imageMask} />
            </div>
          )}
          <div className={classes.textContainer}>
            <Typography className={classes.title}>
              {organization.name}
            </Typography>
            {organization.date && (
              <Typography className={classes.subtitle}>
                Registro: {formatDate(new Date(organization.date))}
              </Typography>
            )}
          </div>
        </div>
        <div className={classes.actions}>
          <Grid container className={classes.actionsContainer}>
            <Grid
              className={classes.edit}
              alignItems="center"
              item
              container
              onClick={() =>
                history.push({
                  pathname: `/EditOrganization/${organization.id}`,
                  state: { organization, index }
                })
              }
              xs={4}
            >
              <div className={classes.actionButtonImageContainer}>
                <img
                  alt="edit"
                  src={Images.Pencil}
                  className={classes.actionButtonImage}
                />
              </div>
              <Typography component="span">
                {intl.formatMessage(messages.edit)}
              </Typography>
            </Grid>
            <Grid
              className={classes.delete}
              alignItems="center"
              item
              container
              xs={4}
              onClick={handleDeleteClick}
            >
              <div className={classes.actionButtonImageContainer}>
                <img
                  alt="delete"
                  src={Images.Trash}
                  className={classes.actionButtonImage}
                />
              </div>
              <Typography component="span">
                {intl.formatMessage(messages.delete)}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    </Grid>
  );
});

export { OrganizationCard };
