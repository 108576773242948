import { useIntl } from 'react-intl';
import {
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import { useStyles } from './EventsTableStyles';
import { messages } from './EventsTableMessages';
import { Pagination } from '@material-ui/lab';
import { ExpandMore } from '@material-ui/icons';
import { usePagination } from 'hooks/usePagination';

const columns = ['name', 'limitParticipants', 'from', 'to'];

export const EventsTable = ({ events }) => {
  const classes = useStyles();
  const intl = useIntl();

  const {
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage
  } = usePagination(0, 5);

  return (
    <Grid item lg={6} xs={12} className={classes.gridItem}>
      <TableContainer className={classes.container}>
        <Table className={classes.table} stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow className={classes.head}>
              {columns.map((column) => (
                <TableCell
                  className={classes.headCell}
                  key={`${column}Cell`}
                  align="left"
                >
                  {intl.formatMessage(messages[`${column}Label`])}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {!!events.length && (
            <TableBody>
              {events
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((event, i) => (
                  <TableRow key={`event${i}`}>
                    {columns.map((column) => (
                      <TableCell
                        className={classes.bodyCell}
                        key={`event1${column}`}
                        align="left"
                      >
                        <div className={classes.bodyCellText}>
                          {event[column]}
                        </div>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
          )}
        </Table>
        {!events.length && (
          <div className={classes.noEvents}>
            <span>No hay eventos para mostrar</span>
          </div>
        )}
      </TableContainer>
      <div className={classes.paginationContainer}>
        <Pagination
          count={Math.ceil(events.length / rowsPerPage)}
          page={page + 1}
          onChange={handleChangePage}
          defaultPage={0}
          size="small"
          variant="outlined"
          shape="rounded"
        />
        <Select
          className={classes.paginationSelect}
          onChange={handleChangeRowsPerPage}
          value={rowsPerPage}
          IconComponent={ExpandMore}
        >
          {[5, 10, 15, 20].map((n) => (
            <MenuItem key={`${n}PaginationOption`} value={n}>
              {n} / {intl.formatMessage(messages.page)}
            </MenuItem>
          ))}
        </Select>
      </div>
    </Grid>
  );
};
