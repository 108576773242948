import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.Partners.SideDrawer.title',
    defaultMessage: 'Configurar stand'
  },
  editTitle: {
    id: 'components.Partners.SideDrawer.edit.title',
    defaultMessage: 'Actualizar stand'
  },
  oneStep: {
    id: 'components.Partners.SideDrawer.stepOne',
    defaultMessage: 'Ingresar el ID del video'
  },
  twoStep: {
    id: 'components.Partners.SideDrawer.twoStep',
    defaultMessage: 'Color de fondo'
  },
  threeStep: {
    id: 'components.Partners.SideDrawer.threeStep',
    defaultMessage: 'Título llamativo'
  },
  fourStep: {
    id: 'components.Partners.SideDrawer.fourStep',
    defaultMessage: 'Texto breve informativo o promocional'
  },
  fiveStep: {
    id: 'components.Partners.SideDrawer.fiveStep',
    defaultMessage: 'Eslogan de la empresa'
  },
  sixStep: {
    id: 'components.Partners.SideDrawer.sixStep',
    defaultMessage: 'Seleccionar imagen promocional uno'
  },
  sevenStep: {
    id: 'components.Partners.SideDrawer.sevenStep',
    defaultMessage: 'Seleccionar imagen promocional dos'
  },

  submitButtonLabel: {
    id: 'components.Partners.SideDrawer.button.submit.label',
    defaultMessage: 'GUARDAR'
  },
  editSubmitButtonLabel: {
    id: 'components.Partners.SideDrawer.edit.button.submit.label',
    defaultMessage: 'ACTUALIZAR'
  },
  databaseSuccessfullyLoaded: {
    id: 'components.Partners.SideDrawer.modal.databaseSuccessfullyLoaded',
    defaultMessage: 'La base de datos ha sido cargada correctamente'
  },
  success: {
    id: 'components.Partners.SideDrawer.modal.success',
    defaultMessage: 'El stand ha sido actualizado correctamente'
  },
  ok: {
    id: 'components.Partners.SideDrawer.modal.ok',
    defaultMessage: 'Ok'
  },
  standUrlLabel: {
    id: 'components.Partners.SideDrawer.idVideo',
    defaultMessage: 'Id del video'
  },
  isRequiredError: {
    id: 'components.Partners.SideDrawer.isRequiredError',
    defaultMessage: 'Este campo es requerido'
  },
  invalidUrlError: {
    id: 'components.Partners.SideDrawer.invalidUrlError',
    defaultMessage: 'url inválida'
  },
  uploadButtonLabel: {
    id: 'components.Partners.SideDrawer.uploadButtonLabel',
    defaultMessage: 'SUBIR'
  },
  close: {
    id: 'components.Partners.SideDrawer.close',
    defaultMessage: 'CERRAR'
  },
  seeOne: {
    id: 'components.Partners.SideDrawer.seeOne',
    defaultMessage: 'Ver imagen uno'
  },
  seeTwo: {
    id: 'components.Partners.SideDrawer.seeTwo',
    defaultMessage: 'Ver imagen dos'
  }
});
