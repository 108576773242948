import { BaseLayout, FormLayout } from 'layouts';
import {
  Organizations,
  EditForm as OrganizationsEditForm,
  CreateForm as OrganizationsCreateForm
} from '../components/Organizations';
import { Database } from '../components/Database';
import {
  Challenge,
  CreateForm as ChallengeCreateForm
} from '../components/Challenge';
import {
  Principal,
  ConferenceCreateForm,
  ConferenceEditForm
} from '../components/Principal';
import {
  Exhibition,
  ExhibitionCreateForm,
  ExhibitionEditForm
} from 'components/Exhibition';
import { Quiz, CreateFormQuiz, EditFormQuiz } from 'components/Quiz';
import { Events, EventsCreateForm, EventsEditForm } from 'components/Events';
import {
  Coordinators,
  CoordinatorsCreateForm,
  CoordinatorsEditForm
} from 'components/Coordinators';
import {
  Partners,
  PartnersCreateForm,
  PartnersEditForm
} from 'components/Partners';
import {
  Licenses,
  LicensesCreateForm,
  LicensesEditForm
} from 'components/Licenses';
import { Analytics } from 'components/Analytics/Analytics';

export const routes = [
  {
    path: '/Events',
    layout: BaseLayout,
    component: Events,
    exact: true
  },
  {
    path: '/Organizations',
    layout: BaseLayout,
    component: Organizations,
    exact: true
  },
  {
    path: '/Database',
    layout: BaseLayout,
    component: Database,
    exact: true
  },
  {
    path: '/Challenge',
    layout: BaseLayout,
    component: Challenge,
    exact: true
  },
  {
    path: '/Coordinators',
    layout: BaseLayout,
    component: Coordinators,
    exact: true
  },
  {
    path: '/EditCoordinator/:idCoordinator',
    layout: FormLayout,
    component: CoordinatorsEditForm,
    exact: true
  },
  {
    path: '/EditEvent/:idEvent',
    layout: FormLayout,
    component: EventsEditForm,
    exact: true
  },
  {
    path: '/CreateEvent',
    layout: FormLayout,
    component: EventsCreateForm,
    exact: true
  },
  {
    path: '/Partners',
    layout: BaseLayout,
    component: Partners,
    exact: true
  },
  {
    path: '/Exhibition',
    layout: BaseLayout,
    component: Exhibition,
    exact: true
  },
  {
    path: '/Principal',
    layout: BaseLayout,
    component: Principal,
    exact: true
  },
  {
    path: '/CreateOrganization',
    layout: FormLayout,
    component: OrganizationsCreateForm,
    exact: true
  },
  {
    path: '/EditOrganization/:organizationId',
    layout: FormLayout,
    component: OrganizationsEditForm,
    exact: true
  },
  {
    path: '/CreateCoordinator',
    layout: FormLayout,
    component: CoordinatorsCreateForm,
    exact: true
  },
  {
    path: '/CreateExhibition',
    layout: FormLayout,
    component: ExhibitionCreateForm,
    exact: true
  },
  {
    path: '/EditExhibition/:idProduct',
    layout: FormLayout,
    component: ExhibitionEditForm,
    exact: true
  },
  {
    path: '/CreateChallenge',
    layout: BaseLayout,
    component: ChallengeCreateForm,
    exact: true
  },
  {
    path: '/CreatePartner',
    layout: FormLayout,
    component: PartnersCreateForm,
    exact: true
  },
  {
    path: '/EditPartner/:idPartner',
    layout: FormLayout,
    component: PartnersEditForm,
    exact: true
  },
  {
    path: '/CreateConferences',
    layout: BaseLayout,
    component: ConferenceCreateForm,
    exact: true
  },
  {
    path: '/EditConferences/:eventId',
    layout: BaseLayout,
    component: ConferenceEditForm,
    exact: true
  },
  {
    path: '/Quiz',
    layout: BaseLayout,
    component: Quiz,
    exact: true
  },
  {
    path: '/CreateQuiz',
    layout: BaseLayout,
    component: CreateFormQuiz,
    exact: true
  },
  {
    path: '/EditQuiz/:idEvent',
    layout: BaseLayout,
    component: EditFormQuiz,
    exact: true
  },
  {
    path: '/Licenses',
    layout: BaseLayout,
    component: Licenses,
    exact: true
  },
  {
    path: '/CreateLicense',
    layout: FormLayout,
    component: LicensesCreateForm,
    exact: true
  },
  {
    path: '/EditLicense/:idLicense',
    layout: FormLayout,
    component: LicensesEditForm,
    exact: true
  },
  {
    path: '/Analytics',
    layout: BaseLayout,
    component: Analytics,
    exact: true
  }
];
