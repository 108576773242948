import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  submitButton: {
    backgroundColor: '#009FDA',
    minWidth: '120px',
    height: '40px',
    marginLeft: '8px',
    borderRadius: '8px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      width: '80%'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  }
}));
