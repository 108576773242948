import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '169px',
    boxShadow: '0px 0px 5px #00000029',
    borderRadius: '5px',
    maxWidth: '400px',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: '#2AA4D5',
    transition: 'all 300ms',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#00040F',
      '& $imageMask': {
        transition: '.3s ease',
        backgroundColor: '#00040F'
      }
    }
  },
  imageMaskContainer: {
    height: '80px',
    width: '80px',
    borderRadius: '50%',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(2),
    boxShadow: '0px 3px 6px #00000029',
    overflow: 'hidden'
  },
  imageMask: ({ icon }) => ({
    height: '60%',
    width: '60%',
    backgroundColor: '#009FDA',
    transition: 'all 300ms',
    maskImage: `url("${icon}")`,
    maskSize: '41px',
    maskRepeat: 'no-repeat',
    maskPosition: 'center'
  }),
  title: {
    font: 'normal normal bold 15px/20px Roboto',
    marginBottom: theme.spacing(0.5),
    color: '#fff'
  },
  subtitle: {
    font: 'normal normal normal 15px/20px Roboto',
    color: '#FFFFFF7D'
  },
  info: {
    height: '75%',
    display: 'flex',
    padding: theme.spacing(2)
  },
  actions: {
    height: '25%',
    display: 'flex',
    alignItems: 'center',
    borderTop: '1px solid #E6E6E6'
  },
  addIcon: {
    color: '#fff',
    borderRadius: '50%',
    padding: '5px',
    fontSize: '39px',
    transition: 'all 200ms',
    marginRight: '5px',
    cursor: 'pointer',
    backgroundColor: '#ffffff20'
  }
}));

export { useStyles };
