import { Typography } from '@material-ui/core';
import { useStyles } from './LabelStyles';

export const Label = ({ className, numeration, children }) => {
  const classes = useStyles();

  return (
    <Typography className={`${classes.label} ${className}`} component="div">
      {numeration && <span className={classes.numeration}>{numeration} )</span>}
      {children}
    </Typography>
  );
};
