import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  import: {
    marginTop: '23px',
    marginBottom: '25px'
  },
  importTitle: {
    font: 'normal normal bold 15px/20px Roboto',
    color: '#323232'
  },
  importDescription: {
    marginTop: '11px',
    font: 'normal normal normal 15px/16px Roboto',
    color: '#00040F7F'
  },
  fileInput: {
    marginTop: '17px'
  },
  submitButton: {
    margin: '20px 0 25px 0',
    width: '136px',
    height: '40px'
  }
}));
