import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  label: {
    font: 'normal normal normal 15px/20px Roboto',
    lineHeight: '30px',
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
      lineHeight: '20px'
    }
  },
  numeration: {
    marginRight: '10px',
    font: 'normal normal normal 15px/20px Roboto',
    fontWeight: 'bolder'
  }
}));
