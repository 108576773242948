import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.Organizations.EditForm.Left.editTitle',
    defaultMessage: 'Editar organizacion'
  },
  imageLabel: {
    id: 'components.Organizations.EditForm.Left.label.image',
    defaultMessage: 'Seleccionar una imagen'
  },
  dataLabel: {
    id: 'components.Organizations.EditForm.Left.label.data',
    defaultMessage: 'Agregar los datos necesarios para crear un registro'
  },
  countryLabel: {
    id: 'components.Organizations.CreateForm.Left.label.country',
    defaultMessage: 'Agregar pais al que pertenece la organizacion'
  },
  participantsLabel: {
    id: 'components.Organizations.EditForm.Left.label.participants',
    defaultMessage: 'Agregar no. de participantes'
  },
  sponsorsLabel: {
    id: 'components.Organizations.EditForm.Left.label.sponsors',
    defaultMessage: 'Agregar no. de patrocinadores'
  }
});
