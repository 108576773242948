import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  drawer: {
    width: '443px',
    padding: '35px 50px 66px 50px',
    boxSizing: 'border-box',
    position: 'relative',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      padding: '17px 25px 33px 25px'
    }
  },
  closeIconContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: '20px',
    color: theme.palette.secondary.main
  },
  closeIcon: {
    cursor: 'pointer',
    transition: 'color 300ms',
    padding: '5px',
    boxSizing: 'content-box',
    '&:hover': {
      color: '#00040F'
    }
  },
  title: {
    font: 'normal normal bold 21px/28px Roboto',
    color: '#00040F',
    marginBottom: '27px'
  },
  upload: {
    display: 'flex',
    alignItems: 'center'
  },
  button: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '8px',
    color: '#fff',
    fontWeight: 'bolder',
    padding: '9px 25px 9px 25px',
    marginBottom: '10px',
    transition: 'all 300ms',
    '&:hover': {
      backgroundColor: '#00040F'
    }
  },
  submitButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '50px'
  },
  fileName: {
    color: '#00040F7F',
    font: 'normal normal normal 15px/20px Roboto',
    maxWidth: '60%'
  },
  fileInput: {
    visibility: 'hidden',
    display: 'block',
    height: 0,
    width: '5px',
    pointerEvents: 'none'
  },
  steps: {
    font: 'normal normal normal 15px/20px Roboto',
    color: '#000000',
    marginTop: '26px',
    marginBottom: '7px'
  },
  textEditor: {
    marginTop: '56px'
  },
  modalImage: {
    height: '75%',
    width: '75%'
  },
  see: {
    font: 'normal normal normal 15px/20px Roboto',
    cursor: 'pointer'
  },
  containerVideo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%'
  },
  radioButtonsContainer: {
    width: '40%',
    marginTop: '8px',
    marginLeft: '15px',
    marginRight: '5px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '-3px'
    }
  },
  centerRadio: {
    width: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  sizeLogo: {
    marginLeft: '-10px',
    width: '30px',
    height: '30px'
  },
  logoVimeo: {
    marginLeft: '-10px',
    width: '25px',
    height: '25px'
  }
}));
