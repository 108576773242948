import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: '6px',
    marginRight: '6px',
    marginBottom: '18px',
    '& > div': {
      borderRadius: '5px',
      backgroundColor: '#F9F9F9',
      '&.Mui-focused': {
        backgroundColor: '#F9F9F9 !important'
      },
      '&:hover': {
        backgroundColor: '#F3F3F3 !important'
      }
    },
    '& *': {
      '&::before': {
        display: 'none'
      },
      '&::after': {
        display: 'none'
      }
    },
    '& .MuiFormHelperText-root': {
      marginBottom: '-10px'
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      width: '212px'
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      width: '80%'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
    phone: {
      color: 'white'
    }
  }
}));
