import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  country: {
    id: 'components.Events.Cards.Edit.country',
    defaultMessage: 'Pais'
  },
  time: {
    id: 'components.Events.Cards.Edit.time',
    defaultMessage: 'Hora'
  },
  date: {
    id: 'components.Events.Cards.Edit.date',
    defaultMessage: 'Fecha'
  },
  edit: {
    id: 'components.Events.Cards.Edit.edit',
    defaultMessage: 'Editar'
  },
  delete: {
    id: 'components.Events.Cards.Edit.delete',
    defaultMessage: 'Eliminar'
  },
  areYouSureDelete: {
    id: 'components.Events.Cards.Edit.areYouSureDelete',
    defaultMessage: '¿ESTÁS SEGURO DE QUERER ELIMINAR EL EVENTO:'
  }
});
