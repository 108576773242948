import { useIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { useFormik } from 'formik';
import {
  Row,
  RightContainer,
  ImagePicker,
  TextField,
  TextFieldPhone,
  SubmitButton
} from 'components/CreationForm';
import { Person } from '@material-ui/icons';
import { useRowsStyles } from '../RowsStyles';
import { messages } from './RightMessages';
import { initialValuesForm, getValidationSchema } from './helper';
import { useDashboard } from 'components/Dashboard';
import { useStyles } from './RightStyles';

const Right = withRouter(({ history }) => {
  const rowsClasses = useRowsStyles();
  const classes = useStyles();
  const intl = useIntl();
  const { addNewCoordinator } = useDashboard();

  const formik = useFormik({
    initialValues: initialValuesForm,
    validationSchema: getValidationSchema(intl),
    onSubmit: async (values) => {
      const dataCoordinatorWithRole = {
        ...values,
        role: 'coordinator'
      };

      const error = await addNewCoordinator(dataCoordinatorWithRole);

      if (!!!error) history.goBack();
    }
  });

  const submitButton = (
    <Row>
      <SubmitButton
        label={intl.formatMessage(messages.submitButtonLabel)}
        onClick={formik.handleSubmit}
      />
    </Row>
  );

  return (
    <RightContainer submitButton={submitButton}>
      <Row className={rowsClasses[1]}>
        <ImagePicker
          id="coordinatorsCreateLogo"
          onChange={(logo) => {
            formik.setFieldValue('logoUrl', logo.blobUrl);
            formik.setFieldValue('logo', logo);
          }}
          Icon={Person}
          label={intl.formatMessage(messages.logoUrlLabel)}
          name="logoUrl"
          imageUrl={formik.values.logoUrl || formik.values.logo?.blobUrl}
        />
      </Row>
      <Row className={rowsClasses[2]}>
        {['userName', 'completeName'].map((name) => (
          <TextField
            className={classes.input}
            key={`${name}Input`}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors[name]}
            value={formik.values[name]}
            touched={formik.touched[name]}
            label={intl.formatMessage(messages[`${name}Label`])}
            name={name}
          />
        ))}
        <TextFieldPhone
          key={`contactPhoneInput`}
          onChange={(phone) =>
            formik.setFieldValue('contactPhone', phone, true)
          }
          onBlur={formik.handleBlur}
          error={formik.errors['contactPhone']}
          value={formik.values['contactPhone']}
          touched={formik.touched['contactPhone']}
          placeholder={intl.formatMessage(messages['contactPhoneLabel'])}
          name={'contactPhone'}
          className={classes.input}
        />
        <TextField
          className={classes.input}
          key={'emailInput'}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors['email']}
          value={formik.values['email']}
          touched={formik.touched['email']}
          label={intl.formatMessage(messages['emailLabel'])}
          name={'email'}
        />
      </Row>
    </RightContainer>
  );
});

export { Right };
