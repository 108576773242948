import { ArrowBack } from '@material-ui/icons';
import { withRouter } from 'react-router';
import { useStyles } from './GoBackStyles';

export const GoBack = withRouter(
  ({ history, containerClassName, iconClassName }) => {
    const classes = useStyles();

    return (
      <div className={`${classes.goBackIconContainer} ${containerClassName}`}>
        <ArrowBack
          onClick={() => history.goBack()}
          className={`${classes.goBackIcon} ${iconClassName}`}
        />
      </div>
    );
  }
);
