export const templateGetAnalytics = {
  headerResponse: {
    code: 'headerResponse.code',
    message: 'headerResponse.message'
  },
  payload: {
    usersQuantity: 'payload.usersCount',
    eventsQuantity: 'payload.eventsCount',
    partnersByEvent: [
      'payload.partnersByEvent',
      {
        eventName: 'name',
        partnersQuantity: 'count'
      }
    ],
    activitiesByEvent: [
      'payload.activitiesByEvent',
      {
        eventName: 'name',
        activitiesQuantity: 'count'
      }
    ],
    participantsByEvent: [
      'payload.participantsByEvent',
      {
        eventName: 'name',
        participantsQuantity: 'count'
      }
    ],
    events: [
      'payload.events',
      {
        name: 'name',
        from: 'from',
        to: 'to',
        limitParticipants: 'limit_participants'
      }
    ]
  }
};
