import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  drawer: {
    zIndex: '1490 !important'
  },
  container: {
    width: '460px',
    padding: '0 5px 10px 5px',
    boxSizing: 'border-box',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
      maxWidth: '100vw',
      padding: '25px 5px 10px 5px'
    },
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  header: {
    position: 'sticky',
    backgroundColor: 'white',
    top: 0,
    left: 0,
    right: 0,
    display: 'flex',
    zIndex: 1,
    alignItems: 'center',
    padding: '18px 10px 18px 40px',
    [theme.breakpoints.down('xs')]: {
      padding: '0 10px 0 20px'
    }
  },
  closeIconContainer: {
    color: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center'
  },
  timeline: {
    margin: 0
  },
  closeIcon: {
    cursor: 'pointer',
    transition: 'color 300ms',
    padding: '5px',
    boxSizing: 'content-box',
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  title: {
    font: 'normal normal bold 21px/28px Roboto',
    color: theme.palette.primary.main,
    flexGrow: 1
  },
  dot: {
    backgroundColor: '#2AA4D5 !important',
    boxShadow: '0px 3px 6px #00000029 !important',
    width: '30px',
    height: '30px'
  },
  connector: {
    width: '2px',
    borderRadius: '20px',
    backgroundColor: '#2AA4D5 !important',
    minHeight: '70px !important',
    [theme.breakpoints.down('xs')]: {
      minHeight: '120px !important'
    }
  },
  date: {
    font: 'normal normal bold 18px/24px Roboto',
    color: theme.palette.primary.main
  },
  dataContainer: {
    padding: '11px',
    backgroundColor: '#F9F9F9',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '5px',
    width: '158px',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1)
    }
  },
  name: {
    font: 'normal normal bold 15px/20px Roboto',
    color: theme.palette.primary.main,
    display: 'block',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    overflow: 'hidden'
  },
  info: {
    font: 'normal normal bold 12px/16px Roboto',
    color: '#00040F7F',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  noNextEvents: {
    textAlign: 'center',
    color: theme.palette.secondary.main,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 1,
    marginTop: '-80px',
    fontSize: '16px',
    '& img': {
      marginBottom: '20px',
      height: '45px',
      width: '45px'
    }
  }
}));
