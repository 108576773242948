import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.Database.UploadCard.title',
    defaultMessage: 'CARGAR'
  },
  subtitle: {
    id: 'components.Database.UploadCard.subtitle',
    defaultMessage: 'Base de datos'
  },
  downloadExample: {
    id: 'components.Database.downloadExample',
    defaultMessage: 'Descargar ejemplo de base de datos'
  }
});
