import { createContext, useMemo, useContext } from 'react';
import { availableCountry, americaCountries } from './provider';

const CountriesContext = createContext();

export function CountriesProvider(props) {
  const values = useMemo(
    () => ({
      availableCountry,
      americaCountries
    }),
    [availableCountry, americaCountries]
  );

  return <CountriesContext.Provider value={values} {...props} />;
}

export function useCountries() {
  const context = useContext(CountriesContext);

  if (!context) {
    throw new Error(
      'useCountries debe estar dentro del provedor CountriesContext'
    );
  }

  return context;
}
