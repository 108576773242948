import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  accordionLabel: {
    id:
      'components.Principal.ConferencesForms.Create.Conferences.accordionLabel',
    defaultMessage: 'Conferencia programada'
  },
  imageLabel: {
    id:
      'components.Principal.ConferencesForms.Create.Conferences.input.photo.label',
    defaultMessage: 'Seleccionar foto'
  },
  speakerNameLabel: {
    id:
      'components.Principal.ConferencesForms.Edit.Conferences.input.speakerName.label',
    defaultMessage: 'Nombre del ponente'
  },
  speakerResumeLabel: {
    id:
      'components.Principal.ConferencesForms.Edit.Conferences.input.speakerResume.label',
    defaultMessage: 'Resumen del ponente'
  },
  speakerEducationLabel: {
    id:
      'components.Principal.ConferencesForms.Edit.Conferences.input.speakerEducation.label',
    defaultMessage: 'Educacion del ponente'
  },
  speakerBackgroundLabel: {
    id:
      'components.Principal.ConferencesForms.Edit.Conferences.input.speakerBackground.label',
    defaultMessage: 'Dedicacion del ponente'
  },
  dateLabel: {
    id:
      'components.Principal.ConferencesForms.Create.Conferences.input.date.label',
    defaultMessage: 'Fecha del evento'
  },
  timeLabel: {
    id:
      'components.Principal.ConferencesForms.Create.Conferences.input.time.label',
    defaultMessage: 'Hora del evento'
  },
  descriptionLabel: {
    id:
      'components.Principal.ConferencesForms.Create.Conferences.input.description.label',
    defaultMessage: 'Descripcion'
  },
  streamIdLabel: {
    id:
      'components.Principal.ConferencesForms.Create.Conferences.input.streamIframe.label',
    defaultMessage: 'ID del stream'
  },
  chatIframeLabel: {
    id:
      'components.Principal.ConferencesForms.Create.Conferences.input.chatIframe.label',
    defaultMessage: 'Iframe del chat'
  },
  doYouWantToAdd: {
    id:
      'components.Principal.ConferencesForms.Create.Conferences.modal.doYouWantToAdd',
    defaultMessage:
      '¿Quieres agregar esta conferencia antes de seguir creando otras?'
  },
  yes: {
    id: 'components.Principal.ConferencesForms.Create.Conferences.modal.yes',
    defaultMessage: 'Sí'
  },
  anticipationError: {
    id:
      'components.Principal.ConferencesForms.Create.Conferences.anticipationError',
    defaultMessage: 'Debe tener una hora de anticipacion'
  }
});
