import { makeStyles } from '@material-ui/core';

export const useRowsStyles = makeStyles((theme) => ({
  1: {
    height: '195px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  },
  2: {
    height: '170px'
  },
  3: {
    height: '70px'
  },
  4: {
    height: '70px'
  }
}));
