import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.Licenses.LicenseHistory.title',
    defaultMessage: 'Historial de licencia'
  },
  renewalTitle: {
    id: 'components.Licenses.LicenseHistory.renewal.title',
    defaultMessage: 'Renovación'
  },
  renewalDescription: {
    id: 'components.Licenses.LicenseHistory.renewal.description',
    defaultMessage: 'Se renovó la suscripción antes de fecha de suspensión'
  },
  activationTitle: {
    id: 'components.Licenses.LicenseHistory.activation.title',
    defaultMessage: 'Activación'
  },
  activationDescription: {
    id: 'components.Licenses.LicenseHistory.activation.description',
    defaultMessage: 'Se inició la activación de la suscripción'
  },
  suspensionTitle: {
    id: 'components.Licenses.LicenseHistory.suspension.title',
    defaultMessage: 'Suspensión'
  },
  suspensionDescription: {
    id: 'components.Licenses.LicenseHistory.suspension.description',
    defaultMessage: 'Se suspendió la suscripción. Fecha de suspensión excedida'
  },
  reactivationTitle: {
    id: 'components.Licenses.LicenseHistory.reactivation.title',
    defaultMessage: 'Re-activación'
  },
  reactivationDescription: {
    id: 'components.Licenses.LicenseHistory.reactivation.description',
    defaultMessage:
      'Se ha reactivado la suscripción. Fecha de suspensión reasignada'
  }
});
