import { useIntl } from 'react-intl';
import { useStyles } from './ChallengeStyles';
import { messages } from './ChallengeMessages';
import { Questions } from '../Questions/Questions';

export const Challenge = ({ questions, correctAnswers, setCorrectAnswers }) => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Questions
        room="challenge"
        title={intl.formatMessage(messages.accordionTitle)}
        numberAnswers={5}
        correctAnswers={correctAnswers}
        setCorrectAnswer={setCorrectAnswers}
        questions={questions}
      />
    </div>
  );
};
