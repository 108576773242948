import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.Coordinators.Forms.Edit.Left.title',
    defaultMessage: 'Editar usuario coordinador'
  },
  imageLabel: {
    id: 'components.Coordinators.Forms.Edit.Left.imageLabel',
    defaultMessage: 'Seleccionar una imagen'
  },
  dataLabel: {
    id: 'components.Coordinators.Forms.Edit.Left.dataLabel',
    defaultMessage: 'Agregar los datos necesarios para crear un registro'
  }
});
