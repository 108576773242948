import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  '404Title': {
    id: `views.ErrorHTTP.404Title`,
    defaultMessage: 'Pagina no encontrada'
  },
  '404Description': {
    id: `views.ErrorHTTP.404Description`,
    defaultMessage: 'La página no existe o el contenido ya no está disponible'
  },
  '401Title': {
    id: `views.ErrorHTTP.401Title`,
    defaultMessage: 'Sin permisos necesarios'
  },
  '401Description': {
    id: `views.ErrorHTTP.401Description`,
    defaultMessage:
      'No cuentas con los permisos necesarios para el acceso a esta pagina'
  },
  button: {
    id: `views.ErrorHTTP.button`,
    defaultMessage: 'Ir a la página de inicio'
  }
});
