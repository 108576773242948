import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Typography, Grid, Box, Select, MenuItem } from '@material-ui/core';
import { messages } from './PartnersMessages';
import { useStyles } from './PartnersStyles';
import { Edit as Partner } from 'components/Partners/Cards/Edit';
import { Create as CreatePartnerCard } from 'components/Partners/Cards/Create';
import { useDashboard } from 'components/Dashboard';
import { RefreshControl } from 'components/RefreshControl/RefreshControl';
import { useNoEvents } from 'components/NoEvents/hooks/useNoEvents';
import { Pagination } from '@material-ui/lab';
import { ExpandMore } from '@material-ui/icons';
import { usePagination } from 'hooks/usePagination';

const Partners = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { show, handleNoEventShow } = useNoEvents();

  const { partners, fetchPartners } = useDashboard();

  const getData = () => {
    fetchPartners();
    handleNoEventShow(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const {
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage
  } = usePagination();

  return (
    <>
      <Grid>
        <Grid container direction="row" justify="space-between">
          <Grid>
            <Typography className={classes.title}>
              {intl.formatMessage(messages.title)}
            </Typography>
            <Typography className={classes.subTitle}>
              {intl.formatMessage(messages.subTitle)}
            </Typography>
          </Grid>
          <RefreshControl refreshFunction={getData} />
        </Grid>
        {!show && (
          <Box className={classes.cardsContainer}>
            <Grid container justify="flex-start" spacing={3}>
              {page === 0 && <CreatePartnerCard />}
              {partners.map((partnerData, index) => (
                <Partner
                  key={partnerData.id}
                  idPartner={partnerData.id}
                  partner={partnerData}
                  index={index}
                />
              ))}
            </Grid>

            <div className={classes.paginationContainer}>
              <Pagination
                count={Math.ceil((partners.length + 1) / rowsPerPage)}
                page={page + 1}
                onChange={handleChangePage}
                defaultPage={0}
                size="small"
                variant="outlined"
                shape="rounded"
              />
              <Select
                className={classes.paginationSelect}
                onChange={handleChangeRowsPerPage}
                value={rowsPerPage}
                IconComponent={ExpandMore}
              >
                {[3, 9, 15, 21, 27].map((n) => (
                  <MenuItem key={`${n}PaginationOption`} value={n}>
                    {n} / {intl.formatMessage(messages.page)}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </Box>
        )}
      </Grid>
    </>
  );
};

export { Partners };
