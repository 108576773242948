import { useIntl } from 'react-intl';
import { useState, useEffect } from 'react';
import { Box, Drawer, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useModal } from '@octopy/react-modal';
import { useStyles } from './ProfileStyles';
import { messages } from './ProfileMessages';
import { TextField, SubmitButton, ImagePicker } from 'components/CreationForm';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Person } from '@material-ui/icons';
import { useLoader } from '@octopy/react-loader';
import { useDashboard } from 'components/Dashboard';
import { organizedUserInformation } from './helper';
import { changeUserPassword } from 'providers/api';
import {
  createSuccessModalConfig,
  createErrorModalConfig,
  getDataError
} from 'utils/helpers';

const company = {
  name: 'My Motion',
  image: null,
  admin: 'Admin Gral',
  roomUrl: 'http://rooms.octopy.com/mymottion/',
  email: 'mymottion.rooms@dominio.com',
  activationDate: 'dd/mm/aaaa',
  eventTypes: 'Eventos masivos',
  license: 'Normal',
  subscriptionState: 'Activa'
};

export const Profile = ({ visible, handleHide }) => {
  const intl = useIntl();
  const classes = useStyles();
  const { handleOpenModal } = useModal();
  const {
    updateDataActiveUser,
    logoutPasswordChange,
    logoutUser,
    user
  } = useDashboard();
  const [changeEmail, setChangeEmail] = useState(false);
  const { handleShowLoader } = useLoader();

  useEffect(() => {
    formik.setValues({
      logoUrl: user.avatar,
      email: user.email,
      currentPassword: '',
      newPassword: '',
      newPasswordConfirmation: ''
    });
  }, [visible]);

  const handleChangePassword = async (values, initialValues) => {
    let updateFlag = false;
    let passwordChanged = false;
    const valuesChanged = {};

    if (values.file) {
      updateFlag = true;
      valuesChanged.file = values.file.file;
    }

    if (values.newPassword && values.newPasswordConfirmation) {
      updateFlag = true;
      passwordChanged = true;
      valuesChanged.password = values.newPassword;
    }

    if (user.email !== formik.values.email.trim()) {
      updateFlag = true;
      valuesChanged.email = formik.values.email;
    }

    if (!updateFlag) return;
    try {
      handleShowLoader(true);
      const actions = organizedUserInformation(valuesChanged, user.email);
      const token = localStorage.getItem('token');

      const updateFunctions = actions.map(({ type, payload }) => async () => {
        try {
          if (type === 'updatePassword') {
            await changeUserPassword(payload, token);
          } else if (type === 'updateInformation') {
            await updateDataActiveUser(payload);
          }
        } catch (request) {
          throw request;
        }
      });

      const responses = await Promise.all(updateFunctions.map((fn) => fn()));

      if (passwordChanged) logoutPasswordChange();

      handleHide();

      handleOpenModal(
        createSuccessModalConfig(messages.changesMadeSuccessfully, '', 'ok')
      );
    } catch (request) {
      const { code, message } = getDataError(request);

      handleOpenModal(createErrorModalConfig(code, message));
    } finally {
      handleShowLoader(false);
    }
  };

  const requiredErrorMessage = intl.formatMessage(messages.requiredError);
  const differentPasswordError = intl.formatMessage(messages.differentPassword);
  const invalidEmailError = intl.formatMessage(messages.invalidEmailError);
  const minLengthError = intl.formatMessage(messages.minLengthError);

  const formik = useFormik({
    initialValues: {
      logoUrl: user.avatar,
      email: user.email,
      currentPassword: '',
      newPassword: '',
      newPasswordConfirmation: ''
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email(invalidEmailError)
        .required(requiredErrorMessage),
      newPassword: yup.string().trim().min(8, minLengthError),
      newPasswordConfirmation: yup.string().when('newPassword', {
        is: (val) => (val && val.length > 0 ? true : false),
        then: yup
          .string()
          .required(requiredErrorMessage)
          .oneOf([yup.ref('newPassword')], differentPasswordError)
      })
    }),
    onSubmit: handleChangePassword
  });

  const createURL = () =>
    `http://rooms.octopy.com/${user?.name.replace(' ', '').toLowerCase()}`;

  const completeZero = (number) => (number < 10 ? `0${number}` : `${number}`);

  const createDate = () => {
    if (user?.created_at) {
      const convertedDate = new Date(user?.created_at);

      return `${convertedDate.getFullYear()}/${completeZero(
        convertedDate.getMonth() + 1
      )}/${completeZero(convertedDate.getDate() + 1)}`;
    }
  };
  const handleChangeEmail = () => {
    setChangeEmail(!changeEmail);
  };
  const onPressEmail = (event) => {
    if (event.key === 'Enter') {
      handleChangeEmail();
    }
  };

  return (
    <Drawer
      anchor="right"
      open={visible}
      onClose={handleHide}
      className={classes.drawer}
    >
      <div className={classes.container}>
        <div className={classes.content}>
          <div className={classes.closeIconContainer}>
            <Close className={classes.closeIcon} onClick={handleHide} />
          </div>

          <Typography className={classes.title}>
            {intl.formatMessage(messages.title)}
          </Typography>

          <div className={classes.subscriptionState}>
            {intl.formatMessage(messages.subscriptionState)}{' '}
            {company.subscriptionState}
          </div>

          <Box p={2} className={classes.dataContainer}>
            <div className={classes.imageContainer}>
              <ImagePicker
                onChange={(logo) => {
                  formik.setFieldValue('logoUrl', null);
                  formik.setFieldValue('file', logo);
                }}
                Icon={Person}
                name="logoUrl"
                imageUrl={formik.values.logoUrl || formik.values.file?.blobUrl}
              />
            </div>
            <div className={classes.name}>{user.name}</div>
            <div className={classes.text}>
              {user.role.name.toString().toUpperCase()}
            </div>
            <div className={classes.text}>{createURL()}</div>
            <div className={classes.text}>
              {intl.formatMessage(messages.email)}{' '}
              {!changeEmail ? (
                <Box onClick={handleChangeEmail}>
                  <span className={classes.grayText}>
                    {formik.values['email']}
                  </span>
                </Box>
              ) : (
                <TextField
                  key={'emailInput'}
                  label={intl.formatMessage(messages.emailLabel)}
                  className={classes.emailInput}
                  name={'email'}
                  value={formik.values['email']}
                  touched={formik.touched['email']}
                  error={formik.errors['email']}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onKeyPress={onPressEmail}
                />
              )}
            </div>
            <div className={classes.text}>
              {intl.formatMessage(messages.activationDate)}{' '}
              <span className={classes.grayText}>{createDate()}</span>
            </div>
          </Box>

          <div className={classes.inputContainer}>
            {['newPassword', 'newPasswordConfirmation'].map((name) => (
              <TextField
                disabled={
                  (name === 'newPasswordConfirmation' &&
                    formik.values.newPassword.length > 0) ||
                  name === 'newPassword'
                    ? false
                    : true
                }
                key={`${name}Input`}
                type="password"
                className={classes.input}
                label={intl.formatMessage(messages[`${name}Label`])}
                name={name}
                value={formik.values[name]}
                touched={formik.touched[name]}
                error={formik.errors[name]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            ))}
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <SubmitButton
            onClick={formik.handleSubmit}
            className={classes.button}
            label={intl.formatMessage(messages.changePasswordButtonLabel)}
          />
          <SubmitButton
            onClick={logoutUser}
            className={`${classes.button} closeSession`}
            label={intl.formatMessage(messages.closeSessionButtonLabel)}
          />
        </div>
      </div>
    </Drawer>
  );
};
