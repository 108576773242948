import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { Header } from 'components/Header';
import { Menu } from 'components/Menu';
import { Modal as LibModal } from '@octopy/react-modal';
import { Loader as LibLoader } from '@octopy/react-loader';
import { BasicLoader } from '@octopy/react-loader';
import { useStyles } from './BaseLayoutStyles';
import { useNoEvents } from 'components/NoEvents/hooks/useNoEvents';
import { NoEvents } from 'components/NoEvents/NoEvents';

function BaseLayout({ children }) {
  const classes = useStyles();
  const { show } = useNoEvents();

  return (
    <>
      <LibLoader>
        <BasicLoader />
      </LibLoader>
      <LibModal />
      <Header />
      <Box className={classes.container}>
        <Menu />
        <Box className={classes.content}>
          {children}
          {show && <NoEvents />}
        </Box>
      </Box>
    </>
  );
}

BaseLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export { BaseLayout };
