import { useState } from 'react';
import { NoEventsContext } from './NoEventsContext';

export const NoEventsProvider = ({ children }) => {
  const [show, setShow] = useState(false);

  const handleNoEventShow = (visible) => setShow(visible);

  const contextValue = {
    show,
    handleNoEventShow
  };

  return (
    <NoEventsContext.Provider value={contextValue}>
      {children}
    </NoEventsContext.Provider>
  );
};
