import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  chartBrother: {
    '& + div': {
      overflowX: 'auto',
      overflowY: 'hidden'
    }
  },
  yTitle: {
    font: 'normal normal normal 10px/12px Verdana',
    color: '#323232'
  },
  xLabels: {
    font: 'normal normal normal 8px/10px Verdana',
    color: '#323232'
  },
  noData: {
    color: theme.palette.secondary.main,
    fontSize: '15px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: '-10px',
    '& img': {
      marginBottom: '10px',
      height: '45px',
      width: '45px'
    }
  }
}));
