import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  drawer: {
    zIndex: '1450 !important'
  },
  container: {
    width: '460px',
    padding: '0 5px 10px 5px',
    boxSizing: 'border-box',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
      maxWidth: '100vw',
      padding: '25px 5px 10px 5px'
    }
  },
  header: {
    position: 'sticky',
    backgroundColor: 'white',
    top: 0,
    left: 0,
    right: 0,
    height: '80px',
    display: 'flex',
    zIndex: 1,
    alignItems: 'center',
    padding: '0 10px 0 40px',
    [theme.breakpoints.down('xs')]: {
      padding: '0 10px 0 20px'
    }
  },
  closeIconContainer: {
    color: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center'
  },
  timeline: {
    margin: 0
  },
  closeIcon: {
    cursor: 'pointer',
    transition: 'color 300ms',
    padding: '5px',
    boxSizing: 'content-box',
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  title: {
    font: 'normal normal bold 21px/28px Roboto',
    color: theme.palette.primary.main,
    flexGrow: 1
  },
  dot: {
    boxShadow: `
      inset 0px 3px 6px #00000029,
      0px 3px 6px #00000029 !important
    `,
    width: '30px',
    height: '30px',
    backgroundColor: '#00000000 !important',
    '&.activation': {
      boxShadow: '0px 3px 6px #00000029 !important',
      backgroundColor: `${theme.palette.secondary.main} !important`
    },
    '&.renewal': {
      border: `3px solid ${theme.palette.secondary.main}`
    },
    '&.suspension': {
      border: '3px solid #D47600'
    },
    '&.reactivation': {
      border: '3px solid #2AD5B8'
    }
  },
  connector: {
    width: '2px',
    borderRadius: '20px',
    backgroundColor: `${theme.palette.secondary.main} !important`,
    minHeight: '70px !important',
    [theme.breakpoints.down('xs')]: {
      minHeight: '120px !important'
    }
  },
  date: {
    font: 'normal normal bold 18px/24px Roboto',
    color: theme.palette.primary.main
  },
  dataContainer: {
    padding: '11px',
    backgroundColor: '#F9F9F9',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '5px',
    width: '158px',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1)
    }
  },
  type: {
    font: 'normal normal bold 15px/20px Roboto',
    color: theme.palette.primary.main
  },
  info: {
    font: 'normal normal bold 12px/16px Roboto',
    color: '#00040F7F'
  }
}));
