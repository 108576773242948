import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  input: {
    width: '290px',
    marginLeft: '6px',
    marginRight: '6px',
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      width: '212px'
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      width: '80%'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  }
}));
