import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  modal: {
    zIndex: '1475 !important',
    position: 'absolute',
    top: '50px !important',
    left: '133px !important',
    [theme.breakpoints.down('xs')]: {
      top: '0 !important',
      left: '0 !important'
    }
  },
  container: {
    position: 'absolute !important',
    width: '100%',
    height: 'auto',
    minHeight: '247px',
    backgroundColor: '#fff',
    padding: theme.spacing(3)
  },
  title: {
    font: 'normal normal bold 21px/28px Roboto',
    color: theme.palette.secondary.main
  },
  description: {
    font: 'normal normal normal 15px/20px Roboto',
    color: theme.palette.primary.main,
    marginTop: '20px'
  },
  line: {
    height: '0',
    width: '100%',
    border: '1px solid #009FDA',
    marginTop: '5px'
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  input: {
    marginTop: theme.spacing(3),
    width: '290px',
    marginRight: '20px',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  button: {
    marginTop: theme.spacing(3),
    maxWidth: '100px'
  }
}));
