import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.Partners.Cards.Create.title',
    defaultMessage: 'AGREGAR'
  },
  subTitle: {
    id: 'components.Partners.Cards.Create.subTitle',
    defaultMessage: 'Partner'
  }
});
