import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  upload: {
    display: 'flex',
    alignItems: 'center'
  },
  uploadButton: {
    margin: 0,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '8px',
    color: '#fff',
    fontWeight: 'bolder',
    transition: 'all 300ms',
    padding: '9px 13px 9px 13px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& span': {
      marginLeft: '9px'
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  fileName: {
    color: '#00040F7F',
    font: 'normal normal normal 15px/20px Roboto',
    maxWidth: '60%'
  },
  error: {
    color: '#f44336',
    font: 'normal normal normal 15px/20px Roboto',
    maxWidth: '60%'
  },
  fileInput: {
    visibility: 'hidden',
    display: 'block',
    height: 0,
    width: '5px',
    pointerEvents: 'none'
  }
}));
