import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.Database.SideForm.title',
    defaultMessage: 'Importar base de datos'
  },
  editTitle: {
    id: 'components.Database.SideForm.edit.title',
    defaultMessage: 'Actualizar base de datos'
  },
  body: {
    id: 'components.Database.SideForm.body',
    defaultMessage:
      'Selecciona el archivo CSV que contiene los datos de registro que serán cargados en la base de datos.'
  },
  requiredError: {
    id: 'components.Database.SideDrawer.input.error.required',
    defaultMessage: 'Este campo es obligatorio'
  },
  eventIdLabel: {
    id: 'components.Database.SideForm.input.event.label',
    defaultMessage: 'Evento'
  },
  uploadButtonLabel: {
    id: 'components.Database.SideForm.button.upload.label',
    defaultMessage: 'SUBIR'
  },
  submitButtonLabel: {
    id: 'components.Database.SideForm.button.submit.label',
    defaultMessage: 'CARGAR'
  },
  editSubmitButtonLabel: {
    id: 'components.Database.SideForm.edit.button.submit.label',
    defaultMessage: 'ACTUALIZAR'
  },
  databaseSuccessfullyLoaded: {
    id: 'components.Database.SideForm.modal.databaseSuccessfullyLoaded',
    defaultMessage:
      'Satisfactoriamente cargada la base de datos para el evento:'
  },
  databaseSuccessfullyUpdated: {
    id: 'components.Database.SideForm.modal.databaseSuccessfullyUpdated',
    defaultMessage:
      'Satisfactoriamente actualizada la base de datos para el evento:'
  },
  ok: {
    id: 'components.Database.SideForm.modal.ok',
    defaultMessage: 'Ok'
  },
  downloadExample: {
    id: 'components.Database.downloadExample',
    defaultMessage: 'Descargar ejemplo de archivo para la base de datos'
  }
});
