import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.Coordinators.title',
    defaultMessage: 'Usuarios coordinadores de Rooms'
  },
  subTitle: {
    id: 'components.Coordinators.subTitle',
    defaultMessage:
      'Listado de usuarios que han sido creados para acceder a la plataforma'
  },
  error: {
    id: 'components.Coordinators.error',
    defaultMessage: 'Ha ocurrido un error durante la petición'
  },
  successfullyDeleted: {
    id: 'components.Coordinators.successfullyDeleted',
    defaultMessage: 'SE HA ELIMINADO EL USUARIO'
  },
  ok: {
    id: 'components.Coordinators.ok',
    defaultMessage: 'Ok'
  },
  page: {
    id: 'components.Coordinators.page',
    defaultMessage: 'Página'
  }
});
