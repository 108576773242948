import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Typography, Grid, Box, MenuItem, Select } from '@material-ui/core';
import { messages } from './OrganizationsMessages';
import { useStyles } from './OrganizationsStyles';
import { OrganizationCard } from 'components/Organizations/OrganizationCard';
import { CreateCard } from 'components/Organizations/CreateCard';
import { useNoEvents } from 'components/NoEvents/hooks/useNoEvents';
import { useDashboard } from 'components/Dashboard';
import { RefreshControl } from 'components/RefreshControl/RefreshControl';
import { Pagination } from '@material-ui/lab';
import { ExpandMore } from '@material-ui/icons';
import { usePagination } from 'hooks/usePagination';

const Organizations = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { handleNoEventShow } = useNoEvents();
  const { organizations, fetchOrganizations } = useDashboard();

  const getData = () => {
    fetchOrganizations();
  };

  useEffect(() => {
    handleNoEventShow(false);

    getData();
  }, []);

  const {
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage
  } = usePagination();

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Grid container direction="row" justify="space-between">
          <Grid>
            <Typography className={classes.title}>
              {intl.formatMessage(messages.title)}
            </Typography>
            <Typography className={classes.subTitle}>
              {intl.formatMessage(messages.subtitle)}
            </Typography>
          </Grid>
          <RefreshControl refreshFunction={getData} />
        </Grid>
      </div>
      <Box className={classes.cardsContainer}>
        <Grid container justify="flex-start" spacing={3}>
          {page === 0 && <CreateCard />}
          {organizations
            .slice(
              page * rowsPerPage - +!!page,
              page * rowsPerPage + rowsPerPage - 1
            )
            .map((organization, index) => (
              <OrganizationCard
                key={organization.id}
                organization={organization}
                index={index}
              />
            ))}
        </Grid>
      </Box>
      {!!organizations.length && (
        <div className={classes.paginationContainer}>
          <Pagination
            count={Math.ceil((organizations.length + 1) / rowsPerPage)}
            page={page + 1}
            onChange={handleChangePage}
            defaultPage={0}
            size="small"
            variant="outlined"
            shape="rounded"
          />
          <Select
            className={classes.paginationSelect}
            onChange={handleChangeRowsPerPage}
            value={rowsPerPage}
            IconComponent={ExpandMore}
          >
            {[3, 9, 15, 21, 27].map((n) => (
              <MenuItem key={`${n}PaginationOption`} value={n}>
                {n} / {intl.formatMessage(messages.page)}
              </MenuItem>
            ))}
          </Select>
        </div>
      )}
    </div>
  );
};

export { Organizations };
