import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
  const responsive = {
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      width: '212px'
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      width: '80%'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  };

  return {
    input: {
      width: '290px',
      marginLeft: '6px',
      marginRight: '6px',
      marginTop: theme.spacing(1),
      maxHeight: '120px',
      ...responsive
    },
    fullwidth: {
      width: '100%'
    },
    containerVideo: {
      width: '290px',
      display: 'flex',
      flexDirection: 'column',
      margin: '0px',
      maxHeight: '210px',
      marginLeft: '6px',
      marginRight: '6px',
      ...responsive,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        alignItems: 'center'
      }
    },
    multimediaRow: {
      width: '100%',
      textAlign: 'center',
      [theme.breakpoints.up('md')]: { display: 'flex' }
    },
    radioGroup: {
      display: 'inline-flex',
      height: '20px',
      alignItems: 'flex-start',
      justifyContent: 'center',
      alignItems: 'center'
    },
    platformLogoImage: {
      width: '23px',
      height: '23px'
    }
  };
});

export { useStyles };
