import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    marginBottom: theme.spacing(3),
    position: 'relative'
  },
  title: {
    textAlign: 'left',
    font: 'normal normal bold 21px/28px Roboto',
    letterSpacing: '0px',
    color: '#0E0E0E'
  },
  subTitle: {
    marginTop: '9px',
    textAlign: 'left',
    font: 'normal normal normal 15px/20px Roboto',
    color: '#0E0E0E80'
  },
  tabs: {
    width: '100%',
    borderBottom: '2px solid #2AA4D5',
    '& .MuiTabs-indicator': {
      height: '1px',
      backgroundColor: theme.palette.secondary.main
    }
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    font: 'normal normal normal 18px/24px Roboto',
    color: '#E6E6E6',
    transition: 'color 300ms',
    textTransform: 'initial',
    '&.active': {
      color: theme.palette.secondary.main,
      '& $imageMaskContainer > div': {
        backgroundColor: theme.palette.secondary.main
      }
    }
  },
  imageMaskContainer: {
    display: 'inline-block',
    marginRight: theme.spacing(1)
  },
  chairMask: ({ image }) => ({
    height: '18px',
    width: '18px',
    backgroundColor: '#E6E6E6',
    transition: 'all 300ms',
    maskRepeat: 'no-repeat',
    maskPosition: 'center',
    maskImage: `url("${image}")`
  }),
  listContainer: {
    marginTop: '13px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  list: {
    width: '290px'
  },
  questionGridContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginBottom: '49.5px',
    marginTop: '36px'
  },
  headTable: {
    width: '22px',
    height: '20px',
    textAlign: 'center',
    margin: '2.25px 7.5px 2.25px 7.5px'
  },
  columnTitle: {
    textAlign: 'center',
    font: 'normal normal bold 12px/16px Roboto',
    color: '#009FDA',
    letterSpacing: '0px'
  },
  numberRow: {
    textAlign: 'left',
    font: 'normal normal bold 12px/16px Roboto',
    color: '#009FDA',
    letterSpacing: '0px'
  },
  questionContainer: {
    border: '1px solid #C7C2BA',
    borderRadius: '5px',
    width: '22px',
    height: '20px',
    margin: '2.25px 7.5px 2.25px 7.5px'
  },
  questionActive: {
    background: '#2AA4D5 0% 0% no-repeat padding-box',
    borderRadius: '5px',
    width: '22px',
    height: '20px',
    margin: '2.25px 7.5px 2.25px 7.5px'
  },
  submitButton: {
    marginBottom: '25px'
  },
  containerSubmitButton: {
    width: '60px'
  }
}));

export { useStyles };
