import * as yup from 'yup';
import { messages } from './EditMessages';

export const getValidationSchema = (intl) => {
  const requiredMessage = intl.formatMessage(messages.requiredError);

  return yup.object({
    eventId: yup.string().trim().required(requiredMessage),
    conferences: yup.array().of(
      yup.object({
        imageUrl: yup.string(),
        speakerName: yup.string().trim().required(requiredMessage),
        speakerResume: yup.string().trim().required(requiredMessage),
        speakerEducation: yup.string().trim().required(requiredMessage),
        speakerBackground: yup.string().required(requiredMessage),
        date: yup.string().required(requiredMessage),
        time: yup.string().required(requiredMessage),
        description: yup.string().trim().required(requiredMessage),
        streamId: yup.string().trim().required(requiredMessage),
        streamPlatform: yup.string().required(requiredMessage),
        chatIframe: yup.string().trim().required(requiredMessage)
      })
    )
  });
};
