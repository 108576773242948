import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '10px',
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    cursor: 'pointer',
    minHeight: '118px',
    marginBottom: '10px',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  content: {
    [theme.breakpoints.down('sm')]: {
      margin: '20px 0 20px 0'
    }
  },
  title: {
    textAlign: 'left',
    font: 'normal normal bold 21px/28px Roboto',
    color: '#FFFFFF',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  subtitle: {
    textAlign: 'left',
    font: 'normal normal normal 21px/21px Source Sans Pro',
    color: '#FFFFFF',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  handIcon: {
    color: 'white',
    width: '59px',
    height: '67px',
    marginRight: '36px',
    [theme.breakpoints.down('sm')]: {
      marginRight: 0
    }
  },
  arrowIcon: {
    color: 'gray',
    backgroundColor: 'white',
    height: 36,
    width: 36,
    borderRadius: '50%',
    marginLeft: 'auto',
    marginRight: '24px'
  }
}));

export { useStyles };
