import { useIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Typography, Grid } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useStyles } from './CreateCardStyles';
import { messages } from './CreateCardMessages';
import { Images } from 'assets';

const CreateCard = withRouter(({ history }) => {
  const classes = useStyles({ icon: Images.PuzzlePiece });
  const intl = useIntl();

  const onClick = () =>
    history.push({
      pathname: `/CreateChallenge`,
      state: { id: 0, type: 'create' }
    });

  return (
    <Grid item lg={4} md={6} sm={12} xs={12} onClick={onClick}>
      <div className={classes.container}>
        <div className={classes.info}>
          <div className={classes.imageMaskContainer}>
            <div className={classes.imageMask} />
          </div>
          <div>
            <Typography className={classes.title}>
              {intl.formatMessage(messages.title)}
            </Typography>
            <Typography className={classes.subtitle}>
              {intl.formatMessage(messages.subtitle)}
            </Typography>
          </div>
        </div>
        <div className={classes.actions}>
          <Grid container justify="center" className={classes.actionsContainer}>
            <Add className={classes.addIcon} />
          </Grid>
        </div>
      </div>
    </Grid>
  );
});

export { CreateCard };
