import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.Challenge.CreateChallenge.title',
    defaultMessage: 'CREAR'
  },
  subtitle: {
    id: 'components.Challenge.CreateChallenge.subtitle',
    defaultMessage: 'Challenge'
  }
});
