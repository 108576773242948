export const templateGetDataQuizzes = {
  quizzes: [
    'events',
    {
      partners: 'partners',
      id: '_id',
      name: 'name',
      from: 'from',
      to: 'to',
      imageUrl: 'image_url',
      limitParticipants: '',
      company: 'company',
      quizId: 'quiz._id',
      quizName: 'quiz.name',
      quizCreatedAt: 'quiz.created_at'
    }
  ]
};

export const templateGetDataQuiz = {
  id: '_id',
  updatedAt: 'updated_at',
  questions: 'questions',
  event: 'event',
  deleted: 'deleted',
  createdby: 'created_by',
  created_at: 'created_at'
};

export const templateGetByIdQuiz = {
  id: '_id',
  idEvent: 'event',
  questions: 'questions'
};

export const templateGetHeaderQuiz = {
  headerResponse: {
    code: 'code',
    message: 'message'
  }
};
