import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  goBackIconContainer: {
    position: 'absolute',
    top: '0',
    right: '0',
    padding: 'inherit',
    color: '#989898'
  },
  goBackIcon: {
    cursor: 'pointer',
    padding: '5px',
    boxSizing: 'content-box',
    transition: 'background-color 300ms',
    height: '36px',
    width: '36px',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: '#00040F26'
    }
  }
}));
