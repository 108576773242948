import { useState } from 'react';
import { Typography, Container, Box, Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { messages } from './EditMessages';
import EventIcon from '@material-ui/icons/Event';
import { useStyles } from './EditStyles';
import { Images } from 'assets';
import { useModal } from '@octopy/react-modal';
import { ModalButton, ModalText } from 'components/ModalMessages';
import { useDashboard } from 'components/Dashboard';
import { formatDate, formatTime } from 'utils/helpers';

const Edit = withRouter(({ idEvent, event, index }) => {
  const history = useHistory();
  const intl = useIntl();
  const classes = useStyles();
  const { handleOpenModal } = useModal();
  const { deleteEventById } = useDashboard();
  const [idEventCard] = useState(idEvent);

  const handleDeleteEvent = async () => {
    await deleteEventById(event, index);
  };

  const handleDeleteClick = () => {
    const modalTitle = (
      <ModalText>
        <ModalText>
          {intl.formatMessage(messages.areYouSureDelete)}
          <ModalText white> {` ${event.name}`}</ModalText>
          {' ?'}
        </ModalText>
      </ModalText>
    );
    const modalActionButtons = (
      <ModalButton onClick={handleDeleteEvent}>
        {intl.formatMessage(messages.delete)}
      </ModalButton>
    );

    handleOpenModal({
      title: modalTitle,
      actionButtons: modalActionButtons,
      configProps: { maxWidth: 'xs' }
    });
  };

  const dateAndTime = (dateTime, type) => {
    if (dateTime) {
      const dateUpdate = new Date(dateTime);

      if (type === 'time') {
        let timeEvent = formatTime(dateUpdate);
        let time = `${timeEvent} hrs.`;

        return time;
      } else {
        return formatDate(dateUpdate);
      }
    } else {
      return null;
    }
  };

  return (
    <Grid item lg={4} md={6} sm={12} xs={12}>
      <Container className={classes.Container}>
        <Box className={classes.Content}>
          <Box className={classes.imgBox}>
            <EventIcon className={classes.iconCalendar} />
          </Box>
          <Box className={classes.info}>
            <Typography className={classes.title}>{event.name}</Typography>
            <Typography className={classes.country}>
              {intl.formatMessage(messages.country)}: {event.company.country}
            </Typography>

            <Typography className={classes.date}>
              {intl.formatMessage(messages.date)}:{' '}
              {dateAndTime(event.from, 'date')}
            </Typography>
          </Box>
        </Box>

        <div className={classes.actions}>
          <Grid container className={classes.actionsContainer}>
            <Grid
              className={classes.edit}
              alignItems="center"
              item
              container
              onClick={() =>
                history.push({
                  pathname: `/EditEvent/${idEventCard}`,
                  state: {
                    index
                  }
                })
              }
              xs={4}
            >
              <div className={classes.actionButtonImageContainer}>
                <img
                  alt="edit"
                  src={Images.Pencil}
                  className={classes.actionButtonImage}
                />
              </div>
              <Typography component="span">
                {intl.formatMessage(messages.edit)}
              </Typography>
            </Grid>
            <Grid
              className={classes.delete}
              alignItems="center"
              item
              container
              xs={4}
              onClick={handleDeleteClick}
            >
              <div className={classes.actionButtonImageContainer}>
                <img
                  alt="delete"
                  src={Images.Trash}
                  className={classes.actionButtonImage}
                />
              </div>
              <Typography component="span">
                {intl.formatMessage(messages.delete)}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Container>
    </Grid>
  );
});

export { Edit };
