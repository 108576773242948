import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    font: 'normal normal bold 21px/28px Roboto',
    color: '#0E0E0E',
    marginBottom: theme.spacing(1)
  },
  subtitle: {
    font: 'normal normal normal 15px/20px Roboto',
    color: '#0E0E0E80'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    '&.minHeight': {
      minHeight: '95%'
    }
  },
  cardsContainer: {
    paddingTop: theme.spacing(2),
    flexGrow: 1
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    }
  },
  paginationSelect: {
    border: 0,
    borderRadius: '4px',
    outline: 'none',
    boxShadow: '0px 0px 2px #4D4F5C80',
    backgroundColor: '#fff',
    height: '23px',
    '& > .MuiSelect-root': {
      color: '#808495'
    },
    padding: '0',
    font: 'normal normal normal 10px/14px Roboto',
    marginTop: theme.spacing(2),
    '& .MuiSelect-icon': {
      fontSize: '23px',
      paddingRight: '10px 0 0 0',
      margin: 0,
      color: '#808495'
    },
    '& .MuiSelect-iconOpen': {
      fontSize: '23px',
      paddingRight: '10px 0 0 0',
      margin: 0
    }
  }
}));

export { useStyles };
