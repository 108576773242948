import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  title: {
    font: 'normal normal bold 15px/20px Roboto',
    color: '#323232'
  },
  description: {
    marginTop: '10px',
    font: 'normal normal normal 15px/16px Roboto',
    color: '#00040F7F'
  },
  input: {
    width: '290px',
    marginTop: '10px',
    marginRight: '25px',
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      width: '100%'
    }
  },
  followingCategoriesContainer: {
    margin: '25px 0 25px 0'
  },
  actionButtonsContainer: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto'
    }
  },
  actionButton: {
    marginTop: '10px',
    marginRight: '10px',
    borderRadius: '50%'
  },
  actionButtonImage: {
    width: '28px',
    height: '28px'
  },
  followingCategorieContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: '10px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '20px'
    }
  },
  submitButton: {
    margin: '20px 0 25px 0',
    width: '136px',
    height: '40px'
  }
}));
