import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.Coordinators.Cards.Create.title',
    defaultMessage: 'CREAR'
  },
  subTitle: {
    id: 'components.Coordinators.Cards.Create.subTitle',
    defaultMessage: 'Usuario'
  }
});
