import { useIntl } from 'react-intl';
import { Typography, Box, Hidden, Drawer } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { messages } from './MenuMessages';
import { useStyles } from './MenuStyles';
import { Images } from 'assets';
import { useMenu } from './hooks/useMenu';
import { useDashboard } from 'components/Dashboard';

const sections = {
  Analytics: { roles: ['client'], image: Images.Chart },
  Events: { roles: ['client'], image: Images.List },
  Principal: { roles: ['client', 'coordinator'], image: Images.event },
  Exhibition: {
    roles: ['client', 'coordinator'],
    image: Images.exhibitionIcon
  },
  Partners: { roles: ['client', 'coordinator'], image: Images.handshake },
  Challenge: { roles: ['client', 'coordinator'], image: Images.PuzzlePiece },
  Quiz: { roles: ['client', 'coordinator'], image: Images.Question },
  Database: { roles: ['client', 'coordinator'], image: Images.BD },
  Organizations: { roles: ['admin'], image: Images.Work },
  Coordinators: { roles: ['client'], image: Images.Person },
  Licenses: { roles: ['root'], image: Images.Licenses }
};

const Menu = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { user } = useDashboard();

  const { menuVisibility, hideMenu } = useMenu();

  const content = (
    <Box className={classes.container}>
      {Object.keys(sections).map(
        (section) =>
          user &&
          sections[section].roles.includes(user.role.name) && (
            <Box key={`${section}Section`} className={classes.boxOption}>
              <NavLink
                to={`/${section}`}
                onClick={hideMenu}
                className={classes.option}
              >
                <img
                  className={classes.icon}
                  src={sections[section].image}
                  alt={section}
                />
                <Typography className={classes.title}>
                  {intl.formatMessage(messages[section.toLowerCase()])}
                </Typography>
              </NavLink>
            </Box>
          )
      )}
    </Box>
  );

  return (
    <>
      <Hidden xsDown>{content}</Hidden>
      <Hidden smUp>
        <Drawer open={menuVisibility} onClose={hideMenu} anchor="left">
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export { Menu };
