import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 69px)'
  },
  accordionContainer: {
    flex: 1
  },
  header: {
    marginBottom: theme.spacing(3),
    position: 'relative'
  },
  title: {
    font: 'normal normal bold 21px/28px Roboto',
    color: '#0E0E0E',
    marginBottom: theme.spacing(1)
  },
  subtitle: {
    font: 'normal normal normal 15px/20px Roboto',
    color: '#0E0E0E80',
    marginBottom: theme.spacing(2)
  },
  eventSelect: {
    width: '300px',
    maxWidth: '100%'
  },
  submitButton: {
    width: '136px',
    height: '40px',
    marginBottom: '25px'
  }
}));
