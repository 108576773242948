import { useState, useEffect } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import { useStyles } from './FormTabsStyles';
import { TabPanel } from './TabPanel';
import { AwardPolicies } from '../AwardPolicies';
import { RankingCategories } from '../RankingCategories/RankingCategories';
import { Images } from 'assets';
import { messages } from './FormTabsMessages';
import { useIntl } from 'react-intl';
import { useDashboard } from 'components/Dashboard';
import { ChallengeList } from '../ChallengeList';
import { useFormik } from 'formik';
import { getValidationSchema, initialValues } from '../FormTabs/helpers';

const getAllyProps = (index) => ({
  id: `scrollable-auto-tab-${index}`,
  'aria-controls': `scrollable-auto-tabpanel-${index}`
});

export const FormTabs = ({ eventSelect }) => {
  const { challenges, challengesSaved, fetchChallengesSaved } = useDashboard();

  const intl = useIntl();

  const classes = useStyles({
    file: Images.File,
    star: Images.Star,
    medal: Images.Medal
  });

  const errorMessages = {
    requiredMessage: intl.formatMessage(messages.requiredError)
  };

  const [value, setValue] = useState(0);
  const [step, setStep] = useState('Create');
  const [idRel, setIdRel] = useState();
  const handleTabChange = (_, value) => setValue(value);

  function mapperSaved(challenges) {
    let challengesSaved = [];

    challenges.map((challenge) => {
      challengesSaved.push({
        id: challenge.challenges.id,
        name: challenge.challenges.name,
        score: challenge.challenges.score,
        room: challenge.challenges.room,
        enable: challenge.challenges.enable,
        id_action: challenge.challenges.id_action
      });
    });

    return challengesSaved;
  }

  const formik = useFormik({
    isInitialValid: false,
    enableReinitialize: true,
    validationSchema: getValidationSchema(errorMessages),
    initialValues
  });

  useEffect(async () => {
    const response = await fetchChallengesSaved(eventSelect);
  }, [eventSelect]);

  useEffect(() => {
    if (challengesSaved.length > 0) {
      let newChallengesSaved = mapperSaved(challengesSaved);

      setIdRel(challengesSaved[0].idRel);

      formik.setFieldValue('challengeList', newChallengesSaved);
      setStep('Update');
    } else {
      formik.setFieldValue('challengeList', challenges);
      setStep('Create');
    }
  }, [challengesSaved]);

  return (
    <>
      <Tabs
        scrollButtons="auto"
        variant="scrollable"
        aria-label="scrollable auto tabs example"
        value={value}
        className={classes.tabs}
        onChange={handleTabChange}
      >
        <Tab
          {...getAllyProps(0)}
          label={
            <div className={`${classes.label} ${value === 0 && 'active'}`}>
              <div className={classes.imageMaskContainer}>
                <div className={classes.medalImageMask} />
              </div>
              <span>{intl.formatMessage(messages.challenges)}</span>
            </div>
          }
        />
        <Tab
          {...getAllyProps(1)}
          label={
            <div className={`${classes.label} ${value === 1 && 'active'}`}>
              <div className={classes.imageMaskContainer}>
                <div className={classes.fileImageMask} />
              </div>
              <span>{intl.formatMessage(messages.policies)}</span>
            </div>
          }
        />
        <Tab
          {...getAllyProps(2)}
          label={
            <div className={`${classes.label} ${value === 2 && 'active'}`}>
              <div className={classes.imageMaskContainer}>
                <div className={classes.starImageMask} />
              </div>
              <span>{intl.formatMessage(messages.categories)}</span>
            </div>
          }
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <ChallengeList
          eventSelect={eventSelect}
          formik={formik}
          step={step}
          setStep={setStep}
          idRel={idRel}
        />
      </TabPanel>
      <TabPanel
        value={value}
        description={intl.formatMessage(messages.awardPoliciesDescription)}
        index={1}
      >
        <AwardPolicies eventSelect={eventSelect} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <RankingCategories eventSelect={eventSelect} />
      </TabPanel>
    </>
  );
};
