import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  logoUrlLabel: {
    id: 'components.Partners.Forms.Create.Right.logoUrlLabel',
    defaultMessage: 'Seleccionar logo'
  },
  businessNameLabel: {
    id: 'components.Partners.Forms.Create.Right.userNameLabel',
    defaultMessage: 'Razón social'
  },
  contactNameLabel: {
    id: 'components.Partners.Forms.Create.Right.userNamePlaceholder',
    defaultMessage: 'Nombre del contacto'
  },
  descriptionLabel: {
    id: 'components.Partners.Forms.Create.Right.completeNameLabel',
    defaultMessage: 'Descripción breve de la empresa'
  },
  contactPhoneLabel: {
    id: 'components.Partners.Forms.Create.Right.contactPhoneLabel',
    defaultMessage: 'Teléfono del contacto'
  },
  contactPhonePlaceholder: {
    id: 'components.Partners.Forms.Create.Right.contactPhonePlaceholder',
    defaultMessage: 'Teléfono del contacto'
  },
  emailLabel: {
    id: 'components.Partners.Forms.Create.Right.emailLabel',
    defaultMessage: 'Correo electrónico'
  },
  submitButtonLabel: {
    id:
      'components.Partners.Forms.Create.Right.input.submitButton.create.label',
    defaultMessage: 'CREAR'
  },
  editSubmitButtonLabel: {
    id: 'components.Partners.Forms.Create.Right.input.submitButton.edit.label',
    defaultMessage: 'EDITAR'
  },
  isRequiredError: {
    id: 'components.Partners.Forms.Create.Right.isRequiredError',
    defaultMessage: 'Este campo es requerido'
  },
  invalidEmailError: {
    id: 'components.Partners.Forms.Create.Right.invalidEmailError',
    defaultMessage: 'Correo no válido'
  },
  selectCountry: {
    id: 'components.Partners.Forms.Create.Right.selectCountry',
    defaultMessage: 'País'
  },
  success: {
    id: 'components.Partners.Forms.Create.Right.success',
    defaultMessage: 'EL ALIADO HA SIDO AGREGADO EXITOSAMENTE'
  },
  ok: {
    id: 'components.Partners.Forms.Create.Right.ok',
    defaultMessage: 'OK'
  },
  error: {
    id: 'components.Partners.Forms.Create.Right.error',
    defaultMessage: 'Ha ocurrido un error.'
  },
  internalServerError: {
    id: 'components.Partners.Forms.Create.Right.internalServerError',
    defaultMessage:
      'Ha ocurrido un error interno en el servidor. Vuelve a intentarlo'
  },
  duplicatedError: {
    id: 'components.Partners.Forms.Create.Right.duplicatedError',
    defaultMessage: 'El registro ya se encuentra en la base de datos.'
  },
  notice: {
    id: 'components.Partners.Forms.Create.Right.notice',
    defaultMessage:
      'Una vez que se cree el partner podras modificar su stand desde el listado'
  },
  errorPhone: {
    id: 'components.Partners.Form.Create.Right.errorPhone',
    defaultMessage: 'Teléfono inválido'
  }
});
