const templateGetPartner = {
  headerResponse: {
    code: 'headerResponse.code',
    message: 'headerResponse.message'
  },
  payload: {
    items: [
      'payload.items',
      {
        id: '_id',
        logoUrl: 'image',
        businessName: 'business_name',
        contactName: 'contact_name',
        email: 'email',
        contactPhone: 'phone',
        country: 'country',
        description: 'description',
        type: 'type',
        stand: {
          url: 'stand.url',
          backgroud: 'stand.backgroud',
          title: 'stand.title',
          description: 'stand.description',
          slogan: 'stand.slogan',
          fileOne: 'stand.image_1',
          fileTwo: 'stand.image_2'
        },
        partners: 'partners',
        clients: 'clients'
      }
    ]
  }
};
const templateAddPartners = {
  file: 'logoUrl.file',
  business_name: 'businessName',
  contact_name: 'contactName',
  email: 'email',
  phone: 'contactPhone',
  country: 'country',
  description: 'description',
  video_id: 'videoId',
  plataform: 'plataform',
  stand_url: 'stand.Url',
  stand_backgroud: 'stand.Backgroud',
  stand_title: 'stand.Title',
  stand_description: 'stand.Description',
  stand_slogan: 'stand.Slogan',
  stand_image_1: 'stand.fileOne',
  stand_image_2: 'stand.fileTwo'
};

const templateUpdatePartners = {
  file: 'file',
  business_name: 'businessName',
  contact_name: 'contactName',
  email: 'email',
  phone: 'contactPhone',
  country: 'country',
  description: 'description',
  video_id: 'videoId',
  plataform: 'plataform'
};

const responseTemplateAddPartners = {
  headerResponse: {
    code: 'headerResponse.code',
    message: 'headerResponse.message'
  },
  payload: {
    id: 'payload._id',
    logoUrl: 'payload.image',
    businessName: 'payload.business_name',
    contactName: 'payload.contact_name',
    email: 'payload.email',
    contactPhone: 'payload.phone',
    country: 'payload.country',
    description: 'payload.description',
    stand: {
      url: 'payload.stand.url',
      backgroud: 'payload.stand.backgroud',
      title: 'payload.stand.title',
      description: 'payload.stand.description',
      slogan: 'payload.stand.slogan',
      fileOne: 'payload.stand.image_1',
      fileTwo: 'payload.stand.image_2'
    },
    createdBy: 'payload.created_by',
    type: 'payload.type',
    deleted: 'payload.deleted'
  }
};

const TemplateRequiredUpdatePartners = {
  file: 'logoUrl.file',
  business_name: 'businessName',
  contact_name: 'contactName',
  email: 'email',
  phone: 'contactPhone',
  country: 'country',
  description: 'description'
};
const templateGetPartnerById = {
  headerResponse: {
    code: 'headerResponse.code',
    message: 'headerResponse.message'
  },
  payload: {
    id: 'payload._id',
    logoUrl: 'payload.image',
    businessName: 'payload.business_name',
    contactName: 'payload.contact_name',
    email: 'payload.email',
    contactPhone: 'payload.phone',
    country: 'payload.country',
    description: 'payload.description',
    stand: {
      videoId: 'payload.stand.video_id',
      plataform: 'payload.stand.plataform',
      backgroud: 'payload.stand.backgroud',
      title: 'payload.stand.title',
      description: 'payload.stand.description',
      slogan: 'payload.stand.slogan',
      fileOne: 'payload.stand.image_1',
      fileTwo: 'payload.stand.image_2'
    }
  }
};

const TemplateUpdatePartnersStand = {
  video_id: 'stand.videoId',
  plataform: 'stand.plataform',
  stand_backgroud: 'stand.backgroud',
  stand_title: 'stand.title',
  stand_description: 'stand.description',
  stand_slogan: 'stand.slogan',
  stand_image_1: 'stand.fileOne',
  stand_image_2: 'stand.fileTwo'
};

const responseTemplateUpdatePartners = {
  headerResponse: {
    code: 'headerResponse.code',
    message: 'headerResponse.message'
  },
  payload: {
    logoUrl: 'payload.file',
    businessName: 'payload.business_name',
    contactName: 'payload.contact_name',
    email: 'payload.email',
    contactPhone: 'payload.phone',
    country: 'payload.country',
    description: 'payload.description',
    stand: {
      url: 'payload.stand.url',
      backgroud: 'payload.stand.backgroud',
      title: 'payload.stand.title',
      description: 'payload.stand.description',
      slogan: 'payload.stand.slogan',
      fileOne: 'payload.stand.image_1',
      fileTwo: 'payload.stand.image_2'
    },
    updatedBy: 'payload.updated_by'
  }
};

const templateGetHeaderPartner = {
  headerResponse: {
    code: 'headerResponse.code',
    message: 'headerResponse.message'
  },
  payload: {}
};

export {
  templateGetPartner,
  templateAddPartners,
  responseTemplateAddPartners,
  templateGetHeaderPartner,
  TemplateRequiredUpdatePartners,
  responseTemplateUpdatePartners,
  TemplateUpdatePartnersStand,
  templateGetPartnerById,
  templateUpdatePartners
};
