import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  titleEdit: {
    id: 'components.Exhibition.Forms.Edit.Left.titleEdit',
    defaultMessage: 'Editar producto'
  },
  selectMultimedia: {
    id: 'components.Exhibition.Forms.Edit.Left.selectMultimedia',
    defaultMessage: 'Seleccionar material multimedia del producto'
  },
  data: {
    id: 'components.Exhibition.Forms.Edit.Left.data',
    defaultMessage: 'Agrega los datos necesarios para agregar un producto'
  },
  user: {
    id: 'components.Exhibition.Forms.Edit.Left.user',
    defaultMessage: 'Usuario Comercial'
  }
});
