import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '70vh'
  },
  header: {
    marginBottom: theme.spacing(3),
    position: 'relative'
  },
  title: {
    font: 'normal normal bold 21px/28px Roboto',
    color: '#0E0E0E',
    marginBottom: theme.spacing(1)
  },
  name: {
    font: 'normal normal bold 21px/28px Roboto',
    color: '#0E0E0E',
    width: '291px',
    marginLeft: '30px'
    //marginBottom: theme.spacing(1)
  },
  subtitle: {
    font: 'normal normal normal 15px/20px Roboto',
    color: '#0E0E0E80',
    marginBottom: theme.spacing(2)
  },
  eventSelect: {
    width: '300px',
    maxWidth: '100%'
  },
  submitButton: {
    margin: '20px 0 25px 0',
    width: '136px',
    height: '40px'
  },
  selectEvents: {
    width: '290px',
    height: '45px',
    font: 'normal normal normal 12px/16px Roboto',
    color: '#00040F'
  }
}));
