import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.Coordinators.Cards.Edit.title',
    defaultMessage: 'Coordinador'
  },
  date: {
    id: 'components.Coordinators.Cards.Edit.subTitle',
    defaultMessage: 'Registro'
  },
  edit: {
    id: 'components.Coordinators.Cards.Edit.edit',
    defaultMessage: 'Editar'
  },
  delete: {
    id: 'components.Coordinators.Cards.Edit.delete',
    defaultMessage: 'Eliminar'
  },
  areYouSureDelete: {
    id: 'components.Coordinators.Cards.Edit.areYouSureDelete',
    defaultMessage: '¿ESTÁS SEGURO DE QUERER ELIMINAR EL USUARIO:'
  }
});
