import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  EventDeleted: {
    id: 'components.DashboardProvider.Deleted',
    defaultMessage: 'Exitosamente eliminado el evento:'
  },
  LicensesDeleted: {
    id: 'components.DashboardProvider.LicensesDeleted',
    defaultMessage: 'Exitosamente eliminada la licencia:'
  },
  OrganizationsDeleted: {
    id: 'components.DashboardProvider.OrganizationsDeleted',
    defaultMessage: 'Exitosamente eliminada la organizacion:'
  },
  CoordinatorsDeleted: {
    id: 'components.DashboardProvider.CoordinatorsDeleted',
    defaultMessage: 'Exitosamente eliminado el coordinador:'
  },
  PartnersDeleted: {
    id: 'components.DashboardProvider.PartnersDeleted',
    defaultMessage: 'Exitosamente eliminado el partner:'
  },
  ChallengesDeleted: {
    id: 'components.DashboardProvider.ChallengesDeleted',
    defaultMessage: 'Exitosamente eliminado el challenge:'
  },
  ExhibitionsDeleted: {
    id: 'components.DashboardProvider.ExhibitionsDeleted',
    defaultMessage: 'Exitosamente eliminada la exibicion:'
  },
  EventAdded: {
    id: 'components.DashboardProvider.Added',
    defaultMessage: 'Exitosamente creado el evento:'
  },
  LicensesAdded: {
    id: 'components.DashboardProvider.LicensesAdded',
    defaultMessage: 'Exitosamente creada la licencia:'
  },
  OrganizationsAdded: {
    id: 'components.DashboardProvider.OrganizationsAdded',
    defaultMessage: 'Exitosamente creada la organizacion:'
  },
  CoordinatorsAdded: {
    id: 'components.DashboardProvider.CoordinatorsAdded',
    defaultMessage: 'Exitosamente creado el coordinador:'
  },
  PartnersAdded: {
    id: 'components.DashboardProvider.PartnersAdded',
    defaultMessage: 'Exitosamente creado el partner:'
  },
  ChallengesAdded: {
    id: 'components.DashboardProvider.ChallengesAdded',
    defaultMessage: 'Exitosamente creado el challenge'
  },
  ExhibitionsAdded: {
    id: 'components.DashboardProvider.ExhibitionsAdded',
    defaultMessage: 'Exitosamente creada la exibicion:'
  },
  EventUpdated: {
    id: 'components.DashboardProvider.Updated',
    defaultMessage: 'Exitosamente actualizado el evento:'
  },
  LicensesUpdated: {
    id: 'components.DashboardProvider.LicensesUpdated',
    defaultMessage: 'Exitosamente actualizada la licencia:'
  },
  OrganizationsUpdated: {
    id: 'components.DashboardProvider.OrganizationsUpdated',
    defaultMessage: 'Exitosamente actualizada la organizacion:'
  },
  CoordinatorsUpdated: {
    id: 'components.DashboardProvider.CoordinatorsUpdated',
    defaultMessage: 'Exitosamente actualizado el coordinador:'
  },
  PartnersUpdated: {
    id: 'components.DashboardProvider.PartnersUpdated',
    defaultMessage: 'Exitosamente actualizado el partner:'
  },
  ChallengesUpdated: {
    id: 'components.DashboardProvider.ChallengesUpdated',
    defaultMessage: 'Exitosamente actualizado el challenge'
  },
  ExhibitionsUpdated: {
    id: 'components.DashboardProvider.ExhibitionsUpdated',
    defaultMessage: 'Exitosamente actualizada la exhibicion:'
  },
  ok: {
    id: 'components.Events.ok',
    defaultMessage: 'Ok'
  },
  PolicieAdded: {
    id: 'components.DashboardProvider.PolicieAdded',
    defaultMessage: 'Exitosamente guardada politica de premiación'
  },
  CategoriesAdded: {
    id: 'components.DashboardProvider.CategoriesAdded',
    defaultMessage: 'Exitosamente guardadas las categorias'
  }
});
