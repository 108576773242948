import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: ({ bgLogin }) => ({
    flex: 1,
    width: '100%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundImage: `url("${bgLogin}")`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
    '& button:nth-child(3)': {
      background: theme.palette.secondary.main,
      '&:hover': {
        background: '#1D7295'
      },
      '& p': {
        color: theme.palette.common.white
      }
    },
    '& form': {
      '&  div:nth-child(2)': {
        background: '#040D1A',
        borderRadius: '0px'
      },
      '& label': { color: '#81858C' }
    },
    '& input:nth-child(1)': {
      color: '#81858C',
      '&::-webkit-input-placeholder': { color: '81858C' }
    },
    '&  button': {
      color: theme.palette.common.white,
      '& .MuiSvgIcon-colorPrimary': {
        color: theme.palette.secondary.main
      }
    }
  })
}));

export { useStyles };
