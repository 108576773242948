import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    height: '266px',
    boxShadow: '0px 0px 5px #00000029',
    borderRadius: '5px',
    marginLeft: 'auto',
    marginRight: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    display: 'flex',
    flexDirection: 'column'
  },
  gridItem: {
    overflow: 'hidden'
  },
  headCell: {
    font: 'normal normal normal 10px/13px Roboto',
    padding: '15px 15px 15px 15px',
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
    maxWidth: '100px'
  },
  bodyCell: {
    font: 'normal normal normal 10px/13px Roboto',
    padding: '15px 15px 15px 15px',
    color: theme.palette.primary.main,
    backgroundColor: '#fff',
    maxWidth: '100px'
  },
  bodyCellText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '100px'
  },
  noEvents: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '15px',
    color: theme.palette.secondary.main,
    marginTop: '-7px'
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    }
  },
  paginationSelect: {
    border: 0,
    borderRadius: '4px',
    outline: 'none',
    boxShadow: '0px 0px 2px #4D4F5C80',
    backgroundColor: '#fff',
    height: '23px',
    '& > .MuiSelect-root': {
      color: '#808495'
    },
    padding: '0',
    font: 'normal normal normal 10px/14px Roboto',
    marginTop: theme.spacing(2),
    '& .MuiSelect-icon': {
      fontSize: '23px',
      paddingRight: '10px 0 0 0',
      margin: 0,
      color: '#808495'
    },
    '& .MuiSelect-iconOpen': {
      fontSize: '23px',
      paddingRight: '10px 0 0 0',
      margin: 0
    }
  }
}));
