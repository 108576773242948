import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.Partner.title',
    defaultMessage: 'Partners'
  },
  subTitle: {
    id: 'components.Partner.subTitle',
    defaultMessage: 'Listado de empresas aliadas y patrocinadores.'
  },
  error: {
    id: 'components.Partner.error',
    defaultMessage: 'Ha ocurrido un error.'
  },
  500: {
    id: 'components.Partner.internalServerError',
    defaultMessage:
      'Ha ocurrido un error interno en el servidor. Vuelve a intentarlo'
  },
  successfullyDeleted: {
    id: 'components.Partner.successfullyDeleted',
    defaultMessage: 'Exitosamente eliminado el partner: '
  },
  ok: {
    id: 'components.Partner.ok',
    defaultMessage: 'Ok'
  },
  page: {
    id: 'components.Partner.page',
    defaultMessage: 'página'
  }
});
