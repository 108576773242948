import * as yup from 'yup';

export const initialCorrectAnsers = {
  0: 1,
  1: 1,
  2: 1,
  3: 1
};

export const initialValues = {
  event: '',
  questionshall: [],
  questionsmainRoom: [],
  questionsexhibitionRoom: [],
  questionspartnerRoom: [],
  questionschallenge: [],
  questionscoffeeRoom: []
};

export const validationSchema = ({
  requiredMessage,
  minScoreError,
  isNotIntegerError
}) =>
  yup.object({
    event: yup.string().required(requiredMessage),
    questionshall: yup
      .array()
      .of(
        yup.object({
          question: yup
            .string()
            .trim()
            .min(1, requiredMessage)
            .required(requiredMessage),
          points: yup
            .number()
            .integer(isNotIntegerError)
            .positive(minScoreError)
            .min(50, minScoreError)
            .required(requiredMessage),
          room: yup.string().required(requiredMessage),
          answers: yup
            .array()
            .min(2)
            .required(requiredMessage),
          right_answer: yup.number().required(requiredMessage)
        })
      )
      .min(0),
    questionsmainRoom: yup
      .array()
      .of(
        yup.object({
          question: yup
            .string()
            .trim()
            .min(1, requiredMessage)
            .required(requiredMessage),
          points: yup
            .number()
            .integer(isNotIntegerError)
            .positive(minScoreError)
            .min(50, minScoreError)
            .required(requiredMessage),
          room: yup.string().required(requiredMessage),
          answers: yup
            .array()
            .min(2)
            .required(requiredMessage),
          right_answer: yup.number().required(requiredMessage)
        })
      )
      .min(0),
    questionsexhibitionRoom: yup
      .array()
      .of(
        yup.object({
          question: yup
            .string()
            .trim()
            .min(1, requiredMessage)
            .required(requiredMessage),
          points: yup
            .number()
            .integer(isNotIntegerError)
            .positive(minScoreError)
            .min(50, minScoreError)
            .required(requiredMessage),
          room: yup.string().required(requiredMessage),
          answers: yup
            .array()
            .min(2)
            .required(requiredMessage),
          right_answer: yup.number().required(requiredMessage)
        })
      )
      .min(0),
    questionspartnerRoom: yup
      .array()
      .of(
        yup.object({
          question: yup
            .string()
            .trim()
            .min(1, requiredMessage)
            .required(requiredMessage),
          points: yup
            .number()
            .integer(isNotIntegerError)
            .positive(minScoreError)
            .min(50, minScoreError)
            .required(requiredMessage),
          room: yup.string().required(requiredMessage),
          answers: yup
            .array()
            .min(2)
            .required(requiredMessage),
          right_answer: yup.number().required(requiredMessage)
        })
      )
      .min(0),
    questionschallenge: yup
      .array()
      .of(
        yup.object({
          question: yup
            .string()
            .trim()
            .min(1, requiredMessage)
            .required(requiredMessage),
          points: yup
            .number()
            .integer(isNotIntegerError)
            .positive(minScoreError)
            .min(50, minScoreError)
            .required(requiredMessage),
          room: yup.string().required(requiredMessage),
          answers: yup
            .array()
            .min(2)
            .required(requiredMessage),
          right_answer: yup.number().required(requiredMessage)
        })
      )
      .min(0),
    questionscoffeeRoom: yup
      .array()
      .of(
        yup.object({
          question: yup
            .string()
            .trim()
            .min(1, requiredMessage)
            .required(requiredMessage),
          points: yup
            .number()
            .integer(isNotIntegerError)
            .positive(minScoreError)
            .min(50, minScoreError)
            .required(requiredMessage),
          room: yup.string().required(requiredMessage),
          answers: yup
            .array()
            .min(2)
            .required(requiredMessage),
          right_answer: yup.number().required(requiredMessage)
        })
      )
      .min(0)
  });
