import {
  TextField as TextFieldMUI,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useState } from 'react';

export const TextField = ({ error, touched, type, inputProps, ...props }) => {
  const [innerType, setInnerType] = useState(type);
  const switchInnerType = () =>
    setInnerType(innerType === type ? 'text' : type);

  const adornment = type === 'password' && (
    <InputAdornment>
      <IconButton onClick={switchInnerType}>
        {innerType === 'password' ? (
          <VisibilityOff fontSize="small" />
        ) : (
          <Visibility fontSize="small" />
        )}
      </IconButton>
    </InputAdornment>
  );

  return (
    <TextFieldMUI
      error={touched && Boolean(error)}
      helperText={touched && error}
      InputLabelProps={{
        className: props.value && 'MuiInputLabel-shrink'
      }}
      InputProps={{ endAdornment: adornment, inputProps }}
      type={innerType}
      {...props}
    />
  );
};
