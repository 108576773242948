import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.Database.title',
    defaultMessage: 'Base de datos'
  },
  subtitle: {
    id: 'components.Database.subTitle',
    defaultMessage: 'Datos de registro que se cargan en la base de datos'
  },
  page: {
    id: 'components.Database.pagination.select.page',
    defaultMessage: 'página'
  }
});
