import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  drawer: {
    width: '443px',
    padding: '35px 50px 66px 50px',
    boxSizing: 'border-box',
    position: 'relative',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      padding: '17px 25px 33px 25px'
    }
  },
  closeIconContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: '20px',
    color: theme.palette.secondary.main
  },
  closeIcon: {
    cursor: 'pointer',
    transition: 'color 300ms',
    padding: '5px',
    boxSizing: 'content-box',
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  title: {
    font: 'normal normal bold 21px/28px Roboto',
    color: theme.palette.primary.main,
    marginBottom: '50px'
  },
  body: {
    font: 'normal normal normal 15px/20px Roboto',
    color: '#000000',
    marginBottom: '35px'
  },
  submitButtonContainer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    padding: 'inherit'
  },
  eventSelect: {
    width: '300px',
    maxWidth: '100%',
    marginBottom: '50px'
  },
  downloadExample: {
    marginBottom: '10px',
    '& a': {
      color: theme.palette.secondary.main,
      fontWeight: 'bold',
      textDecoration: 'none'
    }
  }
}));
