import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Box, Grid, Typography, Select, MenuItem } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { messages } from './ExhibitionMessages';
import { useStyles } from './ExhibitionStyles';
import { Create, Edit } from './Cards';
import { useNoEvents } from 'components/NoEvents/hooks/useNoEvents';
import { useDashboard } from 'components/Dashboard';
import { RefreshControl } from 'components/RefreshControl/RefreshControl';
import { v4 as uuidv4 } from 'uuid';

const Exhibition = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { show } = useNoEvents();
  const {
    fetchExhibitions,
    deleteExhibitionById,
    exhibitions,
    fetchEvents
  } = useDashboard();

  const [itemsPerPage, setItemsPerPage] = useState(9);
  const [paginationData, setPaginationData] = useState({
    totalProducts: 0,
    activePage: 1
  });
  const { activePage, totalProducts } = paginationData;

  const handleDeleteProduct = (product, positionInArray) => {
    deleteExhibitionById(product, positionInArray);
  };
  const handleChangePage = (_, value) => {
    setPaginationData({
      ...paginationData,
      activePage: value
    });
  };

  const getCompleteData = () => {
    fetchExhibitions();
    fetchEvents();
  };

  useEffect(() => {
    getCompleteData();
  }, []);

  useEffect(() => {
    setPaginationData({
      ...paginationData,
      totalProducts: exhibitions.length
    });
  }, [exhibitions]);

  return (
    <>
      <Grid>
        <Grid container direction="row" justify="space-between">
          <Grid>
            <Typography className={classes.title}>
              {intl.formatMessage(messages.title)}
            </Typography>
            <Typography className={classes.subTitle}>
              {intl.formatMessage(messages.subTitle)}
            </Typography>
          </Grid>
          <RefreshControl refreshFunction={getCompleteData} />
        </Grid>
        {!show && (
          <Box className={classes.cardsContainer}>
            <Grid container justify="flex-start" spacing={3}>
              {activePage === 1 ? <Create /> : null}
              {exhibitions
                .slice(
                  (activePage - 1) * itemsPerPage - (activePage > 1 ? 1 : 0),
                  (activePage - 1) * itemsPerPage + (itemsPerPage - 1)
                )
                .map((product, index) => (
                  <Edit
                    key={uuidv4()}
                    positionInArray={
                      index -
                      (activePage > 1 ? 1 : 0) +
                      itemsPerPage * (activePage - 1)
                    }
                    productDetails={product}
                    handleDeleteProduct={handleDeleteProduct}
                  />
                ))}
            </Grid>
            <Box display="flex" justifyContent="center">
              <Pagination
                onChange={handleChangePage}
                count={Math.ceil((totalProducts + 1) / itemsPerPage)}
                color="primary"
                page={activePage}
              />
              <Select
                className={classes.paginationSelect}
                size="small"
                onChange={(e) => {
                  setItemsPerPage(e.target.value);
                  setPaginationData({
                    ...paginationData,
                    activePage: 1
                  });
                }}
                value={itemsPerPage}
              >
                {[3, 9, 15, 21, 27].map((n) => (
                  <MenuItem key={`${n}`} value={n}>
                    {n} / {intl.formatMessage(messages.page)}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <br />
          </Box>
        )}
      </Grid>
    </>
  );
};

export { Exhibition };
