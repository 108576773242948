import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.Events.Cards.Create.title',
    defaultMessage: 'CREAR'
  },
  subTitle: {
    id: 'components.Events.Cards.Create.subTitle',
    defaultMessage: 'Evento'
  }
});
