import es from 'react-phone-input-2/lang/es.json';
import en from 'react-phone-input-2/lang/es.json';
import { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { FormControl, FormHelperText } from '@material-ui/core';
import { useStyles } from './TextFieldPhoneStyles';
import { useIntl } from 'react-intl';

const localizations = { es, en };

export const TextFieldPhone = ({
  className,
  containerClass,
  onChange,
  value,
  error,
  touched,
  onBlur,
  placeholder,
  name,
  ...props
}) => {
  const [isTouched, setIsTouched] = useState(false);
  const classes = useStyles();
  const intl = useIntl();

  useEffect(() => touched && setIsTouched(true), [touched]);

  const country = intl.locale.split('-')[1];
  const location = intl.defaultLocale;

  const inputStyle = {
    width: '100%',
    backgroundColor: '#F9F9F9',
    border: 0,
    height: '45px',
    font: 'normal normal normal 12px/16px Roboto',
    color: '#00040F'
  };

  const buttonStyle = { borderRadius: '5px', height: '45px', border: 0 };

  return (
    <FormControl className={className} error={touched && !!error}>
      <PhoneInput
        {...props}
        localization={localizations[location] || localizations.es}
        country={country}
        className={classes.textField}
        onChange={onChange}
        name={name}
        value={value}
        placeholder={placeholder}
        containerClass={containerClass}
        onBlur={(e) => {
          onBlur(e);
          setIsTouched(true);
        }}
        inputStyle={inputStyle}
        buttonStyle={buttonStyle}
      />
      {!!error && isTouched && (
        <FormHelperText error={!!error}>{error}</FormHelperText>
      )}
    </FormControl>
  );
};
