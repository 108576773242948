import { useIntl } from 'react-intl';
import { Row, Title, Label, LeftContainer } from 'components/CreationForm';
import { useRowsStyles } from '../RowsStyles';
import { messages } from './LeftMessages';

export const Left = ({ edit }) => {
  const intl = useIntl();
  const rowsClasses = useRowsStyles();

  return (
    <LeftContainer>
      <Title>{intl.formatMessage(messages.title)}</Title>

      <Row className={rowsClasses[1]}>
        <Label>{intl.formatMessage(messages.imageLabel)}</Label>
      </Row>
      <Row className={rowsClasses[2]}>
        <Label>{intl.formatMessage(messages.dataLabel)}</Label>
      </Row>
      <Row className={rowsClasses[3]}>
        <Label>{intl.formatMessage(messages.countryLabel)}</Label>
      </Row>
      <Row className={rowsClasses[4]}>
        <Label>{intl.formatMessage(messages.participantsLabel)}</Label>
      </Row>
      <Row className={rowsClasses[5]}>
        <Label>{intl.formatMessage(messages.sponsorsLabel)}</Label>
      </Row>
    </LeftContainer>
  );
};
