import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.Analytics.Filter.title',
    defaultMessage: 'Filtro'
  },
  description: {
    id: 'components.Analytics.Filter.description',
    defaultMessage: 'Por evento'
  },
  eventLabel: {
    id: 'components.Analytics.Filter.input.event.label',
    defaultMessage: 'Nombre del evento'
  },
  dateLabel: {
    id: 'components.Analytics.Filter.input.date.label',
    defaultMessage: 'Fecha'
  },
  submitButtonLabel: {
    id: 'components.Analytics.Filter.input.button.submit.label',
    defaultMessage: 'Buscar'
  },
  requiredError: {
    id: 'components.Analytics.Filter.input.error.required',
    defaultMessage: 'Este campo es requerido'
  }
});
