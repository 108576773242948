import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  titleEdit: {
    id: 'components.Quiz.Forms.Edit.titleEdit',
    defaultMessage: 'Editar quiz'
  },
  subtitle: {
    id: 'components.Quiz.Forms.Edit.subtitle',
    defaultMessage: 'Listado de trivias asociadas a algún evento.'
  },
  placeholderNameEvent: {
    id: 'components.Quiz.Forms.Edit.placeholderName',
    defaultMessage: 'Nombre del evento'
  },
  hall: {
    id: 'components.Quiz.Forms.Edit.hall',
    defaultMessage: 'Hall'
  },
  hallAbbreviation: {
    id: 'components.Quiz.Forms.Edit.hallAbbreviation',
    defaultMessage: 'H'
  },
  mainHall: {
    id: 'components.Quiz.Forms.Edit.mainHall',
    defaultMessage: 'Sala principal'
  },
  mainHallAbbreviation: {
    id: 'components.Quiz.Forms.Edit.mainHallAbbreviation',
    defaultMessage: 'SP'
  },
  exhibitionHall: {
    id: 'components.Quiz.Forms.Edit.exhibitionHall',
    defaultMessage: 'Sala de exibición'
  },
  exhibitionHallAbbreviation: {
    id: 'components.Quiz.Forms.Edit.exhibitionHallAbbreviation',
    defaultMessage: 'SE'
  },
  partnerHall: {
    id: 'components.Quiz.Forms.Edit.partnerHall',
    defaultMessage: 'Sala partner'
  },
  partnerHallAbbreviation: {
    id: 'components.Quiz.Forms.Edit.partnerHallAbbreviation',
    defaultMessage: 'PS'
  },
  challenge: {
    id: 'components.Quiz.Forms.Edit.Challenge',
    defaultMessage: 'Challenge'
  },
  challengeAbbreviation: {
    id: 'components.Quiz.Forms.Edit.challengeAbbreviation',
    defaultMessage: 'CH'
  },
  coffeeHall: {
    id: 'components.Quiz.Forms.Edit.coffeeHall',
    defaultMessage: 'Sala de café'
  },
  coffeeHallAbbreviation: {
    id: 'components.Quiz.Forms.Edit.ccoffeeHallAbbreviation',
    defaultMessage: 'SC'
  },
  submit: {
    id: 'components.Quiz.Forms.Edit.submitButton',
    defaultMessage: 'GUARDAR'
  },
  requiredError: {
    id: 'components.Quiz.Forms.Edit.required',
    defaultMessage: 'Este campo es requerido'
  },
  minScoreError: {
    id: 'components.Quiz.Forms.Edit.minScoreError',
    defaultMessage: 'El puntaje mínimo es 50'
  },
  isNotIntegerError: {
    id: 'components.Quiz.Forms.Edit.isNotIntegerError',
    defaultMessage: 'Sólo números enteros'
  },
  savedQuizMessage: {
    id: 'components.Quiz.Forms.Edit.savedQuizMessage',
    defaultMessage: 'LOS DATOS HAN SIDO ACTUALIZADOS CORRECTAMENTE'
  }
});
