import { useIntl } from 'react-intl';
import { Grid, Tab, Tabs, Typography } from '@material-ui/core';
import { Select } from 'components/CreationForm';
import { useFormik } from 'formik';
import { useStyles } from './FormStyles';
import { messages } from './FormMessages';
import { SubmitButton } from 'components/CreationForm';
import { useState, useEffect } from 'react';
import { Images } from 'assets';
import { GoBack } from 'components/GoBack/GoBack';
import { TabPanel } from './TabPanel';
import { Challenge } from './Tabs/Challenge/Challenge';
import { CoffeeRoom } from './Tabs/CoffeeRoom/CoffeeRoom';
import { ExhibitionRoom } from './Tabs/ExhibitionRoom/ExhibitionRoom';
import { Hall } from './Tabs/Hall/Hall';
import { MainRoom } from './Tabs/MainRoom/MainRoom';
import { PartnerRoom } from './Tabs/PartnerRoom/PartnerRoom';
import {
  initialCorrectAnsers,
  initialValues,
  validationSchema
} from './helpers';
import { FormikProvider } from 'formik';
import { postQuiz } from 'providers/api';
import { useDashboard } from 'components/Dashboard';
import { useLoader } from '@octopy/react-loader';
import { useModal } from '@octopy/react-modal';
import {
  getDataError,
  createErrorModalConfig,
  createSuccessModalConfig
} from 'utils/helpers';

const Form = ({ history }) => {
  const classes = useStyles({ image: Images.Chair });
  const intl = useIntl();
  const { events } = useDashboard();
  const { handleOpenModal } = useModal();
  const { handleShowLoader } = useLoader();
  const [value, setValue] = useState(0);
  const [optionsForSelect, setOptionsForSelect] = useState([]);
  const [correctAnswersHall, setCorrectAnswersHall] = useState(
    initialCorrectAnsers
  );
  const [correctAnswersMainRoom, setCorrectAnswersMainRoom] = useState(
    initialCorrectAnsers
  );
  const [correctAnswersExhibition, setCorrectAnswersExhibition] = useState(
    initialCorrectAnsers
  );
  const [correctAnswersPartner, setCorrectAnswersPartner] = useState(
    initialCorrectAnsers
  );
  const [correctAnswersChallenge, setCorrectAnswersChallenge] = useState(
    initialCorrectAnsers
  );
  const [correctAnswersCoffee, setCorrectAnswersCoffee] = useState(
    initialCorrectAnsers
  );

  useEffect(() => {
    try {
      handleShowLoader(true);
      if (history.location.state.idEventsWithQuiz !== undefined) {
        const eventsWithQuiz = history.location.state.idEventsWithQuiz;
        const eventsWithoutQuiz = events.filter(
          (event) => !eventsWithQuiz.includes(event.id)
        );
        const options = eventsWithoutQuiz.map((event) => ({
          value: event.id,
          label: event.name
        }));

        setOptionsForSelect(options);
      }
    } catch (e) {
      handleShowLoader(false);
      history.push('/Quiz');
    } finally {
      handleShowLoader(false);
    }
  }, []);

  const handleTabChange = (event, index) => {
    setValue(index);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema({
      requiredMessage: intl.formatMessage(messages.requiredError),
      minScoreError: intl.formatMessage(messages.minScoreError),
      isNotIntegerError: intl.formatMessage(messages.isNotIntegerError)
    }),
    onSubmit: async (values) => {
      try {
        handleShowLoader(true);

        const eventName = optionsForSelect.filter(
          (event) => event.value === values.event
        )[0].label;

        const objectApiStructure = {
          event: values.event,
          questions: [
            ...values.questionshall,
            ...values.questionsmainRoom,
            ...values.questionsexhibitionRoom,
            ...values.questionspartnerRoom,
            ...values.questionschallenge,
            ...values.questionscoffeeRoom
          ]
        };

        if (objectApiStructure.questions.length === 0) {
          handleOpenModal(createErrorModalConfig('createLeastOneQuestion', ''));

          return;
        }

        await postQuiz(objectApiStructure);
        handleOpenModal(
          createSuccessModalConfig(messages.newQuizMessage, eventName, 'ok')
        );
      } catch (request) {
        const { code, message } = getDataError(request);

        handleOpenModal(createErrorModalConfig(code, message));
      } finally {
        handleShowLoader(false);
      }

      history.push('/Quiz');
    }
  });

  const submitButtonFormik = (
    <div className={classes.containerSubmitButton}>
      <SubmitButton
        label={intl.formatMessage(messages.submit)}
        onClick={formik.handleSubmit}
        className={classes.submitButton}
      />
    </div>
  );

  return (
    <FormikProvider value={formik}>
      <div>
        <div className={classes.container}>
          <div className={classes.header}>
            <Typography className={classes.title}>
              {intl.formatMessage(messages.title)}
            </Typography>
            <Typography className={classes.subTitle}>
              {intl.formatMessage(messages.subtitle)}
            </Typography>
            <GoBack />
            <div className={classes.listContainer}>
              <Select
                key={`event`}
                name={`event`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.errors[`event`]}
                value={formik.values[`event`]}
                touched={formik.touched[`event`]}
                label={'Selecciona un evento'}
                className={classes.list}
                options={optionsForSelect}
              />
            </div>
            <div className={classes.questionGridContainer}>
              <Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid className={classes.headTable} item xs={12 / 7}>
                      <span className={classes.columnTitle}></span>
                    </Grid>
                    <Grid className={classes.headTable} item xs={12 / 7}>
                      <span className={classes.columnTitle}>
                        {intl.formatMessage(messages.hallAbbreviation)}
                      </span>
                    </Grid>
                    <Grid className={classes.headTable} item xs={12 / 7}>
                      <span className={classes.columnTitle}>
                        {intl.formatMessage(messages.mainHallAbbreviation)}
                      </span>
                    </Grid>
                    <Grid className={classes.headTable} item xs={12 / 7}>
                      <span className={classes.columnTitle}>
                        {intl.formatMessage(
                          messages.exhibitionHallAbbreviation
                        )}
                      </span>
                    </Grid>
                    <Grid className={classes.headTable} item xs={12 / 7}>
                      <span className={classes.columnTitle}>
                        {intl.formatMessage(messages.partnerHallAbbreviation)}
                      </span>
                    </Grid>
                    <Grid className={classes.headTable} item xs={12 / 7}>
                      <span className={classes.columnTitle}>
                        {intl.formatMessage(messages.challengeAbbreviation)}
                      </span>
                    </Grid>
                    <Grid className={classes.headTable} item xs={12 / 7}>
                      <span className={classes.columnTitle}>
                        {intl.formatMessage(messages.coffeeHallAbbreviation)}
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
                {[0, 1, 2, 3].map((index) => (
                  <Grid item xs={12} key={index}>
                    <Grid container>
                      <Grid className={classes.headTable} item xs={12 / 7}>
                        <span className={classes.numberRow}>{index + 1}</span>
                      </Grid>
                      {[0, 1, 2, 3, 4, 5].map((column, ind) => (
                        <Grid
                          keyy={ind}
                          className={
                            value === column
                              ? classes.questionActive
                              : classes.questionContainer
                          }
                          item
                          xs={12 / 7}
                        />
                      ))}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
          <Tabs
            scrollButtons="auto"
            variant="scrollable"
            aria-label="scrollable auto tabs example"
            value={value}
            className={classes.tabs}
            onChange={handleTabChange}
          >
            <Tab
              label={
                <div className={`${classes.label} ${value === 0 && 'active'}`}>
                  <div className={classes.imageMaskContainer}>
                    <div className={classes.chairMask} />
                  </div>
                  <span>{intl.formatMessage(messages.hall)}</span>
                </div>
              }
            />
            <Tab
              label={
                <div className={`${classes.label} ${value === 1 && 'active'}`}>
                  <div className={classes.imageMaskContainer}>
                    <div className={classes.chairMask} />
                  </div>
                  <span>{intl.formatMessage(messages.mainHall)}</span>
                </div>
              }
            />
            <Tab
              label={
                <div className={`${classes.label} ${value === 2 && 'active'}`}>
                  <div className={classes.imageMaskContainer}>
                    <div className={classes.chairMask} />
                  </div>
                  <span>{intl.formatMessage(messages.exhibitionHall)}</span>
                </div>
              }
            />
            <Tab
              label={
                <div className={`${classes.label} ${value === 3 && 'active'}`}>
                  <div className={classes.imageMaskContainer}>
                    <div className={classes.chairMask} />
                  </div>
                  <span>{intl.formatMessage(messages.partnerHall)}</span>
                </div>
              }
            />
            <Tab
              label={
                <div className={`${classes.label} ${value === 4 && 'active'}`}>
                  <div className={classes.imageMaskContainer}>
                    <div className={classes.chairMask} />
                  </div>
                  <span>{intl.formatMessage(messages.challenge)}</span>
                </div>
              }
            />
            <Tab
              label={
                <div className={`${classes.label} ${value === 5 && 'active'}`}>
                  <div className={classes.imageMaskContainer}>
                    <div className={classes.chairMask} />
                  </div>
                  <span>{intl.formatMessage(messages.coffeeHall)}</span>
                </div>
              }
            />
          </Tabs>
          <TabPanel value={value} index={0}>
            <Hall
              questions={formik.values.questionshall}
              correctAnswers={correctAnswersHall}
              setCorrectAnswers={setCorrectAnswersHall}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <MainRoom
              questions={formik.values.questionsmainRoom}
              correctAnswers={correctAnswersMainRoom}
              setCorrectAnswers={setCorrectAnswersMainRoom}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ExhibitionRoom
              questions={formik.values.questionsexhibitionRoom}
              correctAnswers={correctAnswersExhibition}
              setCorrectAnswers={setCorrectAnswersExhibition}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <PartnerRoom
              questions={formik.values.questionspartnerRoom}
              correctAnswers={correctAnswersPartner}
              setCorrectAnswers={setCorrectAnswersPartner}
            />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Challenge
              questions={formik.values.questionschallenge}
              correctAnswers={correctAnswersChallenge}
              setCorrectAnswers={setCorrectAnswersChallenge}
            />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <CoffeeRoom
              questions={formik.values.questionscoffeeRoom}
              correctAnswers={correctAnswersCoffee}
              setCorrectAnswers={setCorrectAnswersCoffee}
            />
          </TabPanel>
        </div>
        {submitButtonFormik}
      </div>
    </FormikProvider>
  );
};

export { Form as CreateFormQuiz };
