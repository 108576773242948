import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  edit: {
    id: 'components.Exhibition.Cards.Edit.edit',
    defaultMessage: 'Editar'
  },
  delete: {
    id: 'components.Exhibition.Cards.Edit.delete',
    defaultMessage: 'Eliminar'
  },
  areYouSureDelete: {
    id: 'components.Exhibition.Cards.Edit.areYouSureDelete',
    defaultMessage: '¿ESTÁS SEGURO DE QUERER ELIMINAR EL PRODUCTO:'
  },
  deleteButton: {
    id: 'components.Exhibition.Cards.Edit.deleteButton',
    defaultMessage: 'ELIMINAR'
  }
});
