import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: '0.75rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    paddingLeft: '10px'
  },
  picker: {
    display: 'block',
    height: '158px',
    width: '158px',
    textAlign: 'center',
    border: '1px solid #009FDA',
    borderRadius: '50%',
    color: '#009FDA',
    paddingTop: '30px',
    backgroundColor: '#F9F9F9',
    transition: 'background-color 300ms',
    cursor: 'pointer',
    '&.error': {
      color: theme.palette.error.main,
      border: `1px solid ${theme.palette.error.main}`
    },
    '&.disabled': {
      cursor: 'auto',
      opacity: theme.palette.action.disabledOpacity
    },
    '&:hover': {
      backgroundColor: '#2AA4D526'
    },
    '&.disabled:hover': {
      backgroundColor: '#F9F9F9'
    },
    marginBottom: '5px'
  },
  pickerWithImage: {
    padding: 0,
    overflow: 'hidden'
  },
  defaultAvatar: {
    height: '50%',
    width: '50%',
    marginBottom: '-10px',
    '&.error': {
      color: '#00040F80'
    }
  },
  fileInput: {
    visibility: 'hidden',
    display: 'block',
    height: 0,
    width: '5px',
    pointerEvents: 'none'
  },
  image: {
    height: '100%',
    width: '100%',
    objectFit: 'cover'
  }
}));
