import { useIntl } from 'react-intl';
import { Typography, Grid, Box, Select, MenuItem } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { messages } from './LicensesMessages';
import { useStyles } from './LicensesStyles';
import { Edit as License } from 'components/Licenses/Cards/Edit';
import { Create as CreateLicenseCard } from 'components/Licenses/Cards/Create';
import { LicenseHistory } from './LicenseHistory';
import { useState, useEffect } from 'react';
import { changeFormatDate } from 'utils/helpers';
import { v4 as uuidv4 } from 'uuid';
import { useDashboard } from 'components/Dashboard';
import { RefreshControl } from 'components/RefreshControl/RefreshControl';

const Licenses = () => {
  const intl = useIntl();
  const classes = useStyles();
  const [showLicenseHistory, setShowLicenseHistory] = useState(false);
  const { licenses, fetchLicences } = useDashboard();

  const [historySelectedLicense, setHistorySelectedLicense] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(9);
  const [paginationData, setPaginationData] = useState({
    totalLicenses: 0,
    activePage: 1
  });
  const { activePage, totalLicenses } = paginationData;

  const handleHideLicenseHistory = () => setShowLicenseHistory(false);
  const handleShowLicenseHistory = () => setShowLicenseHistory(true);
  const getInformationComplete = () => {
    fetchLicences();
  };
  const handleChangePage = (_, value) => {
    setPaginationData({
      ...paginationData,
      activePage: value
    });
  };
  const handleLicenseHistoryClick = (licenseId) => {
    const licenseHistory = licenses.filter(
      (license) => license.id === licenseId
    )[0].history;

    setHistorySelectedLicense(licenseHistory);
    handleShowLicenseHistory();
  };

  useEffect(() => {
    getInformationComplete();
  }, []);

  useEffect(() => {
    setPaginationData({ ...paginationData, totalLicenses: licenses.length });
  }, [licenses]);

  return (
    <>
      <LicenseHistory
        historyLicense={historySelectedLicense}
        show={showLicenseHistory}
        handleHide={handleHideLicenseHistory}
      />
      <Grid>
        <Grid container direction="row" justify="space-between">
          <Grid>
            <Typography className={classes.title}>
              {intl.formatMessage(messages.title)}
            </Typography>
            <Typography className={classes.subTitle}>
              {intl.formatMessage(messages.subTitle)}
            </Typography>
          </Grid>
          <RefreshControl refreshFunction={getInformationComplete} />
        </Grid>

        <Box className={classes.cardsContainer}>
          <Grid container justify="flex-start" spacing={3}>
            {activePage === 1 ? <CreateLicenseCard /> : null}
            {licenses
              .slice(
                (activePage - 1) * itemsPerPage - (activePage > 1 ? 1 : 0),
                (activePage - 1) * itemsPerPage + (itemsPerPage - 1)
              )
              .map((license, index) => (
                <License
                  key={uuidv4()}
                  idCard={license.id ? license.id.substr(4, 5) : ''}
                  idLicense={license.id}
                  positionInArray={
                    index -
                    (activePage > 1 ? 1 : 0) +
                    itemsPerPage * (activePage - 1)
                  }
                  status={license.status}
                  activation={
                    license.licenseDate
                      ? changeFormatDate(license.licenseDate)
                      : ''
                  }
                  handleHistoryClick={handleLicenseHistoryClick}
                  licenseDetails={
                    license.status
                      ? license
                      : {
                          ...license,
                          businessName: license.businessName.split('_')[0]
                        }
                  }
                />
              ))}
          </Grid>
          <br />
          <Box display="flex" justifyContent="center">
            <Pagination
              onChange={handleChangePage}
              count={Math.ceil((totalLicenses + 1) / itemsPerPage)}
              color="primary"
              page={activePage}
            />
            <Select
              className={classes.paginationSelect}
              size="small"
              onChange={(e) => {
                setItemsPerPage(e.target.value);
                setPaginationData({
                  ...paginationData,
                  activePage: 1
                });
              }}
              value={itemsPerPage}
            >
              {[3, 9, 15, 21, 27].map((n) => (
                <MenuItem key={`${n}`} value={n}>
                  {n} / {intl.formatMessage(messages.page)}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <br />
        </Box>
      </Grid>
    </>
  );
};

export { Licenses };
