import { Box, Grid, Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { useIntl } from 'react-intl';
import EventIcon from '@material-ui/icons/Event';
import { messages } from './CreateMessages';
import { useStyles } from './CreateStyles';
import { Images } from 'assets';

const Create = withRouter(({ history }) => {
  const intl = useIntl();
  const classes = useStyles();
  const onClick = () => history.push('/createEvent');

  return (
    <Grid item lg={4} md={6} sm={12} xs={12} onClick={onClick}>
      <Box className={classes.Container}>
        <Box className={classes.Content}>
          <Box className={classes.imgBox}>
            <EventIcon className={classes.iconCalendar} />
          </Box>
          <Box className={classes.info}>
            <Typography className={classes.title}>
              {intl.formatMessage(messages.title)}
            </Typography>
            <Typography className={classes.subTitle}>
              {intl.formatMessage(messages.subTitle)}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.Actions}>
          <Box className={classes.addBox}>
            <Box className={classes.imgAddBox}>
              <img src={Images.add} className={classes.addIcon} alt="add" />
            </Box>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
});

export { Create };
