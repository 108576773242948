import * as yup from 'yup';
import { messages } from './RightMessages';

export const getValidationSchema = (intl) => {
  const requiredErrorMessage = intl.formatMessage(messages.isRequiredError);
  const invalidUrlError = intl.formatMessage(messages.invalidUrlError);
  const minError = intl.formatMessage(messages.minPartners);
  const onlyIntegersErrorMessage = intl.formatMessage(messages.onlyIntegers);

  return yup.object({
    videoId: yup.string(),
    plataform: yup.string(),
    name: yup
      .string()
      .trim()
      .required(requiredErrorMessage),
    limitParticipants: yup.string().required(requiredErrorMessage),
    amount: yup.number().integer(onlyIntegersErrorMessage),
    partners: yup.array(yup.object()).min(1, minError)
  });
};
