import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
  const iconContainer = {
    flexWrap: 'nowrap',
    cursor: 'pointer',
    '&:hover $actionButtonImageContainer': {
      backgroundColor: theme.palette.grey[200]
    }
  };

  return {
    container: {
      marginBottom: '27px',
      marginLeft: 'auto',
      marginRight: 'auto',
      background: '#ffffff',
      padding: '0px',
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '400px',
      height: '169px',
      boxShadow: '0px 0px 5px #00000029',
      borderRadius: '5px'
    },
    content: {
      marginTop: '16px',
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      width: '100%'
    },
    info: {
      marginLeft: '17px',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    },
    imgBox: {
      marginLeft: '14px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: '0px 3px 6px #00000029',
      height: '80px',
      width: '80px',
      minWidth: '80px',
      borderRadius: '40px'
    },
    title: {
      font: 'normal normal bold 15px/20px Roboto',
      letterSpacing: '0px',
      color: theme.palette.primary,
      maxWidth: '250px',
      overflow: 'hidden',
      whiteSpace: 'noWrap',
      textOverflow: 'ellipsis'
    },
    country: {
      font: 'normal normal normal 15px/20px Roboto',
      letterSpacing: '0px',
      color: theme.palette.secondary
    },
    date: {
      textAlign: 'left',
      font: 'normal normal normal 15px/20px Roboto',
      color: '#989898',
      letterSpacing: '0px'
    },
    assign: {
      textAlign: 'left',
      font: 'normal normal normal 15px/20px Roboto',
      color: theme.palette.secondary.main,
      maxWidth: '250px',
      overflow: 'hidden',
      whiteSpace: 'noWrap',
      textOverflow: 'ellipsis'
    },
    quizLogo: {
      width: '30px',
      height: '40px'
    },
    actions: {
      height: '25%',
      display: 'flex',
      alignItems: 'center',
      borderTop: '1px solid #E6E6E6'
    },
    image: {
      height: '100%',
      width: '100%',
      objectFit: 'cover'
    },
    actionsContainer: {
      paddingLeft: theme.spacing(2)
    },
    edit: {
      color: theme.palette.secondary.main,
      ...iconContainer
    },
    delete: {
      color: '#989898',
      ...iconContainer
    },
    actionButtonImageContainer: {
      borderRadius: '50%',
      padding: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '5px',
      boxSizing: 'content-box',
      transition: 'all 300ms'
    },
    actionButtonImage: {
      width: '21px',
      height: '21px'
    },
    imgDefault: {
      height: '40px',
      width: '40px'
    },
    img: {
      height: '100%',
      width: '100%',
      borderRadius: '50%'
    }
  };
});

export { useStyles };
