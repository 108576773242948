import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  ok: {
    id: 'components.ModalMessages.ModalErrorMessage.ok',
    defaultMessage: 'Ok'
  },
  error: {
    id: 'components.ModalMessages.ModalErrorMessage.error',
    defaultMessage: 'Ha ocurrido un error'
  },
  500: {
    id: 'components.ModalMessages.ModalErrorMessage.internalServerError',
    defaultMessage:
      'Ha ocurrido un error interno en el servidor. Vuelve a intentarlo'
  },
  409: {
    id: 'components.ModalMessages.ModalErrorMessage.duplicatedError',
    defaultMessage: 'El registro ya se encuentra en la base de datos'
  },
  400: {
    id: 'components.ModalMessages.ModalErrorMessage.wrongRequest',
    defaultMessage: 'Peticion incorrecta'
  },
  401: {
    id: 'components.ModalMessages.ModalErrorMessage.notAuthorized',
    defaultMessage: 'No estas autorizado'
  },
  403: {
    id: 'components.ModalMessages.ModalErrorMessage.insufficientPrivileges',
    defaultMessage: 'No cuentas con los privilegios suficientes'
  },
  404: {
    id: 'components.ModalMessages.ModalErrorMessage.elementNotFound',
    defaultMessage: 'Recurso no encontrado'
  },
  noEventsWithoutConferences: {
    id: 'components.ModalMessages.ModalErrorMessage.noEventsWithoutConferences',
    defaultMessage: 'No tienes eventos disponibles para agregar conferencias'
  },
  'Network Error': {
    id: 'components.ModalMessages.ModalErrorMessage.NetworkError',
    defaultMessage: 'Tienes problemas de conexión'
  },
  createLeastOneQuestion: {
    id: 'components.ModalMessages.ModalErrorMessage.createLeastOneQuestion',
    defaultMessage: 'Se requiere crear al menos una pregunta'
  },
  haveLeastOneQuestion: {
    id: 'components.ModalMessages.ModalErrorMessage.haveLeastOneQuestion',
    defaultMessage: 'Se requiere tener al menos una pregunta'
  }
});
