import { useIntl } from 'react-intl';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  ButtonBase,
  Button,
  Radio
} from '@material-ui/core';
import { ExpandMore, Person } from '@material-ui/icons';
import { useStyles } from './ConferencesStyles';
import {
  ImagePicker,
  TextField,
  Select,
  TimePicker
} from 'components/CreationForm';
import { messages } from './ConferencesMessages.js';
import { Images } from 'assets';
import { Field, FieldArray } from 'formik';
import { TextArea } from 'components/CreationForm/Right/inputs/TextArea/TextArea';
import { Fragment } from 'react';
import { deleteConference } from 'providers/api';
import { useLoader } from '@octopy/react-loader';
import { useModal } from '@octopy/react-modal';
import { getDataError, createErrorModalConfig } from 'utils/helpers';
import { useDashboard } from 'components/Dashboard';
import { formatDate, verifyHourAnticipation } from 'utils/helpers';

export const Conferences = ({
  conferences,
  setFieldValue,
  eventId,
  availableDates = [],
  updateConference
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const { handleOpenModal } = useModal();
  const { handleShowLoader } = useLoader();
  const { addNumberOfEventConferences } = useDashboard();

  const handleDelete = async (conference, deleteAccordion) => {
    if (conference.id) {
      try {
        handleShowLoader(true);
        await deleteConference(conference.id);
        addNumberOfEventConferences(eventId, -1);
        deleteAccordion();
      } catch (request) {
        const { code, message } = getDataError(request);

        handleOpenModal(createErrorModalConfig(code, message));
      } finally {
        handleShowLoader(false);
      }
    } else {
      deleteAccordion();
    }
  };

  return (
    <div className={classes.container}>
      <FieldArray name="conferences">
        {({ remove, push }) =>
          conferences.map((conference, index, arr) => (
            <Fragment
              key={`${conference.id || conference.key}ConferenceAccordion`}
            >
              <Accordion className={classes.accordion}>
                <AccordionSummary
                  className={classes.summary}
                  expandIcon={<ExpandMore />}
                >
                  <Typography className={classes.title}>
                    {intl.formatMessage(messages.accordionLabel)}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  <div className={classes.left}>
                    <Field name={`conferences.${index}.image`}>
                      {({ field: { value, name } }) => (
                        <ImagePicker
                          label={intl.formatMessage(messages.imageLabel)}
                          imageUrl={value?.blobUrl || conference.imageUrl}
                          onChange={(image) => setFieldValue(name, image)}
                          Icon={Person}
                          id={conference.id || conference.key}
                        />
                      )}
                    </Field>
                  </div>
                  <div className={classes.right}>
                    <div className={classes.rightTopRow}>
                      <div className={classes.innerRow}>
                        {[
                          'speakerName',
                          'speakerEducation',
                          'speakerBackground'
                        ].map((name) => (
                          <Field name={`conferences.${index}.${name}`}>
                            {({ field, meta: { error, touched } }) => (
                              <TextField
                                defaultValue={field.value}
                                name={field.name}
                                onBlur={(event) => {
                                  field.onBlur(event);
                                  field.onChange(event);
                                }}
                                error={error}
                                touched={touched}
                                className={classes.input}
                                label={intl.formatMessage(
                                  messages[`${name}Label`]
                                )}
                              />
                            )}
                          </Field>
                        ))}
                      </div>
                      <div className={classes.innerRow}>
                        <Field name={`conferences.${index}.speakerResume`}>
                          {({ field, meta: { error, touched } }) => (
                            <TextArea
                              value={field.value}
                              name={field.name}
                              onBlur={(event) => {
                                field.onBlur(event);
                                field.onChange(event);
                              }}
                              error={error}
                              touched={touched || conference.triedToPush}
                              className={classes.input}
                              label={intl.formatMessage(
                                messages.speakerResumeLabel
                              )}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className={classes.rightBottomRow}>
                      <div className={classes.innerRow}>
                        <Field name={`conferences.${index}.date`}>
                          {({ field, meta: { error, touched } }) => (
                            <Select
                              {...field}
                              error={error}
                              touched={touched}
                              className={classes.input}
                              label={intl.formatMessage(messages.dateLabel)}
                              options={availableDates.map((date) => ({
                                label: date,
                                value: date
                              }))}
                            />
                          )}
                        </Field>
                        <Field
                          validate={(value) => {
                            const selectedDateIsToday =
                              formatDate(new Date()) === conference.date;

                            const error =
                              selectedDateIsToday &&
                              verifyHourAnticipation(value, 0);

                            return (
                              error &&
                              intl.formatMessage(messages.anticipationError)
                            );
                          }}
                          name={`conferences.${index}.time`}
                        >
                          {({
                            field: { value, name },
                            meta: { error, touched },
                            form: { setFieldValue }
                          }) => (
                            <TimePicker
                              className={classes.input}
                              value={value}
                              error={error}
                              touched={touched}
                              onChange={(value) => setFieldValue(name, value)}
                              label={intl.formatMessage(messages.timeLabel)}
                            />
                          )}
                        </Field>
                      </div>
                      <div className={classes.innerRow}>
                        <Field name={`conferences.${index}.streamId`}>
                          {({ field, meta: { error, touched } }) => (
                            <TextField
                              defaultValue={field.value}
                              name={field.name}
                              onBlur={(event) => {
                                field.onBlur(event);
                                field.onChange(event);
                              }}
                              error={error}
                              touched={touched || conference.triedToPush}
                              className={classes.input}
                              label={intl.formatMessage(
                                messages['streamIdLabel']
                              )}
                              disabled={conference.pushed}
                            />
                          )}
                        </Field>
                        <Field name={`conferences.${index}.streamPlatform`}>
                          {({ field, form }) => (
                            <div
                              className={`${classes.input} ${classes.radioGroup}`}
                            >
                              <Radio
                                className={classes.radio}
                                checked={field.value === 'youtube'}
                                value="youtube"
                                name={field.name}
                                onChange={form.handleChange}
                              />
                              <img
                                src={Images.youtube}
                                alt="youtube"
                                className={classes.platformLogoImage}
                              />
                              <Radio
                                className={classes.radio}
                                checked={field.value === 'vimeo'}
                                value="vimeo"
                                name={field.name}
                                onChange={form.handleChange}
                              />
                              <img
                                src={Images.vimeo}
                                alt="vimeo"
                                className={classes.platformLogoImage}
                              />
                            </div>
                          )}
                        </Field>
                      </div>
                      <div className={classes.innerRow}>
                        {['chatIframe', 'description'].map((name) => (
                          <Field
                            key={`${name}Input`}
                            name={`conferences.${index}.${name}`}
                          >
                            {({ field, meta: { error, touched } }) => (
                              <TextArea
                                value={field.value}
                                name={field.name}
                                onBlur={(event) => {
                                  field.onBlur(event);
                                  field.onChange(event);
                                }}
                                error={error}
                                touched={touched}
                                className={classes.input}
                                label={intl.formatMessage(
                                  messages[`${name}Label`]
                                )}
                              />
                            )}
                          </Field>
                        ))}
                      </div>
                    </div>
                    <div>
                      {arr.length > 1 && (
                        <Button
                          onClick={() =>
                            handleDelete(conference, () => remove(index))
                          }
                          className={classes.deleteButton}
                          variant="contained"
                          color="primary"
                        >
                          {intl.formatMessage(messages.delete)}
                        </Button>
                      )}
                      <Button
                        className={classes.deleteButton}
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          updateConference(conferences[index], index)
                        }
                      >
                        {intl.formatMessage(messages.save)}
                      </Button>
                    </div>
                  </div>
                  <div className={classes.actionButtonsContainer}></div>
                </AccordionDetails>
              </Accordion>
              {index === arr.length - 1 && (
                <div className={classes.actionButtonsContainer}>
                  <ButtonBase
                    onClick={() =>
                      push({
                        key: (conference.key || 0) + 1,
                        imageUrl: '',
                        image: {
                          file: null,
                          blobUrl: ''
                        },
                        speakerName: '',
                        speakerResume: '',
                        speakerEducation: '',
                        speakerBackground: '',
                        date: '',
                        time: new Date(),
                        description: '',
                        streamId: '',
                        streamPlatform: 'vimeo',
                        chatIframe: ''
                      })
                    }
                    className={classes.actionButton}
                  >
                    <img
                      className={classes.actionButtonImage}
                      src={Images.addCircle}
                      alt="addIcon"
                    />
                  </ButtonBase>
                </div>
              )}
            </Fragment>
          ))
        }
      </FieldArray>
    </div>
  );
};
