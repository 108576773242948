import { withRouter } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Typography, Grid } from '@material-ui/core';
import { Images } from 'assets';
import { useStyles } from './ConferencesCardStyles';
import { messages } from './ConferencesCardMessages';
import { useModal } from '@octopy/react-modal';
import { ModalButton, ModalText } from 'components/ModalMessages';

const ConferencesCard = withRouter(
  ({ history, event, handleDeleteConferences }) => {
    const intl = useIntl();
    const classes = useStyles({ icon: Images.event });
    const { handleOpenModal } = useModal();

    const handleDeleteClick = () => {
      const modalTitle = (
        <ModalText>
          {intl.formatMessage(messages.areYouSureDelete)}{' '}
          <ModalText white>{event.name}</ModalText>?
        </ModalText>
      );

      const modalActionButtons = (
        <ModalButton onClick={handleDeleteConferences}>
          {intl.formatMessage(messages.deleteElement)}
        </ModalButton>
      );

      handleOpenModal({
        title: modalTitle,
        actionButtons: modalActionButtons,
        configProps: { maxWidth: 'xs' }
      });
    };

    return (
      <Grid item lg={4} md={6} sm={12} xs={12}>
        <div className={classes.container}>
          <div className={classes.info}>
            <div className={classes.imageMaskContainer}>
              <div className={classes.imageMask} />
            </div>
            <div className={classes.textContainer}>
              <Typography className={classes.title}>
                Conferencias {event.name}
              </Typography>
              <div className={classes.event}>
                Nombre del evento: {event.name}
              </div>
              <div className={classes.conferencesQuantityLabel}>
                Total de conferencias:
              </div>
              <div className={classes.conferencesQuantity}>
                <img alt="event" src={Images.event} />
                <span>{event.conferencesQuantity}</span>
              </div>
            </div>
          </div>
          <div className={classes.actions}>
            <Grid container className={classes.actionsContainer}>
              <Grid
                className={classes.edit}
                alignItems="center"
                item
                container
                onClick={() =>
                  history.push({
                    pathname: `/EditConferences/${event.id}`,
                    state: { event }
                  })
                }
                xs={4}
              >
                <div className={classes.actionButtonImageContainer}>
                  <img
                    alt="edit"
                    src={Images.Pencil}
                    className={classes.actionButtonImage}
                  />
                </div>
                <Typography component="span">
                  {intl.formatMessage(messages.edit)}
                </Typography>
              </Grid>
              <Grid
                className={classes.delete}
                alignItems="center"
                item
                container
                xs={4}
                onClick={handleDeleteClick}
              >
                <div className={classes.actionButtonImageContainer}>
                  <img
                    alt="delete"
                    src={Images.Trash}
                    className={classes.actionButtonImage}
                  />
                </div>
                <Typography component="span">
                  {intl.formatMessage(messages.deleteElement)}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </div>
      </Grid>
    );
  }
);

export { ConferencesCard };
