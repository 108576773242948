import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.Licenses.Forms.ColorForm.title',
    defaultMessage: 'Configurar licencia Branding'
  },
  editTitle: {
    id: 'components.Licenses.Forms.ColorForm.editTitle',
    defaultMessage: 'Actualizar stand'
  },
  colorTexts: {
    id: 'components.Licenses.Forms.ColorForm.colorTexts',
    defaultMessage: 'Color textos'
  },
  titles: {
    id: 'components.Licenses.Forms.ColorForm.titles',
    defaultMessage: 'Títulos'
  },
  subtitles1: {
    id: 'components.Licenses.Forms.ColorForm.subtitles1',
    defaultMessage: 'Subtitulos'
  },
  subtitles2: {
    id: 'components.Licenses.Forms.ColorForm.subtitles2',
    defaultMessage: 'Subtitulos 2'
  },
  colorBottoms: {
    id: 'components.Licenses.Forms.ColorForm.colorBottoms',
    defaultMessage: 'Color botones'
  },
  default: {
    id: 'components.Licenses.Forms.ColorForm.default',
    defaultMessage: 'Default'
  },
  hover: {
    id: 'components.Licenses.Forms.ColorForm.Hover',
    defaultMessage: 'hover'
  },

  submitButtonLabel: {
    id: 'components.Licenses.Forms.ColorForm.submitButtonLabel',
    defaultMessage: 'GUARDAR'
  },
  editSubmitButtonLabel: {
    id: 'components.Licenses.Forms.ColorForm.editSubmitButtonLabel',
    defaultMessage: 'ACTUALIZAR'
  },
  AreYouSureYouWantToContinue: {
    id: 'components.Licenses.Forms.ColorForm.AreYouSureYouWantToContinue',
    defaultMessage: '¿ESTÁ SEGURO QUE DESEA CAMBIAR LOS COLORES POR DEFAULT?'
  },
  ok: {
    id: 'components.Licenses.Forms.ColorForm.ok',
    defaultMessage: 'Ok'
  },
  cancel: {
    id: 'components.Licenses.Forms.ColorForm.cancel',
    defaultMessage: 'Cancelar'
  },
  urlVideo: {
    id: 'components.Licenses.Forms.ColorForm.urlVideo',
    defaultMessage: 'URL del video'
  }
});
