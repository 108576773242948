import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.Analytics.ParticipantsByEvents.title',
    defaultMessage: 'Numero de participantes'
  },
  yTitle: {
    id: 'components.Analytics.ParticipantsByEvents.yLabel',
    defaultMessage: 'Participantes'
  }
});
