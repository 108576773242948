import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Typography, Grid, Box, Select, MenuItem } from '@material-ui/core';
import { messages } from './PrincipalMessages';
import { useStyles } from './PrincipalStyles';
import { CreateConferenceCard } from './CreateConferenceCard';
import { ConferencesCard } from './ConferencesCard';
import { useNoEvents } from 'components/NoEvents/hooks/useNoEvents';
import { Images } from 'assets';
import { useDashboard } from 'components/Dashboard';
import { useModal } from '@octopy/react-modal';
import {
  getDataError,
  createErrorModalConfig,
  createSuccessModalConfig
} from 'utils/helpers';
import { deleteConference, getEventConferences } from 'providers/api/requests';
import { useLoader } from '@octopy/react-loader';
import { RefreshControl } from 'components/RefreshControl/RefreshControl';
import { usePagination } from 'hooks/usePagination';
import { Pagination } from '@material-ui/lab';
import { ExpandMore } from '@material-ui/icons';

const Principal = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { show: showNoEvents } = useNoEvents();
  const { events, fetchEvents, addNumberOfEventConferences } = useDashboard();
  const { handleShowLoader } = useLoader();
  const { handleOpenModal } = useModal();

  const [totalConferences, setTotalConferences] = useState(0);
  const [eventsWithConferences, setEventsWithConferences] = useState([]);

  const {
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage
  } = usePagination();

  useEffect(() => {
    if (events.length) {
      setTotalConferences(
        events.reduce((total, event) => total + event.conferencesQuantity, 0)
      );

      setEventsWithConferences(
        events.filter((event) => !!event.conferencesQuantity)
      );
    }
  }, [events]);

  const handleDeleteEventConferences = async ({
    id: eventId,
    name: eventName
  }) => {
    try {
      handleShowLoader(true);

      const conferences = await getEventConferences(eventId);

      const deleteFunctions = conferences.map((conference) => async () => {
        try {
          const { id: conferenceId } = conference;

          await deleteConference(conferenceId);

          addNumberOfEventConferences(eventId, -1);
        } catch (code) {
          return code;
        }
      });

      const responses = await Promise.all(deleteFunctions.map((fn) => fn()));

      const errors = responses.filter((error) => !!error);

      if (errors.length) throw [...errors].pop();

      handleOpenModal(
        createSuccessModalConfig(messages.deletedSuccessfully, eventName, 'ok')
      );
    } catch (request) {
      const { code, message } = getDataError(request);

      handleOpenModal(createErrorModalConfig(code, message));
    } finally {
      handleShowLoader(false);
    }
  };

  const getData = () => {
    fetchEvents();
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className={`${classes.container} ${!showNoEvents && 'minHeight'}`}>
        <div className={classes.header}>
          <Grid container direction="row" justify="space-between">
            <Grid>
              <Typography className={classes.title}>
                {intl.formatMessage(messages.title)}
              </Typography>
              <Typography className={classes.subtitle}>
                {intl.formatMessage(messages.subtitle)}
              </Typography>
            </Grid>
            <RefreshControl refreshFunction={getData} />
          </Grid>
          {!showNoEvents && (
            <div className={classes.conferencesQuantity}>
              <img alt="event" src={Images.event} />
              <span>
                {intl.formatMessage(messages.conferencesQuantity)}{' '}
                {totalConferences}
              </span>
            </div>
          )}
        </div>
        {!showNoEvents && (
          <>
            <Box className={classes.cardsContainer}>
              <Grid container justify="flex-start" spacing={3}>
                {page === 0 && <CreateConferenceCard />}
                {eventsWithConferences
                  .slice(
                    page * rowsPerPage - +!!page,
                    page * rowsPerPage + rowsPerPage - 1
                  )
                  .map((event) => (
                    <ConferencesCard
                      key={event.id}
                      event={event}
                      handleDeleteConferences={() =>
                        handleDeleteEventConferences(event)
                      }
                    />
                  ))}
              </Grid>
            </Box>
            {!!eventsWithConferences.length && (
              <div className={classes.paginationContainer}>
                <Pagination
                  count={Math.ceil(
                    (eventsWithConferences.length + 1) / rowsPerPage
                  )}
                  page={page + 1}
                  onChange={handleChangePage}
                  defaultPage={0}
                  size="small"
                  variant="outlined"
                  shape="rounded"
                />
                <Select
                  className={classes.paginationSelect}
                  onChange={handleChangeRowsPerPage}
                  value={rowsPerPage}
                  IconComponent={ExpandMore}
                >
                  {[3, 9, 15, 21, 27].map((n) => (
                    <MenuItem key={`${n}PaginationOption`} value={n}>
                      {n} / {intl.formatMessage(messages.page)}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export { Principal };
