import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DashboardContext } from './DashboardContext';
import {
  getEvents,
  deleteEvent,
  addEvent,
  updateEvent,
  getLicenses,
  deleteLicense,
  postLicense,
  updateLicenseStatus,
  updateLicenseRenovation,
  updateSpecificLicense,
  getCoordinators,
  deleteCoordinator,
  updateSpecificUser,
  getOrganizations,
  deleteOrganization,
  getPartners,
  deletePartner,
  getChallenges,
  getChallengesSaved,
  deleteChallenge,
  //updateSpecificChallenge,
  updateChallengeById,
  getProducts,
  deleteProduct,
  addOrganization,
  postCoordinator,
  addPartner,
  postProduct,
  updateOrganization,
  updatePartner,
  updateProduct,
  signInUser,
  getUser,
  logOut,
  changeUserPassword,
  updateDataUserConnected,
  addPolicie,
  addCategories,
  addChallenge,
  getPolicies,
  getCategories
} from 'providers/api';
import { useLoader } from '@octopy/react-loader';
import { useNoEvents } from 'components/NoEvents/hooks/useNoEvents';
import { messages } from './DashboardProviderMessages';
import { useAuth } from '@octopy/react-auth';
import { getItem } from 'utils/persistentStorage';
import history from '../../routes/history';
import {
  createSuccessModalConfig,
  createErrorModalConfig,
  getDataError
} from 'utils/helpers';
import { useModal as useModalLib } from '@octopy/react-modal';

const DashboardProvider = (props) => {
  const { children } = props;

  const { handleOpenModal } = useModalLib();
  const { handleNoEventShow } = useNoEvents();
  const { handleShowLoader } = useLoader();
  const { actions: authActions } = useAuth();

  const [events, setEvents] = useState([]);
  const [licenses, setLicenses] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [coordinators, setCoordinators] = useState([]);
  const [partners, setPartners] = useState([]);
  const [challenges, setChallenges] = useState([]);
  const [challengesSaved, setChallengesSaved] = useState([]);
  const [exhibitions, setExhibitions] = useState([]);
  const [user, setUser] = useState();
  const [policies, setPolicies] = useState();
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const checkSession = async () => {
      try {
        handleShowLoader(true);
        const existSession = getItem('session');

        if (existSession) {
          const completeUser = await getUser(existSession.id);

          window.localStorage.removeItem('session');

          window.localStorage.setItem('session', JSON.stringify(completeUser));

          setUser(completeUser);
        }
      } catch (error) {
        window.localStorage.removeItem('session');
        handleShowLoader(false);
      } finally {
        handleShowLoader(false);
      }
    };

    checkSession();
  }, [getItem]);

  useEffect(() => {
    handleNoEventShow(
      user && user.role.name === 'client' && events.length === 0
    );
  }, [events]);

  const changePassword = async (passwordInfo) => {
    try {
      handleShowLoader(true);

      await changeUserPassword(passwordInfo);
    } catch (request) {
      const { code, message } = getDataError(request);

      handleOpenModal(createErrorModalConfig(code, message));

      return code;
    } finally {
      handleShowLoader(false);
    }
  };

  const loginUser = async (user) => {
    try {
      handleShowLoader(true);

      const payload = await signInUser(user);

      authActions.login({ token: payload.access_token, user: payload.user });

      const completeUser = await getUser(payload.user.id);

      window.localStorage.removeItem('session');

      window.localStorage.setItem('session', JSON.stringify(completeUser));
      setUser(completeUser);
    } catch (request) {
      const { code, message } = getDataError(request);

      handleOpenModal(createErrorModalConfig(code, message));

      return code;
    } finally {
      handleShowLoader(false);
    }
  };

  const logoutUser = async () => {
    try {
      handleShowLoader(true);

      await logOut();

      window.localStorage.removeItem('session');
      window.localStorage.removeItem('token');

      setUser(undefined);

      history.push('/');
    } catch (request) {
      const { code, message } = getDataError(request);

      handleOpenModal(createErrorModalConfig(code, message));

      return code;
    } finally {
      handleShowLoader(false);
    }
  };

  const logoutPasswordChange = () => {
    window.localStorage.removeItem('session');
    window.localStorage.removeItem('token');
    setUser(undefined);

    history.push('/');
  };

  const updateDataActiveUser = async (form) => {
    try {
      handleShowLoader(true);
      const response = await updateDataUserConnected(form);

      const { avatar, email } = response?.data.payload || {
        avatar: null,
        email: null
      };

      setUser({
        ...user,
        avatar: !!avatar ? avatar : user.avatar,
        email: !!email ? email : user.email
      });
    } catch (request) {
      const { code, message } = getDataError(request);

      handleOpenModal(createErrorModalConfig(code, message));

      return code;
    } finally {
      handleShowLoader(false);
    }
  };

  const fetchEvents = async () => {
    try {
      handleShowLoader(true);
      const requestedEvents = await getEvents();

      setEvents(requestedEvents);
    } catch (request) {
      const { code, message } = getDataError(request);

      handleOpenModal(createErrorModalConfig(code, message));

      return code;
    } finally {
      handleShowLoader(false);
    }
  };

  const addNewEvent = async (newEvent) => {
    try {
      handleShowLoader(true);
      const inserted = await addEvent(newEvent);
      const auxEvents = [...events];

      auxEvents.push(inserted);

      setEvents(auxEvents);
      handleOpenModal(
        createSuccessModalConfig(messages.EventAdded, inserted.name, 'ok')
      );
    } catch (request) {
      const { code, message } = getDataError(request);

      handleOpenModal(createErrorModalConfig(code, message));

      return code;
    } finally {
      handleShowLoader(false);
    }
  };

  const deleteEventById = async (deletedEvent, index) => {
    try {
      handleShowLoader(true);
      await deleteEvent(deletedEvent.id);
      const auxOrganizations = [...events];

      auxOrganizations.splice(index, 1);

      setEvents(auxOrganizations);
      handleOpenModal(
        createSuccessModalConfig(messages.EventDeleted, deletedEvent.name, 'ok')
      );
    } catch (request) {
      const { code, message } = getDataError(request);

      handleOpenModal(createErrorModalConfig(code, message));

      return code;
    } finally {
      handleShowLoader(false);
    }
  };

  const updateEventById = async (newEvent, id, index) => {
    try {
      handleShowLoader(true);
      const updated = await updateEvent(id, newEvent);

      const auxEvents = [...events];

      auxEvents[index] = updated;

      setEvents(auxEvents);
      handleOpenModal(
        createSuccessModalConfig(messages.EventUpdated, updated.name, 'ok')
      );
    } catch (request) {
      const { code, message } = getDataError(request);

      handleOpenModal(createErrorModalConfig(code, message));

      return code;
    } finally {
      handleShowLoader(false);
    }
  };

  const addNumberOfEventConferences = (eventId, quantity) => {
    setEvents((events) => {
      const event = events.find((event) => event.id === eventId);
      const updatedEvent = {
        ...event,
        conferencesQuantity: event.conferencesQuantity + quantity
      };

      return [...events.filter((event) => event.id !== eventId), updatedEvent];
    });
  };

  const fetchLicences = async () => {
    try {
      handleShowLoader(true);
      const requestedLicenses = await getLicenses();

      setLicenses(requestedLicenses);
    } catch (request) {
      const { code, message } = getDataError(request);

      handleOpenModal(createErrorModalConfig(code, message));

      return code;
    } finally {
      handleShowLoader(false);
    }
  };

  const addNewLicence = async (newLicence) => {
    try {
      handleShowLoader(true);
      const inserted = await postLicense(newLicence);

      const auxLicenses = [...licenses];

      auxLicenses.push(inserted);

      setLicenses(auxLicenses);
      handleOpenModal(
        createSuccessModalConfig(
          messages.LicensesAdded,
          newLicence.businessName,
          'ok'
        )
      );
    } catch (request) {
      const { code, message } = getDataError(request);

      handleOpenModal(createErrorModalConfig(code, message));

      return code;
    } finally {
      handleShowLoader(false);
    }
  };

  const deleteLicenseById = async (deletedLicense, index) => {
    try {
      handleShowLoader(true);
      await deleteLicense(deletedLicense.id);
      const auxLicenses = [...licenses];

      auxLicenses.splice(index, 1);

      setLicenses(auxLicenses);
      handleOpenModal(
        createSuccessModalConfig(
          messages.LicensesDeleted,
          deletedLicense.businessName,
          'ok'
        )
      );
    } catch (request) {
      const { code, message } = getDataError(request);

      handleOpenModal(createErrorModalConfig(code, message));

      return code;
    } finally {
      handleShowLoader(false);
    }
  };

  const updateLicenseStatusById = async (license, index) => {
    try {
      handleShowLoader(true);
      const updated = await updateLicenseStatus(license.id, {
        status: !license.status
      });

      const auxLicenses = [...licenses];

      auxLicenses[index] = updated;

      setLicenses(auxLicenses);
      handleOpenModal(
        createSuccessModalConfig(
          messages.LicensesUpdated,
          !!license.businessName ? license.businessName : updated.businessName,
          'ok'
        )
      );
    } catch (request) {
      const { code, message } = getDataError(request);

      handleOpenModal(createErrorModalConfig(code, message));

      return code;
    } finally {
      handleShowLoader(false);
    }
  };

  const updateSpecificLicenseById = async (license, index) => {
    try {
      handleShowLoader(true);
      const updated = await updateSpecificLicense(license.id, license);
      const auxLicenses = [...licenses];

      auxLicenses[index] = updated;

      setLicenses(auxLicenses);
      handleOpenModal(
        createSuccessModalConfig(
          messages.OrganizationsUpdated,
          license.businessName,
          'ok'
        )
      );
    } catch (request) {
      const { code, message } = getDataError(request);

      handleOpenModal(createErrorModalConfig(code, message));

      return code;
    } finally {
      handleShowLoader(false);
    }
  };

  const updateRenovationLicenseById = async (license, index) => {
    try {
      handleShowLoader(true);
      const updated = await updateLicenseRenovation(license.id, license);
      const auxLicenses = [...licenses];

      auxLicenses[index] = updated;

      setLicenses(auxLicenses);
      handleOpenModal(
        createSuccessModalConfig(
          messages.OrganizationsUpdated,
          license.name,
          'ok'
        )
      );
    } catch (request) {
      const { code, message } = getDataError(request);

      handleOpenModal(createErrorModalConfig(code, message));

      return code;
    } finally {
      handleShowLoader(false);
    }
  };

  const fetchOrganizations = async () => {
    try {
      handleShowLoader(true);
      const requestedOrganizations = await getOrganizations();

      setOrganizations(requestedOrganizations);
    } catch (request) {
      const { code, message } = getDataError(request);

      handleOpenModal(createErrorModalConfig(code, message));

      return code;
    } finally {
      handleShowLoader(false);
    }
  };

  const addNewOrganization = async (newOrganization) => {
    try {
      handleShowLoader(true);
      const inserted = await addOrganization(newOrganization);
      const auxOrganizations = [...organizations];

      auxOrganizations.push(inserted);

      setOrganizations(auxOrganizations);
      handleOpenModal(
        createSuccessModalConfig(
          messages.OrganizationsAdded,
          newOrganization.businessName,
          'ok'
        )
      );
    } catch (request) {
      const { code, message } = getDataError(request);

      handleOpenModal(createErrorModalConfig(code, message));

      return code;
    } finally {
      handleShowLoader(false);
    }
  };

  const deleteOrganizationById = async (deletedOrganization, index) => {
    try {
      handleShowLoader(true);
      await deleteOrganization(deletedOrganization.id);
      const auxOrganizations = [...organizations];

      auxOrganizations.splice(index, 1);

      setOrganizations(auxOrganizations);
      handleOpenModal(
        createSuccessModalConfig(
          messages.OrganizationsDeleted,
          deletedOrganization.businessName,
          'ok'
        )
      );
    } catch (request) {
      const { code, message } = getDataError(request);

      handleOpenModal(createErrorModalConfig(code, message));

      return code;
    } finally {
      handleShowLoader(false);
    }
  };

  const updateOrganizationById = async (newOrganization, index) => {
    try {
      handleShowLoader(true);
      const updated = await updateOrganization(
        newOrganization.id,
        newOrganization
      );
      const auxOrganizations = [...organizations];

      auxOrganizations[index] = updated;

      setOrganizations(auxOrganizations);
      handleOpenModal(
        createSuccessModalConfig(
          messages.OrganizationsUpdated,
          newOrganization.businessName,
          'ok'
        )
      );
    } catch (request) {
      const { code, message } = getDataError(request);

      handleOpenModal(createErrorModalConfig(code, message));

      return code;
    } finally {
      handleShowLoader(false);
    }
  };

  const fetchCoordinators = async () => {
    try {
      handleShowLoader(true);
      const requestedCoordinators = await getCoordinators();

      setCoordinators(requestedCoordinators);
    } catch (request) {
      const { code, message } = getDataError(request);

      handleOpenModal(createErrorModalConfig(code, message));

      return code;
    } finally {
      handleShowLoader(false);
    }
  };

  const addNewCoordinator = async (newCoordinator) => {
    try {
      handleShowLoader(true);
      const inserted = await postCoordinator(newCoordinator);

      const auxCoordinators = [...coordinators];

      auxCoordinators.push(inserted);

      setCoordinators(auxCoordinators);
      handleOpenModal(
        createSuccessModalConfig(
          messages.CoordinatorsAdded,
          newCoordinator.completeName,
          'ok'
        )
      );
    } catch (request) {
      const { code, message } = getDataError(request);

      handleOpenModal(createErrorModalConfig(code, message));

      return code;
    } finally {
      handleShowLoader(false);
    }
  };

  const deleteCoordinatorById = async (deletedCoordinator, index) => {
    try {
      handleShowLoader(true);
      await deleteCoordinator(deletedCoordinator.id);
      const auxCoordinators = [...coordinators];

      auxCoordinators.splice(index, 1);

      setCoordinators(auxCoordinators);
      handleOpenModal(
        createSuccessModalConfig(
          messages.CoordinatorsDeleted,
          deletedCoordinator.name,
          'ok'
        )
      );
    } catch (request) {
      const { code, message } = getDataError(request);

      handleOpenModal(createErrorModalConfig(code, message));

      return code;
    } finally {
      handleShowLoader(false);
    }
  };

  const updateCoordinatorById = async (newCoordinator, index) => {
    try {
      handleShowLoader(true);
      const updated = await updateSpecificUser(
        newCoordinator.id,
        newCoordinator
      );
      const auxCoordinators = [...coordinators];

      auxCoordinators[index] = updated;

      setCoordinators(auxCoordinators);
      handleOpenModal(
        createSuccessModalConfig(
          messages.CoordinatorsUpdated,
          newCoordinator.name,
          'ok'
        )
      );
    } catch (request) {
      const { code, message } = getDataError(request);

      handleOpenModal(createErrorModalConfig(code, message));

      return code;
    } finally {
      handleShowLoader(false);
    }
  };

  const fetchPartners = async () => {
    try {
      handleShowLoader(true);
      const requestedPartners = await getPartners();

      setPartners(requestedPartners);
    } catch (request) {
      const { code, message } = getDataError(request);

      handleOpenModal(createErrorModalConfig(code, message));

      return code;
    } finally {
      handleShowLoader(false);
    }
  };

  const addNewPartner = async (newPartner) => {
    try {
      handleShowLoader(true);
      const inserted = await addPartner(newPartner);
      const auxPartner = [...partners];

      auxPartner.push(inserted);

      setPartners(auxPartner);
      handleOpenModal(
        createSuccessModalConfig(
          messages.PartnersAdded,
          newPartner.businessName,
          'ok'
        )
      );
    } catch (request) {
      const { code, message } = getDataError(request);

      handleOpenModal(createErrorModalConfig(code, message));

      return code;
    } finally {
      handleShowLoader(false);
    }
  };

  const deletePartnerById = async (deletedPartner, index) => {
    try {
      handleShowLoader(true);
      await deletePartner(deletedPartner.id);
      const auxPartners = [...partners];

      auxPartners.splice(index, 1);

      setPartners(auxPartners);
      handleOpenModal(
        createSuccessModalConfig(
          messages.PartnersDeleted,
          deletedPartner.businessName,
          'ok'
        )
      );
    } catch (request) {
      const { code, message } = getDataError(request);

      handleOpenModal(createErrorModalConfig(code, message));

      return code;
    } finally {
      handleShowLoader(false);
    }
  };

  const updatePartnerById = async (newPartner, index) => {
    try {
      handleShowLoader(true);
      const updated = await updatePartner(newPartner.id, newPartner);
      const auxPartner = [...partners];

      auxPartner[index] = updated;

      setPartners(auxPartner);
      handleOpenModal(
        createSuccessModalConfig(
          messages.PartnersUpdated,
          newPartner.businessName,
          'ok'
        )
      );
    } catch (request) {
      const { code, message } = getDataError(request);

      handleOpenModal(createErrorModalConfig(code, message));

      return code;
    } finally {
      handleShowLoader(false);
    }
  };

  const fetchChallenges = async () => {
    try {
      handleShowLoader(true);
      const requestedChallenges = await getChallenges();

      setChallenges(requestedChallenges);
    } catch (request) {
      const { code, message } = getDataError(request);

      handleOpenModal(createErrorModalConfig(code, message));

      return code;
    } finally {
      handleShowLoader(false);
    }
  };

  const fetchChallengesSaved = async (id) => {
    try {
      handleShowLoader(true);
      const requestedChallenges = await getChallengesSaved(id);

      setChallengesSaved(requestedChallenges);
    } catch (request) {
    } finally {
      handleShowLoader(false);
    }
  };

  const addNewChallenge = async (newChallenge) => {
    try {
      handleShowLoader(true);
      const inserted = await addChallenge(newChallenge);
      const auxChallenges = [...challenges];

      auxChallenges.push(inserted);

      setChallenges(auxChallenges);
      handleOpenModal(
        createSuccessModalConfig(messages.ChallengesAdded, '', 'ok')
      );
    } catch (request) {
      const { code, message } = getDataError(request);

      handleOpenModal(createErrorModalConfig(code, message));

      return code;
    } finally {
      handleShowLoader(false);
    }
  };

  const deleteChallengeById = async (deletedChallenge, index) => {
    try {
      handleShowLoader(true);
      await deleteChallenge(deletedChallenge.id);
      const auxChallenges = [...challenges];

      auxChallenges.splice(index, 1);

      setChallenges(auxChallenges);
      handleOpenModal(
        createSuccessModalConfig(
          messages.ChallengesDeleted,
          deletedChallenge.name,
          'ok'
        )
      );
    } catch (request) {
      const { code, message } = getDataError(request);

      handleOpenModal(createErrorModalConfig(code, message));

      return code;
    } finally {
      handleShowLoader(false);
    }
  };

  const updateChallengeByIdSaved = async (id, newChallenge) => {
    try {
      handleShowLoader(true);
      const updated = await updateChallengeById(id, newChallenge);

      handleOpenModal(
        createSuccessModalConfig(messages.ChallengesUpdated, '', 'ok')
      );
    } catch (request) {
      const { code, message } = getDataError(request);

      handleOpenModal(createErrorModalConfig(code, message));

      return code;
    } finally {
      handleShowLoader(false);
    }
  };

  const fetchExhibitions = async () => {
    try {
      handleShowLoader(true);
      const requestedExhibitions = await getProducts();

      setExhibitions(requestedExhibitions);
    } catch (request) {
      const { code, message } = getDataError(request);

      handleOpenModal(createErrorModalConfig(code, message));

      return code;
    } finally {
      handleShowLoader(false);
    }
  };

  const addNewExhibition = async (newExhibition) => {
    try {
      handleShowLoader(true);
      const inserted = await postProduct(newExhibition);
      const auxExhibitions = [...exhibitions];

      auxExhibitions.push(inserted);

      setExhibitions(auxExhibitions);
      handleOpenModal(
        createSuccessModalConfig(
          messages.ExhibitionsAdded,
          newExhibition.productName,
          'ok'
        )
      );
    } catch (request) {
      const { code, message } = getDataError(request);

      handleOpenModal(createErrorModalConfig(code, message));

      return code;
    } finally {
      handleShowLoader(false);
    }
  };

  const deleteExhibitionById = async (deletedExhibition, index) => {
    try {
      handleShowLoader(true);
      await deleteProduct(deletedExhibition.id);
      const auxExhibitions = [...exhibitions];

      auxExhibitions.splice(index, 1);

      setExhibitions(auxExhibitions);
      handleOpenModal(
        createSuccessModalConfig(
          messages.ExhibitionsDeleted,
          deletedExhibition.name,
          'ok'
        )
      );
    } catch (request) {
      const { code, message } = getDataError(request);

      handleOpenModal(createErrorModalConfig(code, message));

      return code;
    } finally {
      handleShowLoader(false);
    }
  };

  const updateExhibitionById = async (newExhibition, index) => {
    try {
      handleShowLoader(true);
      const updated = await updateProduct(newExhibition.id, newExhibition);
      const auxExhibitions = [...exhibitions];

      auxExhibitions[index] = updated;

      setExhibitions(auxExhibitions);
      handleOpenModal(
        createSuccessModalConfig(
          messages.ExhibitionsUpdated,
          newExhibition.name,
          'ok'
        )
      );
    } catch (request) {
      const { code, message } = getDataError(request);

      handleOpenModal(createErrorModalConfig(code, message));

      return code;
    } finally {
      handleShowLoader(false);
    }
  };

  const fetchPolicies = async (id) => {
    try {
      handleShowLoader(true);
      const requestedPolicies = await getPolicies(id);

      setPolicies(requestedPolicies);
    } catch (request) {
      setPolicies('');
      const { code, message } = getDataError(request);

      return code;
    } finally {
      handleShowLoader(false);
    }
  };
  const fetchCategories = async (id) => {
    try {
      handleShowLoader(true);
      const requestedCategories = await getCategories(id);

      setCategories(requestedCategories);
    } catch (request) {
      const { code, message } = getDataError(request);

      return code;
    } finally {
      handleShowLoader(false);
    }
  };

  const addNewPolicie = async (newPolicie) => {
    try {
      handleShowLoader(true);
      const inserted = await addPolicie(newPolicie);

      handleOpenModal(
        createSuccessModalConfig(messages.PolicieAdded, '', 'ok')
      );
    } catch (request) {
      const { code, message } = getDataError(request);

      handleOpenModal(createErrorModalConfig(code, message));

      return code;
    } finally {
      handleShowLoader(false);
    }
  };

  const addNewCategories = async (newCategories) => {
    try {
      handleShowLoader(true);
      const inserted = await addCategories(newCategories);

      handleOpenModal(
        createSuccessModalConfig(messages.CategoriesAdded, '', 'ok')
      );
    } catch (request) {
      const { code, message } = getDataError(request);

      handleOpenModal(createErrorModalConfig(code, message));

      return code;
    } finally {
      handleShowLoader(false);
    }
  };

  const contextValue = useMemo(
    () => ({
      events,
      fetchEvents,
      deleteEventById,
      addNewEvent,
      updateEventById,
      addNumberOfEventConferences,
      licenses,
      fetchLicences,
      deleteLicenseById,
      updateLicenseStatusById,
      updateSpecificLicenseById,
      updateRenovationLicenseById,
      addNewLicence,
      organizations,
      addNewOrganization,
      fetchOrganizations,
      deleteOrganizationById,
      updateOrganizationById,
      coordinators,
      fetchCoordinators,
      addNewCoordinator,
      deleteCoordinatorById,
      updateCoordinatorById,
      partners,
      fetchPartners,
      deletePartnerById,
      updatePartnerById,
      addNewPartner,
      challenges,
      challengesSaved,
      fetchChallenges,
      fetchChallengesSaved,
      deleteChallengeById,
      updateChallengeByIdSaved,
      //updateSpecificChallengeSaved,
      addNewChallenge,
      exhibitions,
      fetchExhibitions,
      addNewExhibition,
      deleteExhibitionById,
      updateExhibitionById,
      user,
      loginUser,
      changePassword,
      logoutUser,
      logoutPasswordChange,
      updateDataActiveUser,
      policies,
      addNewPolicie,
      fetchPolicies,
      categories,
      fetchCategories,
      addNewCategories
    }),
    [
      events,
      fetchEvents,
      deleteEventById,
      addNewEvent,
      updateEventById,
      addNumberOfEventConferences,
      licenses,
      fetchLicences,
      deleteLicenseById,
      updateLicenseStatusById,
      updateSpecificLicenseById,
      updateRenovationLicenseById,
      addNewLicence,
      organizations,
      addNewOrganization,
      fetchOrganizations,
      deleteOrganizationById,
      updateOrganizationById,
      coordinators,
      fetchCoordinators,
      addNewCoordinator,
      deleteCoordinatorById,
      updateCoordinatorById,
      partners,
      fetchPartners,
      deletePartnerById,
      updatePartnerById,
      addNewPartner,
      challenges,
      challengesSaved,
      fetchChallenges,
      fetchChallengesSaved,
      deleteChallengeById,
      updateChallengeByIdSaved,
      //updateSpecificChallengeSaved,
      addNewChallenge,
      exhibitions,
      fetchExhibitions,
      addNewExhibition,
      deleteExhibitionById,
      updateExhibitionById,
      user,
      loginUser,
      logoutUser,
      logoutPasswordChange,
      updateDataActiveUser,
      policies,
      addNewPolicie,
      fetchPolicies,
      categories,
      fetchCategories,
      addNewCategories
    ]
  );

  return (
    <DashboardContext.Provider value={contextValue}>
      {children}
    </DashboardContext.Provider>
  );
};

DashboardProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { DashboardProvider };
