export const templateGetDataExhibitions = {
  id: '_id',
  name: 'name',
  image: 'image',
  slogan: 'slogan',
  description: 'description',
  event: 'event',
  company: 'company',
  chat: 'chat',
  videoId: 'video_id',
  plataform: 'plataform',
  createdBy: 'created_by'
};

export const templateSendDataExhibitions = {
  image: 'logo.file',
  name: 'productName',
  slogan: 'slogan',
  description: 'description',
  video_id: 'videoId',
  plataform: 'plataform',
  event: 'event',
  chat: 'urlChat'
};

export const templateUpdateDataExhibitions = {
  image: 'logo.file',
  name: 'productName',
  slogan: 'slogan',
  description: 'description',
  event: 'event',
  chat: 'urlChat',
  video_id: 'videoId',
  plataform: 'plataform'
};

export const templateGetHeaderExhibitions = {
  headerResponse: {
    code: 'headerResponse.code',
    message: 'headerResponse.message'
  }
};
