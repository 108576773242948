import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2)
  },
  description: {
    color: '#0E0E0E80',
    font: 'normal normal normal 15px/20px Roboto',
    marginBottom: theme.spacing(1)
  }
}));
