import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  Container: {
    background: theme.palette.secondary.main,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    maxWidth: '400px',
    height: '169px',
    boxShadow: '0px 0px 5px #00000029',
    borderRadius: '5px',
    transition: '.3s ease',
    '&:hover': {
      backgroundColor: '#000000',
      cursor: 'pointer',
      '& $iconPerson': {
        transition: '.3s ease',
        color: '#000000'
      }
    }
  },
  Content: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    height: '149px',
    width: '100%'
  },
  Actions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '38px',
    boxShadow: '0px 0px 5px #00000029',
    borderTop: '1px solid #E6E6E6'
  },
  iconPerson: {
    transition: '.3s ease',
    width: '76px',
    height: '59px',
    color: theme.palette.secondary.main
  },
  info: {
    marginLeft: 14,
    marginTop: 15
  },
  imgBox: {
    marginLeft: 14,
    marginTop: 15,
    display: 'flex',
    background: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 3px 6px #00000029',
    height: 80,
    width: 80,
    borderRadius: 40
  },
  title: {
    font: 'normal normal bold 15px/20px Roboto',
    color: '#FFFFFF'
  },
  subTitle: {
    font: 'normal normal normal 15px/20px Roboto',
    color: '#FFFFFF7D'
  },
  addBox: {
    height: '35px',
    borderRadius: '20px',
    background: 'transparent'
  },
  addIcon: {
    color: 'white',
    width: '35px',
    height: '35px'
  },
  link: {
    textDecoration: 'none'
  }
}));

export { useStyles };
