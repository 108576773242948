import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: theme.spacing(1),
    color: theme.palette.secondary.main,
    font: 'normal normal normal 16px/21px Roboto',
    '&:hover': {
      backgroundColor: '#2AA4D526'
    }
  }
}));
